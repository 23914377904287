const getOnRenderAnimation = (
  captionSettings,
  type,
  lineIdx = 0,
  wordIdx = 0
) => {
  var animObject;
  const textSettings = captionSettings.text;
  // console.log("TEEEXT SETTINGS: ", textSettings);

  const lines = textSettings.lines;

  const currentLine = lines ? lines[lineIdx] : null;

  switch (type) {
    case "text":
      animObject = textSettings.anim;
      break;

    case "line":
      if (currentLine) {
        animObject = currentLine.anim;
      }
      break;
  }

  // console.log("SETTINGS: ON RENDER ANIM OBJECT ", animObject);
  return animObject;
};

const getActiveTextAnimation = (
  captionSettings,
  type,
  lineIdx = 0,
  wordIdx = 0
) => {
  var animObject;
  const activeTextSettings = captionSettings.activeText;

  const lines = activeTextSettings?.lines;
  const words = activeTextSettings?.words;

  const currentLine = lines && lineIdx < lines.length ? lines[lineIdx] : null;
  const currentWord = words ? words[0] : null;

  switch (type) {
    case "line":
      if (currentLine) {
        animObject = currentLine.anim;
      }
      break;

    case "word":
      if (currentWord) {
        animObject = currentWord.anim;
      }
      break;
  }
  // console.log("SETTINGS: ACTIVE TEXT ANIM OBJECT ", animObject);
  return animObject;
};

const getActiveTextOptions = (
  captionSettings,
  type,
  lineIdx = 0,
  wordIdx = 0
) => {
  var activeTextObj;
  const activeTextSettings = captionSettings.activeText;

  const lines = activeTextSettings?.lines;
  const words = activeTextSettings?.words;

  const currentLine = lines && lineIdx < lines.length ? lines[lineIdx] : null;
  const currentWord = words ? words[0] : null;

  switch (type) {
    case "line":
      if (currentLine) {
        activeTextObj = currentLine;
      }
      break;

    case "word":
      if (currentWord) {
        activeTextObj = currentWord;
      }
      break;
  }
  // console.log("SETTINGS: ACTIVE TEXT ANIM OBJECT ", animObject);
  return activeTextObj;
};

const createTextTimeline = (textTimestampInfo, captionSettings) => {
  console.log("Text Timestamp Info: ", textTimestampInfo);
  var words = textTimestampInfo.words;
  var textTimeline = [];

  console.log("Words: ", words);

  const maxCharCount = captionSettings.charCount ?? 15;
  const maxLineCount = captionSettings.lineCount ?? 1;

  var mainText = "";
  var lineInfoArray = [];
  var wordInfoArray = [];

  var currCharCounter = 0;
  var currLineCounter = 0;

  var currWordCounter = 0;
  // TODO: Compose word anim object

  var isSentenceEndDetected = false;

  for (let i = 0; i < words.length; i++) {
    var wordObj = words[i];
    // console.log("------------- x --------------");
    // console.log("Index: ", i);
    // console.log("Word: ", wordObj);

    if (currCharCounter < maxCharCount && !isSentenceEndDetected) {
      currCharCounter += wordObj.punctuated_word.length;
      var wordInfo = {
        text: wordObj.punctuated_word,
        startTime: wordObj.start,
        endTime: wordObj.end,
        onActive: getActiveTextOptions(
          captionSettings,
          "word",
          currLineCounter,
          currWordCounter
        ),
        activeAnim: getActiveTextAnimation(
          captionSettings,
          "word",
          currLineCounter,
          currWordCounter
        ),
      };
      currWordCounter++;
      wordInfoArray.push(wordInfo);

      if (wordObj.isLastWord) {
        isSentenceEndDetected = true;
      }
    }

    if (
      currCharCounter >= maxCharCount ||
      isSentenceEndDetected ||
      i == words.length - 1
    ) {
      var lineInfo = {
        text: wordInfoArray.map((obj) => obj.text).join(" "),
        startTime: wordInfoArray[0].startTime,
        endTime: wordInfoArray[wordInfoArray.length - 1].endTime,
        renderAnim: getOnRenderAnimation(
          captionSettings,
          "line",
          currLineCounter,
          currWordCounter
        ),
        onActive: getActiveTextOptions(
          captionSettings,
          "line",
          currLineCounter,
          currWordCounter
        ),
        activeAnim: getActiveTextAnimation(
          captionSettings,
          "line",
          currLineCounter,
          currWordCounter
        ),
        words: wordInfoArray,
      };
      lineInfoArray.push(lineInfo);
      wordInfoArray = [];

      currCharCounter = 0;
      currWordCounter = 0;

      currLineCounter++;
    }

    if (
      currLineCounter == maxLineCount ||
      isSentenceEndDetected ||
      i == words.length - 1
    ) {
      mainText = lineInfoArray.map((obj) => obj.text).join("\n");
      var textInfo = {
        text: mainText,
        startTime: lineInfoArray[0].startTime,
        endTime: lineInfoArray[lineInfoArray.length - 1].endTime,
        renderAnim: getOnRenderAnimation(
          captionSettings,
          "text",
          currLineCounter,
          currWordCounter
        ),
        restoreState: captionSettings.activeText?.restoreState,
        lines: lineInfoArray,
      };

      textTimeline.push(textInfo);

      mainText = "";

      isSentenceEndDetected = false;
      lineInfoArray = [];
      wordInfoArray = [];

      currCharCounter = 0;
      currWordCounter = 0;
      currLineCounter = 0;
    }
  }

  console.log("TEXT TIMELINE COMPOSED: ", textTimeline);
  return textTimeline;
};

export { createTextTimeline };
