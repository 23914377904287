import React, { useState, useEffect, useRef, useCallback } from 'react'
import WordSettings from './WordSettings';
import EnableSwitch from '../ui/EnableSwitch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LineSettings from './LineSettings';
import { getFallbackStyleOptions } from '../../utils/captions';
import useEffectOnChange from '../../hooks/useEffectOnChange';
import Chevron from '../ui/Chevron';

const ActiveTextSettings = ({
    captionRef,
    captionConfig,
    refreshCaptionState,
    activeTextSettings,
    setActiveTextSettings
}) => {

    const mode = 'active'

    const isInternalUpdate = useRef(false);

    const [lineSettings, setLineSettings] = useState(null);
    const [wordSettings, setWordSettings] = useState(null);

    const [activeTextConfig, setActiveTextConfig] = useState()

    const [enabler, setEnabler] = useState({ lines: false, words: false, restoreState: false })

    useEffect(() => {
            console.log('INIT ACTIVE TEXT SETTINGS ===> ', captionConfig)
            setActiveTextConfig(captionConfig.activeText)

            isInternalUpdate.current = false;

            var lineOptionsArray = activeTextSettings?.lines;
            setLineSettings(lineOptionsArray);
    
            var wordOptionsArray =  activeTextSettings?.words;
            setWordSettings(wordOptionsArray)

            setEnabler({
                lines: lineOptionsArray ? true : false,
                words: wordOptionsArray ? true : false,
                restoreState: activeTextSettings.restoreState ? true : false,
            })

    }, [])

    useEffect(() => {
        if(refreshCaptionState){
            console.log('REFRESH CAPTION STATE:: ACTIVE SETTINGS -> ', captionConfig)
            const activeText = captionConfig.activeText

            isInternalUpdate.current = false;

            setActiveTextConfig(activeText);
            setLineSettings(activeText?.lines);
            setWordSettings(activeText?.words);

            setEnabler({
                lines: activeText?.lines ? true : false,
                words: activeText?.words ? true : false,
                restoreState: activeText?.restoreState ? true : false,
            })
        }
    }, [refreshCaptionState]);

    useEffectOnChange(() => {
        const lineOptionsArray = activeTextSettings?.lines;
        if(lineOptionsArray){
            updateLineSettings(lineOptionsArray);
        }
    }, [captionConfig.lineCount])

    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            console.log('LINE SETTINGS CHANGED:: ', lineSettings)
            var activeTextSettingsTmp = { ...activeTextSettings, lines: lineSettings, words: wordSettings }
            console.log('UPDATING ACTIVE TEXT SETTINGS: ', activeTextSettingsTmp)
            setActiveTextSettings(prevValue => ({
                ...prevValue,
                lines: lineSettings,
            }));

            if (!enabler.lines && lineSettings) {
                setEnabler(prevValue => ({
                    ...prevValue,
                    lines: true,
                }))
            }
        }
       
    }, [lineSettings])

    useEffectOnChange(() => {
        if(isInternalUpdate.current){

            console.log('WORD SETTINGS CHANGED:: ', wordSettings)
            var activeTextSettingsTmp = { ...activeTextSettings, lines: lineSettings, words: wordSettings }
            console.log('UPDATING ACTIVE TEXT SETTINGS: ', activeTextSettingsTmp)
    
            setActiveTextSettings(prevValue => ({
                ...prevValue,
                words: wordSettings
            }))  

            if (!enabler.words && wordSettings) {
                setEnabler(prevValue => ({
                    ...prevValue,
                    words: true,
                }))
            }
        }

    }, [wordSettings])

    useEffectOnChange(() => {
        console.log('ENABLER LINE: ', enabler)
        console.log('LINE: ', lineSettings)
        if(isInternalUpdate.current){
            if (!enabler.lines && lineSettings) {
                updateLineSettings(null)
            }

            if (enabler.lines && !lineSettings) {
                const lineOptionsArray = initialiseLineSettings()
                updateLineSettings(lineOptionsArray);

            }
        }
    }, [enabler.lines])

    useEffectOnChange(() => {
        console.log('ENABLER RESTORE: ', enabler)
        if(isInternalUpdate.current){
            console.log('RESTORE STATE:: ', enabler?.restoreState)
            setActiveTextSettings(prevValue => ({
                ...prevValue,
                restoreState: enabler.restoreState,
            }));
        }
    }, [enabler?.restoreState])


    useEffectOnChange(() => {
        console.log('ENABLER WORD: ', enabler)
        console.log('WORD: ', wordSettings)
        if(isInternalUpdate.current){
            if (!enabler.words && wordSettings) {
                updateWordSettings(null);
            }

            if (enabler.words && !wordSettings) {
                const wordOptionsArray = initialiseWordSettings();
                updateWordSettings(wordOptionsArray);
            }
        }
    }, [enabler.words])


    const initialiseWordSettings = () => {
        var wordOptionsArray = []

        var wordOptions = {
            indexInfo: { idx: 0, start: 0, end: -1 },
            options: {},
            boxOptions: null,
            anim: null,
        }
        wordOptionsArray.push(wordOptions)
        return wordOptionsArray;

    }

    const initialiseLineSettings = () => {
        var lineOptionsArray = []
        for (let i = 0; i < captionConfig.lineCount; i++) {
            const { flbkOptions, flbkBoxOptions } = getFallbackStyleOptions(captionConfig, i, mode)
            var lineOptions = {
                options: flbkOptions,
                boxOptions: flbkBoxOptions,
                anim: null,
            }
            lineOptionsArray.push(lineOptions);
        }

        console.log('INITIALISING LINE SETTINGS:: ACTIVE ', lineOptionsArray)
        return lineOptionsArray;
    }

    const updateEnabler = (newEnablerInfo) => {
        isInternalUpdate.current = true;
        setEnabler(newEnablerInfo)
    }

    const updateLineSettings = (newLineSettings) => {
        isInternalUpdate.current = true;
        setLineSettings(newLineSettings);
    }

    const updateWordSettings = (newWordSettings) => {
        isInternalUpdate.current = true;
        setWordSettings(newWordSettings);
    }


    const showLineSettings = () => {
        console.log('SHOW LINE SETTINGS!!!')
        var lineSettingsArray = []
        var lines = [
            { id: 0, text: 'This is sample sentence 1' },
            { id: 1, text: 'This is sample sentence 2' }
        ]
        for (let i = 0; i < captionConfig.lineCount; i++) {
            lineSettingsArray.push(<LineSettings idx={i} mode={mode} info={lines[i]} captionRef={captionRef} captionConfig={captionConfig} refreshCaptionState={refreshCaptionState} lineSettings={lineSettings} setLineSettings={updateLineSettings} />)
        }
        console.log('LINE ARRAY: ', lineSettingsArray)
        return lineSettingsArray;
    }

    const showWordSettings = () => {
        console.log('SHOW WORD SETTINGS!!!')
        var wordSettingsArray = []
        

        var wordsCount = captionConfig.activeText.words?.length ?? 1
        console.log('WORD COUNT: ', wordsCount)

        for (let i = 0; i < wordsCount; i++) {
            const indexInfo = { idx: i, start: 0, end: -1 }
            wordSettingsArray.push(<WordSettings mode={mode} indexInfo={indexInfo} captionRef={captionRef} captionConfig={captionConfig} refreshCaptionState={refreshCaptionState} wordSettings={wordSettings} setWordSettings={updateWordSettings} />)
        }
        console.log('WORD ARRAY: ', wordSettingsArray)
        return wordSettingsArray;

    }

  
    return (
        <>
            {
                activeTextConfig && 
                    <div style={{ width: '98%', fontSize: '0.8rem', margin: '10px auto', padding: 10, paddingLeft: 20, border: '1px solid #C7BFF3', borderLeft: '8px solid #C7BFF3', backgroundColor: '#fff', borderRadius: 10 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1, fontSize: 18, fontWeight: 700, width: '95%', margin: 'auto', color: '#424242' }}>
                            Active Text
                        </div>

                    </div>
                    <div className='form-row' >
                        <div className='label' style={{flex: 2, fontSize: 16, fontWeight: 700, color: '#424242' }}>
                            Restore State <br/><span style={{fontSize: 14, opacity: 0.9}}>(When Inactive)</span>
                        </div>
                        <div className='edit-box'>
                            <EnableSwitch target='restoreState' value={enabler.restoreState} setEnabler={updateEnabler} />
                        </div>
                    </div>
                    <div style={{ border: '1px solid #f2cc8f', borderLeft: '6px solid #f2cc8f', backgroundColor: '#fff', padding: 10, paddingLeft: 20, width: '98%', margin: '10px auto',  borderRadius: 10  }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ flex: 1, fontSize: 16, fontWeight: 700, width: '95%', margin: 'auto', color: '#424242' }}>
                                Lines
                            </div>
                            <div style={{ flex: 2, display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                <EnableSwitch target='lines' value={enabler.lines} setEnabler={updateEnabler} />
                            </div>
                        </div>
                        <div style={{ display: enabler.lines ? 'block' : 'none' }}>
                            cdvc
                            {
                                
                                lineSettings &&
                                showLineSettings()
                            }

                        </div>


                    </div>
                    <div style={{  border: '1px solid #FFC5B5', borderLeft: '6px solid #FFC5B5', backgroundColor: '#fff', padding: 10, paddingLeft: 20, width: '98%', margin: '10px auto',  borderRadius: 10  }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ flex: 1, fontSize: 16, fontWeight: 700, width: '95%', margin: 'auto', color: '#424242' }}>
                                Words
                            </div>
                            <div style={{ flex: 2, display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                <EnableSwitch target='words' value={enabler.words} setEnabler={updateEnabler} />
                                
                            </div>
                        </div>
                        <div style={{ display: enabler.words ? 'block' : 'none' }}>
                            {
                                wordSettings &&
                                showWordSettings()
                            }

                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ActiveTextSettings
