import React, { useState, useEffect } from 'react';

const ToggleSwitch = ({target, value, setSwitchValue}) => {
    const [isEnabled, setIsEnabled] = useState(value);

    useEffect(() => {
        setIsEnabled(value)
    }, [value])

    const handleToggle = () => {
        const value = !isEnabled
        setIsEnabled(value)

        console.log('KEY: ', target)
        setSwitchValue(prevValue => ({
            ...prevValue,
            [target]: value
        }))
    };

    return (
        <div
            onClick={handleToggle}
            className={`toggle-switch
                ${isEnabled ? 'toggle-switch--enabled' : 'toggle-switch--disabled'}
            `}
        >
            <div
                className={`
                toggle-switch__circle
                ${isEnabled ? 'toggle-switch__circle--enabled' : 'toggle-switch__circle--disabled'}
            `}
            />
        </div>
    );
};

export default ToggleSwitch;