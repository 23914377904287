const menuIndex = {
  CAPTIONS_MENU_INDEX: 0,
  TRANSCRIPT_MENU_INDEX: 1,
};

const getSettingsForComponent = (label) => {
  if (["Roundness"].includes(label)) {
    return {
      min: 0,
      max: 100,
      step: 1,
    };
  } else if (["Opacity"].includes(label)) {
    return {
      min: 0,
      max: 1,
      step: 0.1,
    };
  } else if (["Scale"].includes(label)) {
    return {
      min: 0,
      max: 3,
      step: 0.1,
    };
  } else if (["LineSpacing"].includes(label)) {
    return {
      min: 0,
      max: 50,
      step: 1,
    };
  } else if (["lOffset"].includes(label)) {
    return {
      min: 0,
      max: 300,
      step: 10,
    };
  } else if (["WordSpacing", "StrokeWidth"].includes(label)) {
    return {
      min: 1,
      max: 20,
      step: 1,
    };
  } else if (["lineCount"].includes(label)) {
    return {
      min: 1,
      max: 10,
      step: 1,
    };
  } else if (["charCount"].includes(label)) {
    return {
      min: 1,
      max: 40,
      step: 1,
    };
  } else if (["Intensity", "Speed"].includes(label)) {
    return {
      min: 10,
      max: 500,
      step: 10,
    };
  }

  return {
    min: 0,
    max: 100,
    step: 1,
  };
};

export { menuIndex, getSettingsForComponent };
