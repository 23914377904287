import React, { useState, useRef, useEffect } from "react";
import { fabric } from "fabric";
import {
    getPreviewContainerDimensions,
} from "../constants/canvasSettings";
import { useDispatch, useSelector } from 'react-redux'
import { updateCaptionInfo, setRefreshCaptionState, setCaptionBtnLock, updateAssets } from "../redux/projectSlice";
import { textData } from '../constants/data'
import { compose, renderFactory, getCurrentIndexFromTextTimestamps, isVideoReady } from '../utils/render'
import { createTextTimeline } from "../utils/textProcessor";
import useEffectOnChange from "../hooks/useEffectOnChange";
import { generateRandom8DigitNumber } from "../utils/commonUtils";
import { processAction } from "../utils/actionManager";
import Loader from "./ui/Loader";
import uuid from 'react-uuid';
import VideoPlayback from "./VideoPlayback";

const Player = ({ captionRef, textTimestampsRef }) => {
    const dispatch = useDispatch()

    const canvasRef = useRef();
    const fabricRef = useRef();
    const clipPathRef = useRef();

    const videoRef = useRef({});
    const textRef = useRef();

    const renderRef = useRef();
    const renderInitTime = useRef();
    const currentTimeRef = useRef(0);

    const [currTime, setCurrTime] = useState(0)

    const timelineElementsCacheRef = useRef({})

    // const textTimestampsRef = useRef([]);

    const hasLinesRef = useRef(false);

    const playRef = useRef(false)

    const [loading, setLoading] = useState(false)

    const [playing, setPlaying] = useState(false)
    const [containerParams, setContainerParams] = useState();
    const [videoDuration, setVideoDuration] = useState()

    const captionInfo = useSelector(
        (state) => state.projectReducer.captionInfo
    )

    const captionConfig = useSelector(
        (state) => state.projectReducer.captionInfo?.config
    )

    const refreshCaptionState = useSelector(
        (state) => state.projectReducer.refreshCaptionState
    )

    const actionLog = useSelector(
        (state) => state.projectReducer.actionLog
    )

    const timeline = useSelector(
        (state) => state.projectReducer.timeline
    )

    const videoInfo = useSelector(
        (state) => state.projectReducer.videoInfo
    )

    const skipRanges = useSelector(
        (state) => state.projectReducer.skipRanges
    )

    const projectSettings = useSelector(
        (state) => state.projectReducer.settings
    )

    useEffect(() => {
        if (!containerParams) {
            const aspectRatio = projectSettings?.aspectRatio ?? '16/9';
            const containerSettings = getPreviewContainerDimensions(aspectRatio);
            setContainerParams(containerSettings);

            const duration = videoInfo.mainVideo.endTime - videoInfo.mainVideo.startTime;
            setVideoDuration(duration)
        }
    }, []);
    

    useEffect(() => {
        if(fabricRef.current){
            console.log('Current Time Changed: ', currTime)
            console.log('renderRef.current: ', renderRef.current)
    
            currentTimeRef.current = currTime;
    
            var renderer = initRenderFunction();
            renderer.renderAtTime(currTime);
            fabricRef.current.requestRenderAll();
        }
       
    }, [currTime])


    useEffect(() => {
        if(fabricRef.current){
            sendActionsForProcessing(actionLog[0])
        }
    }, [actionLog])

    const sendActionsForProcessing = async(action) => {
        setLoading(true);
        if(action?.status == 0){
            console.log('ACTION LOG: ', actionLog)
            await processAction(action, timeline, timelineElementsCacheRef.current, currentTimeRef.current, fabricRef.current, containerParams, clipPathRef)
           
        }else if(action?.status == 1){

        }
        setLoading(false);
    }

    useEffect(() => {
        console.log('CAPTION INFO::TEXT CHANGED!!')
        if (fabricRef.current) {
            fabricRef.current.renderAll()
        }
        if(!captionConfig?.text.lines && hasLinesRef.current){
            // Lines Config is Disabled
            console.log('LINES CONFIG DISABLED:: ')
            hasLinesRef.current = false;
            updateTextTimestampsAndRender();
        }else if(captionConfig?.text.lines && !hasLinesRef.current){
            hasLinesRef.current = true
        }
    }, [captionConfig?.text])


    useEffect(() => {
        renderRef.current = null;
    }, [captionConfig, timeline])


    useEffectOnChange(() => {
        // Set newTextInfo to existing captions
        if(captionRef.current && fabricRef.current){
            updateTextTimestampsAndRender();
        }
        
    }, [captionConfig?.lineCount, captionConfig?.charCount, captionConfig?.topPos ])


    useEffectOnChange(() => {        
        if(captionRef.current && refreshCaptionState && fabricRef.current){
            updateTextTimestampsAndRender();
        }
    }, [refreshCaptionState ])

    useEffect(() => {
        if (containerParams) {
            const aspectRatio = projectSettings?.aspectRatio ?? '16/9';
            const containerSettings = getPreviewContainerDimensions(aspectRatio);
            setContainerParams(containerSettings);

            const clearCanvasAsync = (canvas) => {
                return new Promise((resolve) => {
                if (canvas) {
                    var handle = setInterval(() => {
                    // var video = videoRef.current;
                    const objects = canvas.getObjects();
                    if (objects.length === 0) {
                        console.log("Canvas Cleared");
                        clearInterval(handle);
                        resolve();
                    }
                    }, 100);
                } else {
                    resolve();
                }
                });
            };

            async function clearAndLoadCanvas(){
                const canvas = fabricRef.current
                if(canvas){
                    canvas.clear()
                    await clearCanvasAsync(canvas)

                    canvas.setBackgroundColor("#EBECF0");
                    
                    canvas.renderAll();

                    var clipPath = new fabric.Rect({
                        id: 'clip-path',
                        width: containerSettings.viewWidth,
                        height: containerSettings.viewHeight,
                        top: containerSettings.viewY,
                        left: containerSettings.viewX,
                        fill: "#ddd",
                        absolutePositioned: true,
                        selectable: false,
                    });
                    clipPathRef.current = clipPath;

                    canvas.add(clipPath)
                    canvas.moveTo(clipPath, 0)
                    canvas.requestRenderAll()
        
                    // console.log('Setting up stage after aspect change: ', fabricRef.current)
                    getCanvasReady(containerSettings)
                    canvas.renderAll();
                }
            }

            if(fabricRef.current){
                clearAndLoadCanvas()
            }
        }

    }, [projectSettings?.aspectRatio])

    useEffect(() => {
        if (!canvasRef.current) return;
        if (fabricRef.current) return;
        if (containerParams) {
            console.log('Setting Up Stage')
            const canvas = new fabric.Canvas('canvas', {
                width: containerParams.width,
                height: containerParams.height,
                preserveObjectStacking: true,
                backgroundColor: "#EBECF0",
            });

            fabric.Object.prototype.transparentCorners = false;
            fabric.Object.prototype.cornerColor = 'white';
            fabric.Object.prototype.cornerSize = 10;
            fabric.Object.prototype.borderColor = '#7CB9E8';
            fabric.Object.prototype.cornerStyle = 'circle';
            fabric.Object.prototype.setControlsVisibility({ mtr: false });

            var clipPath = new fabric.Rect({
                id: 'clip-path',
                width: containerParams.viewWidth,
                height: containerParams.viewHeight,
                top: containerParams.viewY,
                left: containerParams.viewX,
                fill: "#ddd",
                absolutePositioned: true,
                selectable: false,
            });
            clipPathRef.current = clipPath;

            canvas.add(clipPath)
            canvas.moveTo(clipPath, 0)
            canvas.requestRenderAll()

            fabricRef.current = canvas;

            console.log('CANVAS OBJS 111: ', fabricRef.current.getObjects())

            // Set total scene count
            console.log('Setting up stage: ', fabricRef.current)

            // Add video to canvas
            // renderVideo()
            getCanvasReady(containerParams)
        }

    }, [containerParams]);

    async function getCanvasReady(containerSettings){
        window.videoSeekStatus = {}
        await exportThumbnailFromVideo(containerSettings);
       
        canvasCompose()
    }

    function updateTextTimestampsAndRender() {
        console.log('Caption Settings Info:: ', captionInfo);
    
        captionRef.current.updateSettings(captionConfig)

        const startTime = 10.2;
        textTimestampsRef.current = createTextTimeline(videoInfo?.transcript, captionConfig);
        const textIndex = getCurrentIndexFromTextTimestamps(
            textTimestampsRef,
            startTime
        );
        var newTextInfo = textTimestampsRef.current[textIndex];
        var textBox = captionRef.current.setNew(newTextInfo);
    
        fabricRef.current.add(textBox);
        fabricRef.current.renderAll();
    }

    function exportThumbnailFromVideo(containerSettings) {
        return new Promise(async (resolve, reject) => {
          const offScreenCanvas = document.createElement("canvas");
          offScreenCanvas.width = containerSettings.viewWidth; // Thumbnail width
          offScreenCanvas.height = containerSettings.viewHeight; // Thumbnail height
      
          const fabricCanvas = new fabric.Canvas(offScreenCanvas, {
            preserveObjectStacking: true,
            width: containerSettings.viewWidth,
            height: containerSettings.viewHeight,
            backgroundColor: "#ddd",
          });
      
          await compose(
            videoInfo,
            fabricCanvas,
            containerSettings,
            videoRef,
            captionRef,
            null,
            textTimestampsRef,
            captionInfo
          );
      
          await isVideoReady(videoRef.current);
          const assets = {
            thumbnail: fabricCanvas.toDataURL("image/png"),
          };
          dispatch(updateAssets({ assets: assets }));
          offScreenCanvas.remove();
      
          resolve();
        });
      }

    const canvasCompose = async () => {

        await compose(
            videoInfo,
            fabricRef.current,
            containerParams,
            videoRef,
            captionRef,
            clipPathRef,
            textTimestampsRef,
            captionInfo,
        )

    }

    const initRenderFunction = () => {
        const renderInfo = {
            start: currentTimeRef.current ?? 0,
            end: videoInfo?.mainVideo?.endTime,
            skipRanges: skipRanges ?? []
        }

        var renderer;
        if(!renderRef.current){
            renderer = renderFactory(
                renderInfo,
                renderInitTime,
                currentTimeRef,
                fabricRef,
                containerParams,
                clipPathRef,
                videoRef,
                captionRef,
                setPlaying,
                textTimestampsRef,
                timeline,
                timelineElementsCacheRef.current
            )
            renderer.preloadMedia();
            renderRef.current = renderer;
        }else{
            renderer = renderRef.current;
        }

        return renderer;
    }

    const startRenderAnimation = async () => {
        console.log('PLAY REF:: ', playing)
        if (!playing) {
            setPlaying(true)
            renderInitTime.current = performance.now() / 1000; // Get the start time in seconds

            console.log('Starting Render')

            textTimestampsRef.current = createTextTimeline(videoInfo?.transcript, captionConfig);
            console.log('TEXT TIMESTAMPS:: ', textTimestampsRef.current);

            const info = {
                start: currentTimeRef.current ?? 0,
                end: 175
            }

            var renderer = initRenderFunction();

            renderer.preloadMedia();
            renderer.start(info);
        }else{
            currentTimeRef.current = renderRef.current.stop()
        }
    }

    const loadCaptions = () => {
        const captionSettings =  {
            "id": uuid(),
            "config": {
                "lineCount": 1,
                "text": {
                    "anim": {
                        "type": "pop",
                        "speed": 0
                    },
                    "options": {
                        "fontSize": 30,
                        "fontFamily": "Lato",
                        "fontStyle": "normal",
                        "fontWeight": 800,
                        "lineSpacing": 5,
                        "fill": "#ffffff",
                        "case": "sentence",
                        "shadowInfo": {
                            "fill": "#000",
                            "blur": 10,
                            "offsetX": 5,
                            "offsetY": 5
                        }
                    },
                    "boxOptions": null,
                    "lines": null
                },
                "activeText": {
                    "lines": null,
                    "words": null
                },
                "charCount": 2
            },
            "access": true,
            "tags": [],
            "userId": "ORXANU5lHMWG4tz1uszuhaWqWXl2",
            "creatorId": "Diveo Creators",
            "created": {
                "seconds": 1734954574,
                "nanoseconds": 486000000
            },
            "featured": false,
            "score": 1
        }

        dispatch(setCaptionBtnLock({ isActive: false }))
        dispatch(updateCaptionInfo({captionInfo: captionSettings}));
        dispatch(setRefreshCaptionState({ flag: true }));

    }

    return (
        <div style={{ width: '100%', height: '90vh', backgroundColor: '#EBECF0', paddingInline: 20 }}>
            {
                containerParams &&
                <>
                    <div style={{ position: 'relative', height: containerParams.height, width: containerParams.width}}>
                        <canvas id="canvas" ref={canvasRef} style={{}}>

                        </canvas>
                        {
                            loading && 
                                <div style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', height: containerParams.viewHeight, width: containerParams.viewWidth, top: containerParams.viewY, left: containerParams.viewX, backgroundColor: 'rgba(0,0,0,0.4)' }}>
                                    <Loader type="spin" size={30} />
                                </div>
                        }

                        <div style={{ width: containerParams.viewWidth, margin: 'auto' }}>
                            <VideoPlayback 
                                duration={videoDuration} 
                                setCurrTime={setCurrTime} 
                                startRender={startRenderAnimation} 
                                playing={playing}   
                                setPlaying={setPlaying}
                            />
                        </div>
                       
                    </div>
                    
                    {/* <div style={{marginTop: 100}}>
                        <button onClick={loadCaptions}>Load Captions</button>    
                    </div>  */}
                    
                </>
            }


        </div>
    )
}

export default Player


