import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInputModalActive, initNewState } from '../redux/projectSlice';
import '../css/input.css'
import MenuBar from '../components/MenuBar';
import Sidebar from '../components/Sidebar';
import { useLocation } from "react-router-dom";
import ModalComponent from '../components/modals/ModalComponent';
import InputView from '../components/modals/InputView';
import ProjectView from '../components/ProjectView';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

const HomePage = ({activeMenuIndex}) => {
    const dispatch = useDispatch()
    const { state } = useLocation();

    const [active, setActive] = useState(0);
    const [sizeFilterError, setSizeFilterError] = useState();

    const isInputModalOpen = useSelector(
        (state) => state.projectReducer.isInputModalActive
    )

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        console.log('WINDOW SIZE: ', 720)
        if(windowSize.width < 720){
            setSizeFilterError('The tool is only available on desktop sizes for now.')
        }else{
            setSizeFilterError()
        }
    }, [windowSize])
    
    useEffect(() => {
       if(active){
           console.log('Active Menu Index: ', active)
           console.log('History Stack', window.history)
       }
    }, [active])

    useEffect(() => {
        if(activeMenuIndex){
            setActive(activeMenuIndex)
        }
    }, [activeMenuIndex])

    // useEffect(() => {
    //     dispatch(initNewState({}))
    // }, [])

    useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        };

        window.addEventListener("resize", handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    }, []);

    const pageContent = () => {
        // switch(active){
        //     case 0:
        //         window.history.pushState(null, "", "/home")
        //         return <InputView projectInfo={state?.data} />
        //     case 1:
        //         window.history.pushState(null, "", "/projects")
        //         return <ProjectView />
        // }
    }

    const openInputModal = () => {
        dispatch(setInputModalActive({isActive: true}))
    }

    const closeInputModal = () => {
        dispatch(setInputModalActive({isActive: false}))
    }


    return (
        <>
            <MenuBar />
            {
                sizeFilterError ? 
                    <div style={{margin: 30, fontSize: 18, width: '100%', opacity: 0.8, fontStyle: 'italic', textAlign: 'center'}}>{sizeFilterError}</div>
                    :
                    <div style={{height: '92vh', overflow: 'scroll'}}>
                        <Sidebar active={active} setActive={setActive}/>
                       
                        <div className='main-container'>
                            {/* {pageContent()} */}
                            
                            <div style={{display: 'flex', flexDirection: 'column',  width: '100%', height: 200}}>
                                <div style={{margin: 10, fontSize: 24, fontWeight: 700}}>New Project</div>
                                <div className='input-menu' onClick={openInputModal}>
                                    <div style={{fontSize: 18}}>
                                        Generate Captions <AutoFixHighIcon style={{fontSize: 24, marginLeft: 5, marginBottom: 5}}/>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: 'flex', marginTop: 20, flexDirection: 'column', width: '100%'}}>
                                <div style={{margin: 20, fontSize: 24, fontWeight: 700}}>My Projects</div>
                                <div>
                                    <ProjectView />
                                </div>
                                
                            </div>
                            
                          
                        </div>
                        <ModalComponent lockOpen={true} open={isInputModalOpen} handleClose={closeInputModal} >
                            <InputView />         
                        </ModalComponent>  
                    </div>
            }
           
           
          

        </>
    )
}

export default HomePage
