import { auth, db, provider } from "../config/firebaseConfig";
import { collection, doc, setDoc } from "firebase/firestore";
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
  sendSignInLinkToEmail,
  signInWithPopup,
} from "firebase/auth";
import {
  requestLogin,
  receiveLogin,
  loginError,
  requestLogout,
  receiveLogout,
  logoutError,
} from "../redux/authSlice";
import { store } from "../redux/store";

export const signInUserWithEmail = async (email) => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // User is already signed in
    console.log("Authenticating user with email code");
    const userCredentials = await signInWithEmailLink(
      auth,
      email,
      window.location.href
    );
    window.localStorage.removeItem("diveoEmailForSignIn");
    console.log(userCredentials);

    var userDetails = {
      id: userCredentials.user.uid,
      email: userCredentials.user.email,
      name: userCredentials.user.displayName,
      provider: userCredentials.user.providerId,
    };
    console.log("Logged In Successfully");

    store.dispatch(receiveLogin({ user: userDetails }));

    // Save User Credentials in Firestore storage
    await setDoc(doc(db, "users", userCredentials.user.uid), userDetails);
  }
};

export const signInWithGoogle = async () => {
  signInWithPopup(auth, provider)
    .then(async (userCredentials) => {
      var userDetails = {
        id: userCredentials.user.uid,
        email: userCredentials.user.email,
        name: userCredentials.user.displayName,
        provider: userCredentials.user.providerId,
      };
      console.log("Logged In Successfully Using Google");

      // Save User Credentials in Firestore storage
      await setDoc(doc(db, "users", userCredentials.user.uid), userDetails);

      store.dispatch(receiveLogin({ user: userDetails }));
    })
    .catch((err) => {
      switch (err.code) {
        default:
          store.dispatch(loginError());
      }
    });
};

export const sendSignInLinkToUserEmail = async (email) => {
  console.log("EMAIL: ", email);
  sendSignInLinkToEmail(auth, email, {
    url: `${process.env.REACT_APP_ENDPOINT}/login-with-email`,
    handleCodeInApp: true,
  })
    .then(() => {
      console.log("SUCCESSFULLY LOGGED IN!!");
      store.dispatch(requestLogin());
      window.localStorage.setItem("diveoEmailForSignIn", email);
    })
    .catch((err) => {
      console.log("ERROR WHILE LOGGING IN:", err);
      switch (err.code) {
        default:
          store.dispatch(loginError());
      }
    });
};

export const logoutUser = () => {
  console.log("Successfully logged out user!");
  store.dispatch(requestLogout());
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      console.log("Successfully logged out user!");
      store.dispatch(receiveLogout());
      console.log("ENDPOINT: ", process.env.REACT_APP_ENDPOINT);
      window.location.replace(`${process.env.REACT_APP_ENDPOINT}/login`);
    })
    .catch((error) => {
      store.dispatch(logoutError());
      console.log("Error occurred while signing out");
    });
};
