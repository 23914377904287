import React, { useState, useEffect, useRef } from 'react'
import Player from './Player'
import { useDispatch, useSelector } from 'react-redux'
import { 
    setCaptionConfigModalActive, 
    setCaptionCollectionModalActive, 
    setMediaPanelInfo, 
    setMenuIndexSelected,
    updateSettings
 } from '../redux/projectSlice';
import { getScreenDimensionsForQuality } from '../constants/canvasSettings';
import { useNavigate } from "react-router-dom";
import '../css/editor.css';
import { menuIndex } from '../constants/editorConfig';
import CaptionSettings from './caption/CaptionSettings';
import ModalComponent from './modals/ModalComponent';
import CaptionConfigUploader from './modals/CaptionConfigUploader';
import CaptionCollection from './modals/CaptionCollection';
import TranscriptView from './TranscriptView';
import MediaOptions from './modals/MediaOptions';
import uuid from 'react-uuid';
import { exportVideo } from '../utils/process';

const Editor = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [projectName, setProjectName] = useState('New Project')
    const [currentMenuIndex, setCurrentMenuIndex] = useState(0)
    const [aspectRatioValue, setAspectRatioValue] = useState()
    
    const [isProcessing, setProcessing] = useState(false)

    const captionRef = useRef(null);
    const textTimestampsRef = useRef([]);

    const isCaptionConfigModalOpen = useSelector(
        (state) => state.projectReducer.isCaptionConfigModalActive
    )

    const isCaptionCollectionModalOpen = useSelector(
        (state) => state.projectReducer.isCaptionCollectionModalActive
    )

    const mediaPanelInfo = useSelector(
        (state) => state.projectReducer.mediaPanelInfo
    )

    const projectSettings = useSelector(
        (state) => state.projectReducer.settings
    )

    const menuIndexSelected = useSelector(
        (state) => state.projectReducer.menuIndexSelected
    )

    useEffect(() => { 
        dispatch(setMenuIndexSelected({menuIndexSelected: currentMenuIndex}))
    }, [currentMenuIndex])

    useEffect(() => {
        // dispatch(
        //     updateActionLog({actionLog: []})
        // )
        // dispatch(
        //     updateTimeline({timeline: []})
        // )
        // const videoInfo = {
        //     skipRanges: [
        //         { startTime: 5, endTime: 15 },
        //         { startTime: 30, endTime: 40 },
        //     ]
        // }

        // dispatch(
        //     updateVideoInfo({videoInfo: videoInfo})
        // )
        setAspectRatioValue(projectSettings?.aspectRatio)

    }, [])

    const handleSelectionObjectChange = () => {
        switch (currentMenuIndex) {

            case menuIndex.CAPTIONS_MENU_INDEX:
                return <CaptionSettings captionRef={captionRef} textTimestampsRef={textTimestampsRef}/>
            case menuIndex.TRANSCRIPT_MENU_INDEX:
                return <TranscriptView textTimestampsRef={textTimestampsRef}/>
            default:
                return <CaptionSettings captionRef={captionRef}/>
        }
    }

    const changeAspectRatio = (val) => {
        const newSettings = {...projectSettings, aspectRatio: val};
        setAspectRatioValue(val)
        dispatch(updateSettings({settings: newSettings}))
    }

    const closeCaptionConfigModal = () => {
        dispatch(setCaptionConfigModalActive({isActive: false}))
    }

    const closeCaptionCollectionModal = () => {
        dispatch(setCaptionCollectionModalActive({isActive: false}))
    }

    const closeMediaPanel = () => {
        dispatch(setMediaPanelInfo({info: {isActive: false, context: {}}}))
    }

    const goToMainPage = () => { 
        navigate(`/home`)
    }

    const exportPreview = async () => {
        const jobId = uuid();
        setProcessing(true)
        const data =  await exportVideo(jobId)
        navigate(`/export/${jobId}`, {state: {data: data}});
        setProcessing(false)
    }

    return (
        <>
            <div style={{ height: '100%', fontFamily: 'PT Sans' }}>
                <div style={{ height: '100%', overflow: 'hidden' }}>
                    <div style={{ height: '8vh', width: '100%', backgroundColor: '#ffffff', borderBottom: '1px solid #f5f5f5' }}>
                        <div className='row'>
                            <div className='col-1'>
                                <div style={{ margin: 5, marginLeft: 15, fontSize: 24, fontStyle: 'normal', fontWeight: 900, color: '#000', padding: 10, cursor: 'pointer' }} onClick={goToMainPage}><i class="bi bi-arrow-left"></i></div>
                            </div>
                            <div className='col-5'>
                                <div style={{ float: 'left', margin: 10, color: '#000', padding: 10 }}>
                                    <div style={{ fontSize: 16, fontWeight: 600, cursor: 'pointer', color: '#424242', opacity: 0.8 }}>
                                        {projectName} <span style={{ marginLeft: 10, cursor: 'pointer' }}><i class="bi bi-pencil-square"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-3'>
                                {/* <div style={{ marginTop: 20,}}>
                                    <a href='/guide' style={{ fontSize: 18, cursor: 'pointer', color: '#584efd'}} target="_blank">
                                            Guide: How to get started?  
                                    </a>
                                </div> */}
                                <div style={{display: 'flex', width: 150, marginTop: 12, justifyContent: 'space-between'}}>
                                    <div className={aspectRatioValue == '9/16' ? 'aspect-ratio-option-selected' : 'aspect-ratio-option'}  style={{width: 15, height: 27}} onClick={() => changeAspectRatio('9/16')}></div>
                                    <div className={aspectRatioValue == '1/1' ? 'aspect-ratio-option-selected' : 'aspect-ratio-option'}  style={{width: 24, height: 24}} onClick={() => changeAspectRatio('1/1')}></div>
                                    <div className={aspectRatioValue == '16/9' ? 'aspect-ratio-option-selected' : 'aspect-ratio-option'}  style={{width: 32, height: 18}} onClick={() => changeAspectRatio('16/9')}></div>
                                </div>

                            </div>
                            <div className='col-3'>
                                <div style={{ float: 'right', marginTop: 7, marginRight: 30 }}>
                                    {
                                        isProcessing ? 
                                            <div class='action-btn-disabled'>Exporting...</div>

                                            : 
                                            <div class='action-btn-primary' onClick={exportPreview}>Export <span style={{ marginLeft: 5, fontSize: 14 }}><i class="bi bi-download"></i></span></div>

                                    }
                                    
                                </div>

                                {/* <div style={{float: 'right', marginTop: 7, marginRight: 15}}>
                                    <button class='btn' style={{ padding: 8, paddingInline: 15, backgroundColor: '#fff', border: '1px solid #5b44ed', borderRadius: 8, fontSize: 15, color: '#584efd'}} onClick={syncProjectDetails}><i class="bi bi-save"></i></button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '4%', height: '92vh', float: 'left', border: '1px solid #eee', borderRight: 'none' }}>
                        <div className='sidebar'>
                                <div className={menuIndexSelected == menuIndex.TRANSCRIPT_MENU_INDEX ? 'menu-item-selected' : 'menu-item'} onClick={() => setCurrentMenuIndex(1)} >
                                    <div className='menu-icon'><i class="bi bi-card-text"></i></div>
                                </div>
                            
                                <div className={menuIndexSelected == menuIndex.CAPTIONS_MENU_INDEX ? 'menu-item-selected' : 'menu-item'} onClick={() => setCurrentMenuIndex(0)}>
                                    <div className='menu-icon'><i class="bi bi-c-circle-fill"></i></div>
                                </div>
                            </div>
                    </div>
                    <div style={{ width: '33%', height: '92vh', float: 'left', border: '1px solid #eee' }}>
                        <div style={{ width: '95%', margin: 'auto', height: '100%' }}>
                            {handleSelectionObjectChange()}
                        </div>
                    </div>
                    <div style={{ width: '60%', height: '92vh', float: 'left' }}>
                        <Player captionRef={captionRef} textTimestampsRef={textTimestampsRef}/>
                    </div>
                </div>
                <ModalComponent open={isCaptionConfigModalOpen} handleClose={closeCaptionConfigModal} >
                      <CaptionConfigUploader />         
                </ModalComponent>  
                <ModalComponent open={isCaptionCollectionModalOpen} handleClose={closeCaptionCollectionModal} >
                      <CaptionCollection />         
                </ModalComponent>    
                <ModalComponent lockOpen={true} open={mediaPanelInfo?.isActive}  handleClose={closeMediaPanel} >
                      <MediaOptions context={mediaPanelInfo?.context}/>         
                </ModalComponent>  

            </div>
        </>
    )
}

export default Editor
