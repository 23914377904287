import axios from "axios";
import { store } from "../redux/store";
import {
  initNewState,
  updateVideoInfo,
  updateCaptionInfo,
  updateSettings,
} from "../redux/projectSlice";
import { transcribeVideo } from "../services/transcribe";
import { defaultStyle } from "../constants/captions";
import { getScreenDimensionsForQuality } from "../constants/canvasSettings";
import uuid from "react-uuid";
import { syncProjectDetails } from "../services/store";
import { compose, isVideoReady } from "../utils/render";

async function uploadAndTranscibeVideo(userId, projectId, videoInfo, file) {
  var formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", "eweiesmw");
  formData.append("folder", `user/${userId}`);

  try {
    const uploadRes = await axios.post(
      "https://api.cloudinary.com/v1_1/dlmsxlf2n/video/upload",
      formData
    );

    console.log("Successfully uploaded videos to cloudinary");
    console.log(uploadRes);

    var data = uploadRes["data"];
    videoInfo.mainVideo.info.url = data["secure_url"];
    // videoInfo.mainVideo.info.url =
    //   "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1737131106/user/ORXANU5lHMWG4tz1uszuhaWqWXl2/fiutjnh6yh062vt6gtst.mp4";

    const trancribeRes = await transcribeVideo(videoInfo);
    console.log("Transcription Res: ", trancribeRes);
    var transcriptionData = trancribeRes["data"];

    if (!transcriptionData) {
      return {
        status: 0,
        message: "Error Occured While Processing Captions.",
      };
    }

    videoInfo.transcript = transcriptionData;
    console.log("Saving Video Info: ", videoInfo);

    const settings = {
      projectId: projectId,
      aspectRatio: "16/9",
      fps: 30,
      quality: 720,
    };

    store.dispatch(initNewState({}));
    store.dispatch(updateSettings({ settings: settings }));
    store.dispatch(updateVideoInfo({ videoInfo: videoInfo }));
    store.dispatch(updateCaptionInfo({ captionInfo: defaultStyle }));

    return {
      status: 1,
      message: "Transcript Generated Successfully!",
    };
  } catch (error) {
    console.log("Error Occurred While uploading/transcribing video");
    console.log(error);
    return { status: 0, message: `Error: ${error}` };
  }
}

const calculateTotalDuration = (videoInfo, skipRanges) => {
  if (videoInfo) {
    var videoDuration =
      videoInfo.mainVideo.endTime - videoInfo.mainVideo.startTime;
    if (skipRanges?.length) {
      for (let i = 0; i < skipRanges.length; i++) {
        var range = skipRanges[i];
        videoDuration -= range.endTime - range.startTime;
      }
    }
    return videoDuration;
  }
  return 0;
};

async function exportVideo(jobId) {
  const state = store.getState();

  const { videoInfo, captionInfo, settings, timeline, skipRanges, assets } =
    state.projectReducer;

  const userId = state.authReducer.user.id;
  const thumbnail = assets.thumbnail;

  const fps = 30;
  const quality = settings.quality ?? 1080;
  const aspectRatio = settings.aspectRatio ?? "16/9";

  const totalVideoDuration = calculateTotalDuration(videoInfo, skipRanges);
  const totalFrameCount = Math.floor(totalVideoDuration * fps);
  // const totalVideoDuration = 1;
  // const totalFrameCount = 30;

  var dims = getScreenDimensionsForQuality(quality, aspectRatio);
  var projectInfo = { ...videoInfo, skipRanges: skipRanges };

  var data = {
    payload: {
      projectInfo: projectInfo,
      captionInfo: captionInfo,
      renderMode: "video",
      timelineInfo: timeline,
      settings: settings,
    },
    userId: userId,
    thumbnail: thumbnail,
    dims: dims,
    projectId: settings.projectId,
    jobId: jobId,
    fps: fps,
    totalDuration: totalVideoDuration,
    totalFrameCount: totalFrameCount,
  };

  console.log("DATA: ", data);
  return data;
}

export { uploadAndTranscibeVideo, exportVideo };
