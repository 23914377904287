import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const Footer = (props) => {

    const openMail = () => {
        window.open('mailto:support@diveo.io?subject=&body=')
    }

    return (
        <div style={{ margin: 'auto', backgroundColor: '#111827', padding: 50, color: '#fff' }}>
            <div className="row" style={{ fontSize: 14, paddingInline: 50, marginBottom: 100 }}>
                <div className="col-sm-12 col-md-6">
                    <div style={{ fontSize: 60, fontWeight: 700 }}>Diveo.io</div>
                    <div style={{ fontSize: 14, width: '60%' }}>Your All-in-One Caption Maker Tool For Creating Captivating Videos.</div>

                    <div style={{ marginTop: 30, marginBottom: 25, opacity: 0.5 }}><span style={{ fontSize: 14 }}><i class="bi bi-c-circle"></i></span>Copyright 2022 Diveo</div>
                </div>
                <div className="col-sm-12 col-md-3" style={{ marginTop: 20, }}>
                    <div style={{ fontSize: 18, fontWeight: 700, marginBottom: 20 }}>Company</div>
                    <Link style={{ display: 'block', fontSize: 14, fontWeight: 400, color: '#fff', marginBottom: 5, textDecoration: 'none' }} to={"/about/"}>About</Link>
                    <Link style={{ display: 'block', fontSize: 14, fontWeight: 400, color: '#fff', marginBottom: 5, textDecoration: 'none' }} to={"/"} onClick={openMail}>Contact</Link>
                    <Link style={{ display: 'block', fontSize: 14, fontWeight: 400, color: '#fff', marginBottom: 5, textDecoration: 'none' }} to={"/privacy"}>Privacy Policy</Link>
                    <Link style={{ display: 'block', fontSize: 14, fontWeight: 400, color: '#fff', marginBottom: 5, textDecoration: 'none' }} to={"/terms-and-conditions"}>Terms & Conditions</Link>
                </div>
                <div className="col-sm-12 col-md-3" style={{ marginTop: 20 }}>
                    <div style={{ fontSize: 18, fontWeight: 700, marginBottom: 20 }}>Contact Us</div>
                    <div><span style={{ fontSize: 14 }}><i className="bi bi-envelope"></i></span>  support@diveo.io</div>
                </div>
            </div>
        </div >
    )
}

export default Footer

