import axios from "axios";

const renderPresetForCaptionStyle = async (data) => {
  console.log("Calling Caption Style Preset Render API with payload:", data);
  console.log(`${process.env.REACT_APP_SERVER_ENDPOINT}/process-captions`);

  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/process-captions`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    let result = response.data;
    console.log("API Response: ", result);
    return result;
  } catch (error) {
    console.log("Error Occurred");
    console.log(error);
    return { message: `Error: ${error}` };
  }
};

const getExecutionStatus = async (jobId) => {
  console.log(`Calling Execution Status Fetch API with Job-Id: ${jobId}`);
  console.log(`${process.env.REACT_APP_SERVER_ENDPOINT}/gateway`);
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/gateway`,
      {
        params: {
          jobId: jobId,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    let result = response.data.data;
    console.log("Execution Function Status Response: ", result);
    return result;
  } catch (error) {
    console.log("Error Occurred");
    console.log(error);
    return { message: `Error: ${error}` };
  }
};

export { renderPresetForCaptionStyle, getExecutionStatus };
