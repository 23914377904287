import React, { useRef, useState, useEffect } from 'react'
import useEffectOnChange from '../../hooks/useEffectOnChange';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getSettingsForComponent } from '../../constants/editorConfig';

const labelToProp = {
    'Scale': 'scale',
    'Padding': 'padding',
    'lOffset': 'lOffset',
    'LineSpacing': 'lineSpacing',
    'WordSpacing': 'wordSpacing',
    'lineCount': 'lineCount',
    'charCount': 'charCount',
    'StrokeWidth': 'strokeWidth',
    'ShadowBlur': 'blur',
    'ShadowOffsetX': 'offsetX',
    'ShadowOffsetY': 'offsetY',
}

const NumberInput = ({
    label,
    defaultValue,
    options,
    setOptions
}) => {

    const hasMounted = useRef(false);
    const isInternalUpdate = useRef()

    const [min, setMin] = useState(0)
    const [max, setMax] = useState(100)
    const [step, setStep] = useState(1)

    const [value, setValue] = useState()

    var settings = getSettingsForComponent(label);     // TODO::Compose settings object based on prop value
    const prop = labelToProp[label]

    useEffect(() => {
        if (settings) {
            setMin(settings.min)
            setMax(settings.max)
            setStep(settings.step)
        }
    }, [settings])

    useEffect(() => {
        isInternalUpdate.current = false;
        setValue(defaultValue)
    }, [defaultValue])


    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            console.log('PROP: ', prop)
            console.log('UPDATING NUMBR ==>', value)
            if (setOptions) {
                var newInfo = {}
                newInfo[prop] = value;
                options = options ?? {}
                setOptions({ ...options, ...newInfo })
            }
        }
       
    }, [value])

    const handleIncrement = () => {
        updateValue(prev => Math.min(prev + step, max))
    };

    const handleDecrement = () => {
        updateValue(prev => Math.max(prev - step, min))
    };

    const handleChange = (e) => {
        const newValue = Number(e.target.value);
        if (!isNaN(newValue) && newValue >= min && newValue <= max) {
            updateValue(newValue);
        }
    }

    const updateValue = (newValue) => {
        isInternalUpdate.current = true;
        setValue(newValue)
    }

    return (
        <>
            <div style={{ display: 'flex' }}>
                <input
                    type="number"
                    value={value}
                    className="number-input-box"
                    onChange={handleChange}
                    min={min}
                    max={max}
                    style={{ flex: '1 1 0%', minWidth: 40 }}

                />
            </div>
            <div className='chevron-container'>
                <ExpandLessIcon className='chevron-button' onClick={handleIncrement} />
                <ExpandMoreIcon className='chevron-button' onClick={handleDecrement} />
            </div>

        </>
    )
}

export default NumberInput
