import { addMediaAtIndex } from "./render";
import { store } from "../redux/store";
import { createVideoElement, createImageElement } from "./render";
import {
  updateTimeline,
  updateLatestActionStatus,
} from "../redux/projectSlice";

const actionType = {
  REPLACE_MEDIA: "replace_media",
  ADD_MEDIA: "add_media",
  DELETE_MEDIA: "delete_media",
  CAPTION_STYLE: "update_captions",
};

const actionStatus = {
  PROCESS: 0,
  COMPLETE: 1,
};

function addObjectToTimeline(objInfo, timeline) {
  console.log("TIMELINE=", timeline);
  let timelineInfo = [...timeline, objInfo];
  store.dispatch(updateTimeline({ timeline: timelineInfo }));
}

function removeObjectFromTimeline(objInfo, timeline) {}

function isActiveObject(objInfo, currentTime) {
  if (objInfo.startTime <= currentTime && objInfo.endTime > currentTime) {
    return true;
  }
  return false;
}

async function processAction(
  action,
  timeline,
  timelineElementsCache,
  currentTime,
  canvas,
  containerParams,
  clipPathRef
) {
  console.log("PROCESSING ACTION: ", action);
  if (action.status == actionStatus.PROCESS) {
    switch (action.type) {
      case actionType.REPLACE_MEDIA:
        /*
            1. Add object to timeline
            2. Find the object id from timeline
            2. If current object, delete the object from canvas
            3. If current object, add new object 
        */
        var timelineInfo = [...timeline];

        let oldObj = timelineInfo.find(
          (obj) =>
            action.startTime == obj.startTime && action.endTime == obj.endTime
        );

        await deleteMedia(action, timelineInfo, canvas, currentTime);

        if (oldObj) {
          await addMedia(
            action,
            timelineInfo,
            currentTime,
            timelineElementsCache,
            canvas,
            containerParams,
            clipPathRef
          );
        }

        break;
      case actionType.ADD_MEDIA:
        await addMedia(
          action,
          timeline,
          currentTime,
          timelineElementsCache,
          canvas,
          containerParams,
          clipPathRef
        );
        break;

      case actionType.DELETE_MEDIA:
        var timelineInfo = [...timeline];
        await deleteMedia(action, timelineInfo, canvas, currentTime);
        break;
      default:
        console.log("ACTION TYPE::INVALID");
    }
    store.dispatch(updateLatestActionStatus({ status: actionStatus.COMPLETE }));
  }
}

async function deleteMedia(action, timeline, canvas, currentTime) {
  let oldObjIdx = timeline.findIndex(
    (obj) => action.startTime == obj.startTime && action.endTime == obj.endTime
  );
  var oldObj = timeline[oldObjIdx];

  console.log("OLD TIMELINE: ", timeline);

  timeline.splice(oldObjIdx, 1);

  console.log("OBJ INDEX: ", oldObjIdx);
  console.log("NEW TIMELINE: ", timeline);

  store.dispatch(updateTimeline({ timeline: timeline }));

  if (oldObj) {
    console.log("OBj replaced: ", oldObj);
    if (isActiveObject(oldObj, currentTime)) {
      console.log("Removing obj from canvas!");
      // Find and remove the object in canvas
      const canvasObjs = canvas.getObjects();
      for (let i = 0; i < canvasObjs.length; i++) {
        var targetObj;
        var activeObj = canvasObjs[i];
        if (activeObj.id == oldObj.id) {
          console.log("obj found in canvas!");
          targetObj = activeObj;
          break;
        }
      }
      canvas.remove(targetObj);
    }
  }
}

async function addMedia(
  action,
  timeline,
  currentTime,
  timelineElementsCache,
  canvas,
  containerParams,
  clipPathRef
) {
  console.log("ADD MEDIA");
  console.log("Action: ", action);

  let objInfo = action.info;
  /*
    1. Add object to timeline
    2. If current object , add object to canvas
  */
  addObjectToTimeline(objInfo, timeline);

  if (isActiveObject(objInfo, currentTime)) {
    console.log("OBJ ACTIVE:: ->", objInfo);
    var mediaEl;
    if (objInfo.type == "video") {
      mediaEl = createVideoElement(objInfo.info, objInfo.id);
    } else {
      mediaEl = await createImageElement(objInfo.info);
    }
    timelineElementsCache[objInfo.id] = mediaEl;
    await addMediaAtIndex(
      objInfo,
      canvas,
      containerParams,
      clipPathRef,
      mediaEl,
      false
    );
    if (objInfo.type == "video") {
      await mediaEl.play();
      await mediaEl.pause();
      canvas.requestRenderAll();
    }
  }
  return { objInfo, mediaEl };
}

export { actionType, actionStatus, processAction };
