import { fabric } from "fabric";
import { getAnimationObjectFromType } from "../utils/animation";
import { hexToRgbaConvert, getTextByCase } from "../utils/commonUtils";

const CustomTextbox = fabric.util.createClass(fabric.Textbox, {
  type: "textbox",

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      backgroundColor: this.backgroundColor,
      padding: this.padding,
      borderRadius: this.borderRadius,
      maxWidth: this.maxWidth,
    });
  },

  initialize: function (text, options) {
    options || (options = {});
    this.callSuper("initialize", text, options);
    this.set({
      backgroundColor: options.backgroundColor || "",
      padding: options.padding || 0,
      borderRadius: options.borderRadius || 0,
      maxWidth: options.maxWidth || 800,
    });
  },

  _drawRoundRect: function (ctx, x, y, width, height, radius) {
    if (typeof radius === "undefined") {
      radius = 5;
    }
    if (typeof radius === "number") {
      radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      radius = {
        tl: radius.tl || 0,
        tr: radius.tr || 0,
        br: radius.br || 0,
        bl: radius.bl || 0,
      };
    }

    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y); // Top-left corner
    ctx.lineTo(x + width - radius.tr, y); // Top edge
    ctx.arc(
      x + width - radius.tr,
      y + radius.tr,
      radius.tr,
      Math.PI * 1.5,
      Math.PI * 2
    ); // Top-right corner
    ctx.lineTo(x + width, y + height - radius.br); // Right edge
    ctx.arc(
      x + width - radius.br,
      y + height - radius.br,
      radius.br,
      0,
      Math.PI * 0.5
    ); // Bottom-right corner
    ctx.lineTo(x + radius.bl, y + height); // Bottom edge
    ctx.arc(
      x + radius.bl,
      y + height - radius.bl,
      radius.bl,
      Math.PI * 0.5,
      Math.PI
    ); // Bottom-left corner
    ctx.lineTo(x, y + radius.tl); // Left edge
    ctx.arc(x + radius.tl, y + radius.tl, radius.tl, Math.PI, Math.PI * 1.5); // Top-left corner
    ctx.closePath();
    // ctx.stroke();
  },

  set: function (key, value) {
    if (typeof key === "object") {
      // If an object is passed, call set for each property
      for (var prop in key) {
        this.set(prop, key[prop]);
      }
    } else {
      // Custom logic before setting the property
      if (["text", "fontSize, fontFamily"].includes(key)) {
        console.log("Setting TEXt:", value);
        // this.set({ width: 300 });
        this._autoAdjustWidth(value);
      }

      // Call the parent class's set method
      this.callSuper("set", key, value);

      // Custom logic after setting the property (if needed)
      // For example, re-render the object
      // this.canvas && this.canvas.renderAll();
    }

    return this;
  },

  getComputedWidth: function (text) {
    var ctx = this.canvas
      ? this.canvas.getContext()
      : document.createElement("canvas").getContext("2d");
    ctx.font = this._getFontDeclaration(); // Use the current font style
    var textWidth = ctx.measureText(text).width;

    var padding = this.padding || 0;

    return textWidth + padding * 2;
  },

  _autoAdjustWidth: function (text) {
    // Create a temporary canvas context to measure text width
    var ctx = this.canvas
      ? this.canvas.getContext()
      : document.createElement("canvas").getContext("2d");
    ctx.font = this._getFontDeclaration(); // Use the current font style
    var textWidth = ctx.measureText(text).width;

    console.log("MAX WIDTH: ", this.maxWidth);
    console.log("TEXT WIDTH: ", textWidth);

    if (textWidth > this.maxWidth) {
      textWidth = this.maxWidth;
    }

    // Set the new width considering padding
    var padding = this.padding || 0;
    this.set("width", textWidth + padding * 2);
  },

  _renderBackground: function (ctx) {
    if (!this.backgroundColor) {
      return;
    }

    // Save the current canvas state
    ctx.save();

    var left = this.left;

    console.log("-------------------");
    console.log("S::TEXT: ", this.text);
    console.log("S::TOP: ", this.top);
    console.log("S::LEFT: ", left);

    ctx.fillStyle = this.backgroundColor;

    // Compute rectangle properties
    var w = this.width,
      h = this.height,
      r = Math.min(this.borderRadius, w / 2, h / 2),
      x = -w / 2,
      y = -h / 2;

    console.log("S::X: ", -w / 2);
    console.log("S::Y: ", -h / 2);

    // const offsetX = (this.group ? this.group.left : 0) - this.left;

    // Adjust x-coordinate for group context if necessary
    // x += offsetX;

    // Draw rounded rectangle with padding
    this._drawRoundRect(
      ctx,
      x - this.padding,
      y - this.padding,
      w + this.padding * 2,
      h + this.padding * 2,
      r
    );

    this.transform(ctx);

    ctx.fill();

    // Restore canvas state after rendering
    ctx.restore();

    // Call the original render method for text and other properties
    this.callSuper("_render", ctx);
  },
});

function captionFactory(canvas, captionSettings) {
  let currentText;

  var wordSpacing = captionSettings.text.options?.wordSpacing ?? 5;
  var lineSpacing = captionSettings.text.options?.lineSpacing ?? 5;
  var alignText = captionSettings.text.options?.alignText ?? "center";
  var fontSize = captionSettings.text.options?.fontSize ?? 21;

  var lineSettings = captionSettings.text.lines;

  var text;
  var lines;

  var topPos;

  var textOptions;
  var textBoxOptions;

  var wordTextBoxes = [];
  var lineTextBoxes = [];

  var textboundingBox;
  var lineBoundingBox = [];

  var textBox;

  var totalWidth = 0;
  var totalHeight = 0;

  var prevLineOffset = 0;

  function initParams(textInfo) {
    console.log("INIT PARAMS:::", textInfo);
    wordTextBoxes = [];
    lineTextBoxes = [];

    totalWidth = 0;
    totalHeight = 0;
    textboundingBox = null;
    lineBoundingBox = [];

    text = textInfo;
    lines = textInfo.lines;

    topPos = captionSettings.topPos;

    textOptions = captionSettings.text?.options;
    textBoxOptions = captionSettings.text?.boxOptions;

    prevLineOffset = 0;
  }

  function updateSettingsInfo(newSettingsInfo) {
    console.log("FACTORY:: UPDATING SETTINGS ===>: ", newSettingsInfo);
    captionSettings = newSettingsInfo;

    topPos = captionSettings.topPos;

    fontSize = captionSettings.text.options?.fontSize ?? 21;
    wordSpacing = captionSettings.text.options?.wordSpacing ?? 5;
    lineSpacing = captionSettings.text.options?.lineSpacing ?? 5;
    alignText = captionSettings.text.options?.alignText ?? "center";

    textOptions = captionSettings.text?.options;
    textBoxOptions = captionSettings.text?.boxOptions;

    lineSettings = captionSettings.text.lines;
  }

  function createTextBounds() {
    console.log("CREATE TEXT BOUNDS:: LINESETTINGS: ", lineSettings);
    var maxWidth = 0;
    var maxLineWidth = 0;

    var padding = textBoxOptions?.padding ?? 0;

    var offsetX = 0;
    var offsetY = 0;

    var lineDimsArray = [];

    prevLineOffset = padding;

    console.log("FONT SIZE: ", fontSize);

    lines.map((line, lineNum) => {
      const wordList = line["words"];
      var currentLine = [];

      var lineBoxOptions = lineSettings
        ? lineSettings[lineNum]?.boxOptions
        : null;
      var lineOptions = lineSettings
        ? lineSettings[lineNum]?.options
        : { ...textOptions, scale: 1, scaleX: 1, scaleY: 1 };

      var linePadding = lineBoxOptions?.padding ?? 0;
      var wSpacing = lineOptions?.wordSpacing ?? wordSpacing;
      var lOffset = lineOptions?.lOffset ?? 0;
      var textCase = lineOptions?.case;

      var lineHeight;

      console.log("PADDING: ", padding);
      console.log("LINE PADDING: ", linePadding);
      console.log("LINE OPTIONS: ", lineOptions);

      offsetX = lOffset + linePadding;
      offsetY = linePadding;

      maxWidth = 0;

      /*
        Handle cases for shadow
      */

      wordList.map((wordObj) => {
        var textStr = wordObj.text;

        if (textCase) {
          textStr = getTextByCase(wordObj.text, textCase);
        }

        const wordTextbox = new CustomTextbox(textStr, {
          ...lineOptions,
          objectCaching: false,
          fontSize: fontSize,
          left: offsetX,
          top: offsetY,
        });

        // wordTextbox.set('backgroundColor', 'rgba(55, 240, 38, 0.8)')

        var wordWidth = wordTextbox.width;
        offsetX += wordWidth + wSpacing;

        lineHeight = wordTextbox.height;

        maxWidth += wordWidth + wSpacing;
        if (maxLineWidth < maxWidth - wSpacing) {
          maxLineWidth = maxWidth - wSpacing;
        }

        currentLine.push(wordTextbox);
      });

      lineDimsArray[lineNum] = [lineHeight, maxWidth - wSpacing];

      // createLineBackground(lineNum, lineHeight, maxWidth, currentLine);

      // offsetY += lineHeight + linePadding + lineSpacing;
      console.log("OFFSET Y: ", offsetY);

      wordTextBoxes.push(currentLine);
    });

    wordTextBoxes.map((line, lineIdx) => {
      let lHeight = lineDimsArray[lineIdx][0];
      let lWidth = lineDimsArray[lineIdx][1];

      var alignOffset = 0;
      if (alignText == "center") {
        alignOffset = (maxLineWidth - lWidth) / 2;
      } else if (alignText == "right") {
        alignOffset = maxLineWidth - lWidth;
      }

      if (alignOffset < 0) {
        alignOffset = 0;
      }
      console.log("Align Offset::=> ", alignOffset);

      // line.map((wordObj) => {
      //   wordObj.set("left", wordObj.left + alignOffset);
      // });

      createLineBackground(lineIdx, line, lHeight, lWidth, alignOffset);
    });

    // Removing the line spacing after the last line
  }

  function createLineBackground(
    lineNum,
    currentLine,
    lineHeight,
    lineWidth,
    alignOffset
  ) {
    var lineBoxOptions = lineSettings
      ? lineSettings[lineNum]?.boxOptions
      : null;
    var lineOptions = lineSettings
      ? lineSettings[lineNum]?.options
      : { ...textOptions, scale: 1, scaleX: 1, scaleY: 1 };

    var padding = textBoxOptions?.padding ?? 0;
    var linePadding = lineBoxOptions?.padding ?? 0;
    var scaleValue = lineOptions?.scale ?? 1;
    var shadowInfo = lineOptions?.shadowInfo;
    var lOffset = lineOptions?.lOffset ?? 0;

    var shadowObj;
    if (shadowInfo) {
      shadowObj = new fabric.Shadow({
        color: shadowInfo?.fill ?? "#000", // Shadow color
        blur: shadowInfo?.blur ?? 10,
        offsetX: shadowInfo?.offsetX ?? 5,
        offsetY: shadowInfo?.offsetY ?? 5,
      });
    } else {
      shadowObj = null;
    }

    console.log("PADDING: ", padding);
    console.log("LINE PADDING: ", linePadding);
    console.log("SCALE VAL:: ", scaleValue);
    console.log(" SHADOW VAL::-> ", shadowObj);

    const lineBoxWidth = lineWidth + 2 * linePadding;
    const lineBoxHeight = lineHeight + 2 * linePadding;

    console.log("LINE BOX HEIGHT: ", lineBoxHeight);

    const top = prevLineOffset;

    prevLineOffset += scaleValue * lineBoxHeight + lineSpacing;

    totalHeight = prevLineOffset - lineSpacing - padding;
    if (totalWidth < scaleValue * lineBoxWidth) {
      totalWidth = scaleValue * lineBoxWidth;
    }

    console.log("TOP: ", top);
    const left = padding + lOffset + alignOffset;
    console.log("LEFT -> ", left);

    var lineObjs = [];
    var lineBgBox;

    if (lineBoxOptions) {
      lineBgBox = new fabric.Rect({
        width: lineBoxWidth,
        height: lineBoxHeight,
        fill: lineBoxOptions.fill,
        rx: lineBoxOptions.borderRadius ?? 0,
        ry: lineBoxOptions.borderRadius ?? 0,
        opacity: lineBoxOptions.opacity,
      });
    } else {
      lineBgBox = new fabric.Rect({
        width: lineBoxWidth,
        height: lineBoxHeight,
        opacity: 0,
      });
    }

    lineBoundingBox[lineNum] = lineBgBox;
    lineObjs.push(lineBgBox);
    lineObjs.push(...currentLine);

    const lineBoundBox = new fabric.Group(lineObjs, {
      objectCaching: false,
      top: top,
      left: left,
      scaleX: scaleValue,
      scaleY: scaleValue,
      shadow: shadowObj,
    });

    lineTextBoxes[lineNum] = lineBoundBox;
  }

  function createTextBackground() {
    var padding = textBoxOptions?.padding ?? 0;

    const textBoxWidth = totalWidth + 2 * padding;
    const textBoxHeight = totalHeight + 2 * padding;

    if (textBoxOptions) {
      textboundingBox = new fabric.Rect({
        objectCaching: false,
        width: textBoxWidth,
        height: textBoxHeight,
        fill: textBoxOptions.fill,
        rx: textBoxOptions.borderRadius ?? 0,
        ry: textBoxOptions.borderRadius ?? 0,
        opacity: textBoxOptions.opacity,
      });
    } else {
      textboundingBox = new fabric.Rect({
        objectCaching: false,
        width: textBoxWidth,
        height: textBoxHeight,
        opacity: 0,
      });
    }
  }

  function getTextBox() {
    var objs = [];
    if (textboundingBox) {
      objs.push(textboundingBox);
    }
    objs.push(...lineTextBoxes);

    var shadowInfo = textOptions?.shadowInfo;
    var shadowObj;
    if (shadowInfo) {
      shadowObj = new fabric.Shadow({
        color: shadowInfo?.fill ?? "#000", // Shadow color
        blur: shadowInfo?.blur ?? 10,
        offsetX: shadowInfo?.offsetX ?? 5,
        offsetY: shadowInfo?.offsetY ?? 5,
      });
    }

    var centerX = canvas.get("width") / 2;

    console.log("Setting Top Pos For TextBox: ", topPos);
    textBox = new fabric.Group(objs, {
      objectCaching: false,
      id: "m-txt",
      originX: "center",
      originY: "center",
      top: topPos ?? 280,
      left: centerX,
      scaleX: textOptions.scale ?? 1,
      scaleY: textOptions.scale ?? 1,
      shadow: shadowObj,
    });
    return textBox;
  }

  function getWordAtIndex(lineIdx, wordIdx) {
    let wordObjOffset = 0; // To ignore the background box at first index

    let lineBox = getLineAtIndex(lineIdx);

    if (lineBoundingBox[lineIdx]) {
      wordObjOffset = 1;
    }

    if (lineBox) {
      var wordObjs = lineBox.getObjects();
      if (wordObjs.length) {
        return wordObjs[wordIdx + wordObjOffset];
      }
      return;
    }
    return;
  }

  const setOriginCenter = (object) => {
    console.log("--> SET ORIGIN");
    var cPoint = object.getCenterPoint();
    object.set("left", cPoint.x);
    object.set("top", cPoint.y);
    object.set("originX", "center");
    object.set("originY", "center");
  };

  const removeOriginCenter = (object) => {
    console.log("--> REMOVE ORIGIN");
    const w = object.width;
    const h = object.height;

    const left = object.left - w / 2;
    const top = object.top - h / 2;

    object.set("originX", "left");
    object.set("originY", "top");

    // object.set("top", top);
    // object.set("left", left);
  };

  function getLineAtIndex(lineIdx) {
    let lineObjOffset = 0;

    if (textboundingBox) {
      lineObjOffset = 1;
    }

    if (textBox) {
      var lineObjs = textBox.getObjects();
      if (lineObjs.length) {
        return lineObjs[lineIdx + lineObjOffset];
      }
      return;
    }
    return;
  }

  function adjustLinePositions(lineIdx) {
    console.log("ADJUSTING LINE POSITIONS");
    var lineObjs = textBox.getObjects();
    console.log("TEXT OBJS:==> ", lineObjs);

    const lineBox = getLineAtIndex(lineIdx);
    if (!lineBox) return;
    const lineScaleY = lineBox.get("scaleY") ?? 1;
    const lineHeight = lineBox.get("height") * lineScaleY;
    const lineTopPostionValue = lineBox.get("top");

    var yOffset = lineTopPostionValue + lineHeight + lineSpacing;

    for (let i = lineIdx + 1; i < lineObjs.length; i++) {
      const nextLineBox = getLineAtIndex(i);
      console.log("NEXT LINE: ", nextLineBox);

      if (nextLineBox) {
        nextLineBox.set("top", yOffset);
        const nextLineScaleY = nextLineBox.get("scaleY") ?? 1;

        yOffset += nextLineBox.get("height") * nextLineScaleY + lineSpacing;
        console.log(
          "Lien Heigth: ",
          nextLineBox.get("height") * nextLineScaleY
        );
      }
    }
  }

  function getLineBounds(idx) {
    var lineBox = getLineAtIndex(idx);
    const wordObjs = lineBox.getObjects();

    var lineBoxOptions = lineSettings ? lineSettings[idx]?.boxOptions : null;
    var lineOptions = lineSettings ? lineSettings[idx]?.options : null;
    var linePadding = lineBoxOptions?.padding ?? 0;

    var scale = lineOptions?.scale ?? 1;

    var lineBoxWidth = linePadding;
    var lineBoxHeight;

    for (let i = 0; i < wordObjs.length; i++) {
      const wordObj = wordObjs[i];

      if (wordObj.type == "rect") {
        continue;
      }

      var wordWidth = wordObj.width;
      lineBoxWidth += wordWidth + wordSpacing;
      lineBoxHeight = wordObj.height;
    }

    lineBoxWidth = (lineBoxWidth - wordSpacing + linePadding) * scale;

    return { lineBoxWidth, lineBoxHeight };
  }

  function getAlignOffset(lineIdx) {
    if (textBox) {
      var lineObjs = textBox.getObjects();

      var maxLineWidth = 0;
      var lineWidth = 0;
      var lineBoxOptions = lineSettings
        ? lineSettings[lineIdx]?.boxOptions
        : null;

      var linePadding = lineBoxOptions?.padding ?? 0;

      for (let i = 0; i < lineObjs.length; i++) {
        const lineBox = lineObjs[i];
        if (lineBox.type == "rect") {
          continue;
        }
        if (i - 1 == lineIdx) {
          lineWidth = lineBox.width;
        }
        if (maxLineWidth < lineBox.width) {
          maxLineWidth = lineBox.width;
        }
      }

      let alignOffset = 0;
      if (alignText == "center") {
        alignOffset = (maxLineWidth - lineWidth) / 2;
      } else if (alignText == "right") {
        alignOffset = maxLineWidth - lineWidth;
      }

      if (alignOffset < 0) {
        alignOffset = 0;
      }
      console.log("Line Idx -> ", lineIdx);
      console.log("ALIGN OFFSET::=> ", alignOffset);
      return alignOffset;
    }
  }

  function getTextBoxBounds() {
    const padding = textBoxOptions?.padding ?? 0;
    console.log("PADDINGGGG => ", padding);

    var lineObjs = textBox.getObjects();

    var newBoxHeight = 2 * padding;
    var newBoxWidth = 0;

    for (let i = 0; i < lineObjs.length; i++) {
      const lineBox = lineObjs[i];
      var alignOffset = getAlignOffset(i - 1);
      var lOffset =
        lineSettings && i > 0 && lineSettings[i - 1]?.options?.lOffset
          ? lineSettings[i - 1]?.options?.lOffset
          : 0;
      console.log("RBD Line Settings -> ", lineSettings);
      console.log("lOffset -> ", lOffset);

      if (lineBox.type == "rect") {
        continue;
      }

      const scaleX = lineBox.get("scaleX") ?? 1;
      const scaleY = lineBox.get("scaleY") ?? 1;

      var lineBoxHeight = lineBox.get("height") * scaleY;
      // var lineBoxWidth = lineBox.get("width") * scaleX;
      var lineBoxWidth =
        (alignOffset + lOffset + lineBox.get("width")) * scaleX;

      console.log("line height: ", lineBoxHeight);
      console.log("line width: ", lineBoxWidth);

      newBoxHeight += lineBoxHeight + lineSpacing;

      if (newBoxWidth < lineBoxWidth) {
        newBoxWidth = lineBoxWidth;
      }
    }

    newBoxHeight = newBoxHeight - lineSpacing;
    newBoxWidth = newBoxWidth + 2 * padding;

    console.log("NEW BOX HEIGHT: ", newBoxHeight);
    console.log("NEW BOX WIDTH: ", newBoxWidth);
    return { newBoxWidth, newBoxHeight };
  }

  function recomputeBoxDimensions() {
    console.log("RECOMPUTING BOX DIMENSIONS");
    if (textBox) {
      var lineObjs = textBox.getObjects();

      var textBoxBg = lineObjs[0];

      console.log("TEXT BG BOX:: ", textBoxBg);

      if (textBoxBg) {
        var { newBoxWidth, newBoxHeight } = getTextBoxBounds();

        console.log("NEW BOX WIDTH: ", newBoxWidth);

        textBoxBg.set("height", newBoxHeight);
        textBoxBg.set("width", newBoxWidth);
        console.log("TEXT BOX HT::2 ", newBoxHeight);
        console.log("TEXT BOX WD::2 ", newBoxWidth);

        recalcBoundsAndCoords(textBox);
      }
    }
  }

  function recalcBoundsAndCoords(targetObj) {
    if (targetObj) {
      var topVal = targetObj.get("top");
      var leftVal = targetObj.get("left");

      var scaleX = targetObj.get("scaleX");
      var scaleY = targetObj.get("scaleY");

      targetObj.set("scaleX", 1);
      targetObj.set("scaleY", 1);

      targetObj._calcBounds();
      targetObj._updateObjectsCoords();

      targetObj.set("scaleX", scaleX);
      targetObj.set("scaleY", scaleY);

      targetObj.set("left", leftVal);
      targetObj.set("top", topVal);
    }
  }

  function updateStyleForWord(idx, lineIdx, prop, value) {
    var wordObj = getWordAtIndex(lineIdx, idx);
    if (!wordObj) return;
    var topPos = wordObj.top;
    var leftPos = wordObj.left;

    if (["fill", "opacity"].includes(prop)) {
      wordObj.set(prop, value);
    } else if (["scale", "scaleX", "scaleY"].includes(prop)) {
      setOriginCenter(wordObj);
      wordObj.set("scaleX", value);
      wordObj.set("scaleY", value);
      // removeOriginCenter(wordObj);
      // wordObj.set("top", topPos);
      // wordObj.set("left", leftPos);
    } else if (prop == "shadowInfo") {
      var shadowObj;
      if (value) {
        shadowObj = new fabric.Shadow({
          color: value?.fill ?? "#000", // Shadow color
          blur: value?.blur ?? 10,
          offsetX: value?.offsetX ?? 5,
          offsetY: value?.offsetY ?? 5,
        });
      } else {
        shadowObj = null;
      }
      wordObj.set("shadow", shadowObj);
    }
  }

  function updateBoxStyleForWord(idx, lineIdx, prop, value) {
    var wordObj = getWordAtIndex(lineIdx, idx);
    if (!wordObj) return;
    if (prop == "fill") {
      wordObj.set("backgroundColor", value);
    } else if (prop == "opacity") {
      var bgColor = wordObj.get("backgroundColor");
      if (bgColor) {
        var rgbaFill = hexToRgbaConvert(bgColor, value);
        wordObj.set("backgroundColor", rgbaFill);
      }
    } else if (prop == "padding") {
      wordObj.set("padding", value);
    } else if (prop == "borderRadius") {
      wordObj.set("borderRadius", value);
    }
  }

  function updateStyleForLines(lineIdx, prop, value) {
    var lineBoxOptions = lineSettings
      ? lineSettings[lineIdx]?.boxOptions
      : null;
    var lineOptions = lineSettings
      ? lineSettings[lineIdx]?.options
      : { ...textOptions, scale: 1, scaleX: 1, scaleY: 1 };
    var linePadding = lineBoxOptions?.padding ?? 0;

    var lineBox = getLineAtIndex(lineIdx);
    if (!lineBox) return;
    const wordObjs = lineBox?.getObjects() ?? [];

    if (prop == "scale") {
      lineBox.set("scaleX", value);
      lineBox.set("scaleY", value);

      adjustLinePositions(lineIdx);
      recomputeBoxDimensions();
    } else if (["opacity", "fill", "stroke", "strokeWidth"].includes(prop)) {
      wordObjs.map((wordObj) => {
        if (wordObj.type != "rect") {
          wordObj.set(prop, value);
        }
      });
    } else if (prop == "shadowInfo") {
      var shadowObj;
      if (value) {
        shadowObj = new fabric.Shadow({
          color: value?.fill ?? "#000", // Shadow color
          blur: value?.blur ?? 10,
          offsetX: value?.offsetX ?? 5,
          offsetY: value?.offsetY ?? 5,
        });
      } else {
        shadowObj = null;
      }

      console.log("NEW SHADOW VAL: ", shadowObj);

      wordObjs.map((wordObj) => {
        if (wordObj.type != "rect") {
          wordObj.set("shadow", shadowObj);
          console.log("UPDATING WORD:: SHADOW VAL: ", shadowObj);
        }
      });
    } else if (
      [
        "fontFamily",
        "fontWeight",
        "fontStyle",
        "case",
        "wordSpacing",
        "lOffset",
      ].includes(prop)
    ) {
      var maxLineWidth = 0;
      var lineBoxBg = wordObjs[0];

      var wSpacing = lineOptions?.wordSpacing ?? wordSpacing;
      var lOffset = lineOptions?.lOffset ?? 0;

      if (prop == "lOffset") {
        lOffset = value;
        var lineObjs = textBox.getObjects();
        var textBoxBg = lineObjs[0];

        // lineSettings[lineIdx].options.lOffset = value;

        console.log("UPDATING LINE -> ", lineIdx);
        console.log("LINE SETTINGS -> ", lineSettings);
        const updatedLineSettings = lineSettings.map((obj, idx) =>
          idx === lineIdx
            ? {
                ...obj,
                options: { ...obj.options, lOffset: value },
              }
            : obj
        );

        console.log("UPDATED LINE SETTINGS -> ", updatedLineSettings);
        lineSettings = updatedLineSettings;

        var padding = textBoxOptions?.padding ?? 0;
        updateBoxStyleForText("padding", padding);
        return;
      }
      if (prop == "wordSpacing") {
        wSpacing = value;
      }

      var alignOffset = getAlignOffset(lineIdx);
      var offsetX = 0;

      for (let i = 0; i < wordObjs.length; i++) {
        const wordObj = wordObjs[i];
        if (wordObj.type == "rect") {
          // Bounding Box Found
          lineBoxBg = wordObj;
          offsetX = lineBox.get("left") + lOffset + alignOffset;
          wordObj.set("left", offsetX);
          offsetX += linePadding;
          continue;
        }

        if (prop == "case") {
          var newText = getTextByCase(wordObj.text, value);
          wordObj.set("text", newText);
        }

        wordObj.set("left", offsetX);

        wordObj.set(prop, value);
        // wordObj.initDimensions();

        var wordWidth = wordObj.getComputedWidth(wordObj.text);
        wordObj.set("width", wordWidth);

        console.log("WORD WIDTH:: ", wordWidth);

        maxLineWidth += wordWidth + wSpacing;
        offsetX += wordWidth + wSpacing;
      }

      maxLineWidth = maxLineWidth - wSpacing + 2 * linePadding;

      console.log("LINE BOX WIDTH:: ", maxLineWidth);

      if (lineBoxBg) {
        lineBoxBg.set("width", maxLineWidth);
      }

      recalcBoundsAndCoords(lineBox);
      recomputeBoxDimensions();
    }
  }

  function updateBoxStyleForLine(lineIdx, prop, value) {
    /**
     * PROPS: fill, borderRadius, padding, opacity
     */

    var lineOptions = lineSettings ? lineSettings[lineIdx]?.options : null;
    var wSpacing = lineOptions?.wordSpacing ?? wordSpacing;
    var lOffset = lineOptions?.lOffset ?? 0;

    var lineBox = getLineAtIndex(lineIdx);
    if (!lineBox) return;
    const wordObjs = lineBox.getObjects();

    var lineBoxBg;

    if (wordObjs[0].type == "rect") {
      lineBoxBg = wordObjs[0];
    }

    if (lineBoxBg) {
      if (prop == "fill") {
        if (value) {
          console.log("SETTING BOX FILL FOR LINE IDX: ", lineIdx);
          console.log("VALUE", value);
          lineBoxBg.set("fill", value);
        }
      } else if (prop == "opacity") {
        lineBoxBg.set("opacity", value);
      } else if (prop == "borderRadius") {
        lineBoxBg.set("rx", value);
        lineBoxBg.set("ry", value);
      } else if (prop == "padding") {
        console.log("UPDATING BOX STYLE: PADDING:: ", value);

        var top = lineBoxBg.get("top");
        var left = lineBoxBg.get("left");

        var offsetX = left + value;
        var lineBoxWidth = value;
        var lineBoxHeight = 0;

        for (let i = 0; i < wordObjs.length; i++) {
          const wordObj = wordObjs[i];
          if (wordObj.type == "rect") {
            // Bounding Box Found
            lineBoxBg = wordObj;
            continue;
          }

          wordObj.set("top", top + value);
          wordObj.set("left", offsetX);

          var wordWidth = wordObj.width;
          offsetX += wordWidth + wSpacing;
          lineBoxWidth += wordWidth + wSpacing;
          lineBoxHeight = wordObj.height + 2 * value;
        }

        lineBoxWidth = lineBoxWidth - wSpacing + value;

        lineBoxBg.set("height", lineBoxHeight);
        lineBoxBg.set("width", lineBoxWidth);

        recalcBoundsAndCoords(lineBox);

        adjustLinePositions(lineIdx);
        recomputeBoxDimensions();
      }
    }
  }

  function updateStyleForText(prop, value) {
    console.log(`UPDATING PROP FOR TEXT: ${prop} -> ${value}`);
    if (!textBox) return;
    if (["fill", "opacity"].includes(prop)) {
      if (!lineSettings) {
        for (let i = 0; i < captionSettings.lineCount; i++) {
          console.log("UPDATING PROP FOR LINE: ", i);
          updateStyleForLines(i, prop, value);
        }
      }
    } else if (
      [
        "fontFamily",
        "fontWeight",
        "fontStyle",
        "case",
        "shadowInfo",
        "stroke",
        "strokeWidth",
        "wordSpacing",
      ].includes(prop)
    ) {
      if (!lineSettings) {
        for (let i = 0; i < captionSettings.lineCount; i++) {
          console.log("UPDATING PROP FOR LINE: ", i);
          updateStyleForLines(i, prop, value);
        }

        console.log("TEXT BOX OPTIONS: ", textBoxOptions);
        recalcBoundsAndCoords(textBox);
        recomputeBoxDimensions();
      }
    } else if (prop == "scale") {
      textBox.set("scaleX", value);
      textBox.set("scaleY", value);

      recomputeBoxDimensions();
    } else if (prop == "lineSpacing") {
      lineSpacing = value;
      // Reuse same code to change line-spacing by re-enforcing the current padding value
      updateBoxStyleForText("padding", textBoxOptions?.padding ?? 0);
    } else if (prop == "alignText") {
      alignText = value;
      // Reuse same code to change line-spacing by re-enforcing the current padding value
      updateBoxStyleForText("padding", textBoxOptions?.padding ?? 0);
    }
  }

  function updateBoxStyleForText(prop, value) {
    console.log("UPDATING BG BOX PROP FOR TEXT: ", prop);

    var lineObjs = textBox?.getObjects();
    if (!lineObjs) return;
    var textBoxBg = lineObjs[0];

    if (textBoxBg) {
      if (prop == "fill") {
        textBoxBg.set("fill", value);
      } else if (prop == "opacity") {
        textBoxBg.set("opacity", value);
      } else if (prop == "borderRadius") {
        textBoxBg.set("rx", value);
        textBoxBg.set("ry", value);
      } else if (prop == "padding") {
        console.log("UPDATING TEXT BOX STYLE: PADDING:: ", value);

        var top = textBoxBg.get("top");
        var left = textBoxBg.get("left");

        var offsetY = top + value;

        var textBoxWidth = 0;
        var textBoxHeight = value;

        for (let i = 0; i < lineObjs.length; i++) {
          const lineObj = lineObjs[i];
          var alignOffset = getAlignOffset(i - 1);
          var lineOptions =
            lineSettings && i > 0 && lineSettings[i - 1]?.options
              ? lineSettings[i - 1]?.options
              : { ...textOptions, scale: 1, scaleX: 1, scaleY: 1 };
          var lOffset = lineOptions?.lOffset ?? 0;
          var scaleVal = lineOptions?.scale ?? 1;

          if (lineObj.type == "rect") {
            // Text BoundBox Found
            continue;
          }

          lineObj.set("top", offsetY);
          lineObj.set("left", left + alignOffset + lOffset + value);

          var lineHeight = scaleVal * lineObj.height;
          offsetY += lineHeight + lineSpacing;

          var lineWidth = lineObj.width + lOffset + alignOffset;

          textBoxHeight += lineHeight + lineSpacing;
          if (textBoxWidth < lineWidth) {
            textBoxWidth = lineWidth;
          }
        }

        textBoxWidth = textBoxWidth + 2 * value;
        textBoxHeight = textBoxHeight - lineSpacing + value;

        textBoxBg.set("height", textBoxHeight);
        textBoxBg.set("width", textBoxWidth);

        console.log("TEXT BOX HT::1 ", textBoxHeight);
        console.log("TEXT BOX WD::1 ", textBoxWidth);

        recalcBoundsAndCoords(textBox);
        recomputeBoxDimensions();
      }
    }
  }

  function toggleTextBackground(isEnabled) {
    var lineObjs = textBox.getObjects();
    var textBoxBg = lineObjs[0];
    if (isEnabled) {
      updateBoxStyleForText("opacity", 1);

      if (!textBoxOptions) {
        updateBoxStyleForText("borderRadius", 0);
        updateBoxStyleForText("padding", 0);
      }
    } else {
      textBoxBg.set("opacity", 0);
    }
  }

  function toggleLineBackground(idx, isEnabled) {
    var lineBox = getLineAtIndex(idx);
    if (lineBox) {
      var wordObjs = lineBox.getObjects();

      var lineBgBox = wordObjs[0];
      if (isEnabled) {
        // updateBoxStyleForLine(idx, 'opacity', 1)

        if (!lineSettings[idx]?.boxOptions) {
          updateBoxStyleForLine(idx, "padding", 0);
          updateBoxStyleForLine(idx, "borderRadius", 0);
        }
      } else {
        lineBgBox.set("opacity", 0);
      }
    }
  }

  function constructAnimObj(targetObj, animInfo) {
    var animObj = {};
    animObj["targets"] = [targetObj];
    var leftPos = targetObj.left;
    var topPos = targetObj.top;

    Object.keys(animInfo).map((key) => {
      if (key == "items") {
        animInfo[key].map((propObj) => {
          if (["left", "right", "top", "bottom"].includes(propObj.prop)) {
            const targetPropValue = targetObj.get(propObj.prop);
            animObj[propObj.prop] = [
              targetPropValue + propObj.start,
              targetPropValue + propObj.end,
            ];
          } else if (["scaleX", "scaleY"].includes(propObj.prop)) {
            const targetPropValue = targetObj.get(propObj.prop);
            setOriginCenter(targetObj);
            animObj[propObj.prop] = [
              targetPropValue * propObj.start,
              targetPropValue * propObj.end,
            ];
            animObj["complete"] = function () {
              console.log("db");
              // removeOriginCenter(targetObj);
              // targetObj.set("left", leftPos);
              // targetObj.set("top", topPos);
              canvas.renderAll();
            };
          } else if (["opacity", "angle"].includes(propObj.prop)) {
            animObj[propObj.prop] = [propObj.start, propObj.end];
          } else {
          }
        });
      } else {
        animObj[key] = animInfo[key];
      }
    });

    animObj["update"] = function () {
      console.log("db");
      canvas.renderAll();
    };

    console.log("ANIM OBJ ::=> ", animObj);
    return animObj;
  }

  return {
    getText() {
      return text?.text;
    },

    getTextObject() {
      return textBox;
    },

    init() {
      initParams({ text: "", lines: [] });
    },

    updateSettings(info) {
      updateSettingsInfo(info);
    },

    setNew(textInfo) {
      initParams(textInfo);

      createTextBounds();
      createTextBackground();

      if (currentText) {
        canvas.remove(currentText);
      }
      currentText = getTextBox();
      return currentText;
    },

    updateTextTopPositionValue(value) {
      topPos = value;
      if (textBox) {
        textBox.set("top", value);
      }
    },

    updateStyle(target, idx, prop, value) {
      console.log("UPDATING STYLE: ", target);
      if (target == "text") {
        updateStyleForText(prop, value);
      } else if (target == "line") {
        updateStyleForLines(idx, prop, value);
      }
    },

    updateBoxStyle(target, idx, prop, value) {
      if (target == "text") {
        updateBoxStyleForText(prop, value);
      } else if (target == "line") {
        updateBoxStyleForLine(idx, prop, value);
      }
    },

    toggleBackground(target, idx, isEnabled) {
      if (target == "line") {
        toggleLineBackground(idx, isEnabled);
      } else if (target == "text") {
        toggleTextBackground(isEnabled);
      }
    },

    // getWord(idx) {
    //   return getWordAtIndex(idx);
    // },

    getTextAnim() {
      const textObj = textBox;
      let animObj;

      const animKey = "renderAnim";
      console.log("ANIMN TEXT::", text);
      const animInfo = text[animKey];
      console.log("ANIMN TEXT:: INFO", animInfo);

      if (animInfo) {
        const options = {
          speed: animInfo.speed ?? 100,
          intensity: animInfo.intensity ?? 100,
        };

        const animProps = getAnimationObjectFromType(
          animInfo?.type ?? "none",
          options
        );
        console.log("ANIMN LINES:: PROPS", animProps);

        if (animProps) {
          animObj = constructAnimObj(textObj, animProps);
        }
      }

      return animObj;
    },

    restoreStyle(target, lineIdx, wordIdx) {
      var lineOptions = lineSettings
        ? lineSettings[lineIdx]?.options
        : { ...textOptions, scale: 1, scaleX: 1, scaleY: 1 };

      if (lineOptions) {
        if (target == "line") {
          Object.entries(lineOptions).forEach(([key, value]) => {
            updateStyleForLines(lineIdx, key, value);
          });
          // updateActiveLineStyle(lineIdx, lineOptions);
        } else if (target == "word") {
          Object.entries(lineOptions).forEach(([key, value]) => {
            updateStyleForWord(wordIdx, lineIdx, key, value);
          });
          // updateActiveWordStyle(wordIdx, lineIdx, lineOptions);
        }
      }
    },

    restoreBoxStyle(target, lineIdx, wordIdx) {
      var lineBoxOptions = lineSettings
        ? lineSettings[lineIdx]?.boxOptions
        : {
            padding: 0,
            borderRadius: 0,
            fill: "",
            opacity: 0,
          };

      if (target == "line") {
        if (lineBoxOptions) {
          Object.entries(lineBoxOptions).forEach(([key, value]) => {
            updateBoxStyleForLine(lineIdx, key, value);
          });
        }
      } else if (target == "word") {
        const wordBoxOptions = {
          padding: 0,
          borderRadius: 0,
          fill: "",
        };
        Object.entries(wordBoxOptions).forEach(([key, value]) => {
          updateBoxStyleForWord(wordIdx, lineIdx, key, value);
        });
      }
    },

    updateActiveLineStyle(idx, options) {
      Object.entries(options).forEach(([key, value]) => {
        updateStyleForLines(idx, key, value);
      });
    },

    updateActiveLineBoxStyle(idx, boxOptions) {
      Object.entries(boxOptions).forEach(([key, value]) => {
        updateBoxStyleForLine(idx, key, value);
      });
    },

    updateActiveWordStyle(idx, lineIdx, options) {
      Object.entries(options).forEach(([key, value]) => {
        updateStyleForWord(idx, lineIdx, key, value);
      });
    },

    updateActiveWordBoxStyle(idx, lineIdx, boxOptions) {
      Object.entries(boxOptions).forEach(([key, value]) => {
        updateBoxStyleForWord(idx, lineIdx, key, value);
      });
    },

    getLineAnim(lineIdx, type) {
      const lineObj = getLineAtIndex(lineIdx);
      let animObj;
      var animInfo;

      const animKey = type == "active" ? "activeAnim" : "renderAnim";

      if (type == "active") {
        animInfo = lines[lineIdx]["onActive"]["anim"];
      } else {
        animInfo = lines[lineIdx]["renderAnim"];
      }

      console.log("ANIMN LINES:: INFO", animInfo);

      if (animInfo) {
        const options = {
          speed: animInfo.speed ?? 100,
          intensity: animInfo.intensity ?? 100,
        };

        const animProps = getAnimationObjectFromType(
          animInfo?.type ?? "none",
          options
        );
        console.log("ANIMN LINES:: PROPS", animProps);

        if (animProps) {
          animObj = constructAnimObj(lineObj, animProps);
        }
      }

      return animObj;
    },

    getWordAnim(lineIdx, wordIdx, type) {
      const wordObj = getWordAtIndex(lineIdx, wordIdx);
      let animObj;
      var animInfo;

      if (type == "active") {
        animInfo = lines[lineIdx]["words"][wordIdx]["onActive"]["anim"];
      } else {
        animInfo = lines[lineIdx]["words"][wordIdx]["renderAnim"];
      }

      console.log("ANIMN WORDS:: INFO", animInfo);

      if (animInfo) {
        const options = {
          speed: animInfo.speed ?? 100,
          intensity: animInfo.intensity ?? 100,
        };

        const animProps = getAnimationObjectFromType(
          animInfo?.type ?? "none",
          options
        );
        console.log("ANIMN WORDS:: PROPS", animProps);

        if (animProps) {
          animObj = constructAnimObj(wordObj, animProps);
        }
      }

      return animObj;
    },
  };
}

export { CustomTextbox, captionFactory };
