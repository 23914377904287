import React, {useState, useEffect} from 'react'
import '../css/input.css'
import GridViewIcon from '@mui/icons-material/GridView';

const Sidebar = ({active, setActive}) => {

    const menuItems = [
        {
            name: 'Dashboard',
            icon: <GridViewIcon style={{fontSize: '1.5rem'}}/>
        }
    ]

    return (
        <>
            <div className='sidebar-menu'>
                {menuItems.map((item, index) => (
                    <div className={`${active === index ? "box-item-active" : "box-item-container"}`} onClick={() => setActive(index)}>
                        <span >
                            {item.icon}
                        </span>
                        <p className='menu-description'>{item.name}</p>
                    </div>
                    )
                )}
            </div>
        </>
    )
}

export default Sidebar
