import anime from "animejs/lib/anime.es.js";

function getAnimationObjectFromType(type, options) {
  var animObj;

  var speed = options?.speed ? options.speed / 100 : 1;
  var intensity = options?.intensity ? options.intensity / 100 : 1;

  var duration = 250 / speed;

  switch (type) {
    case "fade":
      animObj = {
        items: [
          {
            prop: "opacity",
            start: 0,
            end: 1,
          },
        ],
        easing: "easeOutQuad",
        duration: duration,
      };
      break;
    case "pop":
      animObj = {
        items: [
          {
            prop: "scaleX",
            start: 0,
            end: 1,
          },
          {
            prop: "scaleY",
            start: 0,
            end: 1,
          },
        ],
        easing: "easeOutBack",
        duration: duration,
      };
      break;
    case "popSpin":
      animObj = {
        items: [
          {
            prop: "scaleX",
            start: 0,
            end: 1,
          },
          {
            prop: "scaleY",
            start: 0,
            end: 1,
          },
          {
            prop: "angle",
            start: 360 * intensity,
            end: 0,
          },
        ],
        easing: "easeOutBack",
        duration: duration,
      };
      break;
    case "slideUp":
      animObj = {
        items: [
          {
            prop: "top",
            start: 50 * intensity,
            end: 0,
          },
          {
            prop: "opacity",
            start: 0,
            end: 1,
          },
        ],
        easing: "easeOutQuad",
        duration: duration,
      };
      break;
    case "slideLeft":
      animObj = {
        items: [
          {
            prop: "left",
            start: 50 * intensity,
            end: 0,
          },
          {
            prop: "opacity",
            start: 0,
            end: 1,
          },
        ],
        easing: "easeOutQuad",
        duration: duration,
      };
      break;
    case "slideRight":
      animObj = {
        items: [
          {
            prop: "left",
            start: -50 * intensity,
            end: 0,
          },
          {
            prop: "opacity",
            start: 0,
            end: 1,
          },
        ],
        easing: "easeOutQuad",
        duration: duration,
      };
      break;
    case "slideDown":
      animObj = {
        items: [
          {
            prop: "top",
            start: -50 * intensity,
            end: 0,
          },
          {
            prop: "opacity",
            start: 0,
            end: 1,
          },
        ],
        easing: "easeOutQuad",
        duration: duration,
      };
      break;
    case "zoom":
      animObj = {
        items: [
          {
            prop: "scaleX",
            start: 3 * intensity,
            end: 1,
          },
          {
            prop: "scaleY",
            start: 3 * intensity,
            end: 1,
          },
          // {
          //   prop: "opacity",
          //   start: 0,
          //   end: 1,
          // },
        ],
        easing: "easeOutQuad",
        duration: duration,
      };
      break;
    case "zoomSpin":
      animObj = {
        items: [
          {
            prop: "scaleX",
            start: 3 * intensity,
            end: 1,
          },
          {
            prop: "scaleY",
            start: 3 * intensity,
            end: 1,
          },
          {
            prop: "angle",
            start: 360 * intensity,
            end: 0,
          },
          {
            prop: "opacity",
            start: 0,
            end: 1,
          },
        ],
        easing: "easeOutQuad",
        duration: duration,
      };
      break;
  }

  return animObj;
}

function setOriginCenter(object) {
  var cPoint = object.getCenterPoint();
  object.set("left", cPoint.x);
  object.set("top", cPoint.y);
  object.set("originX", "center");
  object.set("originY", "center");
}

function removeOriginCenter(object) {
  object.set("originX", "left");
  object.set("originY", "top");
}

function getDuration(duration) {
  return duration ? duration * 1000 : 1000;
}

function extendAnimationSequence(canvas, animationSequence, animObj) {
  console.log("Animation Obj: ", animObj);

  if (animObj.type == "image") {
    var object = animObj.target;
    console.log("ANIM TARGET ::=> ", object);

    if (animObj._animType == "imageScaleEffect") {
      var scaleFactor = object.scaleX;
      var top = object.top;
      var left = object.left;
      setOriginCenter(object);
      console.log("Anim Obj Duration: ", animObj);
      var timeline = {
        targets: [object],
        scaleX: [scaleFactor, scaleFactor * 1.3],
        scaleY: [scaleFactor, scaleFactor * 1.3],
        duration: animObj.duration * 1000 ?? 3000,
        easing: "linear",
        update: function (anim) {
          console.log("ANIM PROGRESS: ", anim.progress);
          canvas.renderAll();
        },
        complete: function (anim) {
          // canvas.viewportCenterObject(object);
          removeOriginCenter(object);
          object.set("scaleX", scaleFactor);
          object.set("scaleY", scaleFactor);
          object.set("top", top);
          object.set("left", left);
        },
      };

      animationSequence.push(timeline);
    }
  }
}

function createAnimationTimeline(canvas, timelineObjects, control) {
  var animationSequence = [];
  console.log("Timeline Objects: ", timelineObjects);

  var timelineObjectsInOrder = timelineObjects.sort(function (a, b) {
    return a.start - b.start;
  });
  console.log("Timeline Objects: ", timelineObjectsInOrder);
  timelineObjectsInOrder.forEach((animObj) => {
    extendAnimationSequence(canvas, animationSequence, animObj);
  });

  console.log("Animation Sequence: ", animationSequence);

  var t1 = anime.timeline({
    easing: "easeOutExpo",
    autoplay: control ? false : true,
  });

  animationSequence.forEach((animObj) => {
    t1.add(animObj, animObj.start ?? 0);
  });

  if (control) {
    t1.pause();
  }

  return t1;
}

export { getAnimationObjectFromType, createAnimationTimeline };
