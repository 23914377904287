import { auth } from "../config/firebaseConfig";

export default async function isUserSubscribed() {
  await auth.currentUser?.getIdToken(true);
  console.log("Current User: ", auth.currentUser);
  const decodedToken = await auth.currentUser?.getIdTokenResult(true);
  auth.currentUser?.reload();
  console.log("Decoded Token: ", decodedToken);

  console.log("STRIPE ROLE: ", decodedToken?.claims?.stripeRole);
  return decodedToken?.claims?.stripeRole ? true : false;
}
