import React, {useState, useEffect} from 'react'

const TimestampsView = ({textTimestampsRef}) => {

 
    const [textTimestampInfo, setTextTimestampsInfo] = useState([])

    useEffect(() => {
        console.log('Timestamps View: ', textTimestampsRef)
        setTextTimestampsInfo(textTimestampsRef.current)
    }, [textTimestampsRef.current])

    return (
        <div style={{marginTop: 30, width: '100%', height: '100vh', fontFamily: 'Roboto', overflow: 'scroll'}}>
            {
                textTimestampInfo.map((info) => 
                    <div style={{display: 'flex', width: '100%', marginTop: 15, fontSize: 15}}>
                        <div style={{ flex: 1, color: '#767679', fontSize: 14}}>
                            {info.startTime.toFixed(1)}
                        </div>
                        <div  contentEditable={true} style={{flex: 3}}>
                            <p>{info.text}</p>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default TimestampsView
