import React, { useRef, useState, useEffect } from 'react';
import useEffectOnChange from '../../hooks/useEffectOnChange';
import { getSettingsForComponent } from '../../constants/editorConfig';

const labelToProp = {
    'Roundness': 'borderRadius',
    'Opacity': 'opacity',
    'Speed': 'speed',
    'Intensity': 'intensity'
}

const LabeledSlider = ({
    label,
    defaultValue,
    options,
    setOptions
}) => {
    const hasMounted = useRef(false);
    const isInternalUpdate = useRef()

    const [min, setMin] = useState(0)
    const [max, setMax] = useState(100)
    const [step, setStep] = useState(1)

    const [value, setValue] = useState(defaultValue);

    var settings = getSettingsForComponent(label);     // TODO::Compose settings object based on prop value
    const prop = labelToProp[label]

    useEffect(() => {
        if (settings) {
            setMin(settings.min)
            setMax(settings.max)
            setStep(settings.step)
        }
    }, [settings])

    useEffect(() => {
        isInternalUpdate.current = false;
        setValue(defaultValue)
    }, [defaultValue])

    useEffectOnChange(() => {
        // if (!hasMounted.current) {
        //     hasMounted.current = true;
        //     return;
        // }
        if(isInternalUpdate.current){
            console.log('UPDATING SLIDR==>')
            if (setOptions) {
                var newInfo = {}
                newInfo[prop] = value;
                options = options ?? {}
                setOptions({ ...options, ...newInfo })
            }
        }
    }, [value])

    const updateValue = (newValue) => {
        isInternalUpdate.current = true;
        setValue(newValue)
    }

    return (
        <div className="labeled-slider">
            <div className="labeled-slider-header">
                <div className="labeled-slider-label">{label}</div>
                <div className="labeled-slider-value">{value}</div>
            </div>
            <input
                type="range"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={(e) => updateValue(Number(e.target.value))}
                className="labeled-slider-input"
            />
        </div>
    );
};

export default LabeledSlider;