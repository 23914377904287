import React, {useState, useEffect, useRef} from 'react'
import NumberInput from '../editor/NumberInput';
import { setCaptionCollectionModalActive } from '../../redux/projectSlice';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch, useSelector } from 'react-redux'
import "../../css/captions.css";
import LabeledSlider from '../editor/LabeledSlider';

const FormatSettings = ({captionRef, captionInfo, refreshCaptionState, formatOptions, setFormatOptions}) => {
    const dispatch = useDispatch()

    const [formatConfig, setFormatConfig] = useState()
    const [positionValue, setPositionValue] = useState(70);

    const containerParamsHeight = 405;

    const openCaptionCollection = () => {
        dispatch(setCaptionCollectionModalActive({isActive: true}))
    }

    useEffect(() => {
        setFormatConfig(formatOptions);
        var topPos = captionInfo.config.topPos ?? 280;
        setPositionValue((topPos/containerParamsHeight)*100);
        console.log('TOP POS: ', topPos)
    }, [])

    useEffect(() => {
        if(refreshCaptionState){
            setFormatConfig({
                id: captionInfo.id,
                title: captionInfo.title,
                access: captionInfo.access,
                userId: captionInfo.userId,
                lineCount: captionInfo.config.lineCount ?? 1, 
                charCount: captionInfo.config.charCount ?? 15,
                topPos: captionInfo.config.topPos ?? 280,
            })
            var topPos = captionInfo.config.topPos ?? 280;
            console.log('TOP POS: ', topPos);
            console.log('POSITION VAL: ', (topPos/containerParamsHeight)*100);
            setPositionValue((topPos/containerParamsHeight)*100)
        }
        
    }, [refreshCaptionState])

    const updateValue = (newValue) => {
        setPositionValue(newValue);
        
        var topPos = containerParamsHeight * newValue/100;
        console.log('SET TOP POS: ', topPos)

        setFormatOptions(prevValue => ({
            ...prevValue,
            topPos: topPos,
        }))

        captionRef.current.updateTextTopPositionValue(topPos);
    }

    return (
        <>
            {
                formatOptions && 
                <>
                    <div className='form-row'>
                        <div className='label'> 
                            Caption Style
                        </div>
                        <div className='edit-box'>
                            <div className='select-box' onClick={openCaptionCollection}>
                                <div style={{flex: 1, overflow: 'hidden'}}>
                                    {/* {formatOptions.title} */}
                                    Basic Style
                                </div>
                                <KeyboardArrowRightIcon style={{fontSize: '1rem', marginLeft: 5}} />
                            </div>
                        </div>
                    </div>  
                    <div className='form-row'>
                        <div className='label'> 
                            Position
                        </div>
                        <div className='edit-box'>
                            <input
                                type="range"
                                min={10}
                                max={100}
                                step={1}
                                value={positionValue}
                                className="labeled-slider-input"
                                onChange={(e) => updateValue(Number(e.target.value))}
                                style={{width: 180, margin: 12}}
                            />
                        </div>
                    </div>  
                    <div className='form-row'>
                        <div className='label'> 
                            Lines
                        </div>
                        <div className='edit-box'>
                            <NumberInput defaultValue={formatOptions.lineCount ?? 1} options={formatOptions} setOptions={setFormatOptions} label='lineCount' />
                        </div>
                    </div>  
                    <div className='form-row'>
                        <div className='label'> 
                            Characters
                        </div>
                        <div className='edit-box'>
                            <NumberInput defaultValue={formatOptions.charCount ?? 15} options={formatOptions} setOptions={setFormatOptions} label='charCount' />
                        </div>
                    </div> 
                </>
            }
       
        </>
    )
}

export default FormatSettings
