import React, { useState, useEffect, useRef } from 'react'
import useEffectOnChange from '../../hooks/useEffectOnChange';
import "../../css/captions.css";
import { fontOptions, fontStyleOptions, getFontTypeFromWeight } from '../../constants/fontsConfig';
import Dropdown from '../editor/Dropdown';
import ColorPicker from '../editor/ColorPicker';
import LetterCase from '../editor/LetterCase';
import BackgroundPanel from '../editor/BackgroundPanel';
import AnimationPanel from '../editor/AnimationPanel';
import NumberInput from '../editor/NumberInput';
import ToggleSwitch from '../ui/ToggleSwitch';
import EnableSwitch from '../ui/EnableSwitch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LabeledSlider from '../editor/LabeledSlider';
import { getFallbackStyleOptions } from '../../utils/captions';
import StrokeSettings from '../editor/StrokeSettings';
import ShadowSettings from '../editor/ShadowSettings';
import Chevron from '../ui/Chevron';

const LineSettings = ({
    idx,
    mode,
    info,
    captionRef,
    captionConfig,
    refreshCaptionState, 
    lineSettings,
    setLineSettings
}) => {

    const optionsRef = useRef({})
    const boxOptionsRef = useRef({})

    const target = 'line'
    const isInternalUpdate = useRef(false);

    const [currentLineOptions, setCurrentLineOptions] = useState()

    const [lineConfig, setLineConfig] = useState()

    const [options, setOptions] = useState(null)
    const [boxOptions, setBoxOptions] = useState(null)
    const [animInfo, setAnimInfo] = useState(null)

    const [enabler, setEnabler] = useState({ box: false, anim: false, stroke: false, shadow: false })

    useEffect(() => {
        if(refreshCaptionState){
            console.log('REFRESH CAPTION STATE:: LINE SETTINGS ->  ', captionConfig?.text.lines)

            var lineInfo;
            if(mode == 'active'){
                lineInfo = captionConfig?.activeText.lines ? captionConfig?.activeText.lines[idx] : null;
            }else{
                lineInfo = captionConfig?.text.lines ? captionConfig?.text.lines[idx] : null;
            }
            // var lineInfo = getLineOptions();
            isInternalUpdate.current = false;
            
            setLineConfig(lineInfo)
            setCurrentLineOptions(lineInfo);

            setNewOptions(lineInfo?.options ?? {})
            setNewBoxOptions(lineInfo?.boxOptions)
            setAnimInfo(lineInfo?.anim)

            setEnabler({
                box: lineInfo?.boxOptions ? true : false,
                anim: lineInfo?.anim ? true : false,
                stroke: lineInfo?.stroke ? true : false,
                shadow: lineInfo?.shadowInfo ? true : false,
            })

        }
    }, [refreshCaptionState])

    useEffect(() => {
        if(isInternalUpdate.current){
            if (!enabler.box && boxOptions) {
                updateBoxOptions(null)
            }
    
            if (enabler.box && !boxOptions) {
                setFallbackStyles()
    
            }

            if (captionRef && captionRef.current && mode == 'style') {
                captionRef.current.toggleBackground(target, idx, enabler.box)
            }
        }
        
    }, [enabler.box])

    useEffect(() => {
        if(isInternalUpdate.current){
            if (!enabler.anim && animInfo) {
                updateAnimInfo(null)
            }
    
            if (enabler.anim && !animInfo) {
                updateAnimInfo({
                    type: 'none',
                    speed: 100,
                    intensity: 100
                })
            }

        }
       
    }, [enabler.anim])

    useEffect(() => {
        if(isInternalUpdate.current){
            if (!enabler.shadow && options?.shadowInfo) {
                updateOptions(prevValue => ({
                    ...prevValue,
                    shadowInfo: null,
                }))
            }
    
            if(enabler.shadow && !options?.shadowInfo){
                const shadowInfo = {
                    fill: '#000',
                    blur: 10,
                    offsetX: 5,
                    offsetY: 5
                };
                updateOptions(prevValue => ({
                    ...prevValue,
                    shadowInfo: shadowInfo,
                }))
    
            }

        }
       
    }, [enabler.shadow])

    useEffect(() => {
        if(isInternalUpdate.current){
            if (!enabler.stroke && (options?.stroke || options?.strokeWidth)) {
                updateOptions(prevValue => ({
                    ...prevValue,
                    stroke: '',
                    strokeWidth: null
                }));
            }
    
            if(enabler.stroke && !options?.stroke){
                updateOptions(prevValue => ({
                    ...prevValue,
                    stroke: '#000',
                    strokeWidth: 1
                }));
            }
        }
       
    }, [enabler.stroke])

  

    useEffect(() => {
        console.log('INIT LINE SETTINGS ===> ', captionConfig)

        var lineInfo = lineSettings ?? null;
        setLineConfig(lineInfo)
        isInternalUpdate.current = false;

        console.log('CURRENT LINE OPTIONS:: ', currentLineOptions);
        console.log('LINE SETTING ', lineSettings);
        console.log('LINE INFO IDX ', idx);
        console.log('LINE INFO ', lineInfo[idx]);
       
        if (!currentLineOptions && lineInfo && lineInfo[idx]) {
            var lineOptions;
            console.log('LINE SETTINGS CHNAGED: ', lineInfo)
            console.log('LINE IDX:: ', idx)
            console.log('CURRENT LINE OPTIONS: ', currentLineOptions)
            
            if (lineInfo[idx]) {
                console.log('LINE OPTIONS ==> 1')
                lineOptions = lineInfo[idx]
                setCurrentLineOptions(lineOptions)
                console.log('LINE OPTIONS ==> ', lineOptions)

                setNewOptions(lineOptions?.options)
                setNewBoxOptions(lineOptions?.boxOptions)

                if (lineOptions?.boxOptions) {
                    setEnabler(prevValue => ({
                        ...prevValue,
                        box: true,
                    }))
                }
                setAnimInfo(lineOptions?.anim)

                if (lineOptions?.anim) {
                    setEnabler(prevValue => ({
                        ...prevValue,
                        anim: true,
                    }))
                }

            } else {
                console.log('LINE OPTIONS ==> 2')
                lineOptions = { options: {}, boxOptions: null, anim: null }
                setCurrentLineOptions(lineOptions)
                
            }

            setEnabler({
                box: lineOptions.boxOptions ? true : false,
                anim: lineOptions.anim ? true : false,
                shadow: lineOptions.options?.shadowInfo ? true : false,
                stroke: lineOptions.options?.stroke ? true : false,
            })
        }
    }, [])

    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            var lineOptionsArray = lineSettings ? [...lineSettings] : []
            lineOptionsArray[idx] = currentLineOptions;
    
            console.log('FINALISING LINE SETTINGS: ', lineOptionsArray)
            setLineSettings(lineOptionsArray)
        }
       
    }, [currentLineOptions])


    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            const prevOptions = optionsRef.current ?? {};

        
            if(prevOptions != options){
                console.log('Options Changed for Line IDX: ', idx)
                console.log('Options: ', options)
                console.log('prevOptions: ', prevOptions)
    
                Object.entries(options).forEach(([key, value]) => {
    
                    if (prevOptions[key] !== value) {
                        // Only run side effects for changed keys
                        switch (key) {
                            case "scale":
                                console.log(`Scale changed to ${value}`);
                                captionRef.current.updateStyle(target, idx, 'scale', options?.scale)
                                break;
                            case "opacity":
                                console.log(`Opacity changed to ${value}`);
                                captionRef.current.updateStyle(target, idx, 'opacity', options?.opacity)
                                break;
                            case "fill":
                                console.log(`Fill changed to ${value}`);
                                captionRef.current.updateStyle(target, idx, 'fill', options?.fill)
                                break;
                            case "fontFamily":
                                console.log(`FontFamily changed to ${value}`);
                                captionRef.current.updateStyle(target, idx, 'fontFamily', options?.fontFamily)
                                break;
                            case "fontStyle":
                                console.log(`FontStyle changed to ${value}`);
                                captionRef.current.updateStyle(target, idx, 'fontStyle', options?.fontStyle)
                                break;
                            case "fontWeight":
                                console.log(`FontWeight changed to ${value}`);
                                captionRef.current.updateStyle(target, idx, 'fontWeight', options?.fontWeight)
                                break;
                            case "case":
                                console.log(`Case changed to ${value}`);
                                captionRef.current.updateStyle(target, idx, 'case', options?.case)
                                break;
                            case "wordSpacing":
                                console.log(`Word Spacing changed to ${value}`);
                                captionRef.current.updateStyle(target, idx, 'wordSpacing', options?.wordSpacing)
                                break;
                            case "lOffset":
                                console.log(`Word Spacing changed to ${value}`);
                                captionRef.current.updateStyle(target, idx, 'lOffset', options?.lOffset)
                                break;
                            case "shadowInfo":
                                console.log('Shadow Info changed to ', value);
                                captionRef.current.updateStyle(target, idx, 'shadowInfo', options?.shadowInfo)
                                break;
                            case "stroke":
                                console.log('Shadow Info changed to ', value);
                                captionRef.current.updateStyle(target, idx, 'stroke', options?.stroke)
                                break;
                            case "strokeWidth":
                                console.log('Shadow Info changed to ', value);
                                captionRef.current.updateStyle(target, idx, 'strokeWidth', options?.strokeWidth)
                                break;
                            default:
                                console.log(`${key} changed to ${value}`);
                        }
                    }
                });
    
                optionsRef.current = options;
                setCurrentLineOptions(prevValue => ({
                    ...prevValue,
                    options: options,
                }))
            }

            if(!enabler.shadow && options?.shadowInfo){
                setEnabler(prevValue => ({
                    ...prevValue,
                    shadow: true,
                }))
            }
    
            if (!enabler.stroke && (options?.stroke || options?.strokeWidth)) {
                setEnabler(prevValue => ({
                    ...prevValue,
                    stroke: true,
                }))
            }
        }

       
    }, [options])

    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            const prevBoxOptions = boxOptionsRef.current  ?? {};
            console.log('Box Options Changed for Line IDX: ', idx)
            console.log('prevBoxOptions: ', prevBoxOptions)
            if (boxOptions && prevBoxOptions != boxOptions) {
                Object.entries(boxOptions).forEach(([key, value]) => {
    
                    if (prevBoxOptions[key] !== value) {
                        // Only run side effects for changed keys
                        switch (key) {
                            case "fill":
                                console.log(`Fill changed to ${value}`);
                                captionRef.current.updateBoxStyle(target, idx, 'fill', boxOptions?.fill)
                                break;
                            case "padding":
                                console.log(`Padding changed to ${value}`);
                                captionRef.current.updateBoxStyle(target, idx, 'padding', boxOptions?.padding)
                                break;
                            case "opacity":
                                console.log(`Opacity changed to ${value}`);
                                captionRef.current.updateBoxStyle(target, idx, 'opacity', boxOptions?.opacity)
                                break;
                            case "borderRadius":
                                console.log(`Roundness changed to ${value}`);
                                captionRef.current.updateBoxStyle(target, idx, 'borderRadius', boxOptions?.borderRadius)
                                break;
                            default:
                                console.log(`${key} changed to ${value}`);
                        }
                    }
                });
            }
    
    
            boxOptionsRef.current = boxOptions;
            setCurrentLineOptions(prevValue => ({
                ...prevValue,
                boxOptions: boxOptions,
            }))

            if (!enabler.box && boxOptions) {
                setEnabler(prevValue => ({
                    ...prevValue,
                    box: true,
                }))
            }
    
        }
       
      
    }, [boxOptions])

    useEffectOnChange(() => {

        if(isInternalUpdate.current){
            setCurrentLineOptions(prevValue => ({
                ...prevValue,
                anim: animInfo,
            }))
        }
      

        if (!enabler.anim && animInfo) {
            setEnabler(prevValue => ({
                ...prevValue,
                anim: true,
            }))
        }
    }, [animInfo])

    const setFallbackStyles = () => {

        const { flbkOptions, flbkBoxOptions } = getFallbackStyleOptions(captionConfig, idx, 'style')

        console.log('SETTING FALLBACK STYLES:')
        if (flbkOptions) {
            console.log('SETTING FALLBACK STYLES::OPTIONS: ', flbkOptions)

            var optionsTmp =
                mode == 'active' ?
                    {
                        fill: flbkOptions.fill,
                        opacity: flbkOptions.opacity
                    } :
                    flbkOptions;


            updateOptions(optionsTmp)
        }
        if (flbkBoxOptions) {
            console.log('SETTING FALLBACK STYLES::BOX_OPTIONS: ', flbkBoxOptions)
            updateBoxOptions(flbkBoxOptions)
        }

        return { flbkOptions, flbkBoxOptions }
    }

    const getLineOptions = () => {
        var lineInfo;
        // if(mode == 'active'){
        //     lineInfo = captionConfig?.activeText.lines ? captionConfig?.activeText.lines[idx] : null;
        // }else{
        //     lineInfo = captionConfig?.text.lines ? captionConfig?.text.lines[idx] : null;
        // }

        lineInfo = lineSettings ? lineSettings[idx] : null;
        return lineInfo;
    }

    const updateEnabler = (newEnablerInfo) => {
        isInternalUpdate.current = true;
        setEnabler(newEnablerInfo)
    }

    const updateOptions = (newOptions) => {
        isInternalUpdate.current = true;
        setOptions(newOptions)
    }

    const updateBoxOptions = (newBoxOptions) => {
        isInternalUpdate.current = true;
        setBoxOptions(newBoxOptions)
    }

    const updateAnimInfo = (newAnimInfo) => {
        isInternalUpdate.current = true;
        setAnimInfo(newAnimInfo)
    }


    const setNewOptions = (optionsInfo) => {
        setOptions(optionsInfo)
        optionsRef.current = optionsInfo ?? {};
    }

    const setNewBoxOptions = (boxOptionsInfo) => {
        setBoxOptions(boxOptionsInfo)
        boxOptionsRef.current = boxOptionsInfo ?? {};
    }

    const onFontFamilyChange = (fontFamily) => {
        console.log('Changing fontSize: ', fontFamily.value)
        updateOptions({ ...options, fontFamily: fontFamily.value })
    }

    const onFontStyleChange = (option) => {
        console.log('Changing fontWeight: ', option.value)
        updateOptions({ ...options, fontWeight: option.fontWeight, fontStyle: option.fontStyle })
    }

    return (
            <>
                {
                    lineConfig &&
                    <>                        
                        {
                            mode == 'style' ?
                                <div style={{ border: '1px solid #B7F6D9', borderLeft: '6px solid #B7F6D9', backgroundColor: '#fff',  padding: 10, paddingLeft: 20, width: '98%', margin: '30px auto', borderRadius: 10 }}>
                                    <div className='form-row'>
                                        <div className='label'>
                                            Line {idx+1 ?? 0}
                                        </div>
                                        <div className='edit-box'>
                                            <div style={{ padding: '0.4rem', width: '100%', border: '1px solid #eee' }}>
                                                {info?.text}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='label'>
                                            Font
                                        </div>
                                        <div className='edit-box'>
                                            <div style={{ width: 200 }}>
                                                <Dropdown
                                                    isSearchable
                                                    menuSize={200}
                                                    placeHolder={options?.fontFamily ? options.fontFamily : 'Roboto'}
                                                    options={fontOptions()}
                                                    onChange={(value) => onFontFamilyChange(value)}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    <div className='form-row'>
                                        <div className='label'>
                                            Style
                                        </div>
                                        <div className='edit-box'>
                                            <div style={{ width: 180 }}>
                                                <Dropdown
                                                    isSearchable={false}
                                                    menuSize={180}
                                                    placeHolder={getFontTypeFromWeight(options?.fontWeight, options?.fontStyle)}
                                                    options={fontStyleOptions(options?.fontFamily ?? 'Roboto')}
                                                    onChange={(value) => onFontStyleChange(value)}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='label'>
                                            Color
                                        </div>
                                        <div className='edit-box'>
                                            <div style={{ width: 150, height: 35 }}>
                                                <ColorPicker fill={options?.fill} options={options} setOptions={updateOptions} />
                                            </div>

                                        </div>
                                    </div>

                                    <div className='form-row'>
                                        <div className='label'>
                                            Case
                                        </div>
                                        <div className='edit-box'>
                                            <LetterCase lettercase={options?.case} options={options} setOptions={updateOptions} />
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='label'>
                                            Left Offset
                                        </div>
                                        <div className='edit-box'>
                                            <NumberInput defaultValue={options?.lOffset ?? 0} options={options} setOptions={updateOptions} label='lOffset' />
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='label'>
                                            Word Spacing
                                        </div>
                                        <div className='edit-box'>
                                            <NumberInput defaultValue={options?.wordSpacing ?? 5} options={options} setOptions={updateOptions} label='WordSpacing' />
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='label'>
                                            Scale
                                        </div>
                                        <div className='edit-box'>
                                            <NumberInput defaultValue={options?.scale ?? 1} options={options} setOptions={updateOptions} label='Scale' />
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <LabeledSlider
                                            label='Opacity'
                                            options={options}
                                            setOptions={updateOptions}
                                            defaultValue={options?.opacity ?? 1} />
                                    </div>

                                    <div className='form-row'>
                                        <div className='label'>
                                            Stroke
                                        </div>
                                        <div className='edit-box'>
                                            <EnableSwitch target='stroke' value={enabler.stroke} setEnabler={updateEnabler} />
                                        </div>
                                        <div className='options-container' style={{ display: enabler.stroke ? 'flex' : 'none' }}>
                                            {
                                                options &&
                                                    <StrokeSettings options={options} setOptions={updateOptions} />
                                            }

                                        </div>
                                    </div>

                                    <div className='form-row'>
                                        <div className='label'>
                                            Shadow
                                        </div>
                                        <div className='edit-box'>
                                            <EnableSwitch target='shadow' value={enabler.shadow} setEnabler={updateEnabler} />
                                        </div>
                                        <div className='options-container' style={{ display: enabler.shadow ? 'flex' : 'none' }}>
                                            {
                                                options?.shadowInfo &&
                                                    <ShadowSettings options={options} setOptions={updateOptions}  />
                                            }

                                        </div>
                                    </div>

                                    <div className='form-row'>
                                        <div className='label'>
                                            Background
                                        </div>
                                        <div className='edit-box'>
                                            <EnableSwitch target='box' value={enabler.box} setEnabler={updateEnabler} />
                                        </div>
                                        <div className='options-container' style={{ display: enabler.box ? 'flex' : 'none' }}>
                                            {
                                                boxOptions &&
                                                <BackgroundPanel boxOptions={boxOptions} setBoxOptions={updateBoxOptions} />
                                            }

                                        </div>
                                    </div>

                                    <div className='form-row'>
                                        <div className='label'>
                                            Animation
                                        </div>
                                        <div className='edit-box'>
                                            <EnableSwitch target='anim' value={enabler.anim} setEnabler={updateEnabler} />
                                        </div>
                                        <div className='options-container' style={{ display: enabler.anim ? 'flex' : 'none' }}>
                                            {
                                                animInfo &&
                                                <AnimationPanel animInfo={animInfo} setAnimInfo={updateAnimInfo} />
                                            }

                                        </div>
                                    </div>
                                </div>
                                :
                                <div style={{ border: '1px solid #B7F6D9', borderLeft: '6px solid #B7F6D9', backgroundColor: '#fff',  padding: 10, paddingLeft: 20, width: '98%', margin: '10px auto', borderRadius: 10 }}>
                                    <div className='form-row'>
                                        <div className='label'>
                                            Line {idx ?? 0}
                                        </div>
                                        <div className='edit-box'>
                                            <div style={{ padding: '0.4rem', width: '100%', border: '1px solid #eee' }}>
                                                {info?.text}
                                            </div>

                                        </div>
                                    </div>

                                    <div className='form-row'>
                                        <div className='label'>
                                            Color
                                        </div>
                                        <div className='edit-box'>
                                            <div style={{ width: 150, height: 35 }}>
                                                <ColorPicker fill={options?.fill} options={options} setOptions={updateOptions} />
                                            </div>

                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <LabeledSlider
                                            label='Opacity'
                                            options={options}
                                            setOptions={setOptions}
                                            defaultValue={options?.opacity ?? 1} />
                                    </div>
                                    <div className='form-row'>
                                        <div className='label'>
                                            Stroke
                                        </div>
                                        <div className='edit-box'>
                                            <EnableSwitch target='stroke' value={enabler.stroke} setEnabler={updateEnabler} />
                                        </div>
                                        <div className='options-container' style={{ display: enabler.stroke ? 'flex' : 'none' }}>
                                            {
                                                options &&
                                                    <StrokeSettings options={options} setOptions={updateOptions} />
                                            }

                                        </div>
                                    </div>

                                    <div className='form-row'>
                                        <div className='label'>
                                            Shadow
                                        </div>
                                        <div className='edit-box'>
                                            <EnableSwitch target='shadow' value={enabler.shadow} setEnabler={updateEnabler} />
                                        </div>
                                        <div className='options-container' style={{ display: enabler.shadow ? 'flex' : 'none' }}>
                                            {
                                                options?.shadowInfo &&
                                                    <ShadowSettings options={options} setOptions={updateOptions}  />
                                            }

                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='label'>
                                            Background
                                        </div>
                                        <div className='edit-box'>
                                            <EnableSwitch target='box' value={enabler.box} setEnabler={updateEnabler} />
                                        </div>
                                        <div className='options-container' style={{ display: enabler.box ? 'flex' : 'none' }}>
                                            {
                                                boxOptions &&
                                                <BackgroundPanel boxOptions={boxOptions} setBoxOptions={updateBoxOptions} />
                                            }

                                        </div>
                                    </div>

                                    <div className='form-row'>
                                        <div className='label'>
                                            Animation
                                        </div>
                                        <div className='edit-box'>
                                            <EnableSwitch target='anim' value={enabler.anim} setEnabler={updateEnabler} />
                                        </div>
                                        <div className='options-container' style={{ display: enabler.anim ? 'flex' : 'none' }}>
                                            {
                                                animInfo &&
                                                <AnimationPanel animInfo={animInfo} setAnimInfo={updateAnimInfo} />
                                            }

                                        </div>
                                    </div>
                                </div>
                        }
                    </>
          
         
                }
            </>
                )
}

export default LineSettings
