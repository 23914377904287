import React, {useState, useEffect} from 'react'
import TimestampsView from './TimestampsView'
import TranscriptEditor from './TranscriptEditor'

const TranscriptView = ({textTimestampsRef}) => {

    const [currMenuIdx, setCurrMenuIdx] = useState(0)

    useEffect(() => {
        console.log('TranscriptView renderred!!')
    }, [])

    const ContentView = React.memo(({}) => {
        switch(currMenuIdx){
            case 0:
                return <TranscriptEditor />
            case 1:
                return <TimestampsView textTimestampsRef={textTimestampsRef}/>
            default:
                return <TranscriptEditor />
        }
    });

    return (
        <div style={{width: '95%', margin: 'auto', height: '100vh', padding: 10}}>
             <div style={{display: 'flex'}}>
                <div 
                    style={{flex: 1, padding: 10, paddingInline: 20, textAlign: 'center', borderBottom: currMenuIdx == 0 ? '2px solid #584efd' : '2px solid #ddd', cursor: 'pointer'}}
                    onClick={() => setCurrMenuIdx(0)}
                >
                    Transcript
                </div>
                <div 
                    style={{flex: 1, padding: 10, paddingInline: 20, textAlign: 'center', borderBottom: currMenuIdx == 1 ? '2px solid #584efd' : '2px solid #ddd', cursor: 'pointer'}}
                    onClick={() => setCurrMenuIdx(1)}
                >
                    Timestamps
                </div>
                <div style={{flex: 1, padding: 10,  paddingInline: 20, borderBottom: '2px solid #ddd'}}>

                </div>
            </div>
            <div>
                <ContentView />
            </div>
        </div>
    )
}

export default TranscriptView
