import { useState, useEffect } from 'react';
import { onAuthStateChanged } from '@firebase/auth';
import {auth} from '../config/firebaseConfig'


export function useIsAuthenticated() {
    const [authUser, setAuthUser] = useState(null)

    useEffect(() => {
       const listen = onAuthStateChanged(auth, (user) => {
        console.log('AUTH: ===> ', auth)
        if(user){
            console.log('User: ', user)
            setAuthUser(user)
        }else{
            setAuthUser(null)
        }
       })

       return () => {
           listen();
       }

    }, [])

    return authUser
}