import React, { useEffect, useState, useRef } from 'react'
import '../css/main.css';
import logo from "../assets/img/Diveo-Logo-Black.png";
import Footer from './Footer';
import PricingPage from './PricingPage';

import ContentCutIcon from '@mui/icons-material/ContentCut';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import LanguageIcon from '@mui/icons-material/Language';

function Main() {

    return (
        <div class='main-container-box'>
            {/* <header class="header-navbar">
                    <a class="navbar-brand w-8" href="#" data-config-id="brand">
                        <img src={logo}  height="50" alt=""></img>
                    </a>

                    <a href="/new" class="btn mb-0 cursor-pointer text-white d-btn-cta">
                        Free Trial
                    </a>
            </header> */}
            <header class="header-navbar1">
                <nav>
                    <div class="logo">
                        <a href="#"><img src={logo} class='logo' alt=""></img></a>
                    </div>
                    <ul class="menu">
                  
                        <li><a href="#hiw-section-id">How it works?</a></li>
                        <li><a href="#feature-section-id">Features</a></li>
                        
                        <li><a href='/pricing'>Pricing</a></li>
                        <li><a href='/login'>Login</a></li>
                        {/* <li><a href="#">FAQ</a></li> */}
                    </ul>
                    <div class='cta-btn'>
                        <a href="/login" class="text-decoration-none text-white">Try for free</a>
                    </div>
                </nav>
            </header>


            <header class="header headline-section">
                <div class=" p-4 py-10">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-8  mt-auto mx-auto text-center justify-content-bottom my-auto">
                                <div class="mb-4 font-weight-bolder text-center main-headline-v4 ">The Ultimate Online Caption Maker You'll Ever Need</div>
                                <p class="mb-4 sub-headline-v4">Generate captivating captions in only one click using AI <br /> Tap Into a library of stunning caption templates by fellow creators.</p>

                                    <div class="cta-btn"style={{margin: 'auto', fontSize: 19}} >
                                        <a href="/login" class="text-decoration-none text-white">Start here <i class="bi bi-arrow-right"></i></a>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* <section class="examples-section">
            <div class="examples-section-heading">Here are some example videos</div>
                <div class='row sm-video-container'>
                    <div class='col-3' style={{display: "flex", fontSize: '3em', height: '100%', color: '#36454F', justifyContent: 'center', alignItems: 'center'}}>
                        <span style={{cursor: 'pointer'}} onClick={prevVideo}>
                            <i class="bi bi-arrow-left-circle-fill"></i>
                        </span>
                    </div>
                    <div class='col-6' style={{display: "flex", height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                        <video 
                            preload='auto'
                            controls={true}
                            style={{  height: '100%',  border: '1px solid #eee', borderRadius: 15, backgroundColor: 'white'}}
                            src={videoSamples[currentVideoIndex % videoSamples.length].url}
                            poster={videoSamples[currentVideoIndex % videoSamples.length].poster}
                        />
                    </div>
                    <div class='col-3' style={{display: "flex", fontSize: '3em', height: '100%', color: '#36454F', justifyContent: 'center', alignItems: 'center'}}>
                        <span style={{cursor: 'pointer'}} onClick={nextVideo}>
                            <i class="bi bi-arrow-right-circle-fill"></i>
                        </span>
                    </div>
                </div>
            </section> */}
            {/* 
            <section id='hiw-section-id' class="product-info-section">

                <div class="product-info row">
                    <div class="product-info-container col-6">
                        <div class="product-info-headline">
                            How it works?
                        </div>
                        <div class="product-info-detail">
                            <table class='product-info-table'>
                                <tr class="product-info-list-items">
                                    <td>
                                        <div class='list-item-num'>1</div>
                                    </td>
                                    <td><span style={{ fontWeight: "bold" }}>Enter Your Script:</span> Type or paste your video script into our easy-to-use text input. </td>
                                </tr>
                                <tr class="product-info-list-items">
                                    <td>
                                        <div class='list-item-num'>2</div>
                                    </td>
                                    <td><span style={{ fontWeight: "bold" }}>Select Your Voiceover:</span> Choose from a range of AI-generated voices for your video.</td>
                                </tr>
                                <tr class="product-info-list-items">
                                    <td>
                                        <div class='list-item-num'>3</div>
                                    </td>
                                    <td><span style={{ fontWeight: "bold" }}>Customize Your Captions:</span> Pick from our stylish caption options to make your video stand out.</td>
                                </tr>
                                <tr class="product-info-list-items">
                                    <td>
                                        <div class='list-item-num'>4</div>
                                    </td>
                                    <td><span style={{ fontWeight: "bold" }}>Add Visuals:</span> Browse our stock library or upload your own images and videos.</td>
                                </tr>
                                <tr class="product-info-list-items">
                                    <td>
                                        <div class='list-item-num'>4</div>
                                    </td>
                                    <td><span style={{ fontWeight: "bold" }}>Edit & Finalize:</span> Use our powerful editor to polish your video to perfection.</td>
                                </tr>
                            </table>
                        </div>

                    </div>
                    <div class="product-info-feature-container col-6">
                        <div class="product-info-demo-wrapper">
                            <img
                                width='100%'
                                style={{ margin: 'auto', borderRadius: 10 }}
                                src="https://res.cloudinary.com/dlmsxlf2n/image/upload/v1719043058/Diveo/Assets/images/workflow-preview-img.png"
                            />

                        </div>
                    </div>

                </div>

            </section> */}
            <section id='feature-section-id' class="feature-section">
                <div class="section-heading">Everything You Need to Perfect Your Video, All in One Tool.</div>
                <div class="feature-container">
                    <div class="feature-item">
                        <div class="feature-icon">
                            <AutoAwesomeIcon style={{fontSize: '2rem'}}/>
                        </div>
                        <div class="feature-content">
                            <div class="feature-heading">Synced and Ready in Seconds</div>
                            <div class="feature-details">
                                No more manual syncing! Our AI-powered Auto-Caption feature instantly
                                applies your chosen caption style to your video.
                                <br />
                            </div>
                        </div>
                    </div>
                    <div class="feature-item">
                        <div class="feature-icon">
                            <AutoFixHighIcon style={{fontSize: '2rem'}} />
                        </div>
                        <div class="feature-content">
                            <div class="feature-heading">Design Captions Your Way</div>
                            <div class="feature-details">
                               Customize caption styles, animations, and fine-tune every detail—down to the precise
                                timing and appearance of each word.
                            </div>
                        </div>
                    </div>
                    <div class="feature-item">
                        <div class="feature-icon">
                            <PeopleAltIcon style={{fontSize: '2rem'}}/>
                        </div>
                        <div class="feature-content">
                            <div class="feature-heading">Share your captions. Empower Creators</div>
                            <div class="feature-details">
                                Turn your captions into inspiration! Share your custom-designed captions
                                with the community and help fellow creators
                            </div>
                        </div>

                    </div>
                    <div class="feature-item">
                        <div class="feature-icon">
                            <PermMediaIcon style={{fontSize: '2rem'}}/>
                        </div>
                        <div class="feature-content">
                            <div class="feature-heading">Adding Overlays Made Easy</div>
                            <div class="feature-details">
                                Enhance your videos and keep audience engaged using vast content library
                                with millions of stock images and videos.
                                
                            </div>
                        </div>

                    </div>
                    <div class="feature-item">
                        <div class="feature-icon">
                            <ContentCutIcon style={{fontSize: '2rem'}}/>
                        </div>
                        <div class="feature-content">
                            <div class="feature-heading">Trim Videos Using Text</div>
                            <div class="feature-details">
                                Cut and refine video content by removing specific transcript sections.
                                Save hours of manual trimming.
                            </div>
                        </div>

                    </div>
                    <div class="feature-item">
                        <div class="feature-icon">
                            <LanguageIcon style={{fontSize: '2rem'}}/>
                        </div>
                        <div class="feature-content">
                            <div class="feature-heading">Multilingual Captions</div>
                            <div class="feature-details">
                                Create captions in over 40 languages. Perfect for global audiences.
                                <br />
                                <div style={{marginTop: 20}}>
                                    <span style={{padding: 8, paddingInline: 10, borderRadius: 15, fontSize: 13, backgroundColor: '#4482ed', color: '#fff'}}>Coming Soon!</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div class="feature-item">
                        <div class="feature-icon">
                            <i class="bi bi-music-note-beamed"></i>
                        </div>
                        <div class="feature-content">
                            <div class="feature-heading">Resize Videos for Every Platform</div>
                            <div class="feature-details">Switch between 1:1, 16:9, and 9:16 effortlessly.</div>
                        </div>

                    </div> */}

                </div>
            </section>
            <section class="product-info-section">
                <div class="product-info row">
                    <div class="product-info-container col-6">
                        <div class="product-info-headline">
                            The Ultimate Caption Maker for Creators Who Demand Control
                        </div>
                        <div class="product-info-detail">
                            Take full control of your captions with an intuitive design tool that lets you style,
                            animate, and sync each word to perfection. Whether you want bold colors, smooth animations,
                            or precise timing, our caption editor empowers you to make your vision a reality. Captions 
                            are no longer just text—they’re your creative signature.
                        </div>
                        
                    </div>
                    <div class="product-info-feature-container col-6">
                        <div class="product-info-demo-wrapper"> 
                            <video
                                autoPlay  
                                muted
                                no-controls
                                loop
                                style={{ margin: 'auto',  width: '100%', border: '1px solid #eee', borderRadius: 15}}
                               
                                src='https://res.cloudinary.com/dlmsxlf2n/video/upload/v1738331870/Diveo/Assets/videos/ft-demo.mov'
                            />
                        </div>
                    </div>

                </div>
                {/* <div class="product-info row">
                    <div class="product-info-feature-container col-6">
                    <div class="product-info-demo-wrapper"> 
                            <img
                                width='90%'
                                style={{margin: 'auto'}}
                                src="https://res.cloudinary.com/dlmsxlf2n/image/upload/v1714575501/Diveo/Assets/images/design-edits.png"
                            />

                        </div>
                    </div>
                    <div class="product-info-container col-6">
                        <div class="product-info-headline">
                            A Library of Creativity: Captions from the Community
                        </div>
                        <div class="product-info-detail">
                            Explore a growing library of beautifully crafted captions designed by creators from around the globe.
                            Whether you’re short on time or seeking inspiration, find the perfect captions to elevate your videos
                             with just a click. Join a thriving community and contribute your own creations to share with others.
                            
                        </div>
                        
                    </div>
                </div> */}
                <div class="product-info row">
                    <div class="product-info-feature-container col-6">
                    <div class="product-info-demo-wrapper"> 
                            <img
                                width='90%'
                                style={{margin: 'auto'}}
                                src="https://res.cloudinary.com/dlmsxlf2n/image/upload/v1738340013/Diveo/Assets/images/editor-demo.png"
                            />

                        </div>
                    </div>
                    <div class="product-info-container col-6">
                        <div class="product-info-headline">
                            Text-Based Editing, Simplified
                        </div>
                        <div class="product-info-detail">
                            With our revolutionary word-like editor, you can easily add engaging B-rolls or trim unnecessary sections
                            of your video—just by selecting text in the transcript. It’s fast, intuitive, and perfect for creators 
                            who want to save time without compromising quality.
                        </div>
                        
                    </div>
                </div>

            
            </section>
            <section class="corousal-section">
                <div class="corousal-section-heading">Transform Videos with the Ultimate Caption Tool</div>
                <div class="corousal-section-subheading">Effortless Captions, Endless Possibilities</div>
                <div class="corousal-section-container">
                    <div class="sample-container">
                        <video
                                autoPlay  
                                muted
                                no-controls
                                loop
                                style={{ margin: 'auto',  width: '100%', border: '1px solid #eee', borderRadius: 15}}
                               
                                src='https://res.cloudinary.com/dlmsxlf2n/video/upload/v1739388337/Diveo/Assets/videos/vsample-clip-5.mp4'
                            />
                    </div>
                    <div class="sample-container">
                        <video
                                autoPlay  
                                muted
                                no-controls
                                loop
                                style={{ margin: 'auto',  width: '100%', border: '1px solid #eee', borderRadius: 15}}
                               
                                src='https://res.cloudinary.com/dlmsxlf2n/video/upload/v1739388338/Diveo/Assets/videos/vsample-clip-2.mp4'
                            />
                    </div>
                    <div class="sample-container">
                        <video
                                autoPlay  
                                muted
                                no-controls
                                loop
                                style={{ margin: 'auto',  width: '100%', border: '1px solid #eee', borderRadius: 15}}
                               
                                src='https://res.cloudinary.com/dlmsxlf2n/video/upload/v1739388337/Diveo/Assets/videos/vsample-clip-3.mp4'
                            />
                    </div>
                    <div class="sample-container">
                        <video
                                autoPlay  
                                muted
                                no-controls
                                loop
                                style={{ margin: 'auto',  width: '100%', border: '1px solid #eee', borderRadius: 15}}
                               
                                src='https://res.cloudinary.com/dlmsxlf2n/video/upload/v1739388338/Diveo/Assets/videos/vsample-clip-6.mp4'
                            />
                    </div>

                </div>
            </section>
            <section class="pricing-section">
                <PricingPage />
            </section>
            <section class="final-cta-section">
                <div class="section-heading">Revolutionize Your Videos with Powerful Captions Using The Best Caption Maker</div>
                <div class="section-sub-heading">Effortlessly Create and Customize Captivating Captions For Your Videos Like a Pro</div>
                <div class="cta-btn" style={{margin: 'auto'}}>
                        <a href="/login" class="text-decoration-none text-white">Try for free</a>
                </div>
            </section>

            <Footer />

        </div>
    )
}

export default Main
