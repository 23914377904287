import React from 'react'
import { logoutUser} from '../../services/auth'


const Logout = () => {

    return (
        <div style={{margin: 40}}>
            <button className='btn btn-secondary' onClick={logoutUser}>Sign Out</button>
        </div>
    )
}

export default Logout
