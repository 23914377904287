const previewTextTimestampedData = {
  words: [
    {
      word: "the",
      start: 0,
      end: 0.4,
      confidence: 0.9803699,
      punctuated_word: "The",
    },
    {
      word: "quick",
      start: 0.4,
      end: 0.8,
      confidence: 0.9803699,
      punctuated_word: "quick",
    },
    {
      word: "brown",
      start: 0.8,
      end: 1.2,
      confidence: 0.9803699,
      punctuated_word: "brown",
    },
    {
      word: "fox",
      start: 1.2,
      end: 1.6,
      confidence: 0.9803699,
      punctuated_word: "fox",
    },
    {
      word: "jumps",
      start: 1.6,
      end: 2.0,
      confidence: 0.9803699,
      punctuated_word: "jumps",
    },
    {
      word: "over",
      start: 2.0,
      end: 2.4,
      confidence: 0.9803699,
      punctuated_word: "over",
    },
    {
      word: "the",
      start: 2.4,
      end: 2.8,
      confidence: 0.9803699,
      punctuated_word: "the",
    },
    {
      word: "lazy",
      start: 2.8,
      end: 3.2,
      confidence: 0.9803699,
      punctuated_word: "lazy",
    },
    {
      word: "dog",
      start: 3.2,
      end: 3.6,
      confidence: 0.9803699,
      punctuated_word: "dog",
    },
  ],
};

function generateRandom8DigitNumber() {
  return Math.floor(10000000 + Math.random() * 90000000);
}

export { previewTextTimestampedData };
