import React, { useState, useEffect, useRef, useCallback } from "react";
import rangy from "rangy";
import "rangy/lib/rangy-textrange";
import "rangy/lib/rangy-classapplier";
import '../css/transcript.css';
import { setMediaPanelInfo} from "../redux/projectSlice";
import { useDispatch, useSelector } from 'react-redux'
import { addSkipRangeInfo, getStyleRangeMap, removeObjectFromTimeline, removeSkipRangeInfo } from "../utils/transcript";

const TranscriptEditor = () => {
    
    const dispatch = useDispatch()
    const [transcript, setTranscript] = useState([]);
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, type: null });

    const transcriptRef = useRef(null);
    const transcriptContainerRef = useRef(null);

    const menuRef = useRef(null);

    const isStyleInitialised = useRef(false);

    const transcriptData = useSelector(
        (state) => state.projectReducer.videoInfo?.transcript
    )

    useEffect(() => {

        const initTranscript1 = async () => {
            if(!isStyleInitialised.current){
                console.log('INIT TRANSCRIPT!!', isStyleInitialised.current)
                setTranscript(transcriptData?.words);
                await isTranscriptReady();
                styleTranscriptEdits1();
                isStyleInitialised.current = true;
            }
        }

        initTranscript1()
    }, [])

    useEffect(() => {
        const handleGlobalMouseDown = (event) => {
            // If clicking inside the menu or during selection, do nothing
            if (menuRef.current?.contains(event.target)) {
                console.log('RETURN!')
                return;
            }
            console.log('CLOSE !!')
            setContextMenu({ visible: false, x: 0, y: 0, type: null });
        };

        document.addEventListener('mousedown', handleGlobalMouseDown);
        return () => document.removeEventListener('mousedown', handleGlobalMouseDown);
    }, [])

    useEffect(() => {
        console.log('IS STYLE INIT ==> ', isStyleInitialised.current);
    }, [isStyleInitialised.current])

    const isTranscriptReady = () => {
        return new Promise((resolve) => {
            var handle = setInterval(() => {
                const wordNodes = transcriptRef.current ? Array.from(transcriptRef.current.querySelectorAll(".wordSpan")) : [];
                if (wordNodes.length > 0) {
                    clearInterval(handle);
                    resolve();
                }
                }, 100);
        });
    };


    const styleTranscriptEdits1 = () => {
        if(!isStyleInitialised.current){
            isStyleInitialised.current = true;
            console.log('STYLE TRANSCRIPT!!', isStyleInitialised.current)

            var styledRanges = getStyleRangeMap();
            // skipRanges.map(({startTime, endTime}) => styledRanges.push({startTime, endTime, type: 'strike'}))

            console.log('Styled Ranges: ', styledRanges);
            const transcriptContainer = transcriptRef.current;
            if (!transcriptContainer) return;

            styledRanges.forEach(({startTime, endTime, type}) => {
                const wordNodes = Array.from(transcriptRef.current.querySelectorAll(".wordSpan"));

                const startNode = wordNodes.find(
                    (node) => parseFloat(node.getAttribute("data-start")) === startTime
                );
                const endNode = wordNodes.find(
                    (node) => parseFloat(node.getAttribute("data-end")) === endTime
                );

                if (startNode && endNode) {
                    const range = document.createRange();
                    range.setStartBefore(startNode);
                    range.setEndAfter(endNode);
                    
                    styleRange(range, type);
                }
            })
            
        }
    }

    const checkIfHighlighted = (selection) => {
        if (selection.rangeCount > 0) {
            // Get the first range of the selection
            const range = selection.getRangeAt(0);
    
            // Get the common ancestor of the range
            let commonAncestor = range.commonAncestorContainer;
    
            // If the commonAncestor is a text node, get its parent element
            if (commonAncestor.nodeType === Node.TEXT_NODE) {
                commonAncestor = commonAncestor.parentNode;
            }
    
            // Check if the commonAncestor or its ancestors are a span with the specific class
            let element = commonAncestor;
            while (element) {
                if (
                    element.nodeName === "SPAN" &&
                    element.classList.contains("highlighted-selection")
                ) {
                    console.log("The selected text is wrapped in a span with the class.");
                    return true;
                }
                element = element.parentNode; // Move up the DOM tree
            }
    
            if (!element) {
                console.log("The selected text is not wrapped in a span with the class.");
                return;
            }
        } else {
            console.log("No text is selected.");
            return;
        }
        return;
    }

    const handleSelection = (event) => {
        event.stopPropagation();

        const selection = rangy.getSelection();
        const selectedText = selection.toString().trim();

        if (selection.rangeCount === 0 || selectedText === '') {
            return;
        }

        const isHighlighted = checkIfHighlighted(selection);
        if (isHighlighted) {
            return;
        }

        const coords = getSelectionCoordinates();
        if (!coords) return;

        const transcriptRect = transcriptContainerRef.current.getBoundingClientRect();

        console.log('Rect Bounds: ', coords);
        console.log('Transcript Bounds: ', transcriptRect);

        setContextMenu({
            visible: true,
            x: coords.left + coords.width / 2,
            y: coords.top - 55,
            type: 'selection',
            range: selection.getRangeAt(0).cloneRange()
        });
    };

    const getSelectionCoordinates = () => {
        const selection = window.getSelection();
        console.log('SELECTION:: ', selection)
        if (!selection.rangeCount) return null;

        const range = selection.getRangeAt(0);
        // console.log('RANGE:: ', range)
        const rects = range.getClientRects();

        // console.log('RANGE RECTS:: ', rects);
        if (rects.length === 0) return null;

        // Use the last rect for the context menu position
        const lastRect = rects[0];
        return {
            left: lastRect.left,
            top: lastRect.top,
            height: lastRect.height,
            width: lastRect.width
        };
    };

    function styleRange(range, action) {
        console.log('Action: ', action)
        const clonedContents = range.cloneContents();
    
        const styleWrapper = document.createElement("span");
        styleWrapper.className = action == 'media' ? "highlighted-selection" : "strikethrough-selection";
        styleWrapper.appendChild(clonedContents);
    
        range.deleteContents();
        range.insertNode(styleWrapper);

        var options;
    
        if (action == 'media') {
            const highlightIcon = document.createElement("i");
            highlightIcon.className = "highlight-icon bi bi-play-btn";
    
            styleWrapper.parentNode.insertBefore(highlightIcon, styleWrapper);

            options = [
                {
                    name: 'Replace Media',
                    action: 'replace_media',
                    icon: <i class="bi bi-arrow-left-right"></i>
                },
                {
                    name: 'Delete',
                    action: 'delete',
                    icon: <i class="bi bi-trash3"></i>
                },
            ]
        } else if(action == 'strike'){
            options = [{
                name: 'Remove Cut',
                action: 'remove_cut',
                icon: <i class="bi bi-trash3"></i>
            }]
        }
    
        // Add click handler to highlighted text
        styleWrapper.addEventListener('click', (e) => handleHighlightClick(e, options, styleWrapper));
    }

    const handleStyle = (action) => {
        if (!contextMenu.range) return;

        const range = contextMenu.range;
        // const startNode = range.startContainer.parentNode;
        // const endNode = range.endContainer.parentNode;

        const startNode = range.startContainer.nodeType === Node.TEXT_NODE
            ? range.startContainer.parentNode
            : range.startContainer;
        range.setStartBefore(startNode);
        
        // Expand the end of the range to the closest parent node
        const endNode = range.endContainer.nodeType === Node.TEXT_NODE
            ? range.endContainer.parentNode
            : range.endContainer;
        range.setEndAfter(endNode);

        const startTime = parseFloat(startNode.getAttribute("data-start"));
        const endTime = parseFloat(endNode.getAttribute("data-end"));

        if (!isNaN(startTime) && !isNaN(endTime)) {
            styleRange(range, action);

            if(action == 'media'){
                const context = { actionType: 'add_media', startTime: startTime, endTime: endTime };
                openMediaPanel(context);
            } else if (action == 'strike') {

                var rangeInfo = { startTime: startTime, endTime: endTime };
                addSkipRangeInfo(rangeInfo)
            }
        }

        setContextMenu({ visible: false, x: 0, y: 0, type: null});
        rangy.getSelection().removeAllRanges();
    }

    const handleHighlightClick = (event, options, highlightWrapper) => {
        event.stopPropagation();
        const rect = highlightWrapper.getBoundingClientRect();
        const transcriptRect = transcriptContainerRef.current.getBoundingClientRect();

        console.log('OPTIONS: ', options)

        setContextMenu({
            visible: true,
            x: rect.left + rect.width / 2,
            y: rect.top - 50,
            type: 'highlight',
            options: options,
            highlightWrapper
        });
    };

    const removeHighlight = (action) => {
        if (!contextMenu.highlightWrapper) return;

        console.log('contextMenu: ', contextMenu)

        const highlightWrapper = contextMenu.highlightWrapper;
       
        const parent = highlightWrapper.parentNode;

        const highlightClass = highlightWrapper.className;

        const startNode = highlightWrapper.firstChild;
        const endNode = highlightWrapper.lastChild;

        const startTime = parseFloat(startNode.getAttribute("data-start"));
        const endTime = parseFloat(endNode.getAttribute("data-end"));

        var rangeInfo = {startTime: startTime, endTime: endTime}
        
        console.log('Range Info: ', rangeInfo);
        console.log('Action: ', action)

        if(highlightClass == 'highlighted-selection'){
            if(action == 'delete'){
                removeObjectFromTimeline(rangeInfo)
            }else if(action == 'replace_media'){
                const context = { actionType: 'replace_media', startTime: startTime, endTime: endTime };
                openMediaPanel(context);
            }
            
        }
        else if(highlightClass == 'strikethrough-selection'){
            removeSkipRangeInfo(rangeInfo);
        }   

        const highlightIcon = parent?.querySelector(".highlight-icon");
        // console.log('Highlight icon; ',highlightIcon )
        if(highlightIcon){
            parent.removeChild(highlightIcon);
        }
        
        while (highlightWrapper.firstChild) {
            // console.log('First CLild: ', highlightWrapper.firstChild);
            parent.insertBefore(highlightWrapper.firstChild, highlightWrapper);
        }
       
        parent.removeChild(highlightWrapper);
        
        setContextMenu({ visible: false, x: 0, y: 0, type: null });
        // setSelectedRange(null);
    };

    const openMediaPanel = (context) => {
        dispatch(setMediaPanelInfo({info: {isActive: true, context: context}}))
    }

    return (
        <div style={{width: '100%', height: '100vh'}}>
            <div ref={transcriptContainerRef} style={{ height: '75vh', width: '100%', fontFamily: 'Roboto', fontSize: 14, borderRadius: 5, marginTop: 30, padding: 10, overflow: 'scroll'}}>
                <div 
                    className="transcript" 
                    onMouseUp={handleSelection}  
                    ref={transcriptRef}
                    
                >
                    {transcript.map((word, index) => (
                        <span className='wordSpan' key={index} data-start={word.start} data-end={word.end}>
                            {word.punctuated_word}{" "}
                        </span>
                    ))}
                </div>

                {contextMenu.visible && (
                    <div 
                        className="context-menu"
                        ref={menuRef}
                        style={{
                            position: 'absolute',
                            left: `${contextMenu.x}px`,
                            top: `${contextMenu.y}px`,
                            transform: 'translateX(-50%)',
                            backgroundColor: 'white',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            padding: '4px',
                            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                            zIndex: 1000
                        }}
                    >
                        {contextMenu.type === 'selection' && (
                            <div 
                                className="selection-menu"
                            >
                                <div className='selection-menu-item' onClick={() => handleStyle('media')}><i class="bi bi-plus"></i> Add B-Roll</div>
                                <div className='selection-menu-item' style={{margin:0}} onClick={() => handleStyle('strike')}><i class="bi bi-scissors"></i> Cut</div>
                            </div>
                        )}
                        {contextMenu.type === 'highlight' && (
                            <div 
                                className="selection-menu"
                            >
                                {
                                    contextMenu.options.map((option) => 
                                        <div className='selection-menu-item' style={{margin:0}} onClick={() => removeHighlight(option.action)}>
                                            {option.icon} {option.name}</div>
                                    )
                                }
                            </div>
                        )}
                    </div>
                )}
            </div>
            
        </div>
        
    );
}

export default TranscriptEditor


