import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCaptionInfo, setRefreshCaptionState, setCaptionBtnLock } from "../../redux/projectSlice";
import { getCaptions } from '../../services/store'

const CaptionCollection = ({closeModal}) => {
    const dispatch = useDispatch()

    const [currMenuIdx, setCurrMenuIdx] = useState(0)
    const [items, setItems] = useState([])

    const createdCaptions = useCallback(async (e) => {
        console.log('Get Created Captions')
        const captionItems = await getCaptions();
        console.log('CAPTIONS: ', captionItems)
        setItems(captionItems)
        console.log('ITEMS: ', items)
    }, [setItems]);

    const featuredCaptions = useCallback(async (e) => {
        console.log('Get Featured Captions')
        const captionItems = await getCaptions();
        console.log('CAPTIONS: ', captionItems)
        setItems(captionItems)
        console.log('ITEMS: ', items)

    }, [setItems]);

    useEffect(() => {
        if(currMenuIdx == 0){
            createdCaptions()
        }
        else if(currMenuIdx == 1){
            featuredCaptions()
        }
    }, [currMenuIdx])

    const selectCaption = (caption) => {
        console.log('CAPTION SELECTED: ', caption);

        dispatch(updateCaptionInfo({ captionInfo: caption }));
        // dispatch(setRefreshCaptionState({ flag: true }));
        // dispatch(setCaptionBtnLock({ isActive: false }));

        // dialogRef?.current.close()
        // handleClose()
        closeModal()
    }

    return (
        <div style={{width: 420, margin: 10, padding: 10}}>
            <div style={{display: 'flex'}}>
                <div 
                    style={{flex: 1, padding: 10, paddingInline: 20, textAlign: 'center', borderBottom: currMenuIdx == 0 ? '2px solid #584efd' : '2px solid #ddd', cursor: 'pointer'}}
                    onClick={() => setCurrMenuIdx(0)}
                >
                    My Captions
                </div>
                <div 
                    style={{flex: 1, padding: 10, paddingInline: 20, textAlign: 'center', borderBottom: currMenuIdx == 1 ? '2px solid #584efd' : '2px solid #ddd', cursor: 'pointer'}}
                    onClick={() => setCurrMenuIdx(1)}
                >
                    Featured
                </div>
                <div style={{flex: 1, padding: 10,  paddingInline: 20, borderBottom: '2px solid #ddd'}}>

                </div>
            </div>
            <div style={{paddingTop: 15, width: '100%', height: 400, overflow: 'scroll'}}>
                {
                    items && items.map((caption) => (
                        <div style={{display: 'inline-block', margin: 15, justifyContent: 'center', alignItems: 'center', width: 160, height: 110}} onClick={() => selectCaption(caption)}>
                            <img 
                                height='90px'
                                style={{flex: 1, borderRadius: 5}}
                                src={caption?.presetUrl}
                            />
                           {/* <div style={{color: '#7e7e7e', marginTop: 2, fontSize: 14}}>{caption?.title}</div> */}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default CaptionCollection
