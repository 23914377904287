import React, { useState, useRef, useEffect } from 'react';
import useEffectOnChange from '../../hooks/useEffectOnChange';
import { HexColorPicker } from "react-colorful";
import '../../css/colorPicker.css';

const ColorPicker = ({ fill, options, setOptions }) => {
    const isInternalUpdate = useRef(false)

    const pickerRef = useRef(null);
    const mainInputRef = useRef(null);
    const panelInputRef = useRef(null);

    const [color, setColor] = useState(fill);
    const [showPicker, setShowPicker] = useState(false);

    useEffect(() => {
        // Handler for clicking outside
        const handleClickOutside = (event) => {
            // Check if click is outside the picker component
            if (pickerRef.current && !pickerRef.current.contains(event.target)) {
                setShowPicker(false);
            }
        };

        // Handler for pressing escape key
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                setShowPicker(false);
            }
        };

        // Add event listeners when picker is shown
        if (showPicker) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleEscapeKey);

            // Clean up event listeners
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
                document.removeEventListener('keydown', handleEscapeKey);
            };
        }
    }, [showPicker]);

    useEffect(() => {
        isInternalUpdate.current = false;
        if (fill) {
            console.log('FILL CHANGED: ', fill)
            setColor(fill)
        }
    }, [fill])

    useEffect(() => {
        if(isInternalUpdate.current){
            if (color) {
                updateInputValues(color.substring(1));
            }
            if (options && setOptions) {
                console.log('Setting new options, color: ', color)
                options = options ?? {}
                setOptions({ ...options, fill: color })
            }
        }
       
    }, [color])

    const updateColor = (newColor) => {
        isInternalUpdate.current = true;
        setColor(newColor)
    }

    const isValidHex = (hex) => {
        return /^[0-9A-Fa-f]{6}$/.test(hex);
    };

    const formatHex = (hex) => {
        hex = hex.toLowerCase().replace('#', '');
        if (/^[0-9A-Fa-f]{3}$/.test(hex)) {
            return `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
        }
        return hex;
    };

    const handleColorUpdate = (value, inputRef) => {
        if (value) {
            const hexValue = formatHex(value);

            if (isValidHex(hexValue)) {
                const newColor = '#' + hexValue;
                // setNewColor(newColor);
                updateColor(newColor)
                updateInputValues(hexValue);
            } else {
                updateInputValues(color.substring(1));
            }
        }

    };

    const updateInputValues = (hexValue) => {
        if (mainInputRef.current) {
            mainInputRef.current.innerText = hexValue;
        }
        if (panelInputRef.current) {
            panelInputRef.current.innerText = hexValue;
        }
    };

    const handleKeyDown = (e, inputRef) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleColorUpdate(e.target.innerText, inputRef);
        }
    };

    const handleBlur = (e, inputRef) => {
        handleColorUpdate(e.target.innerText, inputRef);
    };

    const InputField = ({ inputRef, value }) => (
        <div className="input-box">
            {
                value &&
                <span className="input-box-hashtag">#</span>
            }
            <div
                ref={inputRef}
                contentEditable
                className="input-value-box"
                onKeyDown={(e) => handleKeyDown(e, inputRef)}
                onBlur={(e) => handleBlur(e, inputRef)}
                suppressContentEditableWarning={true}
            >
                {value}
            </div>
        </div>
    );

    const ColorSwatch = ({ colorVal }) => {
        if (colorVal) {
            return (
                <div
                    style={{ backgroundColor: color, width: '2.5rem', borderTopLeftRadius: 4, borderBottomLeftRadius: 4, borderRight: '1px solid #eee', display: 'flex', cursor: 'pointer' }}
                    onClick={() => setShowPicker(!showPicker)}
                />
            )
        }
        else {
            return (
                <div
                    className='disable-bx-square disable-bx-diagonal-line'
                    onClick={() => setShowPicker(!showPicker)}
                />
            )
        }
    };

    return (
        <div className="position-relative" ref={pickerRef}>
            <div className="d-flex" style={{ border: '1px solid #eee', borderRadius: 4 }}>

                <ColorSwatch colorVal={color} />
                <InputField inputRef={mainInputRef} value={color && color.substring(1)} />
            </div>

            {showPicker && (
                <div className="position-absolute shadow-lg"
                    style={{ top: '100%', left: '-30%', marginTop: '0.5rem', backgroundColor: '#ffffff', padding: '0.5rem', border: '1px solid #eee', borderRadius: 4, zIndex: 3 }}
                >
                    <HexColorPicker color={color ?? '#fff'} onChange={updateColor} />

                    <div className="d-flex"
                        style={{ marginTop: '1rem', border: '1px solid #eee', borderRadius: 4 }}
                    >
                        <ColorSwatch colorVal={color} />
                        <InputField inputRef={panelInputRef} value={color && color.substring(1)} />
                    </div>
                </div>
            )
            }
        </div >
    )
}

export default ColorPicker
