import axios from "axios";
// import { useDispatch, useSelector } from 'react-redux'
import {
  addKeywordCache,
  refreshKeywordCache,
} from "../redux/keywordCacheSlice";
import { store } from "../redux/store";

var IMAGES = "images";
var VIDEOS = "videos";

const lookupCacheForKeyword = (searchTerm) => {
  const response =
    store.getState().keywordCacheReducer.keywordCache[searchTerm];
  console.log("looking up keyword in cache", response);
  if (response === undefined) {
    return;
  } else {
    return response;
  }
};

export async function getImagesFromSource(searchTerm, page, loadMore) {
  var lastPage = 1;
  var pageCount = 20;
  if (page > 1) {
    pageCount = 5;
  }

  var cachedImages;
  var cachedKeywordObj = lookupCacheForKeyword(searchTerm);

  if (cachedKeywordObj && cachedKeywordObj[IMAGES]) {
    cachedImages = cachedKeywordObj[IMAGES];
    return cachedImages;
  }

  try {
    let imageSearchResponse = await axios.get(
      `${
        process.env.REACT_APP_PEXEL_IMAGE_SEARCH_ENDPOINT
      }?query=${encodeURIComponent(
        searchTerm
      )}&per_page=${pageCount}&page=${page}`,
      {
        headers: {
          Authorization: process.env.REACT_APP_PEXEL_ACCESS_KEY,
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Getting Images");
    console.log(imageSearchResponse.data.photos);
    const imageEntities = imageSearchResponse.data?.photos;

    var imageEntityList = [];

    imageEntities.map((imageObj) => {
      imageEntityList.push({
        mediaType: "image",
        id: imageObj.id,
        thumbnail: imageObj.src.small,
        url: imageObj.src.original,
        height: imageObj.height,
        width: imageObj.width,
      });
    });

    var newKeywordObj = { ...cachedKeywordObj, images: imageEntityList };
    store.dispatch(
      addKeywordCache({ keyword: searchTerm, response: newKeywordObj })
    );

    console.log("Image List: ", imageEntityList);
    return imageEntityList;
  } catch (error) {
    console.log("Error occured while fetching images from source");
    console.log(error);
    throw error;
  }
}

export function getVideosForKeywordsWithFallback(keywordList) {
  return new Promise((res, rej) => {
    for (let i = 0; i < keywordList.length; i++) {
      getVideosFromSource(keywordList[i], 1, null).then((videoData) => {
        if (videoData.length > 0) {
          res(videoData);
        }
      });
    }
  });
}

export async function getVideosFromSource(searchTerm, page, loadMore) {
  // store.dispatch(
  //     refreshKeywordCache(),
  // )

  console.log("ENDPOINT: ", process.env.REACT_APP_PEXEL_VIDEO_SEARCH_ENDPOINT);

  var cachedVideos;
  var cachedKeywordObj = lookupCacheForKeyword(searchTerm);

  if (cachedKeywordObj && cachedKeywordObj[VIDEOS]) {
    cachedVideos = cachedKeywordObj[VIDEOS];
  }

  var lastPage = 1;
  var pageCount = 50;
  if (page > 1) {
    pageCount = 5;
  }

  console.log("Search Term:", searchTerm);
  console.log("Page: ", page);
  page++;

  try {
    /* Return cached videos if the page is already visited*/
    if (cachedVideos && cachedVideos.length > 0) {
      lastPage = cachedVideos[cachedVideos.length - 1].page;
      console.log("Last Page Visited: ", lastPage);
      if (lastPage <= page && !loadMore) {
        return cachedVideos;
      }
      if (loadMore) {
        page = lastPage + 1;
      }
    }

    let videoSearchResponse = await axios.get(
      `${
        process.env.REACT_APP_PEXEL_VIDEO_SEARCH_ENDPOINT
      }?query=${encodeURIComponent(
        searchTerm
      )}&per_page=${pageCount}&page=${page}`,
      {
        headers: {
          Authorization: process.env.REACT_APP_PEXEL_ACCESS_KEY,
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Getting Videos");
    console.log(videoSearchResponse.data.videos);
    const videoEntities = videoSearchResponse.data?.videos;

    console.log(videoEntities);
    var videoEntityList = [];

    /* Filtering HD videos with aspect ratio width/height > 1 */
    videoEntities.map((videoObj) => {
      try {
        var videoFile;
        var lowResFile;

        videoObj.video_files.map((vFile) => {
          if (vFile.quality == "hd") {
            videoFile = vFile;
          } else if (vFile.quality == "sd") {
            lowResFile = vFile;
          }
        });

        if (videoFile && videoFile.width / videoFile.height >= 1) {
          videoEntityList.push({
            mediaType: "video",
            id: videoObj.id,
            thumbnail: videoObj.image,
            encodingFormat: videoFile.file_type,
            author: videoObj.user.name,
            duration: videoObj.duration,
            page: page,
            sd: {
              url: lowResFile.link,
              height: lowResFile.height,
              width: lowResFile.width,
            },
            hd: {
              url: videoFile.link,
              height: videoFile.height,
              width: videoFile.width,
            },
            url: videoFile.link,
            height: videoFile.height,
            width: videoFile.width,
            source: "pexels",
          });
        }
      } catch (error) {
        console.log("Error occured: video dimensions not found");
        console.log(error);
      }
    });

    if (videoEntityList.length > 0 && videoEntityList.length < 5 && page < 5) {
      getVideosFromSource(searchTerm, (page = page++));
    }

    console.log("Video Entity List: ", videoEntityList);

    var videoList = [];

    /* Add Cached videos to the list is exist */
    if (cachedVideos) {
      videoList = cachedVideos;
    }

    videoList = videoList.concat(videoEntityList);

    console.log(`Search Term: ${searchTerm}, Final Video List:  ${videoList}`);

    var newKeywordObj = { ...cachedKeywordObj, videos: videoList };
    store.dispatch(
      addKeywordCache({ keyword: searchTerm, response: newKeywordObj })
    );
    return videoList;
  } catch (error) {
    console.log("Error occured while fetching videos from source");
    console.log(error);
    throw error;
  }
}
