import React, {useState, useEffect} from 'react'
import '../../css/media.css'
import { useDispatch, useSelector } from 'react-redux'
import { updateActionLog } from '../../redux/projectSlice'
import { actionStatus } from '../../utils/actionManager'
import { getVideosFromSource, getImagesFromSource } from '../../services/media'
import { generateRandomString } from '../../utils/commonUtils'
import StockMediaOptions from '../StockMediaOptions'
import LibraryView from '../LibraryView'


const MediaOptions = ({context, closeModal}) => {
    const dispatch = useDispatch()

    const [currMenuIdx, setCurrMenuIdx] = useState(0)

    const actionLog = useSelector(
        (state) => state.projectReducer.actionLog
    )

    useEffect(() => {
        console.log('CONTEXT: ', context)
    }, [context])

    const getObjectInfoFromStock = (obj, context) => {
        var objInfo = {
            id: generateRandomString(),
            startTime: context.startTime,
            endTime: context.endTime,
            type: obj.mediaType
        };
        if(obj.mediaType == 'video'){
            objInfo['info'] = {...obj.sd, duration: obj.duration}
            objInfo['rInfo'] = {...obj.hd, duration: obj.duration}
        }else if(obj.mediaType == 'image'){
            objInfo['info'] = {
                url: obj.url,
                height: obj.height,
                width: obj.width
            };
        }
        return objInfo;
       
    }

    const getObjectInfo = (obj, context, source) => {
        var objInfo = {
            id: generateRandomString(),
            startTime: context.startTime,
            endTime: context.endTime,
            type: obj.mediaType
        };
        if(obj.mediaType == 'video'){
            if(source == 'stock_media'){
                objInfo['info'] = {...obj.sd, duration: obj.duration}
                objInfo['rInfo'] = {...obj.hd, duration: obj.duration}
            }
            else{
                objInfo['info'] = {height: obj.height, width: obj.width, url: obj.url, duration: obj.duration}
            }
           
        }else if(obj.mediaType == 'image'){
            objInfo['info'] = {
                url: obj.url,
                height: obj.height,
                width: obj.width
            };
        }
        return objInfo;
       
    }
    
    const handleMediaOptionClick = (obj, source) => {
        console.log('Media Selected: ', obj)
        console.log('CONTEXT: ', context)

        let actionLogRef = actionLog ?? [];
        console.log('ACTION LOG:: ', actionLogRef)

        var objInfo = getObjectInfo(obj, context, source);
       
        const action = {
            type: context.actionType,
            startTime: context.startTime,
            endTime: context.endTime,
            state: {
                old: null,
                new: objInfo
            },
            info: objInfo,
            status: actionStatus.PROCESS
        }

        dispatch(
            updateActionLog({actionLog: [action, ...actionLogRef]})
        )
        closeModal();
    }

    const ContentView = ({}) => {
        switch(currMenuIdx){
            case 0:
                return <StockMediaOptions handleMediaOptionClick={handleMediaOptionClick}/>
            case 1:
                return <LibraryView handleMediaOptionClick={handleMediaOptionClick}/>
            default:
                return <StockMediaOptions handleMediaOptionClick={handleMediaOptionClick}/>
        }
    }

    


    return (
        <div style={{width: 650}}>
            <div style={{display: 'flex'}}>
                <div 
                    style={{flex: 1, padding: 10, paddingInline: 20, textAlign: 'center', borderBottom: currMenuIdx == 0 ? '2px solid #584efd' : '2px solid #ddd', cursor: 'pointer'}}
                    onClick={() => setCurrMenuIdx(0)}
                >
                    Stock Library
                </div>
                <div 
                    style={{flex: 1, padding: 10, paddingInline: 20, textAlign: 'center', borderBottom: currMenuIdx == 1 ? '2px solid #584efd' : '2px solid #ddd', cursor: 'pointer'}}
                    onClick={() => setCurrMenuIdx(1)}
                >
                    My Library
                </div>
                <div style={{flex: 3, padding: 10,  paddingInline: 20, borderBottom: '2px solid #ddd'}}>

                </div>
            </div>
            <div style={{marginTop: 20, width: '95%', margin: 'auto'}}>
                <ContentView />
            </div>
        </div>
    )
}

export default MediaOptions
