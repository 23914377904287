import React, {useState, useRef, useEffect, useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import { useIsAuthenticated } from '../../hooks/useIsAuthenticated'
import { useNavigate } from "react-router-dom";
import '../../css/input.css'
import { uploadAndTranscibeVideo } from '../../utils/process'
import uuid from 'react-uuid';

const InputView = ({closeModal}) => {
    const authUser = useIsAuthenticated()
    const navigate = useNavigate()

    const [videoBlob, setVideoBlob] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });
    const [videoDuration, setVideoDuration] = useState(0);

    const [boxDims, setBoxDims] = useState({height: 0, width: 0})
    const [isProcessing, setProcessing] = useState(false)

    const videoRef = useRef(null)
    const videoBoxRef = useRef(null)

    useEffect(() => {
        if(videoRef.current){
            const video = videoRef.current;
            const rect = video.getBoundingClientRect();
            setBoxDims({height: rect.height, width: rect.width})
        }
       
    }, [videoRef.current])
    

    const onDrop = useCallback(acceptedFiles => {
        if(authUser){
            console.log('Accepted Files: ', acceptedFiles)
            if(acceptedFiles?.length){
                console.log('User: ', authUser)
                console.log('FILES: ', acceptedFiles)

                const file = acceptedFiles[0];
                if (file && file.type.startsWith('video/')) {
                    const videoURL = URL.createObjectURL(file);
                    setVideoFile(file);
                    setVideoBlob(videoURL);

                    const video = document.createElement('video');
                    video.src = videoURL;
                    video.onloadedmetadata = () => {
                        setVideoDimensions({ width: video.videoWidth, height: video.videoHeight });
                        setVideoDuration(video.duration);
                    };
                    video.onloadeddata = () => {
                        video.remove();
                    };


                } else {
                    alert('Please upload a valid video file.');
                }
                // uploadAssetToCloudAndSaveInDB(authUser?.uid, acceptedFiles)
                // uploadFiles(authUser?.uid, acceptedFiles)
            }
        }
      
    }, [authUser])

    const uploadFile = async () => {
        setProcessing(true);
        const projectId = uuid()

        var videoInfo = {
            mainVideo: {
                info: {
                    height: videoDimensions.height,
                    width: videoDimensions.width,
                },
                startTime: 0,
                endTime: videoDuration
            },
            projectId: projectId
        }
        const { status, message } = await uploadAndTranscibeVideo(authUser?.uid, projectId, videoInfo, videoFile);
        if(status == 0){
            // Display Error Message
            console.log('Error occured while generating transcript -> ', message);
            return;
        }

        setProcessing(false);

        closeModal()

        navigate(`/edit/${projectId}`);
        
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop, 
        accept: 'video/*', // Accept only video files
        multiple: false,
        maxSize: 1024*1000*20
    })

    return (
        <div style={{ flexDirection: 'column', width: 700, height: 500, marginTop: 20, margin: 'auto', padding: 20}}>
            {/* <h2 style={{color: '#6CB4EE'}}>Upload Files</h2> */}
            
            <div style={{display: 'flex', justifyContent: 'center', fontSize: 24, fontWeight: 500 }}>
                Upload Video
            </div>
            {
                videoBlob ? 
                    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center', width: '100%', maxHeight: 450}}>
                        <div ref={videoBoxRef} style={{position: 'relative', height: '350px', margin: 'auto'}}>
                            <video
                                ref={videoRef}
                                controls
                                src={videoBlob}
                                style={{ maxHeight: '100%', maxWidth: '100%', border: '1px solid #ddd' }}
                            >
                                Your browser does not support the video tag.
                            </video>
                            {
                                isProcessing && 
                                    <div style={{position: 'absolute', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', top: 0, left: 0, height: boxDims.height, width: boxDims.width, backgroundColor: 'rgba(0,0,0,0.4)' }}>
                                         <div className="loading-bar"></div>
                                        <div style={{color: '#fff'}}>
                                            Generating Captions...
                                        </div>
                                    </div>
                            } 
                        </div>    
                        {
                            !isProcessing && 
                                <div class='action-btn-primary' onClick={uploadFile}>Upload </div>
                        }   
                        
                        
                    </div> :
                    <div style={{ display: 'flex',  justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 50}}>
                        <div style={{width: 500, height: 400}}>
                            <div {...getRootProps({
                                className: 'p-40 mt-10 border-dashed-2 upload-block'
                            })}>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Click to browse, or ...</p> :
                                    <div>
                                        <div style={{textAlign: 'center', fontSize: 60, color: '#5b44ed', marginBottom: 10}}><i class="bi bi-upload"></i></div>
                                        <h5 style={{ fontSize: 16, fontWeight: 500, textAlign: 'center'}}>Click to browse of drop your video here.</h5>
                                        <p style={{fontSize: 14, fontWeight: 700, textAlign: 'center', color: '#aaa'}}>Max Length: 5 min &nbsp; Format: MP4, MOV</p>
                                    </div>
                                
                            }
                            </div>
                        </div>
                        
                    </div>
                    
            }
            
           
                
        </div>
    )
}

export default InputView
