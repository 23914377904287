import {
  updateSkipInfo,
  updateActionLog,
  updateTimeline,
} from "../redux/projectSlice";
import { store } from "../redux/store";
import { actionType, actionStatus } from "./actionManager";

function addSkipRangeInfo(rangeInfo) {
  const state = store.getState();
  const projectReducer = state.projectReducer;

  var skipRanges = projectReducer.skipRanges;

  var newSkipRangeArr = skipRanges.length
    ? [...skipRanges, rangeInfo]
    : [rangeInfo];
  //   var newSkipRangeArr = [rangeInfo];
  newSkipRangeArr = [...new Set(newSkipRangeArr)];
  console.log("New Skip Info -> ", { skipRanges: newSkipRangeArr });
  store.dispatch(updateSkipInfo({ skipRanges: newSkipRangeArr }));
}

function removeSkipRangeInfo(rangeInfo) {
  const state = store.getState();
  const projectReducer = state.projectReducer;

  var skipRanges = projectReducer.skipRanges;
  var rangeInfoIdx = skipRanges.findIndex(
    (item) =>
      item.startTime == rangeInfo.startTime && item.endTime == rangeInfo.endTime
  );

  if (rangeInfoIdx != null && rangeInfoIdx >= 0) {
    var newSkipRangeArr = [...skipRanges];
    newSkipRangeArr.splice(rangeInfoIdx, 1);

    console.log("New Skip Info -> ", { skipRanges: newSkipRangeArr });
    store.dispatch(updateSkipInfo({ skipRanges: newSkipRangeArr }));
  }
}

function removeObjectFromTimeline(rangeInfo) {
  const state = store.getState();
  const projectReducer = state.projectReducer;

  const timeline = projectReducer.timeline;
  const actionLog = projectReducer.actionLog;

  let actionLogRef = actionLog ?? [];

  var objInfo = timeline.find(
    (item) =>
      item.startTime == rangeInfo.startTime && item.endTime == rangeInfo.endTime
  );
  if (objInfo) {
    const action = {
      type: actionType.DELETE_MEDIA,
      startTime: rangeInfo.startTime,
      endTime: rangeInfo.endTime,
      state: {
        old: null,
        new: objInfo,
      },
      info: objInfo,
      status: actionStatus.PROCESS,
    };

    store.dispatch(updateActionLog({ actionLog: [action, ...actionLogRef] }));
  }
}

function getStyleRangeMap() {
  const state = store.getState();
  const projectReducer = state.projectReducer;

  const timeline = projectReducer.timeline;
  const skipRanges = projectReducer.skipRanges;

  var styledRanges = [];
  skipRanges.map(({ startTime, endTime }) =>
    styledRanges.push({ startTime, endTime, type: "strike" })
  );

  timeline.map((obj) =>
    styledRanges.push({
      startTime: obj.startTime,
      endTime: obj.endTime,
      type: "media",
    })
  );

  return styledRanges;
}

export {
  addSkipRangeInfo,
  getStyleRangeMap,
  removeSkipRangeInfo,
  removeObjectFromTimeline,
};
