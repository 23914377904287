import React from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Chevron = ({isOpen}) => {
    return (
        <>
            {
                isOpen ?
                    <ExpandLessIcon style={{ marginLeft: '0.4rem', cursor: 'pointer', fontSize: 18 }} />
                    :
                    <ExpandMoreIcon style={{ marginLeft: '0.4rem', cursor: 'pointer', fontSize: 18  }} />
            }
        </>
    )
}

export default Chevron
