import React, {useRef, useCallback, useEffect} from 'react'

const ModalComponent = ({lockOpen, open, handleClose, children}) => {

    const modalRef = useRef();

    const closeModal = () => {
        const dialog = modalRef.current;
        dialog?.close()
        handleClose()
    }


    const handleModalClick = useCallback((e) => {
        const dialog = modalRef.current;
        const dialogDimensions = dialog.getBoundingClientRect();
        
        if (
            (e.clientX < dialogDimensions.left ||
            e.clientX > dialogDimensions.right ||
            e.clientY < dialogDimensions.top ||
            e.clientY > dialogDimensions.bottom) && 
            !lockOpen
        ) {
            dialog.close();
            handleClose();
        }
    }, [handleClose]);

    // Handle modal open/close
    useEffect(() => {
        const dialog = modalRef.current;
        
        if (open) {
            dialog.showModal();
            dialog.addEventListener("click", handleModalClick);
            
            return () => dialog.removeEventListener("click", handleModalClick);
        }
    }, [open, handleModalClick]);

    
    return (
        <>
            <dialog ref={modalRef} style={{position: 'relative', border: '1px solid #ddd', borderRadius: 10}}>
                <div className='modal-close' onClick={() => {
                    modalRef.current.close();
                    handleClose();
                }}>
                    x
                </div>
                {
                    React.Children.map(children, child => 
                        React.cloneElement(child, {
                            closeModal
                        })
                    )
                }
            </dialog>
        </>
    )
}

export default ModalComponent
