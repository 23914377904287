function hexToRgbaConvert(input, opacity = 1) {
  // Ensure opacity is within valid bounds
  opacity = Math.min(Math.max(opacity, 0), 1);

  // Regex for hex color (supports 3, 6, and 8-character hex codes)
  const hexRegex = /^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/;
  // Regex for rgba color
  const rgbaRegex =
    /^rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})(?:\s*,\s*(0|1|0?\.\d+))?\s*\)$/;

  if (hexRegex.test(input)) {
    // Convert hex to rgba
    let hex = input.slice(1); // Remove the '#' symbol

    if (hex.length === 3) {
      hex = hex
        .split("")
        .map((c) => c + c)
        .join(""); // Expand shorthand (e.g., #abc to #aabbcc)
    }

    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    // Use provided opacity or default from the hex alpha (if present)
    const a = hex.length === 8 ? parseInt(hex.slice(6, 8), 16) / 255 : opacity;

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  } else if (rgbaRegex.test(input)) {
    // Extract RGBA components from input and apply the new opacity
    const match = input.match(rgbaRegex);
    const r = parseInt(match[1], 10);
    const g = parseInt(match[2], 10);
    const b = parseInt(match[3], 10);
    const a = opacity;

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  } else {
    return "Invalid color format";
  }
}

function getTextByCase(text, letterCase) {
  var newText;
  switch (letterCase) {
    case "uppercase":
      newText = text.toUpperCase();
      break;
    case "lowercase":
      newText = text.toLowerCase();
      break;
    case "sentence":
    default:
      newText = text;
  }
  return newText;
}

function areObjectsEqual(obj1, obj2) {
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return obj1 === obj2; // Compare primitives
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false; // Check key count

  return keys1.every((key) => areObjectsEqual(obj1[key], obj2[key])); // Recursively compare
}

function generateRandom8DigitNumber() {
  return Math.floor(10000000 + Math.random() * 90000000);
}

function generateRandomString() {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let str = "";
  for (let j = 0; j < 5; j++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    str += characters[randomIndex];
  }

  return str;
}

export {
  hexToRgbaConvert,
  getTextByCase,
  areObjectsEqual,
  generateRandom8DigitNumber,
  generateRandomString,
};
