import React, {useState, useEffect} from 'react'
import reactCSS from 'reactcss'
import googleIcon from '../../assets/img/logos/googlefavicon.ico';
import emailIcon from '../../assets/img/email-icon.svg';
import { useDispatch, useSelector } from 'react-redux'
import {signInUserWithEmail, signInWithGoogle, sendSignInLinkToUserEmail, logoutUser} from '../../services/auth'
import { useLocation, useNavigate } from "react-router-dom";
import {Link } from "react-router-dom";

const Auth = () => {
    
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { state } = useLocation();

    const authState = useSelector(
        (state) => state.authReducer
    )

    const [error, setError] = useState()
    const [isErrorDisplayed, setIsErrorDisplayed] = useState()

    const [isEmailSent, setIsEmailSent] = useState(false)

    const [email, setEmail] = useState(
        window.localStorage.getItem('diveoEmailForSignIn') || ''
    ) 

    useEffect(() => {
        if(authState.isAuthenticated){
            // Navigate to storyboard
            navigate(state?.path || "/home");
            // navigate("/home")

        }else{
            // Navigate to login page
        }
     }, [authState])

    useEffect(() => {
        const cachedEmail = window.localStorage.getItem("diveoEmailForSignIn");
        if(!!cachedEmail){
            // If the user has code, sign the user in
            signInUserWithEmail(cachedEmail)
        }
    }, [])

    const trySignInWithEmail = () => {
        if(validateEmail(email)){
          sendSignInLinkToUserEmail(email)
          window.localStorage.setItem("diveoEmailForSignIn", email)
          setIsEmailSent(true)
          setError()
          setIsErrorDisplayed(false)
        }else{
          setError('Please enter a valid email address. ')
          setIsErrorDisplayed(true)
        }
       
    }

    const validateEmail = (email) => {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }

    const resetLogin = () => {
        setIsEmailSent(false)
    }

    const closeErrorDisplay = () => {
      setIsErrorDisplayed(false)
    }

    const styles = reactCSS({
        'default': {
            
         googlelogin: {
            width: '100%',
            height: 50,
            border: '1px solid #eee',
            borderRadius: 10,
            color: '#212529',
          },
          btnIcon: {
            height: 18,
            width: 18,
            fontSize: 14,
            textAlign: 'left',
          },
          email: {
            height: 50,
            width: '100%',
            marginBottom: 10,
            border: '2px solid #eee',
            padding: 5,
            paddingInline: 10,
            fontSize: 14,
            color: '#212529'
          },
          emailSignInBtn: {
            height: 50,
            width: '100%', 
            color: '#ffffff',
            backgroundColor: '#584efd',
          },
          btnText: {
            fontSize: 13,
            marginLeft: 10,
            textAlign: 'center'
          },
          cardHeading: {
            fontSize: 21,
            fontWeight: 500,
            textAlign: 'center',
            width: '100%',
            color: '#212529'
          },
          cardSubHeading: {
            fontSize: 16,
            fontWeight: 300,
            textAlign: 'center',
            width: '100%',
            color: '#212529',
            marginBottom: 30
          },
          emailIconSection: {
            maxWidth: 100,
            margin: 'auto',
          },
          emailStatus: {
            fontSize: 21,
            marginTop: 30,
            color: '#212529',
            fontWeight: 400,
            width: '100%',
            textAlign: 'center'
          },
          emailIcon: {
            height: 100,
            maxWidth: 200,
            alignItems: 'center',    
          },
          message: {
            fontSize: 16,
            fontWeight: 300,
            color: '#212529',
            width: '80%',
            margin: 'auto',
            textAlign: 'center',
            marginTop: 10
          },
          linkSection: {
              width: '80%',
              margin: 'auto',
              textAlign: 'center',
              marginTop: 40
          },
          link: {
            color: '#584efd',
          }

        },
    });

    return (
        <div style={{ margin: 'auto', marginTop: 100, borderRadius: 20, backgroundColor: '#fff', width: 350, minHeight: 420, padding: 30}}>
            {
                isEmailSent === false ? (<div>
                    <div style={styles.cardHeading}>Welcome to Diveo</div>
                    <div style={styles.cardSubHeading}>Sign in to your account and start creating</div>

                    <div style={{ display: isErrorDisplayed ? 'block': 'none',padding: 8, width: '100%', margin: 'auto', marginTop: 5, marginBottom: 10, borderRadius: 5, backgroundColor: '#ED4337', color: '#fff'}}>
                        <span style={{width: '80login-with-emaillogin-with-email%'}}>{error}</span>
                        <div style={{float: 'right', textAlign: 'right', fontSize: 18, width: '10%', cursor: 'pointer'}} onClick={closeErrorDisplay}>
                            <i class="bi bi-x"></i>
                        </div>
                    </div>

                    <button class='btn' style={ styles.googlelogin } onClick={() => signInWithGoogle()}><img style={styles.btnIcon} src={googleIcon}></img><span style={styles.btnText}>Sign in with Google</span></button>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ height: '1px', width: '100%', background: '#d1d5db' }}></div>
                        <p style={{ margin: '10px', fontWeight: 100, fontSize: 15, color: '#94979c' }}>OR</p>
                        <div style={{ height: '1px', width: '100%', background: '#d1d5db' }}></div>
                    </div>
                    <br />
                    <input
                        style={ styles.email }
                        type='email'
                        placeholder='Enter your email address here'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    ></input>
                    
                    <button class='btn' style={ styles.emailSignInBtn } onClick={() => trySignInWithEmail()}> <i class="bi bi-envelope" style={styles.btnIcon}></i> <span style={styles.btnText}>Sign in with email</span></button>
                   
                </div>) : <div>
                    <div style={styles.emailIconSection}>
                        <img style={styles.emailIcon} src={emailIcon}></img>
                    </div>
                    <div style={styles.emailStatus}>Email Sent!</div>
                    <div style={styles.message}>Having trouble finding the email? Make sure to inspect your spam folder.</div>

                    <div style={styles.linkSection}>
                        <Link style={styles.link} onClick={resetLogin}><i class="bi bi-arrow-left"></i>  Go back to login page</Link>
                    </div>
                    
                </div>
            }
           
        </div>
    )
}

export default Auth
