import React, { useState, useEffect } from 'react'
import useEffectOnChange from '../../hooks/useEffectOnChange'
import ColorPicker from './ColorPicker'
import NumberInput from './NumberInput'

const StrokeSettings = ({options, setOptions}) => {

    const [strokeOptions, setStrokeOptions] = useState({fill: options?.stroke,  strokeWidth: options?.strokeWidth || 0})

    useEffectOnChange(() => {
        console.log('STROKE SETTING CHANGED:: STROKE ', strokeOptions?.fill)
        setOptions(prevValue => ({
            ...prevValue,
            stroke: strokeOptions?.fill,
        }));
       
    }, [strokeOptions?.fill])

    useEffectOnChange(() => {
        console.log('STROKE SETTING CHANGED:: STROKE WIDTH ', strokeOptions?.strokeWidth)
        setOptions(prevValue => ({
            ...prevValue,
            strokeWidth: strokeOptions?.strokeWidth,
        }));
       
    }, [strokeOptions?.strokeWidth])

    return (
        <>
            {
                options && 
                <div>
                    <div className='options-form-row'>
                        <div className='options-label'>
                            Color
                        </div>
                        <div className='options-edit-box'>
                            <ColorPicker fill={strokeOptions?.fill} options={strokeOptions} setOptions={setStrokeOptions} />
                        </div>
                    </div>
                    <div className='options-form-row'>
                        <div className='options-label'>
                            Thickness
                        </div>
                        <div className='options-edit-box'>
                            {/* <input type='number' style={{ width: '3rem', border: '1px solid #eee' }} /> */}
                            <NumberInput defaultValue={strokeOptions?.strokeWidth ?? 0} options={strokeOptions} setOptions={setStrokeOptions} label='StrokeWidth' />
                        </div>
                    </div>
                </div>
            }
        </>
           )
}

export default StrokeSettings
