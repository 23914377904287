import { fabric } from "fabric";
import anime from "animejs/lib/anime.es.js";
import { createTextTimeline } from "../utils/textProcessor";
import {
  createTextboxFactory,
  captionFactory,
} from "../constants/customFabric";
import {
  getAnimationObjectFromType,
  createAnimationTimeline,
} from "./animation";

fabric.Object.prototype.getZIndex = function () {
  return this.canvas.getObjects().indexOf(this);
};

function createVideoElement(videoInfo, videoId) {
  console.log("VIDEO INFO ===>", videoInfo);
  var video = document.createElement("video");
  if (videoInfo) {
    video.crossOrigin = "anonymous";
    video.muted = true;
    video.height = videoInfo.height;
    video.width = videoInfo.width;
    video.src = videoInfo.url;
  }
  // video.onseeked = updateStateAfterSeeking;

  video.onseeked = () => {
    window.videoSeekStatus[videoId] = true;
  };

  return video;
}

function createImageElement(imageInfo) {
  console.log("IMAGE INFO: ", imageInfo);
  return new Promise((res, rej) => {
    if (imageInfo) {
      var imgEl = document.createElement("img");
      imgEl.src = imageInfo.url;
      imgEl.height = imageInfo.height;
      imgEl.width = imageInfo.width;
      imgEl.crossOrigin = "anonymous";

      imgEl.onload = function () {
        res(imgEl);
      };
    }
  });
}

const updateStateAfterSeeking = () => {
  console.log("SEEKING FRAME FOR VIDEO");

  window.ready = true;
};

const isVideoReady = (videoEl) => {
  return new Promise((resolve) => {
    const video = videoEl;
    if (video) {
      var handle = setInterval(() => {
        // var video = videoRef.current;
        if (video.readyState == 4) {
          console.log("State ==>", video.readyState);
          clearInterval(handle);
          console.log("VIDEO READY!!");
          resolve();
        }
      }, 100);
    } else {
      resolve();
    }
  });
};

async function compose(
  videoInfo,
  canvas,
  containerParams,
  videoRef,
  captionRef,
  clipPathRef,
  textTimestamps,
  captionInfo
) {
  // const startTime = info.start;

  console.log("VIDOE INFO::=> ", videoInfo);
  const mainVideoData = videoInfo.mainVideo;
  const textData = videoInfo?.transcript;

  const startTime = mainVideoData?.start ?? 0;
  const captionSettings = captionInfo?.config;
  var index = 1;
  if (!clipPathRef) {
    index = 0;
  }
  var renderConfig = [];

  if (mainVideoData) {
    var mediaObj = await addMediaToCanvas(
      startTime,
      mainVideoData,
      canvas,
      containerParams,
      videoRef,
      clipPathRef
    );

    renderConfig.push({
      id: "main_video",
      obj: mediaObj,
      index: index,
    });
  }

  // Render Captions
  if (!textTimestamps.current.length) {
    textTimestamps.current = createTextTimeline(textData, captionSettings);
  }

  const textIndex = getCurrentIndexFromTextTimestamps(
    textTimestamps,
    startTime
  );

  console.log("WORD INDEX: ", textIndex);

  var customText = captionFactory(canvas, captionSettings);

  if (textIndex >= 0) {
    var textInfo = textTimestamps.current[textIndex];
    console.log("TEXT INFO: ", textInfo);

    var textbox = customText.setNew(textInfo);

    console.log("TEXT BOX: ", textbox);

    renderConfig.push({
      id: "m-txt",
      obj: textbox,
      index: 2,
    });
  }

  console.log("SETTING CAPTION REFF: ", customText);
  captionRef.current = customText;

  await renderObjectsFromConfig(renderConfig, canvas);
}

async function renderObjectsFromConfig(renderConfig, canvas) {
  return new Promise((res, rej) => {
    console.log("Render Config::=> ", renderConfig);

    renderConfig.sort(function (a, b) {
      return b.index - a.index;
    });
    console.log("ORDERED RENDER CONFIG: ", renderConfig);

    renderConfig.map((config) => {
      // ADJUST RENDER OFFSET
      canvas.add(config.obj);
      canvas.moveTo(config.obj, config.index);
      canvas.requestRenderAll();
    });

    res();
  });
}

async function addMediaToCanvas(
  startTime,
  objData,
  canvas,
  containerParams,
  videoRef,
  clipPathRef
) {
  var image;
  var mediaEl;
  var centerPoint;

  const videoId = "main-vid";
  mediaEl = createVideoElement(objData.info, videoId);
  console.log(mediaEl);

  // var videoCurrentTime = objData.videoStartTime ?? 0.001;
  var videoCurrentTime = startTime ?? 0.001;

  var clipPath = clipPathRef ? clipPathRef.current : null;

  var image = new fabric.Image(mediaEl, {
    id: videoId,
    scaleX: containerParams.viewHeight / objData.info.height,
    scaleY: containerParams.viewHeight / objData.info.height,
  });

  if (clipPath) {
    image.set("clipPath", clipPath);
  }

  if (objData.scaleX) {
    image.set("scaleX", objData.scaleX);
  }
  if (objData.scaleY) {
    image.set("scaleY", objData.scaleY);
  }

  if (objData.pos) {
    image.set("left", objData.pos.x);
    image.set("top", objData.pos.y);
  } else {
    if (clipPath) {
      centerPoint = clipPath.getCenterPoint();
      console.log("CENTER POINT ===> ", centerPoint);
    } else {
      var canvasCenter = canvas.getCenter();
      centerPoint = new fabric.Point(canvasCenter.left, canvasCenter.top);
      console.log("CENTER POINT ===> ", centerPoint);
    }

    image.setPositionByOrigin(centerPoint, "center", "center");
  }

  videoRef.current = mediaEl;

  await isVideoReady(mediaEl);

  mediaEl.currentTime = videoCurrentTime;
  await mediaEl.play();
  await mediaEl.pause();

  console.log("CANVAS MEDIA::", image);

  return image;
}

async function addMediaAtIndex(
  objData,
  canvas,
  containerParams,
  clipPathRef,
  mediaEl,
  renderControl
) {
  var objId = objData.id;
  var image;
  var centerPoint;

  console.log("MEDIA EL===> ", mediaEl);
  const mediaType = objData.type;

  if (!mediaEl) {
    if (mediaType == "video") {
      mediaEl = createVideoElement(objData.info, objId);
    } else {
      mediaEl = await createImageElement(objData.info);
    }

    console.log("MEDIA EL 1===> ", mediaEl);
  }

  console.log("CLIP PATH ==> ", clipPathRef);
  var clipPath = clipPathRef ? clipPathRef.current : null;

  image = new fabric.Image(mediaEl, {
    id: objId,
    scaleX: containerParams.viewHeight / objData.info.height,
    scaleY: containerParams.viewHeight / objData.info.height,
  });

  if (clipPath) {
    image.set("clipPath", clipPath);
  }

  if (objData.scaleX) {
    image.set("scaleX", objData.scaleX);
  }
  if (objData.scaleY) {
    image.set("scaleY", objData.scaleY);
  }

  if (objData.pos) {
    image.set("left", objData.pos.x);
    image.set("top", objData.pos.y);
  } else {
    if (clipPath) {
      centerPoint = clipPath.getCenterPoint();
      console.log("CENTER POINT ===> ", centerPoint);
    } else {
      var canvasCenter = canvas.getCenter();
      centerPoint = new fabric.Point(canvasCenter.left, canvasCenter.top);
      console.log("CENTER POINT ===> ", centerPoint);
    }

    image.setPositionByOrigin(centerPoint, "center", "center");
  }

  var captionIndex = 2;
  canvas.getObjects().map((canvasObj) => {
    if (canvasObj.id == "m-txt") {
      captionIndex = canvasObj.getZIndex();
      console.log("CAPTION Z-INDEX:: ", captionIndex);
    }
  });

  if (mediaType == "video") {
    console.log("media tYPE: video");
    await isVideoReady(mediaEl);
    mediaEl.currentTime = objData.videoCurrentTime ?? 0;
  }

  canvas.insertAt(image, captionIndex, false);
  canvas.requestRenderAll();

  console.log("CANVAS MEDIA::", image);
  return image;
}

async function preloadElementsCache(timeline, timelineElementsCache) {
  for (let i = 0; i < timeline.length; i++) {
    var obj = timeline[i];
    var objId = obj.id;

    if (!timelineElementsCache[objId]) {
      if (obj.type == "video") {
        var mediaEl = createVideoElement(obj.info, objId);
        timelineElementsCache[objId] = mediaEl;
      }

      if (obj.type == "image") {
        var mediaEl = await createImageElement(obj.info);
        timelineElementsCache[objId] = mediaEl;
      }
    }
  }

  console.log("TIMELINE ELEMENTS CACHE:: ", timelineElementsCache);
}

function isVideoPlayEnabled(videoElement) {
  console.log("videoElement.paused", videoElement.paused);
  console.log("videoElement.ended", videoElement.ended);
  return !videoElement.paused && !videoElement.ended;
}

function renderFactory(
  info,
  renderInitTime,
  currentTimeRef,
  fabricRef,
  containerParams,
  clipPathRef,
  videoRef,
  textRef,
  setPlaying,
  textTimestamps,
  timeline,
  timelineElementsCache
) {
  const canvas = fabricRef.current;

  var skipRanges = info?.skipRanges;
  let skipTimeOffset = 0;

  let animationFrameId;
  let isVideoPlaying = false;
  // let currentTimeTracker;

  let initStatus = true;

  let animTimeline;
  let animStart;
  let textAnimTimeline;

  let textIndexTracker = -1;

  let wordIndexTracker;
  let lineIndexTracker;

  let currentTextInfo;

  let objectsToRender = [];
  let objectsToRemove = [];
  let currentActiveObjects = [];

  let activeMediaTracker = {};

  function inRange(x, min, max) {
    return (x - min) * (x - max) <= 0;
  }

  function updateCurrentTextIndex() {
    const startTime = info.start;
    textIndexTracker = getCurrentIndexFromTextTimestamps(
      textTimestamps,
      startTime
    );
    console.log("CURRENT TEXT INDEX:: ", textIndexTracker);
  }

  async function renderObjectsAtTime(currentTime) {
    console.log("Rendering Objects at time: ", currentTime);
    objectsToRender = timeline.filter((obj) => {
      return currentTime >= obj.startTime && currentTime < obj.endTime;
    });

    objectsToRemove = currentActiveObjects.filter((obj) => {
      return currentTime >= obj.endTime || currentTime < obj.startTime;
    });

    console.log("CURR ACTIVE OBJS ==> ", currentActiveObjects);
    console.log("OBJS TO REMOVE ==> ", objectsToRemove);
    objectsToRender.forEach(async (obj) => {
      if (!currentActiveObjects.includes(obj)) {
        console.log("OBJECTS 1");
        if (obj.type == "video") {
          console.log("PLAYING VIDEO");

          var mediaEl = timelineElementsCache[obj.id];
          addMediaAtIndex(
            obj,
            canvas,
            containerParams,
            clipPathRef,
            mediaEl,
            false
          );
          mediaEl.currentTime = currentTime - obj.startTime;

          activeMediaTracker[obj.id] = mediaEl;
          // mediaEl.play();
          canvas.requestRenderAll();
        } else if (obj.type == "image") {
          console.log("PLAYING IMAGE ANIMATION");

          var mediaEl = timelineElementsCache[obj.id];
          var image = await addMediaAtIndex(
            obj,
            canvas,
            containerParams,
            clipPathRef,
            mediaEl,
            false
          );

          var animObj = {
            id: obj.id,
            target: image,
            start: 0,
            duration: obj.endTime - obj.startTime,
            type: "image",
            _animType: "imageScaleEffect",
          };
          var animTimelineObjs = [animObj];

          console.log("IMAGE OBJ INFO:", obj);
          console.log("DURATION:: ", obj.endTime - obj.startTime);
          animTimeline = createAnimationTimeline(
            canvas,
            animTimelineObjs,
            true
          );
          console.log("ANIM TIMELINE::=> ", animTimeline);
          animStart = obj.startTime;
          canvas.requestRenderAll();
          /**
            RENDER THE OBJECT, THEN ANIMATE

            1. Render the object 
            2. Animate
           */

          // if (obj.info.anim) {
          //   renderAnimation(obj, false);
          // }
        }

        currentActiveObjects.push(obj);
      }
    });

    /**
     Remove object once time ends
     */
    if (objectsToRemove.length) {
      const canvasObjs = canvas.getObjects();

      console.log("REMOVING OBJS");
      console.log("CURR ACTIVE OBJS: ", currentActiveObjects);
      objectsToRemove.forEach((obj) => {
        for (let i = 0; i < canvasObjs.length; i++) {
          var targetObj;
          var activeObj = canvasObjs[i];
          if (activeObj.id == obj.id) {
            targetObj = activeObj;
            break;
          }
        }
        console.log("REMOVING OBJ: ", targetObj);
        canvas.remove(targetObj);
        canvas.requestRenderAll();

        animTimeline = null;
        animStart = 0;

        if (activeMediaTracker[obj.id]) {
          if (isVideoPlayEnabled(activeMediaTracker[obj.id])) {
            activeMediaTracker[obj.id].pause();
          }

          delete activeMediaTracker[obj.id];
        }

        currentActiveObjects = currentActiveObjects.filter((currObj) => {
          return currObj.id != obj.id;
        });
      });
    }
  }

  function triggerPlay(currentTime) {
    if (!isVideoPlaying) {
      videoRef.current.currentTime = currentTime;
      videoRef.current.muted = false;
      videoRef.current.play();
      fabricRef.current.requestRenderAll();

      isVideoPlaying = true;
    }

    Object.entries(activeMediaTracker).forEach(([key, mediaEl]) => {
      console.log("ACTIVE MEDIA TRACKER:: ", mediaEl);
      console.log("ACTIVE MEDIA TRACKER:: ", key);
      if (!isVideoPlayEnabled(mediaEl)) {
        console.log("PLAYING MEDIA ==> ");
        var obj = currentActiveObjects.find((obj) => obj.id == key);
        mediaEl.currentTime = currentTime - obj.startTime;
        console.log("MEDIA CURR TIME ==> ", currentTime - obj.startTime);
        mediaEl.play();
        canvas.requestRenderAll();
      }
    });

    if (animTimeline) {
      var animCurrTime = currentTime - animStart;
      console.log("ANIM CURR TIME: ", animCurrTime);
      animTimeline.seek(animCurrTime * 1000);
      console.log("animTimeline:-> ", animTimeline);
      canvas.requestRenderAll();
    }

    if (textAnimTimeline) {
      var animCurrTime = currentTime - currentTextInfo.startTime;
      console.log("SEEKING ANIM AT TIME:: ", animCurrTime);
      textAnimTimeline.seek(animCurrTime * 1000);
      canvas.requestRenderAll();
    }
  }

  function getCurrentTime(t1) {
    const time = t1 / 1000;
    console.log("Time: ", time);
    const startTime = info.start;

    const elapsedTime =
      time - renderInitTime.current > 0 ? time - renderInitTime.current : 0;
    console.log("Elapsed Time: ", elapsedTime);
    var currentTime = elapsedTime + startTime + skipTimeOffset;
    // currentTimeTracker = currentTime;
    currentTimeRef.current = currentTime;

    console.log("CURRENT TIME: ", currentTime);
    return currentTime;
  }

  function adjustSkipRanges(currentTime) {
    console.log("Skip Ranges: ", skipRanges);
    console.log("Curr Time: ", currentTime);
    const range = skipRanges.find(
      (range) => currentTime >= range.startTime && currentTime < range.endTime
    );
    if (range) {
      console.log("RANGE FOUND: ", range);
      skipTimeOffset += range.endTime - range.startTime;
      console.log("SKIP TIME OFFSET: ", skipTimeOffset);
      console.log("OLD CURR TIME: ", currentTime);
      currentTime += skipTimeOffset;
      // currentTime = range.endTime;
      if (videoRef.current) {
        videoRef.current.currentTime = currentTime;
      }

      console.log("NEW CURR TIME: ", currentTime);
      textIndexTracker = -1;
      textAnimTimeline = null;

      return currentTime;
      /*
        1. Set main-video current time to range end
        2. Fetch caption text at range end and render
        
        renderObjectsAtTime(range end);
        renderCaptionsAtTime(range end);

      */
    }
    return currentTime;
  }

  async function render(t1) {
    var currentTime = getCurrentTime(t1);
    currentTime = adjustSkipRanges(currentTime);

    const endTime = info.end - 0.04;

    renderObjectsAtTime(currentTime);
    renderCaptionsAtTime(currentTime);

    triggerPlay(currentTime);

    fabricRef.current.requestRenderAll();
    animationFrameId = requestAnimationFrame(render);

    // Stop the animation when the timeline ends
    if (currentTime >= endTime) {
      if (setPlaying) {
        setPlaying(false);
      }

      isVideoPlaying = false;

      cancelAnimationFrame(animationFrameId);
      animationFrameId = null;
    }
  }

  async function composeAtTime(currentTime) {
    console.log("COMposing at time: ", currentTime);

    currentTime = adjustSkipRanges(currentTime);

    textIndexTracker = -1;
    textAnimTimeline = null;

    if (videoRef.current) {
      videoRef.current.currentTime = currentTime;
    }

    Object.entries(activeMediaTracker).forEach(([key, mediaEl]) => {
      console.log("Current Objs: ", currentActiveObjects);
      console.log("Current Obj id: ", key);
      var obj = currentActiveObjects.find((obj) => obj.id == key);
      mediaEl.currentTime = currentTime - obj.startTime;
      console.log("MEDIA CURR TIME ==> ", currentTime - obj.startTime);
      canvas.requestRenderAll();
    });

    renderObjectsAtTime(currentTime);
    renderCaptionsAtTime(currentTime);

    fabricRef.current.requestRenderAll();
  }

  function renderCaptionsAtTime(currentTime) {
    console.log("Rendering captions at time: ", currentTime);
    console.log("Word Index: ", textIndexTracker);
    var captionText = textRef.current;

    if (textIndexTracker < 0) {
      textIndexTracker = getCurrentIndexFromTextTimestamps(
        textTimestamps,
        currentTime
      );
    }

    if (textIndexTracker >= 0) {
      if (
        inRange(
          currentTime,
          textTimestamps.current[textIndexTracker]?.startTime,
          textTimestamps.current[textIndexTracker]?.endTime
        )
      ) {
        currentTextInfo = textTimestamps.current[textIndexTracker];
        console.log("TEXT INFO: ", currentTextInfo);
        console.log("FACTORY TEXT: ", captionText.getText());
        console.log("TEXT: ", currentTextInfo.text);
        if (captionText.getText() != currentTextInfo.text) {
          console.log("UPDATING TEXT");
          var textBox = captionText.setNew(currentTextInfo);
          fabricRef.current.add(textBox);

          textAnimTimeline = null;

          animateTextOnRender(captionText, currentTextInfo);

          lineIndexTracker = -1;
          wordIndexTracker = -1;
        }

        textIndexTracker += 1;
      }
      if (currentTextInfo) {
        animateTextOnActive(currentTime, currentTextInfo, captionText);
      }
    }
  }

  function animateTextOnRender(captionText, textInfo) {
    // Animate Text

    var t1 = anime.timeline({
      autoplay: false,
    });

    const textAnimObj = captionText.getTextAnim();
    console.log("RENDER ANIM: TEXT:: ", textAnimObj);
    if (textAnimObj) {
      t1.add(textAnimObj, 0);
      // anime(textAnimObj);
    }

    // Animate Lines
    const lines = textInfo.lines;
    lines.map((line, lineIdx) => {
      const lineAnimObj = captionText.getLineAnim(lineIdx, "render");
      console.log("RENDER ANIM: LINE:: ", textAnimObj);

      if (lineAnimObj) {
        // anime(lineAnimObj);
        t1.add(lineAnimObj, 20);
      }
    });

    // t1.pause();

    textAnimTimeline = t1;
    // t1.play();
    console.log("Timeline ===> ", textAnimTimeline);
  }

  function animateTextOnActive(currentTime, textInfo, captionText) {
    if (!textAnimTimeline) {
      textAnimTimeline = anime.timeline({
        autoplay: false,
      });
    }
    const lines = textInfo.lines;
    // const restoreState = textInfo.restoreState;
    const restoreState = textInfo.restoreState ?? false;
    const startTime = textInfo.startTime;

    console.log("LINES: ", lines);
    const currLineIdx = lines.findIndex(
      (line) => line.startTime <= currentTime && line.endTime > currentTime
    );

    console.log("CURR LINE IDX: ", currLineIdx);

    if (currLineIdx >= 0) {
      const currLine = lines[currLineIdx];
      const prevLine = currLineIdx > 0 ? lines[currLineIdx - 1] : null;
      console.log("CURR LINE: ", currLine);

      initStyleByTime(restoreState, currentTime, lines, captionText);

      if (currLineIdx != lineIndexTracker) {
        lineIndexTracker = currLineIdx;
        wordIndexTracker = -1;

        var lineAnimOffset = currLine.startTime - startTime;

        if (currLine.onActive) {
          if (restoreState) {
            var prevLineIdx = -1;
            if (currLineIdx > 0) {
              prevLineIdx = currLineIdx - 1;

              captionText.restoreStyle("line", prevLineIdx, null);

              captionText.restoreBoxStyle("line", prevLineIdx, null);
            }
          }

          const lineInfo = currLine.onActive;
          if (lineInfo.options) {
            captionText.updateActiveLineStyle(currLineIdx, lineInfo.options);
          }
          if (lineInfo.boxOptions) {
            captionText.updateActiveLineBoxStyle(
              currLineIdx,
              lineInfo.boxOptions
            );
          }
          if (lineInfo.anim) {
            var animObj = captionText.getLineAnim(currLineIdx, "active");
            if (animObj) {
              // anime(animObj);
              console.log("LINE ANIM OFFSET:: ", lineAnimOffset);
              textAnimTimeline.add(animObj, lineAnimOffset * 1000);
            }
          }
        }
      }

      const words = currLine.words;
      console.log("WORDS: ", words);
      const currWordIdx = words.findIndex(
        (word) => word.startTime <= currentTime && word.endTime > currentTime
      );

      if (currWordIdx >= 0) {
        console.log("CURR WORD IDX: ", currWordIdx);

        const currWord = words[currWordIdx];
        console.log("CURR WORD: ", currWord);

        if (currWordIdx != wordIndexTracker) {
          wordIndexTracker = currWordIdx;
          var wordAnimOffset = currWord.startTime - startTime;

          if (currWord.onActive) {
            if (restoreState) {
              var prevWord;
              var wordIdxTmp = currWordIdx - 1;
              var lineIdxTmp = currLineIdx;

              if (currWordIdx > 0) {
                prevWord = words[currWordIdx - 1];
                wordIdxTmp = currWordIdx - 1;
              }
              if (currWordIdx == 0) {
                if (currLineIdx > 0) {
                  let lastIdx = prevLine.words?.length - 1;
                  if (lastIdx >= 0) {
                    prevWord = prevLine.words[lastIdx];
                    wordIdxTmp = lastIdx;
                    lineIdxTmp = currLineIdx - 1;
                  }
                }
              }

              console.log(
                `Restore Word State:: curr word: ${currWordIdx}, line idx: ${currLineIdx}, word idx: ${wordIdxTmp}`
              );

              if (wordIdxTmp >= 0) {
                captionText.restoreStyle("word", lineIdxTmp, wordIdxTmp);
                captionText.restoreBoxStyle("word", lineIdxTmp, wordIdxTmp);
              }
            }

            const wordInfo = currWord.onActive;
            if (wordInfo.options) {
              captionText.updateActiveWordStyle(
                currWordIdx,
                currLineIdx,
                wordInfo.options
              );
            }
            if (wordInfo.boxOptions) {
              captionText.updateActiveWordBoxStyle(
                currWordIdx,
                currLineIdx,
                wordInfo.boxOptions
              );
            }
            if (wordInfo.anim) {
              var wordAnimObj = captionText.getWordAnim(
                currLineIdx,
                currWordIdx,
                "active"
              );

              console.log("ANIM OBJ: ", wordAnimObj);
              if (wordAnimObj) {
                // anime(wordAnimObj);
                console.log("WORD ANIM OFFSET:: ", wordAnimOffset);
                textAnimTimeline.add(wordAnimObj, wordAnimOffset * 1000);
              }
            }
          }
        }
      }
    }
  }

  function initStyleByTime(restoreState, currentTime, lines, captionText) {
    if (!initStatus && !restoreState) {
      console.log("INIT STYLE BY TIME::");

      const currLineIdx = lines.findIndex(
        (line) =>
          (line.startTime <= currentTime && line.endTime > currentTime) ||
          line.startTime > currentTime
      );
      console.log("Curr line Idx: ", currLineIdx);

      if (currLineIdx == 0) {
        let lineTmp = lines[0];
        let wordsTmp = lineTmp.words;
        const currWordIdx = wordsTmp.findIndex(
          (word) =>
            (word.startTime <= currentTime && word.endTime > currentTime) ||
            word.startTime > currentTime
        );
        console.log("Curr Word Idx: ", currWordIdx);
        initWordStylesByTime(wordsTmp, currWordIdx, captionText, 0);
      } else if (currLineIdx > 0) {
        for (let ln = 0; ln < currLineIdx; ln++) {
          console.log(`INIT STYLE:: Line No.: ${ln}`);
          let lineTmp = lines[ln];
          console.log("INIT STYLE:: LINE: ", lineTmp);
          const lineTmpInfo = lineTmp.onActive;
          if (lineTmpInfo?.options) {
            console.log("UPDATE ACTIVE STYLE:: ", lineTmpInfo.options);
            captionText.updateActiveLineStyle(ln, lineTmpInfo.options);
          }
          if (lineTmpInfo?.boxOptions) {
            console.log("UPDATE ACTIVE BOX STYLE:: ", lineTmpInfo.boxOptions);
            captionText.updateActiveLineBoxStyle(ln, lineTmpInfo.boxOptions);
          }

          let wordsTmp = lineTmp.words;
          initWordStylesByTime(wordsTmp, wordsTmp.length, captionText, ln);

          if (ln == currLineIdx - 1) {
            let lineTmp = lines[currLineIdx];
            let wordsTmp = lineTmp.words;
            const currWordIdx = wordsTmp.findIndex(
              (word) =>
                (word.startTime <= currentTime && word.endTime > currentTime) ||
                word.startTime > currentTime
            );
            initWordStylesByTime(
              wordsTmp,
              currWordIdx,
              captionText,
              currLineIdx
            );
          }
        }
      }
      initStatus = true;
    }
  }

  function initWordStylesByTime(wordsTmp, lastWordIdx, captionText, ln) {
    for (let wn = 0; wn < lastWordIdx; wn++) {
      console.log(`INIT STYLE:: Line: ${ln}, Word: ${wn}`);
      let wordTmp = wordsTmp[wn];
      console.log("INIT STYLE WORD:: ", wordTmp);
      const wordTmpInfo = wordTmp.onActive;
      if (wordTmpInfo?.options) {
        console.log("UPDATE ACTIVE WORD STYLE:: ", wordTmpInfo.options);
        captionText.updateActiveWordStyle(wn, ln, wordTmpInfo.options);
      }
      if (wordTmpInfo?.boxOptions) {
        console.log("UPDATE ACTIVE BOX STYLE:: ", wordTmpInfo.options);
        captionText.updateActiveWordBoxStyle(wn, ln, wordTmpInfo.boxOptions);
      }
    }
  }

  return {
    start(playbackInfo) {
      info = playbackInfo;
      var currentTime = info.start;
      var currObjs = timeline.filter((obj) => {
        return currentTime >= obj.startTime && currentTime < obj.endTime;
      });
      currentActiveObjects.push(...currObjs);
      console.log("CURRENT ACTIVE OBJS: ", currentActiveObjects);
      updateCurrentTextIndex();
      animationFrameId = requestAnimationFrame(render);
      return animationFrameId;
    },
    stop() {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
        animationFrameId = null;
      }
      if (setPlaying) {
        setPlaying(false);
      }

      currentActiveObjects = [];
      Object.entries(activeMediaTracker).forEach(([key, mediaEl]) => {
        if (isVideoPlayEnabled(mediaEl)) {
          mediaEl.pause();
        }
      });
      textIndexTracker = -1;

      isVideoPlaying = false;
      if (videoRef.current) {
        videoRef.current.pause();
      }
      return currentTimeRef.current;
    },

    async renderAtTime(currentTime) {
      initStatus = false;
      await composeAtTime(currentTime);
      canvas.requestRenderAll();
    },

    preloadMedia() {
      preloadElementsCache(timeline, timelineElementsCache);
    },

    renderCaptions(t1) {
      return renderCaptionsAtTime(t1);
    },
  };
}

function renderControlFactory(
  info,
  fabricRef,
  containerParams,
  videoRef,
  textRef,
  textTimestamps,
  timeline,
  renderMode
) {
  const canvas = fabricRef.current;

  var skipRanges = info?.skipRanges;

  let currentTextInfo;
  let textAnimTimeline;

  let animTimeline;
  let animStart;
  let initStatus = false;

  let textIndexTracker = -1;

  let wordIndexTracker;
  let lineIndexTracker;

  let objectsToRender = [];
  let objectsToRemove = [];
  let currentActiveObjects = [];

  let currSkipRange;

  function inRange(x, min, max) {
    return (x - min) * (x - max) <= 0;
  }

  async function renderAtTime(currentTime) {
    window.ready = false;
    window.frameReady = false;

    console.log("Resetting VideoSeekStatus Values!!");
    Object.keys(window.videoSeekStatus).forEach((key) => {
      window.videoSeekStatus[key] = false;
      console.log("VideoId:: ", key);
    });

    let canvas = fabricRef.current;
    canvas.setZoom(1);
    canvas.setWidth(containerParams.viewWidth);
    canvas.setHeight(containerParams.viewHeight);

    currentTime = adjustSkipRanges(currentTime);

    console.log("Rendering Objects at time: ", currentTime);

    const currentVideoTime = currentTime;

    if (videoRef.current) {
      const videoEl = videoRef.current;
      console.log("Video currenttime -> ", currentVideoTime);
      console.log("VIDEO EL:: ", videoEl);
      await videoEl.play();
      window.ready = false;
      // window.videoSeekStatus["main-vid"] = false;
      await videoEl.pause();
      videoEl.currentTime = currentVideoTime + 0.001;
    }

    await renderObjectsAtTime(currentTime);
    renderCaptionsAtTime(currentTime);

    canvas.requestRenderAll();

    if (renderMode == "caption-preview") {
      window.frameReady = true;
      window.ready = true;
    }
  }

  function getSkipTimeOffset(currentTime) {
    var skipTimeOffset = 0;
    if (skipRanges?.length) {
      for (let i = 0; i < skipRanges.length; i++) {
        var range = skipRanges[i];
        if (
          range.startTime <= currentTime &&
          range.endTime > currentTime &&
          currSkipRange != range
        ) {
          textIndexTracker = -1;
          currSkipRange = range;
        }
        if (range.startTime > currentTime) {
          break;
        }
        skipTimeOffset += range.endTime - range.startTime;
      }
    }

    return skipTimeOffset;
  }

  function adjustSkipRanges(currentTime) {
    var skipTimeOffset = getSkipTimeOffset(currentTime);

    currentTime += skipTimeOffset;
    if (videoRef.current) {
      videoRef.current.currentTime = currentTime;
    }

    return currentTime;
  }

  async function renderObjectsAtTime(currentTime) {
    console.log("Rendering Objects at time: ", currentTime);
    objectsToRender = timeline.filter((obj) => {
      return currentTime >= obj.startTime && currentTime < obj.endTime;
    });
    console.log("Object-to-render: ", objectsToRender);
    console.log("current-active-objects: ", currentActiveObjects);
    objectsToRemove = currentActiveObjects.filter((obj) => {
      return currentTime >= obj.endTime;
    });

    console.log("Object-to-remove: ", objectsToRemove);

    if (objectsToRemove.length) {
      const canvasObjs = canvas.getObjects();

      console.log("REMOVING OBJS");
      console.log("CURR ACTIVE OBJS: ", currentActiveObjects);
      objectsToRemove.forEach((obj) => {
        for (let i = 0; i < canvasObjs.length; i++) {
          var targetObj;
          var activeObj = canvasObjs[i];
          if (activeObj.id == obj.id) {
            targetObj = activeObj;
            break;
          }
        }
        console.log("REMOVING OBJ: ", targetObj);
        console.log("OBJ ID::-> ", obj.id);

        currentActiveObjects = currentActiveObjects.filter((currObj) => {
          return currObj.id != obj.id;
        });

        canvas.remove(targetObj);
        canvas.requestRenderAll();

        delete window.videoSeekStatus[obj.id];
      });
    }

    for (const obj of objectsToRender) {
      const isObjActive = currentActiveObjects.find(
        (activeObj) => obj.id == activeObj.id
      );
      if (!isObjActive) {
        console.log("OBJECTS");
        if (obj.type == "video") {
          console.log("PLAYING VIDEO");

          var mediaEl = await createVideoElement(obj.rInfo, obj.id);
          // await isVideoReady(mediaEl);
          await addMediaAtIndex(
            obj,
            canvas,
            containerParams,
            null,
            mediaEl,
            true
          );
          mediaEl.pause();
          console.log("MEDIA EL 2===> ", mediaEl);

          currentActiveObjects.push({
            id: obj.id,
            el: mediaEl,
            startTime: obj.startTime,
            endTime: obj.endTime,
            type: "video",
          });

          console.log("CANVAS OBJETS:: ", canvas.getObjects());
          canvas.getObjects().map((canvasObj) => {
            console.log(` ${canvasObj.id}:: , ${canvasObj.getZIndex()}`);
          });
          canvas.requestRenderAll();
        } else if (obj.type == "image") {
          console.log("PLAYING IMAGE ANIMATION");

          var mediaEl = await createImageElement(obj.info);
          var image = await addMediaAtIndex(
            obj,
            canvas,
            containerParams,
            null,
            mediaEl,
            false
          );
          var animObj = {
            id: obj.id,
            target: image,
            start: 0,
            duration: obj.endTime - obj.startTime,
            type: "image",
            _animType: "imageScaleEffect",
          };

          var animTimelineObjs = [animObj];
          animTimeline = createAnimationTimeline(
            canvas,
            animTimelineObjs,
            true
          );
          console.log("ANIM TIMELINE::=> ", animTimeline);
          animStart = obj.startTime;

          currentActiveObjects.push({
            id: obj.id,
            el: mediaEl,
            startTime: obj.startTime,
            endTime: obj.endTime,
            type: "image",
          });

          canvas.requestRenderAll();

          /**
            RENDER THE OBJECT, THEN ANIMATE

            1. Render the object 
            2. Animate
           */

          // if (obj.info.anim) {
          //   renderAnimation(obj, false);
          // }
        }

        // currentActiveObjects.push(obj);
      }
    }

    for (const obj of currentActiveObjects) {
      if (obj.type == "video") {
        console.log("CURR ACTIVE OBJ:: ", obj);
        const sceneTime = currentTime - obj.startTime;
        const currentVideoTime = obj.videoStartTime
          ? obj.videoStartTime + sceneTime
          : sceneTime;

        var videoEl = obj.el;
        videoEl.currentTime = currentVideoTime + 0.001;
        await videoEl.play();
        window.ready = false;
        // window.videoSeekStatus[obj.id] = false;
        await videoEl.pause();
        videoEl.currentTime = currentVideoTime + 0.001;
        // window.frameReady = true;
      } else if (obj.type == "image") {
        const sceneTime = currentTime - obj.startTime;
        animTimeline.seek(sceneTime * 1000);
        // window.ready = true;
        // window.frameReady = true;
      }
    }
  }

  function renderCaptionsAtTime(currentTime) {
    console.log("Rendering captions at time: ", currentTime);
    var captionText = textRef.current;

    console.log("CAPTION TEXT: ", captionText);

    textIndexTracker = getCurrentIndexFromTextTimestamps(
      textTimestamps,
      currentTime
    );

    if (textIndexTracker >= 0) {
      if (
        inRange(
          currentTime,
          textTimestamps.current[textIndexTracker]?.startTime,
          textTimestamps.current[textIndexTracker]?.endTime
        )
      ) {
        /**
         *
         */
        currentTextInfo = textTimestamps.current[textIndexTracker];
        console.log("TEXT INFO: ", currentTextInfo);
        console.log("FACTORY TEXT: ", captionText.getText());
        console.log("TEXT: ", currentTextInfo.text);
        if (captionText.getText() != currentTextInfo.text) {
          console.log("UPDATING TEXT");
          var textBox = captionText.setNew(currentTextInfo);
          canvas.add(textBox);

          // if (currentTime >= currentTextInfo.endTime) {

          // }
          textAnimTimeline = null;

          lineIndexTracker = -1;
          wordIndexTracker = -1;
        }

        textIndexTracker += 1;

        if (!textAnimTimeline) {
          createTextAnimationTimeline(captionText, currentTextInfo);
        }
      }

      updateActiveStyles(currentTime, captionText, currentTextInfo);
      if (textAnimTimeline) {
        var animCurrTime = currentTime - currentTextInfo.startTime;
        console.log("SEEKING ANIM AT TIME:: ", animCurrTime);
        console.log("TXT ANIM TIMELINE: ", textAnimTimeline);

        textAnimTimeline.seek(animCurrTime * 1000);

        canvas.requestRenderAll();
      }
    }
  }

  function createTextAnimationTimeline(captionText, textInfo) {
    var t1 = anime.timeline({
      autoplay: false,
    });

    const textAnimObj = captionText.getTextAnim();
    console.log("RENDER ANIM: TEXT:: ", textAnimObj);

    const startTime = textInfo.startTime;

    // Add TextAnimObj to timeline
    if (textAnimObj) {
      t1.add(textAnimObj, 0);
    }

    const lines = textInfo.lines;
    lines.map((line, lineIdx) => {
      const lineAnimObj = captionText.getLineAnim(lineIdx, "render");
      console.log("RENDER ANIM: LINE:: ", lineAnimObj);
      if (lineAnimObj) {
        // Add LineAnimObj to timeline
        t1.add(lineAnimObj, 50);
      }
    });

    t1.pause();

    textAnimTimeline = t1;
    console.log("Timeline ===> ", textAnimTimeline);
  }

  function updateActiveStyles(currentTime, captionText, textInfo) {
    console.log("UPDATING ACTIVE STYLES:: ");
    const lines = textInfo?.lines;
    if (lines) {
      const restoreState = textInfo.restoreState ?? false;
      const startTime = textInfo.startTime;

      const currLineIdx = lines.findIndex(
        (line) => line.startTime <= currentTime && line.endTime > currentTime
      );
      console.log("CURR LINE IDX: ", currLineIdx);

      if (currLineIdx >= 0) {
        const currLine = lines[currLineIdx];
        const prevLine = currLineIdx > 0 ? lines[currLineIdx - 1] : null;
        console.log("CURR LINE: ", currLine);

        initStyleByTime(restoreState, currentTime, lines, captionText);

        if (currLineIdx != lineIndexTracker) {
          lineIndexTracker = currLineIdx;
          wordIndexTracker = -1;

          var lineAnimOffset = currLine.startTime - startTime;

          if (currLine.onActive) {
            if (restoreState) {
              var prevLineIdx = -1;
              if (currLineIdx > 0) {
                prevLineIdx = currLineIdx - 1;
                captionText.restoreStyle("line", prevLineIdx, null);
                captionText.restoreBoxStyle("line", prevLineIdx, null);
              }
            }

            const lineInfo = currLine.onActive;
            if (lineInfo.options) {
              console.log("UPDATE ACTIVE STYLE:: ", lineInfo.options);
              captionText.updateActiveLineStyle(currLineIdx, lineInfo.options);
            }
            if (lineInfo.boxOptions) {
              console.log("UPDATE ACTIVE BOX STYLE:: ", lineInfo.boxOptions);
              captionText.updateActiveLineBoxStyle(
                currLineIdx,
                lineInfo.boxOptions
              );
            }
            if (lineInfo.anim) {
              var activeAnimObj = captionText.getLineAnim(
                currLineIdx,
                "active"
              );
              console.log("LINE ACTIVE ANIM:: ", activeAnimObj);
              if (activeAnimObj) {
                // Add Active Anim Obj to timeline with offset
                console.log("LINE ANIM OFFSET:: ", lineAnimOffset);
                textAnimTimeline.add(activeAnimObj, lineAnimOffset * 1000);
              }
            }
          }
        }

        const words = currLine.words;
        console.log("WORDS: ", words);
        const currWordIdx = words.findIndex(
          (word) => word.startTime <= currentTime && word.endTime > currentTime
        );

        if (currWordIdx >= 0) {
          console.log("CURR WORD IDX: ", currWordIdx);

          const currWord = words[currWordIdx];
          console.log("CURR WORD: ", currWord);

          if (currWordIdx != wordIndexTracker) {
            wordIndexTracker = currWordIdx;
            var wordAnimOffset = currWord.startTime - startTime;

            if (currWord.onActive) {
              if (restoreState) {
                var prevWord;
                var wordIdxTmp = currWordIdx - 1;
                var lineIdxTmp = currLineIdx;

                if (currWordIdx > 0) {
                  prevWord = words[currWordIdx - 1];
                  wordIdxTmp = currWordIdx - 1;
                }
                if (currWordIdx == 0) {
                  if (currLineIdx > 0) {
                    let lastIdx = prevLine.words?.length - 1;
                    if (lastIdx >= 0) {
                      prevWord = prevLine.words[lastIdx];
                      wordIdxTmp = lastIdx;
                      lineIdxTmp = currLineIdx - 1;
                    }
                  }
                }

                console.log(
                  `Restore Word State:: curr word: ${currWordIdx}, line idx: ${currLineIdx}, word idx: ${wordIdxTmp}`
                );

                captionText.restoreStyle("word", lineIdxTmp, wordIdxTmp);
                captionText.restoreBoxStyle("word", lineIdxTmp, wordIdxTmp);
              }

              const wordInfo = currWord.onActive;
              if (wordInfo.options) {
                console.log("UPDATE ACTIVE WORD STYLE:: ", wordInfo.options);

                captionText.updateActiveWordStyle(
                  currWordIdx,
                  currLineIdx,
                  wordInfo.options
                );
              }
              if (wordInfo.boxOptions) {
                console.log("UPDATE ACTIVE BOX STYLE:: ", wordInfo.options);

                captionText.updateActiveWordBoxStyle(
                  currWordIdx,
                  currLineIdx,
                  wordInfo.boxOptions
                );
              }
              if (wordInfo.anim) {
                var wordAnimObj = captionText.getWordAnim(
                  currLineIdx,
                  currWordIdx,
                  "active"
                );
                console.log("WORD ACTIVE ANIM:: ", wordAnimObj);
                if (wordAnimObj) {
                  // Add wordAnimObj to timeline with offset
                  console.log("WORD ANIM OFFSET:: ", wordAnimOffset);
                  textAnimTimeline.add(wordAnimObj, wordAnimOffset * 1000);
                }
              }
            }
          }
        }
      }
    }
  }

  function initStyleByTime(restoreState, currentTime, lines, captionText) {
    if (!initStatus && !restoreState) {
      console.log("INIT STYLE BY TIME::");
      const currLineIdx = lines.findIndex(
        (line) =>
          (line.startTime <= currentTime && line.endTime > currentTime) ||
          line.startTime > currentTime
      );
      console.log("CURR LINE IDX: ", currLineIdx);
      if (currLineIdx == 0) {
        let lineTmp = lines[0];
        let wordsTmp = lineTmp.words;
        const currWordIdx = wordsTmp.findIndex(
          (word) =>
            (word.startTime <= currentTime && word.endTime > currentTime) ||
            word.startTime > currentTime
        );
        console.log("CURR WORD IDX: ", currWordIdx);
        initWordStylesByTime(wordsTmp, currWordIdx, captionText, 0);
      } else if (currLineIdx > 0) {
        for (let ln = 0; ln < currLineIdx; ln++) {
          console.log(`INIT STYLE:: Line No.: ${ln}`);
          let lineTmp = lines[ln];
          console.log("INIT STYLE:: LINE: ", lineTmp);
          const lineTmpInfo = lineTmp.onActive;
          if (lineTmpInfo?.options) {
            console.log("UPDATE ACTIVE STYLE:: ", lineTmpInfo.options);
            captionText.updateActiveLineStyle(ln, lineTmpInfo.options);
          }
          if (lineTmpInfo?.boxOptions) {
            console.log("UPDATE ACTIVE BOX STYLE:: ", lineTmpInfo.boxOptions);
            captionText.updateActiveLineBoxStyle(ln, lineTmpInfo.boxOptions);
          }

          let wordsTmp = lineTmp.words;
          initWordStylesByTime(wordsTmp, wordsTmp.length, captionText, ln);

          if (ln == currLineIdx - 1) {
            let lineTmp = lines[currLineIdx];
            let wordsTmp = lineTmp.words;
            const currWordIdx = wordsTmp.findIndex(
              (word) =>
                (word.startTime <= currentTime && word.endTime > currentTime) ||
                word.startTime > currentTime
            );

            initWordStylesByTime(
              wordsTmp,
              currWordIdx,
              captionText,
              currLineIdx
            );
          }
        }
      }
      initStatus = true;
    }
  }

  function initWordStylesByTime(wordsTmp, lastWordIdx, captionText, ln) {
    for (let wn = 0; wn < lastWordIdx; wn++) {
      console.log(`INIT STYLE:: Line: ${ln}, Word: ${wn}`);
      let wordTmp = wordsTmp[wn];
      console.log("INIT STYLE WORD:: ", wordTmp);
      const wordTmpInfo = wordTmp.onActive;
      if (wordTmpInfo?.options) {
        console.log("UPDATE ACTIVE WORD STYLE:: ", wordTmpInfo.options);
        captionText.updateActiveWordStyle(wn, ln, wordTmpInfo.options);
      }
      if (wordTmpInfo?.boxOptions) {
        console.log("UPDATE ACTIVE BOX STYLE:: ", wordTmpInfo.options);
        captionText.updateActiveWordBoxStyle(wn, ln, wordTmpInfo.boxOptions);
      }
    }
  }

  return {
    updateTextInfo(textInfo) {
      currentTextInfo = textInfo;
    },
    async render(t1) {
      await renderAtTime(t1);
      window.frameReady = true;
    },
    renderCaptions(t1) {
      return renderCaptionsAtTime(t1);
    },
  };
}

function getCurrentIndexFromTextTimestamps(textTimestamps, currentTime) {
  console.log("CURRENT TIME: ", currentTime);
  for (let i = 0; i < textTimestamps.current.length; i++) {
    const textObj = textTimestamps.current[i];
    //   if (i == 0 && currentTime < textObj.startTime) {
    //     return 0;
    //   }

    if (
      i == textTimestamps.current.length - 1 &&
      textObj.startTime <= currentTime
    ) {
      return i;
    }

    const nextTextObj = textTimestamps.current[i + 1];
    if (
      textObj.startTime <= currentTime &&
      nextTextObj.startTime > currentTime
    ) {
      return i;
    }
  }
  return -1;
}

export {
  createVideoElement,
  createImageElement,
  addMediaToCanvas,
  addMediaAtIndex,
  compose,
  getCurrentIndexFromTextTimestamps,
  renderFactory,
  renderControlFactory,
  isVideoReady,
};
