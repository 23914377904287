import React, { useState, useEffect } from 'react'
import useEffectOnChange from '../../hooks/useEffectOnChange'
const alignToIdx = {
    'left': 0,
    'center': 1,
    'right': 2
}

const TextAlignBox = ({ alignText, options, setOptions }) => {

    const [currentSelectedIdx, setCurrentSelectedIdx] = useState(1)
    const [alignValue, setAlignValue] = useState()

    useEffect(() => {
        if (alignText) {
            const idx = alignToIdx[alignText]
            setCurrentSelectedIdx(idx)
        }
    }, [alignText])

    useEffectOnChange(() => {
        if (setOptions) {
            options = options ?? {}
            setOptions({ ...options, alignText: alignValue })
        }
    }, [alignValue])

    const onAlignChange = (value) => {
        let idx = alignToIdx[value]
        setCurrentSelectedIdx(idx)
        setAlignValue(value)
    }

    return (
        <>
            {/* <div className='lettercase-input-container'>
                <div
                    className={`lettercase-input-item1 ${currentSelectedIdx == 0 ? 'lettercase-input-item-active' : ''}`}
                    onClick={() => onAlignChange('lowercase', 0)}
                >
                    aa
                </div>
                <div
                    className={`lettercase-input-item2 ${currentSelectedIdx == 1 ? 'lettercase-input-item-active' : ''}`}
                    onClick={() => changeCase('sentence', 1)}
                >
                    Aa
                </div>
                <div
                    className={`lettercase-input-item3 ${currentSelectedIdx == 2 ? 'lettercase-input-item-active' : ''}`}
                    style={{ borderRight: null }}
                    onClick={() => changeCase('uppercase', 2)}
                >
                    AA
                </div>
            </div> */}

            <div className='text-align-input-container'>
                <div 
                    className={`text-align-item1 ${currentSelectedIdx == 0 ? 'text-align-item-active' : ''}`} 
                    onClick={() => onAlignChange('left')}
                >
                    <span style={{fontSize: 16}} >
                        <i class="bi bi-text-left"></i>
                    </span>
                </div>
                <div 
                    className={`text-align-item2 ${currentSelectedIdx == 1 ? 'text-align-item-active' : ''}`} 
                    onClick={() => onAlignChange('center')}
                >
                    <span style={{fontSize: 16}} >
                        <i class="bi bi-text-center"></i>
                    </span>
                </div>
                <div 
                    className={`text-align-item3 ${currentSelectedIdx == 2 ? 'text-align-item-active' : ''}`} 
                    onClick={() => onAlignChange('right')}
                >
                    <span style={{fontSize: 16}} >
                        <i class="bi bi-text-right"></i>
                    </span>
                </div>
            </div>
        </>
    )
}

export default TextAlignBox
