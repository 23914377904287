import React, { useState, useEffect } from 'react'
import useEffectOnChange from '../../hooks/useEffectOnChange'
const caseToIdx = {
    'lowercase': 0,
    'sentence': 1,
    'uppercase': 2
}

const LetterCase = ({ lettercase, options, setOptions }) => {

    const [currentSelectedIdx, setCurrentSelectedIdx] = useState(1)
    const [currentCase, setCurrentCase] = useState()

    useEffect(() => {
        if (lettercase) {
            const idx = caseToIdx[lettercase]
            setCurrentSelectedIdx(idx)
        }
    }, [lettercase])

    useEffectOnChange(() => {
        if (setOptions) {
            options = options ?? {}
            setOptions({ ...options, case: currentCase })
        }
    }, [currentCase])

    const changeCase = (value, idx) => {
        setCurrentSelectedIdx(idx)
        setCurrentCase(value)
    }

    return (
        <>
            <div className='lettercase-input-container'>
                <div
                    className={`lettercase-input-item1 ${currentSelectedIdx == 0 ? 'lettercase-input-item-active' : ''}`}
                    onClick={() => changeCase('lowercase', 0)}
                >
                    aa
                </div>
                <div
                    className={`lettercase-input-item2 ${currentSelectedIdx == 1 ? 'lettercase-input-item-active' : ''}`}
                    onClick={() => changeCase('sentence', 1)}
                >
                    Aa
                </div>
                <div
                    className={`lettercase-input-item3 ${currentSelectedIdx == 2 ? 'lettercase-input-item-active' : ''}`}
                    style={{ borderRight: null }}
                    onClick={() => changeCase('uppercase', 2)}
                >
                    AA
                </div>
            </div>
        </>
    )
}

export default LetterCase
