import React, {useState, useEffect} from 'react'
import '../css/media.css'
import { getVideosFromSource, getImagesFromSource } from '../services/media'

const StockMediaOptions = ({handleMediaOptionClick}) => {
    const [mediaType, setMediaType] = useState(0)
    const [loading, setLoading] = useState(false)

    const [mediaItems, setMediaItems] = useState()
    const [searchInput, setSearchInput] = useState()

    const source = 'stock_media'

    useEffect(() => {
        if(mediaItems){
            console.log('Setting Loading => False')
            setLoading(false);
        }
    }, [mediaItems])

    useEffect(() => {
        if(searchInput){
            updateMediaItems(searchInput)
        }
    }, [mediaType])


    const updateMediaItems = (keyword) => {
        // const keywords = extractCommonNouns(currScene.text)
        // console.log('Extracted Keywords: ', keywords)

        // var keyword = keywords.length ? keywords[0] : ''
        // console.log('Keyword: ', keyword)
        
        var entity = mediaType ? mediaType : 'video';
        if(!mediaType){setMediaType('video')}

        if(entity == 'video'){
            console.log('Fetching Videos')
            setMediaItems([])
            getVideosFromSource(keyword, 1, false).then(data => {
                setMediaItems(data)
            });
        }
        else if(entity == 'image'){
            console.log('Fetching Images')
            setMediaItems([])
            getImagesFromSource(keyword, 1, false).then(data => {                
               
                setMediaItems(data)
            });
        }
        else if(entity == 'upload'){
            setMediaItems([])
        }
    }

    const handleSearchInput = (e) => {
        const value = e.target.value   
        setSearchInput(value)
    }

    const searchMedia = (e) => {
        e.stopPropagation();
        console.log('Search videos for keyword: ', searchInput);
        updateMediaItems(searchInput);
    }

    return (
        <div style={{width: '100%', height: 400}}>
           
            <div>
                <div class="search-box">
                    <input class="search-input" type="text" placeholder="Search" onChange={handleSearchInput}/>
                    <div class="search-btn"><i class="bi bi-search" onClick={searchMedia}></i></div>
                </div>
            </div>
            <div style={{display: 'flex'}}>
                <div 
                    style={{flex: 1, padding: 10, paddingInline: 20, textAlign: 'center', borderBottom: mediaType == 'video' ? '2px solid #584efd' : '2px solid #ddd', cursor: 'pointer'}}
                    onClick={() => setMediaType('video')}
                >
                    Video
                </div>
                <div 
                    style={{flex: 1, padding: 10, paddingInline: 20, textAlign: 'center', borderBottom: mediaType == 'image' ? '2px solid #584efd' : '2px solid #ddd', cursor: 'pointer'}}
                    onClick={() => setMediaType('image')}
                >
                    Image
                </div>
                <div style={{flex: 4, padding: 10,  paddingInline: 20, borderBottom: '2px solid #ddd'}}>

                </div>
            </div>
            <div style={{marginTop: 20, width: '95%', margin: 'auto'}}>
                {
                    mediaItems && mediaItems.length ? mediaItems.map((obj, index) =>  
                        <div style={{display: 'inline-block', position: 'relative', border: '1px solid #ddd', backgroundColor: '#ddd', width: 160, height: 90, margin: 15, borderRadius: 5, cursor: 'pointer'}}>
                                <img 
                                    crossOrigin="anonymous"
                                    data-id={index}
                                    height={90}
                                    style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: 5, border: '1px solid #ccc', }}
                                    src={obj?.thumbnail}   
                                    onClick={() => handleMediaOptionClick(obj, source)}
                                />
                        </div>) :
                        <div style={{width: '100%', textAlign: 'center', fontStyle: 'italic', marginTop: 50, color: '#4a4a4a'}}>Search for stock images and videos...</div> 
                }
            </div>
        </div>
    )
}

export default StockMediaOptions
