import React, {useState, useEffect, useRef} from 'react'
import { fabric } from "fabric";
import {
    getPreviewContainerDimensions,
} from "../constants/canvasSettings";
import { textData, previewTextData } from '../constants/data'
import { compose, renderControlFactory } from '../utils/render'
import { exportVideo } from '../utils/process';
import uuid from 'react-uuid';
import { useDeferredValue } from 'react';

const RenderControl = () => {

    const canvasRef = useRef();
    const fabricRef = useRef();

    const captionRef = useRef(null);
    const videoRef = useRef(null);
    const renderRef = useRef();

    const inputRef = useRef();

    const initRenderRef = useRef(false);

    const textTimestamps = useRef([]);

    const [quality, setQuality] = useState(480)
    const [previewContext, setPreviewContext] = useState(null)

    const [fps, setFps] = useState(30);

    const [renderMode, setRenderMode] = useState('video')
    const [containerParams, setContainerParams] = useState();
    const [captionSettings, setCaptionSettings] = useState(null);
    const [projectInfo, setProjectInfo] = useState();
    const [timelineInfo, setTimelineInfo] = useState([]);

    useEffect(() => {
        // const containerSettings = getPreviewContainerDimensions('16/9');
        // setContainerParams(containerSettings);
        // console.log("Container Params: ", containerSettings);
    
        // var context = {
        //     projectInfo: projectData,
        //     captionSettings: captionConfig,
        //     transcript: textData,
        //     renderMode: 'caption-preview',
        //     timelineInfo: timelineInfo,
        // }

        var context = {
            "projectInfo": {
                "transcript": {
                    "words": [
                      {
                        "word": "the",
                        "start": 0,
                        "end": 0.4,
                        "confidence": 0.9803699,
                        "punctuated_word": "The"
                      },
                      {
                        "word": "quick",
                        "start": 0.4,
                        "end": 0.8,
                        "confidence": 0.9803699,
                        "punctuated_word": "quick"
                      },
                      {
                        "word": "brown",
                        "start": 0.8,
                        "end": 1.2,
                        "confidence": 0.9803699,
                        "punctuated_word": "brown"
                      },
                      {
                        "word": "fox",
                        "start": 1.2,
                        "end": 1.6,
                        "confidence": 0.9803699,
                        "punctuated_word": "fox"
                      },
                      {
                        "word": "jumps",
                        "start": 1.6,
                        "end": 2,
                        "confidence": 0.9803699,
                        "punctuated_word": "jumps"
                      },
                      {
                        "word": "over",
                        "start": 2,
                        "end": 2.4,
                        "confidence": 0.9803699,
                        "punctuated_word": "over"
                      },
                      {
                        "word": "the",
                        "start": 2.4,
                        "end": 2.8,
                        "confidence": 0.9803699,
                        "punctuated_word": "the"
                      },
                      {
                        "word": "lazy",
                        "start": 2.8,
                        "end": 3.2,
                        "confidence": 0.9803699,
                        "punctuated_word": "lazy"
                      },
                      {
                        "word": "dog",
                        "start": 3.2,
                        "end": 3.6,
                        "confidence": 0.9803699,
                        "punctuated_word": "dog"
                      }
                    ]
                  },
                  "skipRanges": []
            },
            "captionInfo": {
              "id": "13f57468-25b3-1969-a0c0-9bfe62f134e4",
              "title": 99056589,
              "config": {
                "lineCount": 1,
                "text": {
                    "anim": null,
                    "options": {
                        "fontSize": 30,
                        "fontFamily": "Lato",
                        "fontStyle": "normal",
                        "fontWeight": 800,
                        "lineSpacing": 5,
                        "fill": "#ece300",
                        "case": "sentence",
                        "shadowInfo": {
                            "fill": "#8d5f25",
                            "blur": 10,
                            "offsetX": 5,
                            "offsetY": 5
                        }
                    },
                    "boxOptions": null,
                    "lines": [
                        {
                            "options": {
                                "fontFamily": "Lato",
                                "fontWeight": 800,
                                "fontStyle": "normal",
                                "case": "sentence",
                                "fill": "#ece300",
                                "wordSpacing": 5,
                                "opacity": 0
                            },
                            "boxOptions": null,
                            "anim": null
                        }
                    ]
                },
                "activeText": {
                    "lines": null,
                    "words": [
                        {
                            "indexInfo": {
                                "idx": 0,
                                "start": 0,
                                "end": -1
                            },
                            "options": {
                                "opacity": 1
                            },
                            "boxOptions": null,
                            "anim": {
                                "type": "zoom",
                                "speed": 100,
                                "intensity": 50
                            }
                        }
                    ]
                },
                "charCount": 15
            }
            
            ,
              "access": true,
              "tags": [
                "animated",
                "word-animated"
              ],
              "userId": "ORXANU5lHMWG4tz1uszuhaWqWXl2",
              "creatorId": "Diveo Creative",
              "created": {
                "seconds": 1737821836,
                "nanoseconds": 304000000
              },
              "featured": false,
              "score": 1
            },
            "renderMode": "caption-preview",
            "timelineInfo": []
          }
        // setPreviewContext(context);
       
        // DEBUG
        // async function loadContext(){
        //     const data = await exportVideo(uuid())
        //     console.log('CONTEXT::=> ', data.payload)
        //     setPreviewContext(data.payload);
        // } 

        // loadContext()
    }, [])

    useEffect(() => {
        if(previewContext){
            console.log('CAPTION PREVIEW MODE!!')
            setFrameController()
            const aspectRatio = previewContext.settings.aspectRatio ?? '16/9';
            const containerSettings = getPreviewContainerDimensions(aspectRatio);

            setRenderMode(previewContext.renderMode);
            if(previewContext.renderMode == 'caption-preview'){
                console.log('CAPTION PREVIEW MODE!!');
                console.log('CAPTION CONFIG:: ', previewContext.captionInfo);
                var captionConfig = {...previewContext.captionInfo.config};
                const centerY = containerSettings.viewHeight / 2;

                captionConfig.topPos = centerY;
                captionConfig.text.options.top = centerY;
                captionConfig.text.options.scale = 2;

                setCaptionSettings(previewContext.captionInfo);
                console.log('CAPTION SETTING :: ', captionConfig);

                console.log('TRANSCRIPT :: ', previewTextData);

                // const projectData = {
                //     startTime: 0,
                //     endTime: 3.6,
                //     skipRanges: [],
                // }

                setProjectInfo(previewContext.projectInfo);
                console.log('PROJECT DATA :: ', previewContext.projectInfo);

                setFps(15);

            }else{
                setCaptionSettings(previewContext.captionInfo);
                setProjectInfo(previewContext.projectInfo);
                setTimelineInfo(previewContext.timelineInfo)
            }

            setQuality(previewContext.settings?.quality ?? 1080);
            setContainerParams(containerSettings);

            // setContainerParams({viewHeight: 1080, viewWidth: 1920})
           

        }else{
            console.log('SSSS')
        }
    }, [previewContext])

    useEffect(() => {
        if (!canvasRef.current) return;
        if (fabricRef.current) return;
        if (containerParams) {
            console.log('Setting Up Stage')
            const canvas = new fabric.Canvas('canvas', {
                width: containerParams.viewWidth,
                height: containerParams.viewHeight,
                preserveObjectStacking: true,
                backgroundColor: "#212529",
            });

            fabricRef.current = canvas;

            console.log('CANVAS OBJS 111: ', fabricRef.current.getObjects())

            // Set total scene count
            console.log('Setting up stage: ', fabricRef.current)
            window.isSceneReady = true;
            window.readyForRendering = true

            // Add video to canvas
            // renderVideo()
        }

    }, [containerParams]);

    const renderFrameOnCanvas = () => {
        console.log('CANVAS: ', fabricRef.current)
        fabricRef.current.renderAll()
    }


    const loadPreviewContext = () => {
        console.log('Setting Preview Context')
        var context = window.previewContextObj
       
        setPreviewContext(context)
    }

    const setFrameController = () => {
        window.videoSeekStatus = {}
        window.setFrame = (frameNum) => {
            window.ready = false
            window.frameNumber = frameNum
        }    
    }

    const setFrameNum = () => {
        var value = inputRef.current.value
        console.log('Updating Frame Number:', value)
        window.frameNumber = parseInt(value)

    }

    const setZoom = () => {
        console.log('SETTING ZOOM!')
        var canvas = fabricRef.current
    
        canvas.setZoom(quality/containerParams.viewHeight)
        canvas.setWidth(containerParams.viewWidth * canvas.getZoom());
        canvas.setHeight(containerParams.viewHeight * canvas.getZoom());
        
    }

    const isVideoReady = (videoRef) => {
        return new Promise((resolve) => {
          const video = videoRef.current;
          if (video) {
            var handle = setInterval(() => {
              // var video = videoRef.current;
              if (video.readyState == 4) {
                console.log("State ==>", video.readyState);
                clearInterval(handle);
                console.log("VIDEO READY!!");
                resolve();
              }
            }, 100);
          } else {
            resolve();
          }
        });
      };


    const composeCanvasInControlledRender = async (currentTime) =>{
        return new Promise(async (resolve, reject) => {
            
            compose(
                projectInfo,
                fabricRef.current,
                containerParams,
                videoRef,
                captionRef,
                null,
                textTimestamps,
                captionSettings,
            )
            console.log('VIDEO REFF: ', videoRef.current)

            // setZoom()

            if(videoRef.current){
                await isVideoReady(videoRef);
            }

            window.isSceneReady = true;
                
            initRenderRef.current = true;
            resolve()
        });
    }



    const seekToFrame = async () => {

        window.ready = false
        window.frameReady = false

        // DEBUG
        // if(!window.frameNumber){
        //     window.frameNumber = 1
        // }
        // window.frameNumber = window.frameNumber + 1
        // renderFrameOnCanvas()

        var frame = window.frameNumber ?? 0
        console.log('Frame Number: ', frame);

        var totalTimeElasped = Math.min(frame * (1/fps))
        console.log('totalTimeElasped: ', totalTimeElasped)

        if(!initRenderRef.current){
            await composeCanvasInControlledRender(totalTimeElasped)
        }

        if(!renderRef?.current){
            const renderer = renderControlFactory(
                projectInfo,
                fabricRef,
                containerParams,
                videoRef,
                captionRef,
                textTimestamps,
                timelineInfo,
                renderMode,
            )

            renderRef.current = renderer;
        }
        
        // console.log('TEXT TIMESTAMPS:: ', textTimestamps.current)
        console.log('totalTimeElasped: ', totalTimeElasped)

        // let canvas = fabricRef.current;
        // canvas.setZoom(1)
        // canvas.setWidth(containerParams.viewWidth);
        // canvas.setHeight(containerParams.viewHeight);

        await renderRef.current.render(totalTimeElasped);

        if(renderMode == 'video'){
            setZoom()
        }
      
        // window.ready = true;
        console.log('VIDEO SEEK STATUS: ', window.videoSeekStatus);
        Object.entries(window.videoSeekStatus).forEach(([key, value]) => {
            console.log('VideoId:: ', key);
            console.log('Status:: ', value);
        });
        window.frameReady = true;
    }


    return (
        <>  
            <div>
                <canvas id="canvas" ref={canvasRef}>

                </canvas>
                <button id='seek-frame' class="btn btn-secondary" style={{margin: 20}} onClick={() => seekToFrame()}>Seek Frame</button>
                
                
                <button id='render-frame' onClick={renderFrameOnCanvas} style={{margin: 50}}>Render</button>
                <button id='load-preview-context' onClick={loadPreviewContext} style={{margin: 50}}>Load Context</button>

                <div>
                    <input ref={inputRef} type='text' name='input'></input>
                    <button onClick={setFrameNum}>Update Frame</button>
                </div>
                {/* <p>
                    {textBlock}
                </p> */}

            <button id='zoom' onClick={setZoom} style={{margin: 50}}>Zoom</button>
                
            </div>
        </>
    )
}

export default RenderControl
