import { useEffect, useRef } from 'react';

function useEffectOnChange(callback, dependencies) {
    const isFirstRender = useRef(true);
    const prevDeps = useRef(dependencies);

    useEffect(() => {
        // Skip the first render
        if (isFirstRender.current) {
            isFirstRender.current = false;
            prevDeps.current = dependencies;
            return;
        }

        // Check if dependencies actually changed
        const depsChanged = dependencies.some(
            (dep, index) => dep !== prevDeps.current[index]
        );

        if (depsChanged) {
            callback();
            prevDeps.current = dependencies;
        }
    }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
}

export default useEffectOnChange;
