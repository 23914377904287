import React, {useState, useEffect} from 'react'
import { useIsAuthenticated } from '../hooks/useIsAuthenticated'
import { db } from "../config/firebaseConfig";
import {
    collection,
    query,
    orderBy,
    onSnapshot,
  } from "firebase/firestore";
import UploadBox from './UploadBox'
import Loader from './ui/Loader';

const LibraryView = ({handleMediaOptionClick}) => {
    const authUser = useIsAuthenticated()
    const source = 'uploaded'

    const [projectMediaItems, setProjectMediaItems] = useState()

    useEffect(() => {
        if(projectMediaItems){
            console.log('Setting Loading => False')
            setLoading(false);
        }
    }, [projectMediaItems])

    useEffect(() => {
        var unsubscribe;
        console.log('view auth: ', authUser)
        if(authUser){
            const collectionRef = collection(db, "users", authUser.uid, "assets");
            const querySnapshot = query(collectionRef, orderBy("created", "desc"));
           
            console.log("Query Snapshot: ", querySnapshot);
            unsubscribe = onSnapshot(querySnapshot, (docs) => {
                let assets = [];
                docs.forEach((doc) => {
                    assets.push({ ...doc.data(), id: doc.id });
                });
                console.log('UPDATING MEDIA ITEMS: ', assets)
                setProjectMediaItems(assets)
            });
            
        }
        
        return () => {
            if(authUser){unsubscribe()}
        } 
    }, [authUser])

    const [loading, setLoading] = useState(false)
    const [isErrorDisplayed, setErrorDisplayed] = useState(false)

    const handleClick = (obj) => {

    }

    return (
        <div style={{width: '100%', height: 400}}>
            <UploadBox authUser={authUser} setLoading={setLoading} setErrorDisplayed={setErrorDisplayed}/>
            <div>
                {
                    loading && (
                        <div style={{width: '10%', margin: 'auto', textAlign: 'center', marginBottom: 15}}>
                            <Loader type='spokes' color="#988AF1" height={16} width={16} />
                        </div>
                        
                    )
                }
                <div style={{marginTop: 20, width: '95%', margin: 'auto'}}>
                    {
                        projectMediaItems && projectMediaItems.length ? projectMediaItems.map((obj, index) =>  
                            <div style={{display: 'inline-block', position: 'relative', border: '1px solid #ddd', backgroundColor: '#ddd', width: 126, height: 76, margin: 10, borderRadius: 5, cursor: 'pointer'}}>
                                    <img 
                                        crossOrigin="anonymous"
                                        data-id={index}
                                        height={76}
                                        style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: 5, border: '1px solid #ccc', }}
                                        src={obj?.thumbnail}   
                                        onClick={() => handleMediaOptionClick(obj,source)}
                                    />
                            </div>) :
                            <div style={{width: '100%', textAlign: 'center', fontStyle: 'italic', marginTop: 30, color: '#4a4a4a'}}>No media items to show <br/> Upload a file.</div> 
                    }
                </div>
                
            </div>
        </div>
    )
}

export default LibraryView
