import React, { useState, useEffect, useRef } from 'react'
import { fontOptions, fontStyleOptions, getFontTypeFromWeight } from '../../constants/fontsConfig';
import Dropdown from '../editor/Dropdown';
import ColorPicker from '../editor/ColorPicker';
import "../../css/captions.css";
import LetterCase from '../editor/LetterCase';
import BackgroundPanel from '../editor/BackgroundPanel';
import AnimationPanel from '../editor/AnimationPanel';
import NumberInput from '../editor/NumberInput';
import EnableSwitch from '../ui/EnableSwitch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import useEffectOnChange from '../../hooks/useEffectOnChange';
import StrokeSettings from '../editor/StrokeSettings';
import ShadowSettings from '../editor/ShadowSettings';
import TextAlignBox from '../editor/TextAlignBox';
import LabeledSlider from '../editor/LabeledSlider';
import Chevron from '../ui/Chevron';

const TextSettings = ({ captionRef, captionConfig, refreshCaptionState, textSettings, setTextSettings }) => {

    const optionsRef = useRef({});
    const boxOptionsRef = useRef({});

    const isInternalUpdate = useRef(false);
    const hasLinesConfig = useRef(false);

    const target = 'text';

    const [options, setOptions] = useState({})
    const [boxOptions, setBoxOptions] = useState(null)
    const [animInfo, setAnimInfo] = useState(null)

    const [textConfig, setTextConfig] = useState()

    const [enabler, setEnabler] = useState({ box: false, anim: false, stroke: false, shadow: false })

    useEffect(() => {
        if(refreshCaptionState){
            console.log('REFRESH CAPTION STATE:: TEXT SETTINGS ->  ',  captionConfig?.text)
            const text = captionConfig?.text

            isInternalUpdate.current = false;
            hasLinesConfig.current = text.lines ? true : false;
            setTextConfig(text);

            setNewOptions(text.options);
            setNewBoxOptions(text.boxOptions);
            setAnimInfo(text.anim)

            setEnabler({
                box: text.boxOptions ? true : false,
                anim: text.anim ? true : false,
                shadow: text.options.shadowInfo ? true : false,
                stroke: text.options.stroke ? true : false,
            })
            
        }
    }, [refreshCaptionState])

    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            console.log('ENABLER: ==>', enabler)
            if (!enabler.box && boxOptions) {
                console.log('SETTING BOX OPTIONS !')
                setNewBoxOptions(null);

            }

            if (enabler.box && !boxOptions) {
                console.log('BOX OPTIONS CHANGED 1', boxOptions)
                var info = {
                    fill: '#fff',
                    padding: 0,
                    borderRadius: 0,
                    opacity: 1
                }
                updateBoxOptions(info)
            }

            if (captionRef.current) {
                captionRef.current.toggleBackground(target, 0, enabler.box)
            }
        }
    }, [enabler.box])

    useEffect(() => {
        if(isInternalUpdate.current){
            console.log('ENABLER:2 ==>', enabler)

            if (!enabler.anim && animInfo) {
                updateAnimInfo(null)
            }

            if (enabler.anim && !animInfo) {
                updateAnimInfo({
                    type: 'none',
                    speed: 100,
                    intensity: 100
                })
            }
        }
    }, [enabler.anim])

    useEffect(() => {
        if(isInternalUpdate.current){
            console.log('TEXT SET:: config: ', captionConfig)
            console.log('TEXT SET:: shadowInfo: ', options?.shadowInfo)
            console.log('TEXT SET:: options: ', options);
            console.log('TEXT SET:: enabler-shadow: ', enabler.shadow);

            if (!enabler.shadow && options?.shadowInfo) {
                console.log('Remove Shadow options')
                updateOptions(prevValue => ({
                    ...prevValue,
                    shadowInfo: null,
                }))
            }

            if(enabler.shadow && !options?.shadowInfo){
                const shadowInfo = {
                    fill: '#000',
                    blur: 10,
                    offsetX: 5,
                    offsetY: 5
                };
                updateOptions(prevValue => ({
                    ...prevValue,
                    shadowInfo: shadowInfo,
                }))

            }
        }
    }, [enabler.shadow])

    useEffect(() => {
        if(isInternalUpdate.current){
            if (!enabler.stroke && (options?.stroke || options?.strokeWidth)) {
                updateOptions(prevValue => ({
                    ...prevValue,
                    stroke: '',
                    strokeWidth: null
                }));
            }

            if(enabler.stroke && !options?.stroke){
                updateOptions(prevValue => ({
                    ...prevValue,
                    stroke: '#000',
                    strokeWidth: 1
                }));
            }
        }
    }, [enabler.stroke])

    useEffect(() => {
        console.log('INIT TEXT SETTINGS ===> ', captionConfig)
        var textInfo = textSettings
        setTextConfig(textInfo);
        isInternalUpdate.current = false;
        hasLinesConfig.current = textInfo.lines ? true : false;

        if (textInfo) {
            console.log('TEXT SETTINGS::FOUND -> ', textInfo)

            if (textInfo.options) {
                setNewOptions(textInfo.options);
            }

            if (!boxOptions && textInfo.boxOptions) {
                console.log('SETTING BOX OPTIONS: ', textInfo.boxOptions)
                setNewBoxOptions(textInfo.boxOptions)
            }

            if (!animInfo && textInfo.anim) {
                setAnimInfo(textInfo.anim)
            }
            // setSettingsInitialised(true)

        }
        else {
            console.log('TEXT SETTINGS::INIT')
            textInfo = initialiseTextSettings()
            console.log('TEXT INFO: ', textInfo)

            setNewOptions(textInfo.options)
            setNewBoxOptions(textInfo.boxOptions)

            setAnimInfo(textInfo.anim);

            setTextSettings(textInfo)
            // setSettingsInitialised(true)
        }

        setEnabler({
            box: textInfo.boxOptions ? true : false,
            anim: textInfo.anim ? true : false,
            shadow: textInfo.options?.shadowInfo ? true : false,
            stroke: textInfo.options?.stroke ? true : false,
        })

    }, [])

    // useEffect(() => {
    //     if(hasLinesConfig.current && !textSettings.lines){
    //         console.log('REMOVING LINE CONFIG')
    //         hasLinesConfig.current = false;
    //         optionsRef.current = {};

    //         const tmpOptions = {...options, force: true};
    //         updateOptions(tmpOptions);
    //     }else if(!hasLinesConfig.current && textSettings.lines){
    //         console.log('HAS LINES!!!')
    //         hasLinesConfig.current = true;
    //     }
    // }, [textSettings.lines])



    useEffectOnChange(() => {
        console.log('TEXT OPTIONS CHANGED:: ', options);
        if(isInternalUpdate.current){
            const prevOptions = optionsRef.current;

            console.log('prevOptions: ', prevOptions)
    
            if(captionRef.current){
                Object.entries(options).forEach(([key, value]) => {
    
                    if (prevOptions[key] !== value  ?? {}) {
                        // Only run side effects for changed keys
                        switch (key) {
                            case "scale":
                                console.log(`Scale changed to ${value}`);
                                captionRef.current.updateStyle(target, 0, 'scale', options?.scale)
                                break;
                            case "fill":
                                console.log(`Fill changed to ${value}`);
                                captionRef.current.updateStyle(target, 0, 'fill', options?.fill)
                                break;
                            case "fontFamily":
                                console.log(`FontFamily changed to ${value}`);
                                captionRef.current.updateStyle(target, 0, 'fontFamily', options?.fontFamily)
                                break;
                            case "fontStyle":
                                console.log(`FontStyle changed to ${value}`);
                                captionRef.current.updateStyle(target, 0, 'fontStyle', options?.fontStyle)
                                break;
                            case "fontWeight":
                                console.log(`FontWeight changed to ${value}`);
                                captionRef.current.updateStyle(target, 0, 'fontWeight', options?.fontWeight)
                                break;
                            case "case":
                                console.log(`Case changed to ${value}`);
                                captionRef.current.updateStyle(target, 0, 'case', options?.case)
                                break;
                            case "alignText":
                                console.log(`Align Text changed to ${value}`);
                                captionRef.current.updateStyle(target, 0, 'alignText', options?.alignText)
                                break;
                            case "lineSpacing":
                                console.log(`LineSpacing changed to ${value}`);
                                captionRef.current.updateStyle(target, 0, 'lineSpacing', options?.lineSpacing)
                                break;
                            case "wordSpacing":
                                console.log(`WordSpacing changed to ${value}`);
                                captionRef.current.updateStyle(target, 0, 'wordSpacing', options?.wordSpacing)
                                break;
                            case "opacity":
                                console.log(`Opacity changed to ${value}`);
                                captionRef.current.updateStyle(target, 0, 'opacity', options?.opacity)
                                break;
                            case "shadowInfo":
                                console.log('Shadow Info changed to ', value);
                                captionRef.current.updateStyle(target, 0, 'shadowInfo', options?.shadowInfo)
                                break;
                            case "stroke":
                                console.log('Stroke changed to ', value);
                                captionRef.current.updateStyle(target, 0, 'stroke', options?.stroke)
                                break;
                            case "strokeWidth":
                                console.log('Stroke Width changed to ', value);
                                captionRef.current.updateStyle(target, 0, 'strokeWidth', options?.strokeWidth)
                                break;
                            default:
                                console.log(`${key} changed to ${value}`);
                        }
                    }
                });
    
                optionsRef.current = options;
                setTextSettings(prevValue => ({
                    ...prevValue,
                    options: options,
                }))
        
            }
        }

    }, [options])

    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            console.log('BOX OPTIONS CHANGED!: ', boxOptions)
            if (boxOptions) {
    
                const prevBoxOptions = boxOptionsRef.current  ?? {};
                console.log('prevBoxOptions: ', prevBoxOptions)
    
                Object.entries(boxOptions).forEach(([key, value]) => {
    
                    if (prevBoxOptions[key] !== value) {
                        // Only run side effects for changed keys
                        switch (key) {
                            case "fill":
                                console.log(`Fill changed to ${value}`);
                                captionRef.current.updateBoxStyle(target, 0, 'fill', boxOptions?.fill)
                                break;
                            case "padding":
                                console.log(`Padding changed to ${value}`);
                                captionRef.current.updateBoxStyle(target, 0, 'padding', boxOptions?.padding)
                                break;
                            case "opacity":
                                console.log(`Opacity changed to ${value}`);
                                captionRef.current.updateBoxStyle(target, 0, 'opacity', boxOptions?.opacity)
                                break;
                            case "borderRadius":
                                console.log(`Roundness changed to ${value}`);
                                captionRef.current.updateBoxStyle(target, 0, 'borderRadius', boxOptions?.borderRadius)
                                break;
                            default:
                                console.log(`${key} changed to ${value}`);
                        }
                    }
                });
            }

            boxOptionsRef.current = boxOptions;
            setTextSettings(prevValue => ({
                ...prevValue,
                boxOptions: boxOptions,
            }))

        }
        
        // if (!enabler.box && boxOptions) {
        //     updateEnabler(prevValue => ({
        //         ...prevValue,
        //         box: true,
        //     }))
        // }
    }, [boxOptions])

    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            var textSettingsTmp = { ...textSettings, options: options, boxOptions: boxOptions, anim: animInfo }
            console.log('UPDATING TEXT SETTINGS: ', textSettingsTmp)
            setTextSettings(prevValue => ({
                ...prevValue,
                anim: animInfo
            }))        
        }

        // if (!enabler.anim && animInfo) {
        //     updateEnabler(prevValue => ({
        //         ...prevValue,
        //         anim: true,
        //     }))
        // }
        
    }, [animInfo])


    const initialiseTextSettings = () => {
        const textInfo = {
            anim: null,
            options: {
                fontSize: 21,
                fontFamily: "Raleway",
                fontStyle: 'normal',
                fontWeight: 700,
                lineSpacing: 5,
                fill: "#ffffff",
                case: 'sentence',
                alignText: 'center'
            },
            boxOptions: null
        }
        return textInfo;
    }


    const onFontFamilyChange = (fontFamily) => {
        console.log('Changing fontSize: ', fontFamily.value)
        updateOptions({ ...options, fontFamily: fontFamily.value })
    }

    const onFontStyleChange = (option) => {
        console.log('Changing fontWeight: ', option.value)
        updateOptions({ ...options, fontWeight: option.fontWeight, fontStyle: option.fontStyle })
    }

    const onAlignChange = () => {
        
    }

    const updateOptions = (newOptions) => {
        isInternalUpdate.current = true;
        setOptions(newOptions)
    }

    const updateBoxOptions = (newBoxOptions) => {
        isInternalUpdate.current = true;
        setBoxOptions(newBoxOptions)
    }

    const updateAnimInfo = (newAnimInfo) => {
        isInternalUpdate.current = true;
        setAnimInfo(newAnimInfo)
    }

    const updateEnabler = (newEnablerInfo) => {
        isInternalUpdate.current = true;
        setEnabler(newEnablerInfo)
    }


    const setNewOptions = (optionsInfo) => {
        setOptions(optionsInfo)
        optionsRef.current = optionsInfo ?? {};

    }

    const setNewBoxOptions = (boxOptionsInfo) => {
        setBoxOptions(boxOptionsInfo)
        boxOptionsRef.current = boxOptionsInfo ?? {};
    }


    return (
        <>
        {
            textConfig && 
                <>
                <div className='form-row'>
                    <div className='label'>
                        Font
                    </div>
                    <div className='edit-box'>
                        <div style={{ width: 200 }}>
                            <Dropdown
                                isSearchable
                                menuSize={200}
                                placeHolder={options?.fontFamily ? options?.fontFamily : 'Roboto'}
                                options={fontOptions()}
                                onChange={(value) => onFontFamilyChange(value)}
                            />
                        </div>

                    </div>
                </div>

                <div className='form-row'>
                    <div className='label'>
                        Style
                    </div>
                    <div className='edit-box'>
                        <div style={{ width: 180 }}>
                            <Dropdown
                                isSearchable={false}
                                menuSize={180}
                                placeHolder={getFontTypeFromWeight(options?.fontWeight, options?.fontStyle)}
                                options={fontStyleOptions(options?.fontFamily ?? 'Roboto')}
                                onChange={(value) => onFontStyleChange(value)}
                            />
                        </div>

                    </div>
                </div>
                <div className='form-row'>
                    <div className='label'>
                        Color
                    </div>
                    <div className='edit-box'>
                        <div style={{ width: 150, height: 35 }}>
                            <ColorPicker fill={options?.fill} options={options} setOptions={updateOptions} />
                        </div>

                    </div>
                </div>

                <div className='form-row'>
                    <div className='label'>
                        Case
                    </div>
                    <div className='edit-box'>
                        <LetterCase lettercase={options?.case} options={options} setOptions={updateOptions} />
                    </div>
                </div>
                <div className='form-row'>
                    <div className='label'>
                        Align
                    </div>
                    <div className='edit-box'>
                        <TextAlignBox alignText={options?.alignText} options={options} setOptions={updateOptions}/>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='label'>
                        Scale
                    </div>
                    <div className='edit-box'>
                        <NumberInput defaultValue={options?.scale ?? 1} options={options} setOptions={updateOptions} label='Scale' />
                    </div>
                </div>
                <div className='form-row'>
                    <div className='label'>
                        Line Spacing
                    </div>
                    <div className='edit-box'>
                        <NumberInput defaultValue={options?.lineSpacing ?? 5} options={options} setOptions={updateOptions} label='LineSpacing' />
                    </div>
                </div>
                <div className='form-row'>
                    <div className='label'>
                        Word Spacing
                    </div>
                    <div className='edit-box'>
                        <NumberInput defaultValue={options?.wordSpacing ?? 5} options={options} setOptions={updateOptions} label='WordSpacing' />
                    </div>
                </div>
                <div className='form-row'>
                    <LabeledSlider
                        label='Opacity'
                        options={options}
                        setOptions={updateOptions}
                        defaultValue={options?.opacity ?? 1} />
                </div>

                <div className='form-row'>
                    <div className='label'>
                        Stroke
                    </div>
                    <div className='edit-box'>
                        <EnableSwitch target='stroke' value={enabler.stroke} setEnabler={updateEnabler} />
                    </div>
                    <div className='options-container' style={{ display: enabler.stroke ? 'flex' : 'none' }}>
                        {
                            options &&
                                <StrokeSettings options={options} setOptions={updateOptions} />
                        }

                    </div>
                </div>

                <div className='form-row'>
                    <div className='label'>
                        Shadow
                    </div>
                    <div className='edit-box'>
                        <EnableSwitch target='shadow' value={enabler.shadow} setEnabler={updateEnabler} />
                    </div>
                    <div className='options-container' style={{ display: enabler.shadow ? 'flex' : 'none' }}>
                        {
                            options?.shadowInfo &&
                                <ShadowSettings options={options} setOptions={updateOptions}  />
                        }

                    </div>
                </div>

                <div className='form-row'>
                    <div className='label'>
                        Background
                    </div>
                    <div className='edit-box'>
                        <EnableSwitch target='box' value={enabler.box} setEnabler={updateEnabler} />
                    </div>
                    <div className='options-container' style={{ display: enabler.box ? 'flex' : 'none' }}>
                        {
                            boxOptions &&
                            <BackgroundPanel boxOptions={boxOptions} setBoxOptions={updateBoxOptions} />
                        }

                    </div>
                </div>

                <div className='form-row'>
                    <div className='label'>
                        Animation
                    </div>
                    <div className='edit-box'>
                        <EnableSwitch target='anim' value={enabler.anim} setEnabler={updateEnabler} />
                    </div>
                    <div className='options-container' style={{ display: enabler.anim ? 'flex' : 'none' }}>
                        {
                            animInfo &&
                            <AnimationPanel animInfo={animInfo} setAnimInfo={updateAnimInfo} />
                        }

                    </div>
                </div>
            </>
  
        }
        </>
              
      )
}

export default TextSettings
