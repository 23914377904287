import React, { useState, useEffect } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import Chevron from './Chevron';

const EnableSwitch = ({ setEnabler, value, target }) => {
    const [active, setActive] = useState(value)

    useEffect(() => {
        setActive(value)
    }, [value])

    const toggleSwitch = () => {
        const value = !active
        setActive(value)

        console.log('KEY: ', target)
        setEnabler(prevValue => ({
            ...prevValue,
            [target]: value
        }))

    }

    return (
        <>
            <div style={{ cursor: 'pointer' }} onClick={toggleSwitch}>
                {
                    active ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '1.7rem', width: '1.7rem', border: '1px solid #2BDE72', borderRadius: 9999, backgroundColor: '#33dd00' }}>
                            <CheckIcon style={{ fontSize: 21, color: '#fff' }} />
                        </div>
                        : 
                        <div style={{ height: '1.7rem', width: '1.7rem', border: '1px dashed #aaa', borderRadius: 9999 }}>

                        </div>
                }
            </div>
            {
                target != 'restoreState' &&  <Chevron isOpen={active}/>
            }
           
        </>
        

    )
}

export default EnableSwitch
