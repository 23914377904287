import React, {useEffect, useState, useCallback} from 'react'
import { getAllProjectsForUser } from '../services/store'
import { useIsAuthenticated } from '../hooks/useIsAuthenticated';
import { clearAndApplyNewProject } from '../redux/projectSlice';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import '../css/input.css'

const ProjectView = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const authUser = useIsAuthenticated()
    const [projects, setProjects] = useState([])

    const fetchProjects = useCallback(async () => {
        var projectsList = await getAllProjectsForUser()
        console.log('Projects: ', projectsList)
        setProjects(projectsList)
      }, [authUser])

    useEffect(() => {
        fetchProjects();
    }, [fetchProjects])

    const openProject = async (index) => {
        var project = projects[index]
        console.log('Project Selected: ', project);

        const { projectInfo, captionInfo, timelineInfo, settings } = project.info;

        const videoInfo = {
            mainVideo: projectInfo.mainVideo,
            transcript: projectInfo.transcript
        }
        const timeline = timelineInfo ?? [];
        const skipRanges = projectInfo.skipRanges ?? [];

        dispatch(clearAndApplyNewProject({ captionInfo, timeline, videoInfo, settings, skipRanges }))

        navigate(`/edit/${settings?.projectId}`);
    }

    return (
          <div style={{height: '92vh'}}>
              <div>
                  {
                     projects.length ?  
                        projects.map((project, index) => 
                           ( <div style={{display: 'inline-block', margin: 20}}>
                                <div style={{height: 171, width: 304, backgroundColor: '#eee', display: 'flex', justifyContent: 'center', alignItems: 'center', overflowX: 'clip', borderRadius: 5, cursor: 'pointer', marginBottom: 10}} onClick={() => openProject(index)}>
                                    <img src={project?.thumbnail} height='100%'></img>
                                </div>
                                <div style={{fontSize: 15, marginLeft: 5, color: '#aaa'}}>{project?.storyInfo?.settings?.projectName ?? 'Untitled Project'}</div>
                            </div>
                           )  
                        ) :
                        <div style={{margin: 20, marginTop: 30, color: '#aaa'}}>
                            No projects to show. 
                        </div> 
                    }
              </div>
          </div>  
    )
}

export default ProjectView
