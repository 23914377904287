import getStripe from "./initStripe";
import { db } from "../config/firebaseConfig";
import { doc, addDoc, onSnapshot, collection } from "firebase/firestore";

export async function createCheckoutSession(uid) {
  // Create a new checkout-session in the  subcollection inside the uses document
  const userDocRef = doc(db, "users", uid);
  const checkoutSessionRef = collection(userDocRef, "checkout_sessions");

  const newAdd = await addDoc(checkoutSessionRef, {
    price: "price_1PxrFUG1x57uXAUXVcriuRtV",
    success_url: `${process.env.REACT_APP_ENDPOINT}/home`,
    cancel_url: `${process.env.REACT_APP_ENDPOINT}/pricing`,
  });

  // Wait for the checkout-session to get attached by extension
  onSnapshot(newAdd, async (snap) => {
    console.log("Snap: ", snap.data());
    const { error, sessionId } = snap.data();
    if (sessionId) {
      const stripe = await getStripe();
      console.log("STRIPE: ", stripe);
      stripe.redirectToCheckout({ sessionId });
    }
    if (error) {
      // Show an error to your customer and
      // inspect your Cloud Function logs in the Firebase console.
      alert(`An error occured: ${error.message}`);
    }
  });
}
