const fontConfig = {
  Montserrat: [
    "Normal",
    "Medium",
    "Semi Bold",
    "Bold",
    "Extra Bold",
    "Black",
    "Italic",
    "Medium Italic",
    "Semi Bold Italic",
    "Bold Italic",
    "Extra Bold Italic",
    "Black Italic",
  ],
  Roboto: [
    "Normal",
    "Medium",
    "Bold",
    "Black",
    "Italic",
    "Medium Italic",
    "Bold Italic",
    "Black Italic",
  ],
  "Roboto Mono": [
    "Normal",
    "Medium",
    "Semi Bold",
    "Bold",
    "Italic",
    "Medium Italic",
    "Semi Bold Italic",
    "Bold Italic",
  ],
  Lato: ["Normal", "Bold", "Italic", "Bold Italic"],
  Raleway: [
    "Normal",
    "Medium",
    "Semi Bold",
    "Bold",
    "Extra Bold",
    "Black",
    "Italic",
    "Medium Italic",
    "Semi Bold Italic",
    "Bold Italic",
    "Extra Bold Italic",
    "Black Italic",
  ],
  Oswald: [
    "Normal",
    "Medium",
    "Semi Bold",
    "Bold",
    "Italic",
    "Medium Italic",
    "Semi Bold Italic",
    "Bold Italic",
  ],
  Merriweather: [
    "Normal",
    "Bold",
    "Black",
    "Italic",
    "Bold Italic",
    "Black Italic",
  ],
  "PT Sans": ["Normal", "Bold", "Italic", "Bold Italic"],
};

const fontStyleMap = {
  Normal: {
    fontWeight: 400,
    fontStyle: "normal",
  },
  Medium: {
    fontWeight: 500,
    fontStyle: "normal",
  },
  "Semi Bold": {
    fontWeight: 600,
    fontStyle: "normal",
  },
  Bold: {
    fontWeight: 700,
    fontStyle: "normal",
  },
  "Extra Bold": {
    fontWeight: 800,
    fontStyle: "normal",
  },
  Black: {
    fontWeight: 900,
    fontStyle: "normal",
  },
  Italic: {
    fontWeight: 400,
    fontStyle: "italic",
  },
  "Medium Italic": {
    fontWeight: 500,
    fontStyle: "italic",
  },
  "Semi Bold Italic": {
    fontWeight: 600,
    fontStyle: "italic",
  },
  "Bold Italic": {
    fontWeight: 700,
    fontStyle: "italic",
  },
  "Extra Bold Italic": {
    fontWeight: 800,
    fontStyle: "italic",
  },
  "Black Italic": {
    fontWeight: 900,
    fontStyle: "italic",
  },
};

const getFontTypeFromWeight = (fontWeight, fontStyle) => {
  for (const [key, value] of Object.entries(fontStyleMap)) {
    // console.log(`${key}: ${value}`);
    if (value.fontWeight == fontWeight && value.fontStyle == fontStyle) {
      return key;
    }
  }
  return "Normal";
};

const fontOptions = () => {
  var fontList = [];
  Object.keys(fontConfig).map((font) =>
    fontList.push({ value: font, label: font, fontFamily: font })
  );
  return fontList;
};

const fontStyleOptions = (font) => {
  var options = [];
  fontConfig[font].map((fontStyle) =>
    options.push({
      value: fontStyle,
      label: fontStyle,
      fontWeight: fontStyleMap[fontStyle].fontWeight,
      fontStyle: fontStyleMap[fontStyle].fontStyle,
      fontFamily: font,
    })
  );
  // console.log("FONT STYLE OPTIONS:", options);
  return options;
};

export {
  fontConfig,
  fontStyleMap,
  fontOptions,
  fontStyleOptions,
  getFontTypeFromWeight,
};
