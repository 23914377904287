import React, { useState, useEffect, useRef } from 'react'
import "../../css/captions.css";
import { fontOptions, fontStyleOptions, getFontTypeFromWeight } from '../../constants/fontsConfig';
import { getFallbackStyleOptions } from '../../utils/captions';
import Dropdown from '../editor/Dropdown';
import ColorPicker from '../editor/ColorPicker';
import LetterCase from '../editor/LetterCase';
import BackgroundPanel from '../editor/BackgroundPanel';
import AnimationPanel from '../editor/AnimationPanel';
import NumberInput from '../editor/NumberInput';
import ToggleSwitch from '../ui/ToggleSwitch';
import EnableSwitch from '../ui/EnableSwitch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import useEffectOnChange from '../../hooks/useEffectOnChange';
import LabeledSlider from '../editor/LabeledSlider';
import StrokeSettings from '../editor/StrokeSettings';
import ShadowSettings from '../editor/ShadowSettings';

const WordSettings = ({
    indexInfo,
    mode,
    captionRef,
    captionConfig,
    refreshCaptionState,
    wordSettings,
    setWordSettings
}) => {

    const optionsRef = useRef({});
    const boxOptionsRef = useRef({});

    const isInternalUpdate = useRef(false);

    const target = 'word'

    const [options, setOptions] = useState({})
    const [boxOptions, setBoxOptions] = useState(null)
    const [animInfo, setAnimInfo] = useState(null)

    const [wordConfig, setWordConfig] = useState();

    const [currentWordRangeOptions, setWordRangeLineOptions] = useState()

    const [enabler, setEnabler] = useState({ box: false, anim: false })

    useEffect(() => {
        if(refreshCaptionState){
            console.log('REFRESH CAPTION STATE:: WORD SETTINGS ->  ')

            var idx = indexInfo?.idx ?? 0
            const wordsInfo = captionConfig?.activeText.words ? captionConfig?.activeText.words[idx] : null;
            isInternalUpdate.current = false;

            setWordConfig(wordsInfo)
    
            setWordRangeLineOptions(wordsInfo);

            setOptions(wordsInfo?.options ?? {})
            setBoxOptions(wordsInfo?.boxOptions)
            setAnimInfo(wordsInfo?.anim)

            setEnabler({
                box: wordsInfo?.boxOptions ? true : false,
                anim: wordsInfo?.anim ? true : false,
            })

        }
    }, [refreshCaptionState])

    useEffectOnChange(() => {
        if (!enabler.box && boxOptions) {
            setNewBoxOptions(null)
        }

        if (enabler.box && !boxOptions) {
            var info = {
                padding: 0,
                borderRadius: 0,
                opacity: 1
            }
            updateBoxOptions(info)
        }

        if (captionRef.current) {
            captionRef.current.toggleBackground(target, 0, enabler.box)
        }

    }, [enabler.box])

    useEffect(() => {

        if (!enabler.anim && animInfo) {
            updateAnimInfo(null)
        }

        if (enabler.anim && !animInfo) {
            updateAnimInfo({
                type: 'none',
                speed: 100,
                intensity: 100
            })
        }

    }, [enabler.anim])

    useEffect(() => {
        if (!enabler.shadow && options?.shadowInfo) {
            setOptions(prevValue => ({
                ...prevValue,
                shadowInfo: null,
            }))
        }

        if(enabler.shadow && !options?.shadowInfo){
            const shadowInfo = {
                fill: '#000',
                blur: 10,
                offsetX: 5,
                offsetY: 5
            };
            setOptions(prevValue => ({
                ...prevValue,
                shadowInfo: shadowInfo,
            }))

        }
    }, [enabler.shadow])

    useEffect(() => {
        if (!enabler.stroke && (options?.stroke || options?.strokeWidth)) {
            setOptions(prevValue => ({
                ...prevValue,
                stroke: '',
                strokeWidth: null
            }));
        }

        if(enabler.stroke && !options?.stroke){
            setOptions(prevValue => ({
                ...prevValue,
                stroke: '#000',
                strokeWidth: 1
            }));
        }

    }, [enabler.stroke])

    useEffect(() => {
        console.log('INIT WORD SETTINGS ===> ', captionConfig)

        var idx = indexInfo?.idx ?? 0
        const wordsInfo = wordSettings;
        console.log('WORD RANGE INFO:: ', wordsInfo)
        setWordConfig(wordsInfo)
        isInternalUpdate.current = false;
        var wordsRangeOptions;
        if (!currentWordRangeOptions && wordsInfo && wordsInfo[idx]) {
            if (wordsInfo[idx]) {
                wordsRangeOptions = wordsInfo[idx];

                console.log('WORD RANGE OPTIONS:: ', wordsRangeOptions)
                setWordRangeLineOptions(wordsRangeOptions);

                setNewOptions(wordsRangeOptions?.options);
                setNewBoxOptions(wordsRangeOptions?.boxOptions);

                setAnimInfo(wordsRangeOptions?.anim)

            
            } else {
                wordsRangeOptions = { options: {}, boxOptions: null, anim: null }
                setWordRangeLineOptions(wordsRangeOptions)

                setNewOptions({})
                setNewBoxOptions(null)
                setAnimInfo(null)

            }

            setEnabler({
                box: wordsRangeOptions?.boxOptions ? true : false,
                anim: wordsRangeOptions?.anim ? true : false,
            })

        }
    }, [])

    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            console.log('WORD SETTINGS: ', wordSettings)
            var idx = indexInfo?.idx ?? 0;
            var wordRangeOptionsArray = wordSettings ? [...wordSettings] : []
            wordRangeOptionsArray[idx] = currentWordRangeOptions;
    
            console.log('FINALISING WORD RANGE SETTINGS: ', currentWordRangeOptions)
            setWordSettings(wordRangeOptionsArray)
        }
    }, [currentWordRangeOptions])


    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            console.log('OPTIONS CHANGED: ', options);

            setWordRangeLineOptions(prevValue => ({
                ...prevValue,
                options: options,
            }))
        }
       
    }, [options])


    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            console.log('Box Options Changed for Line Range IDX: ', indexInfo)

            setWordRangeLineOptions(prevValue => ({
                ...prevValue,
                boxOptions: boxOptions,
            }))
        }
    }, [boxOptions])


    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            setWordRangeLineOptions(prevValue => ({
                ...prevValue,
                anim: animInfo,
            }))
        }
        
    }, [animInfo])

    const updateOptions = (newOptions) => {
        isInternalUpdate.current = true;
        setOptions(newOptions)
    }

    const updateBoxOptions = (newBoxOptions) => {
        isInternalUpdate.current = true;
        setBoxOptions(newBoxOptions)
    }

    const updateAnimInfo = (newAnimInfo) => {
        isInternalUpdate.current = true;
        setAnimInfo(newAnimInfo)
    }

    const setNewOptions = (optionsInfo) => {
        setOptions(optionsInfo)
        optionsRef.current = optionsInfo ?? {};

    }

    const setNewBoxOptions = (boxOptionsInfo) => {
        setBoxOptions(boxOptionsInfo)
        boxOptionsRef.current = boxOptionsInfo ?? {};
    }


    return (
        <>
            {
                wordConfig && 
                <>
                    {
                        mode == 'active' ?
                            <>
                                <div className='form-row'>
                                    <div className='label'>
                                        Color
                                    </div>
                                    <div className='edit-box'>
                                        <div style={{ width: 150, height: 35 }}>
                                            <ColorPicker fill={options?.fill} options={options} setOptions={updateOptions} />
                                        </div>

                                    </div>
                                </div>
                                <div className='form-row'>
                                    <div className='label'>
                                        Scale
                                    </div>
                                    <div className='edit-box'>
                                        <NumberInput defaultValue={options?.scale ?? 1} options={options} setOptions={updateOptions} label='Scale' />
                                    </div>
                                </div>
                                <div className='form-row'>
                                    <LabeledSlider
                                        label='Opacity'
                                        options={options}
                                        setOptions={updateOptions}
                                        defaultValue={options?.opacity ?? 1} />
                                </div>
                                <div className='form-row'>
                                    <div className='label'>
                                        Stroke
                                    </div>
                                    <div className='edit-box'>
                                        <EnableSwitch target='stroke' value={enabler.stroke} setEnabler={setEnabler} />
                                    </div>
                                    <div className='options-container' style={{ display: enabler.stroke ? 'flex' : 'none' }}>
                                        {
                                            options &&
                                                <StrokeSettings options={options} setOptions={updateOptions} />
                                        }

                                    </div>
                                </div>

                                <div className='form-row'>
                                    <div className='label'>
                                        Shadow
                                    </div>
                                    <div className='edit-box'>
                                        <EnableSwitch target='shadow' value={enabler.shadow} setEnabler={setEnabler} />
                                    </div>
                                    <div className='options-container' style={{ display: enabler.shadow ? 'flex' : 'none' }}>
                                        {
                                            options?.shadowInfo &&
                                                <ShadowSettings options={options} setOptions={updateOptions}  />
                                        }

                                    </div>
                                </div>

                                <div className='form-row'>
                                    <div className='label'>
                                        Background
                                    </div>
                                    <div className='edit-box'>
                                        <EnableSwitch target='box' setEnabler={setEnabler} />
                                    </div>
                                    <div className='options-container' style={{ display: enabler.box ? 'flex' : 'none' }}>
                                        {
                                            boxOptions &&
                                            <BackgroundPanel boxOptions={boxOptions} setBoxOptions={updateBoxOptions} />
                                        }

                                    </div>
                                </div>

                                <div className='form-row'>
                                    <div className='label'>
                                        Animation
                                    </div>
                                    <div className='edit-box'>
                                        <EnableSwitch target='anim' setEnabler={setEnabler} />
                                    </div>
                                    <div className='options-container' style={{ display: enabler.anim ? 'flex' : 'none' }}>
                                        {
                                            animInfo &&
                                            <AnimationPanel animInfo={animInfo} setAnimInfo={updateAnimInfo} />
                                        }

                                    </div>
                                </div>
                            </> :
                            <></>
                    }
                </>
            }
        </>
        
          )
}

export default WordSettings
