import { db } from "../config/firebaseConfig";
import {
  collection,
  doc,
  addDoc,
  setDoc,
  getDocs,
  updateDoc,
  Timestamp,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { store } from "../redux/store";
import axios from "axios";

const CAPTION_PRESET_COLLECTION = "caption-presets";

export async function getAllProjectsForUser() {
  var state = store.getState();
  var userId = state.authReducer.user.id;
  console.log("User Id ===> ", userId);

  const collectionRef = collection(db, "users", userId, "projects");
  const q = query(collectionRef, orderBy("updated", "desc"));

  const querySnapshot = await getDocs(q);

  let projects = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    projects.push({ ...doc.data(), id: doc.id });
  });

  return projects;
}

async function uploadAndGetThumbnailUrl(file) {
  const images = new FormData();
  images.append("file", file);
  images.append("upload_preset", "eweiesmw");

  var res = await axios.post(
    "https://api.cloudinary.com/v1_1/dlmsxlf2n/image/upload",
    images
  );

  console.log("Successfully uploaded images to cloudinary");
  console.log("Uploaded Thumbnail: ", res);
  const thumbnail = res["data"]["secure_url"];
  return thumbnail;
}

function getThumbnailFromVideoUrl(videoUrl) {
  return new Promise((resolve, reject) => {
    // Create a video element
    const video = document.createElement("video");
    video.preload = "metadata";

    // Set the video source to the provided URL
    video.src = videoUrl;

    video.onloadeddata = () => {
      // Ensure the video loads the first frame
      video.currentTime = 0;
    };

    video.oncanplay = () => {
      // Create a canvas element to capture the frame
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Draw the first frame of the video onto the canvas
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert the canvas to a Blob
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Could not convert canvas to Blob"));
        }

        // Clean up
        URL.revokeObjectURL(video.src);
        canvas.remove();
      }, "image/jpeg");
    };

    video.onerror = () => {
      reject(new Error("Error loading video"));
    };
  });
}

async function syncProjectDetails(userId, projectId, thumbnail, info) {
  console.log("User Id ===> ", userId);
  console.log("Project Id: ", projectId);

  // const videoUrl = info.projectInfo?.mainVideo?.info?.url;
  var thumbnailUrl = await uploadAndGetThumbnailUrl(thumbnail);
  console.log("THUMBNAIL URL: ", thumbnailUrl);
  // if (videoUrl) {
  //   // const thumbnailFile = await getThumbnailFromVideoUrl(videoUrl);
  //   thumbnailUrl = await uploadAndGetThumbnailUrl(thumbnail);
  //   console.log("THUMBNAIL URL: ", thumbnailUrl);
  // }

  var projectData = {
    projectId: projectId,
    info: info,
    thumbnail: thumbnailUrl,
    updated: Timestamp.now(),
  };
  console.log("Project Data: ", projectData);

  const docRef = doc(db, "users", userId, "projects", projectId);
  await setDoc(docRef, projectData);
}

async function uploadCaptionStyleInfoToStore(captionInfo) {
  const { id } = captionInfo;
  captionInfo.created = Timestamp.now();

  console.log("UPLOADING CAPTION INFO:: ", captionInfo);

  const docRef = doc(db, CAPTION_PRESET_COLLECTION, id);
  await setDoc(docRef, captionInfo);
}

async function getCaptions() {
  const collectionRef = collection(db, CAPTION_PRESET_COLLECTION);
  const q = query(collectionRef, orderBy("score", "desc"));

  const querySnapshot = await getDocs(q);

  var captions = [];

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    captions.push({ ...doc.data(), id: doc.id });
  });
  return captions;
}

const getFileType = (fileData) => {
  var file = fileData;
  console.log("File: ", file);
  var type = file.type.split("/")[0];
  console.log("Type: ", type);
  if (type == "video") {
    return "video";
  } else if (type == "image") {
    return "image";
  } else {
    return;
  }
};

async function uploadAssetToCloudAndSaveInDB(userId, files) {
  console.log("Uploading Files to Cloudinary");

  console.log("Files: ", files);

  // const assetsRef = collection(userDocRef, "assets");

  const images = new FormData();
  const videos = new FormData();

  var result;
  var error;

  var uploadResult = [];

  files.forEach(async (file) => {
    const fileType = getFileType(file);
    if (fileType == "image") {
      images.append("file", file);
    } else if (fileType == "video") {
      var formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "eweiesmw");
      formData.append("folder", `user/${userId}`);

      await axios
        .post(
          "https://api.cloudinary.com/v1_1/dlmsxlf2n/video/upload",
          formData
        )
        .then((res) => {
          console.log("Successfully uploaded videos to cloudinary");
          console.log(res);

          var data = res["data"];
          var mediaUrl = data["secure_url"];
          /* Props: mediaType, duration, thumbnail
              files
              -> p_480 
                --> url 
                --> height 
                --> width 
          */
          var videoData = {
            mediaType: "video",
            created: Timestamp.now(),
            duration: data["duration"],
            thumbnail: mediaUrl.substr(0, mediaUrl.lastIndexOf(".")) + ".jpg",
            url: mediaUrl,
            width: data["width"],
            height: data["height"],
          };

          console.log("Video Data: ", videoData);

          console.log("User Id: ", userId);
          const userDocRef = doc(db, "users", userId);
          const assetsRef = collection(userDocRef, "assets");
          addDoc(assetsRef, videoData);

          uploadResult.push(videoData);
          result = videoData;

          // Get upload data and save it in db for the user
        });

      // videoCount++;
    } else {
      // Invalid File Type
      console.log("Invalid File Type: \n", file);
    }
  });

  return { uploadResult, error };
}

export {
  uploadCaptionStyleInfoToStore,
  uploadAssetToCloudAndSaveInDB,
  getCaptions,
  syncProjectDetails,
};
