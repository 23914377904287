const textData = {
  words: [
    {
      word: "and",
      start: 2.8,
      end: 3.04,
      confidence: 0.9803699,
      punctuated_word: "And",
    },
    {
      word: "i",
      start: 3.04,
      end: 3.12,
      confidence: 0.99944454,
      punctuated_word: "I",
    },
    {
      word: "wonder",
      start: 3.12,
      end: 3.4399998,
      confidence: 0.9834194,
      punctuated_word: "wonder",
    },
    {
      word: "what",
      start: 3.4399998,
      end: 3.52,
      confidence: 0.99893755,
      punctuated_word: "what",
    },
    {
      word: "the",
      start: 3.52,
      end: 3.6799998,
      confidence: 0.99945635,
      punctuated_word: "the",
    },
    {
      word: "future",
      start: 3.6799998,
      end: 3.9199998,
      confidence: 0.9991097,
      punctuated_word: "future",
    },
    {
      word: "of",
      start: 3.9199998,
      end: 4.08,
      confidence: 0.9984249,
      punctuated_word: "of",
    },
    {
      word: "ides",
      start: 4.08,
      end: 4.56,
      confidence: 0.9394796,
      punctuated_word: "IDEs",
    },
    {
      word: "looks",
      start: 4.56,
      end: 4.7999997,
      confidence: 0.9989184,
      punctuated_word: "looks",
    },
    {
      word: "like",
      start: 4.7999997,
      end: 4.96,
      confidence: 0.9620629,
      punctuated_word: "like.",
    },
    {
      word: "so",
      start: 4.96,
      end: 5.12,
      confidence: 0.9998036,
      punctuated_word: "So",
    },
    {
      word: "the",
      start: 5.12,
      end: 5.2799997,
      confidence: 0.9997322,
      punctuated_word: "the",
    },
    {
      word: "tooling",
      start: 5.2799997,
      end: 5.68,
      confidence: 0.9998333,
      punctuated_word: "tooling",
    },
    {
      word: "of",
      start: 5.68,
      end: 5.92,
      confidence: 0.99979573,
      punctuated_word: "of",
    },
    {
      word: "interacting",
      start: 5.92,
      end: 6.3199997,
      confidence: 0.9992853,
      punctuated_word: "interacting",
    },
    {
      word: "with",
      start: 6.3199997,
      end: 6.48,
      confidence: 0.99968386,
      punctuated_word: "with",
    },
    {
      word: "ai",
      start: 6.48,
      end: 6.7999997,
      confidence: 0.9709043,
      punctuated_word: "AI",
    },
    {
      word: "systems",
      start: 6.7999997,
      end: 7.12,
      confidence: 0.7816311,
      punctuated_word: "systems,",
    },
    {
      word: "this",
      start: 7.12,
      end: 7.3599997,
      confidence: 0.98146373,
      punctuated_word: "this",
    },
    {
      word: "is",
      start: 7.3599997,
      end: 7.52,
      confidence: 0.99937135,
      punctuated_word: "is",
    },
    {
      word: "true",
      start: 7.52,
      end: 7.68,
      confidence: 0.99934644,
      punctuated_word: "true",
    },
    {
      word: "for",
      start: 7.68,
      end: 7.9199996,
      confidence: 0.9990024,
      punctuated_word: "for",
    },
    {
      word: "programming",
      start: 7.9199996,
      end: 8.32,
      confidence: 0.9990934,
      punctuated_word: "programming",
    },
    {
      word: "and",
      start: 8.32,
      end: 8.48,
      confidence: 0.96742076,
      punctuated_word: "and",
    },
    {
      word: "also",
      start: 8.48,
      end: 8.72,
      confidence: 0.9681456,
      punctuated_word: "also",
    },
    {
      word: "probably",
      start: 8.72,
      end: 9.12,
      confidence: 0.9923528,
      punctuated_word: "probably",
    },
    {
      word: "true",
      start: 9.12,
      end: 9.28,
      confidence: 0.99823856,
      punctuated_word: "true",
    },
    {
      word: "for",
      start: 9.28,
      end: 9.4,
      confidence: 0.99981576,
      punctuated_word: "for",
    },
    {
      word: "in",
      start: 9.5199995,
      end: 9.679999,
      confidence: 0.9996877,
      punctuated_word: "in",
    },
    {
      word: "other",
      start: 9.679999,
      end: 10,
      confidence: 0.9997236,
      punctuated_word: "other",
    },
    {
      word: "contexts",
      start: 10,
      end: 10.5,
      confidence: 0.8115903,
      punctuated_word: "contexts,",
    },
    {
      word: "like",
      start: 10.719999,
      end: 10.96,
      confidence: 0.999754,
      punctuated_word: "like",
    },
    {
      word: "computer",
      start: 10.96,
      end: 11.36,
      confidence: 0.9658334,
      punctuated_word: "computer",
    },
    {
      word: "use",
      start: 11.36,
      end: 11.759999,
      confidence: 0.9196449,
      punctuated_word: "use,",
    },
    {
      word: "but",
      start: 11.759999,
      end: 12,
      confidence: 0.9998247,
      punctuated_word: "but",
    },
    {
      word: "maybe",
      start: 12,
      end: 12.32,
      confidence: 0.9501392,
      punctuated_word: "maybe",
    },
    {
      word: "domain",
      start: 12.32,
      end: 12.719999,
      confidence: 0.99922097,
      punctuated_word: "domain",
    },
    {
      word: "specific",
      start: 12.719999,
      end: 13.2,
      confidence: 0.8571434,
      punctuated_word: "specific.",
    },
    {
      word: "like",
      start: 13.2,
      end: 13.44,
      confidence: 0.94057465,
      punctuated_word: "Like,",
    },
    {
      word: "we",
      start: 13.44,
      end: 13.599999,
      confidence: 0.7668095,
      punctuated_word: "we",
    },
    {
      word: "mentioned",
      start: 13.599999,
      end: 13.92,
      confidence: 0.9617719,
      punctuated_word: "mentioned",
    },
    {
      word: "biology",
      start: 13.92,
      end: 14.42,
      confidence: 0.74821895,
      punctuated_word: "biology.",
    },
    {
      word: "it",
      start: 14.639999,
      end: 14.799999,
      confidence: 0.9991385,
      punctuated_word: "It",
    },
    {
      word: "probably",
      start: 14.799999,
      end: 15.12,
      confidence: 0.99986434,
      punctuated_word: "probably",
    },
    {
      word: "needs",
      start: 15.12,
      end: 15.36,
      confidence: 0.9998275,
      punctuated_word: "needs",
    },
    {
      word: "its",
      start: 15.36,
      end: 15.599999,
      confidence: 0.99725777,
      punctuated_word: "its",
    },
    {
      word: "own",
      start: 15.599999,
      end: 15.795,
      confidence: 0.9998053,
      punctuated_word: "own",
    },
    {
      word: "tooling",
      start: 15.795,
      end: 16.295,
      confidence: 0.9999343,
      punctuated_word: "tooling",
    },
    {
      word: "about",
      start: 16.675,
      end: 16.915,
      confidence: 0.99677414,
      punctuated_word: "about",
    },
    {
      word: "how",
      start: 16.915,
      end: 17.075,
      confidence: 0.99977916,
      punctuated_word: "how",
    },
    {
      word: "to",
      start: 17.075,
      end: 17.155,
      confidence: 0.99925154,
      punctuated_word: "to",
    },
    {
      word: "be",
      start: 17.155,
      end: 17.315,
      confidence: 0.9997838,
      punctuated_word: "be",
    },
    {
      word: "effective",
      start: 17.315,
      end: 17.635,
      confidence: 0.94867194,
      punctuated_word: "effective,",
    },
    {
      word: "and",
      start: 17.635,
      end: 17.875,
      confidence: 0.9994375,
      punctuated_word: "and",
    },
    {
      word: "then",
      start: 17.875,
      end: 18.035,
      confidence: 0.99950325,
      punctuated_word: "then",
    },
    {
      word: "programming",
      start: 18.035,
      end: 18.535,
      confidence: 0.99839467,
      punctuated_word: "programming",
    },
    {
      word: "needs",
      start: 18.595,
      end: 18.835,
      confidence: 0.9993692,
      punctuated_word: "needs",
    },
    {
      word: "its",
      start: 18.835,
      end: 18.994999,
      confidence: 0.99886453,
      punctuated_word: "its",
    },
    {
      word: "own",
      start: 18.994999,
      end: 19.155,
      confidence: 0.9990564,
      punctuated_word: "own",
    },
    {
      word: "tooling",
      start: 19.155,
      end: 19.655,
      confidence: 0.99959403,
      punctuated_word: "tooling.",
    },
    {
      word: "is",
      start: 19.955,
      end: 20.275,
      confidence: 0.99788517,
      punctuated_word: "Is",
    },
    {
      word: "anthropic",
      start: 20.275,
      end: 20.755001,
      confidence: 0.7478289,
      punctuated_word: "anthropic",
    },
    {
      word: "gonna",
      start: 20.755001,
      end: 20.915,
      confidence: 0.99035597,
      punctuated_word: "gonna",
    },
    {
      word: "play",
      start: 20.915,
      end: 21.075,
      confidence: 0.9955428,
      punctuated_word: "play",
    },
    {
      word: "in",
      start: 21.075,
      end: 21.235,
      confidence: 0.9970631,
      punctuated_word: "in",
    },
    {
      word: "that",
      start: 21.235,
      end: 21.395,
      confidence: 0.9990876,
      punctuated_word: "that",
    },
    {
      word: "space",
      start: 21.395,
      end: 21.635,
      confidence: 0.9995789,
      punctuated_word: "space",
    },
    {
      word: "of",
      start: 21.635,
      end: 21.795,
      confidence: 0.9484644,
      punctuated_word: "of",
    },
    {
      word: "also",
      start: 21.795,
      end: 22.115,
      confidence: 0.9271256,
      punctuated_word: "also",
    },
    {
      word: "tooling",
      start: 22.115,
      end: 22.435,
      confidence: 0.9992314,
      punctuated_word: "tooling",
    },
    {
      word: "potentially",
      start: 22.435,
      end: 22.935,
      confidence: 0.9189692,
      punctuated_word: "potentially?",
    },
    {
      word: "i'm",
      start: 22.994999,
      end: 23.235,
      confidence: 0.99785244,
      punctuated_word: "I'm",
    },
    {
      word: "absolutely",
      start: 23.235,
      end: 23.735,
      confidence: 0.9988581,
      punctuated_word: "absolutely",
    },
    {
      word: "convinced",
      start: 23.795,
      end: 24.295,
      confidence: 0.99972755,
      punctuated_word: "convinced",
    },
    {
      word: "that",
      start: 24.435,
      end: 24.935,
      confidence: 0.98811436,
      punctuated_word: "that,",
    },
    {
      word: "powerful",
      start: 25.955,
      end: 26.455,
      confidence: 0.9966607,
      punctuated_word: "powerful",
    },
    {
      word: "ides",
      start: 26.595001,
      end: 27.095001,
      confidence: 0.83576083,
      punctuated_word: "IDEs,",
    },
    {
      word: "that",
      start: 28.595,
      end: 28.915,
      confidence: 0.9998543,
      punctuated_word: "that",
    },
    {
      word: "that",
      start: 28.915,
      end: 29.314999,
      confidence: 0.9994429,
      punctuated_word: "that",
    },
    {
      word: "there's",
      start: 29.314999,
      end: 29.715,
      confidence: 0.98291653,
      punctuated_word: "there's",
    },
    {
      word: "so",
      start: 29.715,
      end: 29.875,
      confidence: 0.9998017,
      punctuated_word: "so",
    },
    {
      word: "much",
      start: 29.875,
      end: 30.375,
      confidence: 0.9997944,
      punctuated_word: "much",
    },
    {
      word: "low",
      start: 30.63,
      end: 30.789999,
      confidence: 0.9992399,
      punctuated_word: "low",
    },
    {
      word: "hanging",
      start: 30.789999,
      end: 31.269999,
      confidence: 0.9994072,
      punctuated_word: "hanging",
    },
    {
      word: "fruit",
      start: 31.269999,
      end: 31.59,
      confidence: 0.99895644,
      punctuated_word: "fruit",
    },
    {
      word: "to",
      start: 31.59,
      end: 31.75,
      confidence: 0.9994997,
      punctuated_word: "to",
    },
    {
      word: "be",
      start: 31.75,
      end: 31.91,
      confidence: 0.99991953,
      punctuated_word: "be",
    },
    {
      word: "grabbed",
      start: 31.91,
      end: 32.23,
      confidence: 0.99892527,
      punctuated_word: "grabbed",
    },
    {
      word: "there",
      start: 32.23,
      end: 32.73,
      confidence: 0.8614001,
      punctuated_word: "there,",
    },
    {
      word: "that",
      start: 33.35,
      end: 33.59,
      confidence: 0.8520669,
      punctuated_word: "that,",
    },
    {
      word: "you",
      start: 33.59,
      end: 33.75,
      confidence: 0.9905087,
      punctuated_word: "you",
    },
    {
      word: "know",
      start: 33.75,
      end: 33.91,
      confidence: 0.99662536,
      punctuated_word: "know,",
    },
    {
      word: "right",
      start: 33.91,
      end: 34.07,
      confidence: 0.99977225,
      punctuated_word: "right",
    },
    {
      word: "now",
      start: 34.07,
      end: 34.309998,
      confidence: 0.8199614,
      punctuated_word: "now,",
    },
    {
      word: "it's",
      start: 34.309998,
      end: 34.47,
      confidence: 0.9964539,
      punctuated_word: "it's",
    },
    {
      word: "just",
      start: 34.47,
      end: 34.629997,
      confidence: 0.9997316,
      punctuated_word: "just",
    },
    {
      word: "like",
      start: 34.629997,
      end: 34.79,
      confidence: 0.969774,
      punctuated_word: "like",
    },
    {
      word: "you",
      start: 34.79,
      end: 34.95,
      confidence: 0.9935266,
      punctuated_word: "you",
    },
    {
      word: "talk",
      start: 34.95,
      end: 35.19,
      confidence: 0.95682836,
      punctuated_word: "talk",
    },
    {
      word: "to",
      start: 35.19,
      end: 35.27,
      confidence: 0.99696964,
      punctuated_word: "to",
    },
    {
      word: "the",
      start: 35.27,
      end: 35.35,
      confidence: 0.9868183,
      punctuated_word: "the",
    },
    {
      word: "model",
      start: 35.35,
      end: 35.67,
      confidence: 0.9999347,
      punctuated_word: "model",
    },
    {
      word: "and",
      start: 35.67,
      end: 35.75,
      confidence: 0.7165687,
      punctuated_word: "and",
    },
    {
      word: "it",
      start: 35.75,
      end: 35.989998,
      confidence: 0.9988714,
      punctuated_word: "it",
    },
    {
      word: "talks",
      start: 35.989998,
      end: 36.23,
      confidence: 0.998464,
      punctuated_word: "talks",
    },
    {
      word: "back",
      start: 36.23,
      end: 36.629997,
      confidence: 0.8802566,
      punctuated_word: "back.",
    },
    {
      word: "but",
      start: 36.629997,
      end: 37.129997,
      confidence: 0.99903107,
      punctuated_word: "But",
    },
    {
      word: "but",
      start: 37.19,
      end: 37.51,
      confidence: 0.9978861,
      punctuated_word: "but",
    },
    {
      word: "look",
      start: 37.59,
      end: 37.91,
      confidence: 0.97839344,
      punctuated_word: "look.",
    },
    {
      word: "i",
      start: 37.91,
      end: 37.989998,
      confidence: 0.9999584,
      punctuated_word: "I",
    },
    {
      word: "mean",
      start: 37.989998,
      end: 38.489998,
      confidence: 0.99928194,
      punctuated_word: "mean,",
    },
    {
      word: "ids",
      start: 39.35,
      end: 39.829998,
      confidence: 0.8207186,
      punctuated_word: "IDs",
    },
    {
      word: "are",
      start: 39.829998,
      end: 39.989998,
      confidence: 0.9997075,
      punctuated_word: "are",
    },
    {
      word: "great",
      start: 39.989998,
      end: 40.309998,
      confidence: 0.9997882,
      punctuated_word: "great",
    },
    {
      word: "at",
      start: 40.309998,
      end: 40.55,
      confidence: 0.99853826,
      punctuated_word: "at",
    },
    {
      word: "kind",
      start: 40.55,
      end: 40.71,
      confidence: 0.9839496,
      punctuated_word: "kind",
    },
    {
      word: "of",
      start: 40.71,
      end: 41.03,
      confidence: 0.9994603,
      punctuated_word: "of",
    },
    {
      word: "lots",
      start: 41.03,
      end: 41.27,
      confidence: 0.94498974,
      punctuated_word: "lots",
    },
    {
      word: "of",
      start: 41.27,
      end: 41.51,
      confidence: 0.99950767,
      punctuated_word: "of",
    },
    {
      word: "static",
      start: 41.51,
      end: 41.91,
      confidence: 0.9995091,
      punctuated_word: "static",
    },
    {
      word: "analysis",
      start: 41.91,
      end: 42.41,
      confidence: 0.99932444,
      punctuated_word: "analysis",
    },
    {
      word: "of",
      start: 42.87,
      end: 43.11,
      confidence: 0.9994017,
      punctuated_word: "of",
    },
    {
      word: "of",
      start: 43.11,
      end: 43.59,
      confidence: 0.8073205,
      punctuated_word: "of,",
    },
    {
      word: "you",
      start: 43.59,
      end: 43.829998,
      confidence: 0.9991265,
      punctuated_word: "you",
    },
    {
      word: "know",
      start: 43.829998,
      end: 44.23,
      confidence: 0.94534963,
      punctuated_word: "know,",
    },
    {
      word: "so",
      start: 44.23,
      end: 44.47,
      confidence: 0.99944896,
      punctuated_word: "so",
    },
    {
      word: "much",
      start: 44.47,
      end: 44.71,
      confidence: 0.99994695,
      punctuated_word: "much",
    },
    {
      word: "is",
      start: 44.71,
      end: 44.87,
      confidence: 0.65355366,
      punctuated_word: "is",
    },
    {
      word: "possible",
      start: 44.87,
      end: 45.35,
      confidence: 0.9997993,
      punctuated_word: "possible",
    },
    {
      word: "with",
      start: 45.35,
      end: 45.59,
      confidence: 0.999292,
      punctuated_word: "with",
    },
    {
      word: "kind",
      start: 45.59,
      end: 45.75,
      confidence: 0.9932667,
      punctuated_word: "kind",
    },
    {
      word: "of",
      start: 45.75,
      end: 46.065,
      confidence: 0.99981016,
      punctuated_word: "of",
    },
    {
      word: "static",
      start: 46.305,
      end: 46.704998,
      confidence: 0.9997298,
      punctuated_word: "static",
    },
    {
      word: "analysis",
      start: 46.704998,
      end: 47.204998,
      confidence: 0.82169974,
      punctuated_word: "analysis,",
    },
    {
      word: "like",
      start: 47.265,
      end: 47.585,
      confidence: 0.99906796,
      punctuated_word: "like",
    },
    {
      word: "many",
      start: 47.585,
      end: 47.824997,
      confidence: 0.84850425,
      punctuated_word: "many",
    },
    {
      word: "bugs",
      start: 47.824997,
      end: 48.065,
      confidence: 0.999683,
      punctuated_word: "bugs",
    },
    {
      word: "you",
      start: 48.065,
      end: 48.305,
      confidence: 0.9872802,
      punctuated_word: "you",
    },
    {
      word: "can",
      start: 48.305,
      end: 48.465,
      confidence: 0.9995134,
      punctuated_word: "can",
    },
    {
      word: "find",
      start: 48.465,
      end: 48.704998,
      confidence: 0.9993573,
      punctuated_word: "find",
    },
    {
      word: "without",
      start: 48.704998,
      end: 49.024998,
      confidence: 0.9991658,
      punctuated_word: "without",
    },
    {
      word: "even",
      start: 49.024998,
      end: 49.265,
      confidence: 0.9997203,
      punctuated_word: "even",
    },
    {
      word: "writing",
      start: 49.265,
      end: 49.664997,
      confidence: 0.998448,
      punctuated_word: "writing",
    },
    {
      word: "the",
      start: 49.664997,
      end: 49.745,
      confidence: 0.99896693,
      punctuated_word: "the",
    },
    {
      word: "code",
      start: 49.745,
      end: 50.245,
      confidence: 0.91755366,
      punctuated_word: "code,",
    },
    {
      word: "then",
      start: 50.625,
      end: 50.864998,
      confidence: 0.9983437,
      punctuated_word: "then,",
    },
    {
      word: "you",
      start: 51.024998,
      end: 51.105,
      confidence: 0.9990043,
      punctuated_word: "you",
    },
    {
      word: "know",
      start: 51.105,
      end: 51.425,
      confidence: 0.9998393,
      punctuated_word: "know,",
    },
    {
      word: "ids",
      start: 51.425,
      end: 51.905,
      confidence: 0.57963127,
      punctuated_word: "IDs",
    },
    {
      word: "are",
      start: 51.905,
      end: 52.065,
      confidence: 0.99955267,
      punctuated_word: "are",
    },
    {
      word: "good",
      start: 52.065,
      end: 52.225,
      confidence: 0.99981767,
      punctuated_word: "good",
    },
    {
      word: "for",
      start: 52.225,
      end: 52.464996,
      confidence: 0.9998524,
      punctuated_word: "for",
    },
    {
      word: "running",
      start: 52.464996,
      end: 52.785,
      confidence: 0.9998479,
      punctuated_word: "running",
    },
    {
      word: "particular",
      start: 52.785,
      end: 53.285,
      confidence: 0.99954116,
      punctuated_word: "particular",
    },
    {
      word: "things",
      start: 53.344997,
      end: 53.824997,
      confidence: 0.99928117,
      punctuated_word: "things,",
    },
    {
      word: "organizing",
      start: 53.824997,
      end: 54.324997,
      confidence: 0.9998203,
      punctuated_word: "organizing",
    },
    {
      word: "your",
      start: 54.545,
      end: 54.785,
      confidence: 0.99960476,
      punctuated_word: "your",
    },
    {
      word: "code",
      start: 54.785,
      end: 55.285,
      confidence: 0.99953467,
      punctuated_word: "code,",
    },
    {
      word: "measuring",
      start: 56.225,
      end: 56.704998,
      confidence: 0.9985361,
      punctuated_word: "measuring",
    },
    {
      word: "coverage",
      start: 56.704998,
      end: 57.105,
      confidence: 0.9995079,
      punctuated_word: "coverage",
    },
    {
      word: "of",
      start: 57.105,
      end: 57.265,
      confidence: 0.9996407,
      punctuated_word: "of",
    },
    {
      word: "unit",
      start: 57.265,
      end: 57.504997,
      confidence: 0.9991359,
      punctuated_word: "unit",
    },
    {
      word: "tests",
      start: 57.504997,
      end: 57.824997,
      confidence: 0.9065279,
      punctuated_word: "tests.",
    },
    {
      word: "like",
      start: 57.824997,
      end: 57.985,
      confidence: 0.99206537,
      punctuated_word: "Like,",
    },
    {
      word: "there's",
      start: 57.985,
      end: 58.225,
      confidence: 0.9996675,
      punctuated_word: "there's",
    },
    {
      word: "so",
      start: 58.225,
      end: 58.464996,
      confidence: 0.99973744,
      punctuated_word: "so",
    },
    {
      word: "much",
      start: 58.464996,
      end: 58.704998,
      confidence: 0.9998983,
      punctuated_word: "much",
    },
    {
      word: "that's",
      start: 58.704998,
      end: 58.945,
      confidence: 0.99829924,
      punctuated_word: "that's",
    },
    {
      word: "been",
      start: 58.945,
      end: 59.105,
      confidence: 0.99850947,
      punctuated_word: "been",
    },
    {
      word: "possible",
      start: 59.105,
      end: 59.605,
      confidence: 0.99844885,
      punctuated_word: "possible",
    },
    {
      word: "with",
      start: 59.780003,
      end: 60.02,
      confidence: 0.97478443,
      punctuated_word: "with",
    },
    {
      word: "a",
      start: 60.02,
      end: 60.100002,
      confidence: 0.8412899,
      punctuated_word: "a",
    },
    {
      word: "normal",
      start: 60.100002,
      end: 60.600002,
      confidence: 0.99962413,
      punctuated_word: "normal",
    },
    {
      word: "i",
      start: 60.66,
      end: 60.7,
      confidence: 0.32751915,
      punctuated_word: "I",
    },
    {
      word: "with",
      start: 60.74,
      end: 60.98,
      confidence: 0.99936265,
      punctuated_word: "with",
    },
    {
      word: "a",
      start: 60.98,
      end: 61.06,
      confidence: 0.90878695,
      punctuated_word: "a",
    },
    {
      word: "normal",
      start: 61.06,
      end: 61.46,
      confidence: 0.9998271,
      punctuated_word: "normal",
    },
    {
      word: "ids",
      start: 61.46,
      end: 61.96,
      confidence: 0.74798435,
      punctuated_word: "IDs.",
    },
    {
      word: "now",
      start: 62.420002,
      end: 62.66,
      confidence: 0.9993649,
      punctuated_word: "Now",
    },
    {
      word: "you",
      start: 62.66,
      end: 62.9,
      confidence: 0.9966628,
      punctuated_word: "you",
    },
    {
      word: "add",
      start: 62.9,
      end: 63.22,
      confidence: 0.99978656,
      punctuated_word: "add",
    },
    {
      word: "something",
      start: 63.22,
      end: 63.7,
      confidence: 0.99960726,
      punctuated_word: "something",
    },
    {
      word: "like",
      start: 63.7,
      end: 64.2,
      confidence: 0.7887653,
      punctuated_word: "like,",
    },
    {
      word: "well",
      start: 64.5,
      end: 64.82,
      confidence: 0.99469656,
      punctuated_word: "well,",
    },
    {
      word: "the",
      start: 64.82,
      end: 64.98,
      confidence: 0.99979776,
      punctuated_word: "the",
    },
    {
      word: "model",
      start: 64.98,
      end: 65.38,
      confidence: 0.999933,
      punctuated_word: "model",
    },
    {
      word: "now",
      start: 65.54,
      end: 65.86,
      confidence: 0.93686575,
      punctuated_word: "now,",
    },
    {
      word: "you",
      start: 65.86,
      end: 66.020004,
      confidence: 0.99902415,
      punctuated_word: "you",
    },
    {
      word: "know",
      start: 66.020004,
      end: 66.18,
      confidence: 0.8531418,
      punctuated_word: "know,",
    },
    {
      word: "the",
      start: 66.18,
      end: 66.42,
      confidence: 0.99955934,
      punctuated_word: "the",
    },
    {
      word: "model",
      start: 66.42,
      end: 66.74,
      confidence: 0.99986684,
      punctuated_word: "model",
    },
    {
      word: "can",
      start: 66.74,
      end: 66.98,
      confidence: 0.9994486,
      punctuated_word: "can",
    },
    {
      word: "now",
      start: 66.98,
      end: 67.22,
      confidence: 0.9876825,
      punctuated_word: "now,",
    },
    {
      word: "like",
      start: 67.22,
      end: 67.72,
      confidence: 0.99909997,
      punctuated_word: "like,",
    },
    {
      word: "write",
      start: 67.86,
      end: 68.1,
      confidence: 0.9979177,
      punctuated_word: "write",
    },
    {
      word: "code",
      start: 68.1,
      end: 68.42,
      confidence: 0.9995383,
      punctuated_word: "code",
    },
    {
      word: "and",
      start: 68.42,
      end: 68.66,
      confidence: 0.9978169,
      punctuated_word: "and",
    },
    {
      word: "run",
      start: 68.66,
      end: 68.98,
      confidence: 0.9998117,
      punctuated_word: "run",
    },
    {
      word: "code",
      start: 68.98,
      end: 69.48,
      confidence: 0.9907857,
      punctuated_word: "code.",
    },
    {
      word: "like",
      start: 69.62,
      end: 70.1,
      confidence: 0.99816203,
      punctuated_word: "Like,",
    },
    {
      word: "i",
      start: 70.1,
      end: 70.26,
      confidence: 0.9997069,
      punctuated_word: "I",
    },
    {
      word: "am",
      start: 70.26,
      end: 70.5,
      confidence: 0.99921,
      punctuated_word: "am",
    },
    {
      word: "absolutely",
      start: 70.5,
      end: 70.98,
      confidence: 0.998738,
      punctuated_word: "absolutely",
    },
    {
      word: "convinced",
      start: 70.98,
      end: 71.46,
      confidence: 0.9992157,
      punctuated_word: "convinced",
    },
    {
      word: "that",
      start: 71.46,
      end: 71.7,
      confidence: 0.9962985,
      punctuated_word: "that",
    },
    {
      word: "over",
      start: 71.7,
      end: 71.86,
      confidence: 0.9989213,
      punctuated_word: "over",
    },
    {
      word: "the",
      start: 71.86,
      end: 72.020004,
      confidence: 0.999747,
      punctuated_word: "the",
    },
    {
      word: "next",
      start: 72.020004,
      end: 72.26,
      confidence: 0.999775,
      punctuated_word: "next",
    },
    {
      word: "year",
      start: 72.26,
      end: 72.5,
      confidence: 0.999166,
      punctuated_word: "year",
    },
    {
      word: "or",
      start: 72.5,
      end: 72.58,
      confidence: 0.9988576,
      punctuated_word: "or",
    },
    {
      word: "2",
      start: 72.58,
      end: 72.9,
      confidence: 0.9651871,
      punctuated_word: "2,",
    },
    {
      word: "even",
      start: 72.9,
      end: 73.14,
      confidence: 0.9998248,
      punctuated_word: "even",
    },
    {
      word: "if",
      start: 73.14,
      end: 73.3,
      confidence: 0.9991327,
      punctuated_word: "if",
    },
    {
      word: "the",
      start: 73.3,
      end: 73.38,
      confidence: 0.9984988,
      punctuated_word: "the",
    },
    {
      word: "quality",
      start: 73.38,
      end: 73.78,
      confidence: 0.9998246,
      punctuated_word: "quality",
    },
    {
      word: "of",
      start: 73.78,
      end: 73.86,
      confidence: 0.99967766,
      punctuated_word: "of",
    },
    {
      word: "the",
      start: 73.86,
      end: 73.94,
      confidence: 0.99876934,
      punctuated_word: "the",
    },
    {
      word: "models",
      start: 73.94,
      end: 74.34,
      confidence: 0.9995427,
      punctuated_word: "models",
    },
    {
      word: "didn't",
      start: 74.34,
      end: 74.66,
      confidence: 0.9999082,
      punctuated_word: "didn't",
    },
    {
      word: "improve",
      start: 74.66,
      end: 75.16,
      confidence: 0.9845021,
      punctuated_word: "improve,",
    },
    {
      word: "that",
      start: 75.365005,
      end: 75.44501,
      confidence: 0.99874145,
      punctuated_word: "that",
    },
    {
      word: "there",
      start: 75.44501,
      end: 75.685005,
      confidence: 0.99985206,
      punctuated_word: "there",
    },
    {
      word: "would",
      start: 75.685005,
      end: 75.76501,
      confidence: 0.9986092,
      punctuated_word: "would",
    },
    {
      word: "be",
      start: 75.76501,
      end: 75.845,
      confidence: 0.98050195,
      punctuated_word: "be",
    },
    {
      word: "enormous",
      start: 75.845,
      end: 76.345,
      confidence: 0.97876257,
      punctuated_word: "enormous",
    },
    {
      word: "opportunity",
      start: 76.565,
      end: 77.065,
      confidence: 0.9992505,
      punctuated_word: "opportunity",
    },
    {
      word: "to",
      start: 77.125,
      end: 77.285,
      confidence: 0.99983954,
      punctuated_word: "to",
    },
    {
      word: "enhance",
      start: 77.285,
      end: 77.685005,
      confidence: 0.9997441,
      punctuated_word: "enhance",
    },
    {
      word: "people's",
      start: 77.685005,
      end: 78.185005,
      confidence: 0.99922,
      punctuated_word: "people's",
    },
    {
      word: "productivity",
      start: 78.40501,
      end: 78.90501,
      confidence: 0.9996823,
      punctuated_word: "productivity",
    },
    {
      word: "by",
      start: 79.045006,
      end: 79.205,
      confidence: 0.9987361,
      punctuated_word: "by",
    },
    {
      word: "catching",
      start: 79.205,
      end: 79.605,
      confidence: 0.99979466,
      punctuated_word: "catching",
    },
    {
      word: "a",
      start: 79.605,
      end: 79.685005,
      confidence: 0.99979883,
      punctuated_word: "a",
    },
    {
      word: "bunch",
      start: 79.685005,
      end: 79.845,
      confidence: 0.99996173,
      punctuated_word: "bunch",
    },
    {
      word: "of",
      start: 79.845,
      end: 80.08501,
      confidence: 0.99981004,
      punctuated_word: "of",
    },
    {
      word: "mistakes",
      start: 80.08501,
      end: 80.58501,
      confidence: 0.9992428,
      punctuated_word: "mistakes,",
    },
    {
      word: "doing",
      start: 80.885,
      end: 81.205,
      confidence: 0.9998275,
      punctuated_word: "doing",
    },
    {
      word: "a",
      start: 81.205,
      end: 81.285,
      confidence: 0.99954283,
      punctuated_word: "a",
    },
    {
      word: "bunch",
      start: 81.285,
      end: 81.44501,
      confidence: 0.9998895,
      punctuated_word: "bunch",
    },
    {
      word: "of",
      start: 81.44501,
      end: 81.685005,
      confidence: 0.99976295,
      punctuated_word: "of",
    },
    {
      word: "grunt",
      start: 81.685005,
      end: 82.005005,
      confidence: 0.9983304,
      punctuated_word: "grunt",
    },
    {
      word: "work",
      start: 82.005005,
      end: 82.325005,
      confidence: 0.99934775,
      punctuated_word: "work",
    },
    {
      word: "for",
      start: 82.325005,
      end: 82.485,
      confidence: 0.99892646,
      punctuated_word: "for",
    },
    {
      word: "people",
      start: 82.485,
      end: 82.985,
      confidence: 0.97295165,
      punctuated_word: "people,",
    },
    {
      word: "and",
      start: 83.205,
      end: 83.365005,
      confidence: 0.9989448,
      punctuated_word: "and",
    },
    {
      word: "that",
      start: 83.365005,
      end: 83.605,
      confidence: 0.9991373,
      punctuated_word: "that",
    },
    {
      word: "we",
      start: 83.605,
      end: 83.685005,
      confidence: 0.9870924,
      punctuated_word: "we",
    },
    {
      word: "haven't",
      start: 83.685005,
      end: 84.005005,
      confidence: 0.9999517,
      punctuated_word: "haven't",
    },
    {
      word: "even",
      start: 84.005005,
      end: 84.245,
      confidence: 0.99997973,
      punctuated_word: "even",
    },
    {
      word: "scratched",
      start: 84.245,
      end: 84.645004,
      confidence: 0.9989993,
      punctuated_word: "scratched",
    },
    {
      word: "the",
      start: 84.645004,
      end: 84.805,
      confidence: 0.99859875,
      punctuated_word: "the",
    },
    {
      word: "surface",
      start: 84.805,
      end: 85.305,
      confidence: 0.9991541,
      punctuated_word: "surface.",
    },
    {
      word: "anthropic",
      start: 85.845,
      end: 86.345,
      confidence: 0.7445506,
      punctuated_word: "Anthropic",
    },
    {
      word: "itself",
      start: 86.485,
      end: 86.965004,
      confidence: 0.93840104,
      punctuated_word: "itself,",
    },
    {
      word: "i",
      start: 86.965004,
      end: 87.045006,
      confidence: 0.99627435,
      punctuated_word: "I",
    },
    {
      word: "mean",
      start: 87.045006,
      end: 87.285,
      confidence: 0.9989515,
      punctuated_word: "mean,",
    },
    {
      word: "you",
      start: 87.285,
      end: 87.365005,
      confidence: 0.9978836,
      punctuated_word: "you",
    },
    {
      word: "can't",
      start: 87.365005,
      end: 87.685005,
      confidence: 0.99941206,
      punctuated_word: "can't",
    },
    {
      word: "say",
      start: 87.685005,
      end: 87.925,
      confidence: 0.95515037,
      punctuated_word: "say,",
    },
    {
      word: "you",
      start: 87.925,
      end: 88.005005,
      confidence: 0.9955071,
      punctuated_word: "you",
    },
    {
      word: "know",
      start: 88.005005,
      end: 88.165,
      confidence: 0.9685565,
      punctuated_word: "know,",
    },
    {
      word: "no",
      start: 88.165,
      end: 88.665,
      confidence: 0.7340829,
      punctuated_word: "no.",
    },
    {
      word: "you",
      start: 90,
      end: 90.08,
      confidence: 0.9928081,
      punctuated_word: "You",
    },
    {
      word: "know",
      start: 90.08,
      end: 90.24,
      confidence: 0.93072796,
      punctuated_word: "know,",
    },
    {
      word: "it's",
      start: 90.24,
      end: 90.4,
      confidence: 0.9996338,
      punctuated_word: "it's",
    },
    {
      word: "hard",
      start: 90.4,
      end: 90.48,
      confidence: 0.9999317,
      punctuated_word: "hard",
    },
    {
      word: "to",
      start: 90.48,
      end: 90.64,
      confidence: 0.99988425,
      punctuated_word: "to",
    },
    {
      word: "say",
      start: 90.64,
      end: 90.96,
      confidence: 0.99990594,
      punctuated_word: "say",
    },
    {
      word: "what",
      start: 90.96,
      end: 91.04,
      confidence: 0.98500824,
      punctuated_word: "what",
    },
    {
      word: "will",
      start: 91.04,
      end: 91.2,
      confidence: 0.9864072,
      punctuated_word: "will",
    },
    {
      word: "happen",
      start: 91.2,
      end: 91.44,
      confidence: 0.9998915,
      punctuated_word: "happen",
    },
    {
      word: "in",
      start: 91.44,
      end: 91.52,
      confidence: 0.99740785,
      punctuated_word: "in",
    },
    {
      word: "the",
      start: 91.52,
      end: 91.68,
      confidence: 0.9991635,
      punctuated_word: "the",
    },
    {
      word: "future",
      start: 91.68,
      end: 92.18,
      confidence: 0.99956614,
      punctuated_word: "future.",
    },
    {
      word: "currently",
      start: 92.32,
      end: 92.82,
      confidence: 0.99887776,
      punctuated_word: "Currently,",
    },
    {
      word: "we're",
      start: 93.28,
      end: 93.52,
      confidence: 0.999686,
      punctuated_word: "we're",
    },
    {
      word: "not",
      start: 93.52,
      end: 93.68,
      confidence: 0.99980384,
      punctuated_word: "not",
    },
    {
      word: "trying",
      start: 93.68,
      end: 94,
      confidence: 0.99991167,
      punctuated_word: "trying",
    },
    {
      word: "to",
      start: 94,
      end: 94.08,
      confidence: 0.9997491,
      punctuated_word: "to",
    },
    {
      word: "make",
      start: 94.08,
      end: 94.24,
      confidence: 0.9999721,
      punctuated_word: "make",
    },
    {
      word: "such",
      start: 94.24,
      end: 94.56,
      confidence: 0.99991155,
      punctuated_word: "such",
    },
    {
      word: "ids",
      start: 94.56,
      end: 94.96,
      confidence: 0.94428897,
      punctuated_word: "IDs",
    },
    {
      word: "ourself",
      start: 94.96,
      end: 95.46,
      confidence: 0.7334261,
      punctuated_word: "ourself,",
    },
    {
      word: "rather",
      start: 95.52,
      end: 95.92,
      confidence: 0.9992681,
      punctuated_word: "rather",
    },
    {
      word: "we're",
      start: 95.92,
      end: 96.16,
      confidence: 0.8975812,
      punctuated_word: "we're",
    },
    {
      word: "powering",
      start: 96.16,
      end: 96.56,
      confidence: 0.9987196,
      punctuated_word: "powering",
    },
    {
      word: "the",
      start: 96.56,
      end: 96.72,
      confidence: 0.9988312,
      punctuated_word: "the",
    },
    {
      word: "companies",
      start: 96.72,
      end: 97.22,
      confidence: 0.9990422,
      punctuated_word: "companies",
    },
    {
      word: "like",
      start: 97.6,
      end: 98.1,
      confidence: 0.7919233,
      punctuated_word: "like",
    },
    {
      word: "cursor",
      start: 98.479996,
      end: 98.979996,
      confidence: 0.95553815,
      punctuated_word: "Cursor",
    },
    {
      word: "or",
      start: 99.04,
      end: 99.28,
      confidence: 0.91401833,
      punctuated_word: "or",
    },
    {
      word: "like",
      start: 99.28,
      end: 99.6,
      confidence: 0.95291275,
      punctuated_word: "like",
    },
    {
      word: "cognition",
      start: 99.6,
      end: 100.1,
      confidence: 0.9791757,
      punctuated_word: "Cognition",
    },
    {
      word: "or",
      start: 100.4,
      end: 100.64,
      confidence: 0.9710045,
      punctuated_word: "or",
    },
    {
      word: "some",
      start: 100.64,
      end: 100.8,
      confidence: 0.9994437,
      punctuated_word: "some",
    },
    {
      word: "of",
      start: 100.8,
      end: 100.88,
      confidence: 0.9998204,
      punctuated_word: "of",
    },
    {
      word: "the",
      start: 100.88,
      end: 101.04,
      confidence: 0.99989533,
      punctuated_word: "the",
    },
    {
      word: "other",
      start: 101.04,
      end: 101.44,
      confidence: 0.85339636,
      punctuated_word: "other,",
    },
    {
      word: "you",
      start: 101.44,
      end: 101.6,
      confidence: 0.9374782,
      punctuated_word: "you",
    },
    {
      word: "know",
      start: 101.6,
      end: 102.1,
      confidence: 0.99948394,
      punctuated_word: "know,",
    },
    {
      word: "expo",
      start: 102.479996,
      end: 102.88,
      confidence: 0.98530436,
      punctuated_word: "expo",
    },
    {
      word: "in",
      start: 102.88,
      end: 103.04,
      confidence: 0.9532311,
      punctuated_word: "in",
    },
    {
      word: "the",
      start: 103.04,
      end: 103.2,
      confidence: 0.9997794,
      punctuated_word: "the",
    },
    {
      word: "security",
      start: 103.2,
      end: 103.7,
      confidence: 0.9982999,
      punctuated_word: "security",
    },
    {
      word: "space",
      start: 103.76,
      end: 104.26,
      confidence: 0.7525607,
      punctuated_word: "space.",
    },
    {
      word: "you",
      start: 104.385,
      end: 104.885,
      confidence: 0.7071926,
      punctuated_word: "You",
    },
    {
      word: "know",
      start: 105.665,
      end: 106.145004,
      confidence: 0.99928665,
      punctuated_word: "know,",
    },
    {
      word: "others",
      start: 106.145004,
      end: 106.385,
      confidence: 0.98567593,
      punctuated_word: "others",
    },
    {
      word: "that",
      start: 106.385,
      end: 106.545,
      confidence: 0.999688,
      punctuated_word: "that",
    },
    {
      word: "i",
      start: 106.545,
      end: 106.625,
      confidence: 0.9998746,
      punctuated_word: "I",
    },
    {
      word: "can",
      start: 106.625,
      end: 106.865005,
      confidence: 0.62863314,
      punctuated_word: "can",
    },
    {
      word: "mention",
      start: 106.865005,
      end: 107.185005,
      confidence: 0.99630725,
      punctuated_word: "mention",
    },
    {
      word: "as",
      start: 107.185005,
      end: 107.345,
      confidence: 0.9998648,
      punctuated_word: "as",
    },
    {
      word: "well",
      start: 107.345,
      end: 107.845,
      confidence: 0.999949,
      punctuated_word: "well",
    },
    {
      word: "that",
      start: 108.385,
      end: 108.885,
      confidence: 0.9375616,
      punctuated_word: "that",
    },
    {
      word: "are",
      start: 109.105,
      end: 109.265,
      confidence: 0.999754,
      punctuated_word: "are",
    },
    {
      word: "building",
      start: 109.265,
      end: 109.665,
      confidence: 0.9998729,
      punctuated_word: "building",
    },
    {
      word: "such",
      start: 109.665,
      end: 109.905,
      confidence: 0.99981445,
      punctuated_word: "such",
    },
    {
      word: "things",
      start: 109.905,
      end: 110.225,
      confidence: 0.9996673,
      punctuated_word: "things",
    },
    {
      word: "themselves",
      start: 110.225,
      end: 110.705,
      confidence: 0.9994886,
      punctuated_word: "themselves",
    },
    {
      word: "on",
      start: 110.705,
      end: 110.785,
      confidence: 0.99961734,
      punctuated_word: "on",
    },
    {
      word: "top",
      start: 110.785,
      end: 111.025,
      confidence: 0.9997242,
      punctuated_word: "top",
    },
    {
      word: "of",
      start: 111.025,
      end: 111.185005,
      confidence: 0.9997919,
      punctuated_word: "of",
    },
    {
      word: "our",
      start: 111.185005,
      end: 111.345,
      confidence: 0.9996356,
      punctuated_word: "our",
    },
    {
      word: "api",
      start: 111.345,
      end: 111.665,
      confidence: 0.93417406,
      punctuated_word: "API.",
    },
    {
      word: "and",
      start: 111.665,
      end: 111.905,
      confidence: 0.9462705,
      punctuated_word: "And",
    },
    {
      word: "our",
      start: 111.905,
      end: 112.065,
      confidence: 0.9997557,
      punctuated_word: "our",
    },
    {
      word: "view",
      start: 112.065,
      end: 112.305,
      confidence: 0.99991786,
      punctuated_word: "view",
    },
    {
      word: "has",
      start: 112.305,
      end: 112.465004,
      confidence: 0.9997105,
      punctuated_word: "has",
    },
    {
      word: "been",
      start: 112.465004,
      end: 112.965004,
      confidence: 0.9999256,
      punctuated_word: "been",
    },
    {
      word: "let",
      start: 113.025,
      end: 113.265,
      confidence: 0.56528705,
      punctuated_word: "let",
    },
    {
      word: "a",
      start: 113.265,
      end: 113.345,
      confidence: 0.9891349,
      punctuated_word: "a",
    },
    {
      word: "1,000",
      start: 113.345,
      end: 113.825005,
      confidence: 0.8569401,
      punctuated_word: "1,000",
    },
    {
      word: "flowers",
      start: 113.825005,
      end: 114.305,
      confidence: 0.97893685,
      punctuated_word: "flowers",
    },
    {
      word: "bloom",
      start: 114.305,
      end: 114.805,
      confidence: 0.90598166,
      punctuated_word: "bloom.",
    },
    {
      word: "we",
      start: 115.025,
      end: 115.265,
      confidence: 0.9986059,
      punctuated_word: "We",
    },
    {
      word: "don't",
      start: 115.265,
      end: 115.585,
      confidence: 0.9999535,
      punctuated_word: "don't",
    },
    {
      word: "internally",
      start: 115.585,
      end: 116.085,
      confidence: 0.9971787,
      punctuated_word: "internally",
    },
    {
      word: "have",
      start: 116.385,
      end: 116.885,
      confidence: 0.99953425,
      punctuated_word: "have",
    },
    {
      word: "the",
      start: 118.27,
      end: 118.35,
      confidence: 0.99981374,
      punctuated_word: "the",
    },
    {
      word: "the",
      start: 118.35,
      end: 118.67,
      confidence: 0.93266,
      punctuated_word: "the,",
    },
    {
      word: "you",
      start: 118.67,
      end: 118.75,
      confidence: 0.998744,
      punctuated_word: "you",
    },
    {
      word: "know",
      start: 118.75,
      end: 118.99,
      confidence: 0.99963,
      punctuated_word: "know,",
    },
    {
      word: "the",
      start: 118.99,
      end: 119.15,
      confidence: 0.9972222,
      punctuated_word: "the",
    },
    {
      word: "resources",
      start: 119.15,
      end: 119.63,
      confidence: 0.9939805,
      punctuated_word: "resources",
    },
    {
      word: "to",
      start: 119.63,
      end: 119.87,
      confidence: 0.99931026,
      punctuated_word: "to",
    },
    {
      word: "trial",
      start: 119.87,
      end: 120.19,
      confidence: 0.7184639,
      punctuated_word: "trial",
    },
    {
      word: "these",
      start: 120.19,
      end: 120.43,
      confidence: 0.8696013,
      punctuated_word: "these",
    },
    {
      word: "different",
      start: 120.43,
      end: 120.75,
      confidence: 0.9997873,
      punctuated_word: "different",
    },
    {
      word: "things",
      start: 120.75,
      end: 121.229996,
      confidence: 0.99269843,
      punctuated_word: "things.",
    },
    {
      word: "let's",
      start: 121.229996,
      end: 121.55,
      confidence: 0.9995246,
      punctuated_word: "Let's",
    },
    {
      word: "let",
      start: 121.55,
      end: 121.71,
      confidence: 0.9996736,
      punctuated_word: "let",
    },
    {
      word: "our",
      start: 121.71,
      end: 121.87,
      confidence: 0.9995753,
      punctuated_word: "our",
    },
    {
      word: "customers",
      start: 121.87,
      end: 122.35,
      confidence: 0.9996445,
      punctuated_word: "customers",
    },
    {
      word: "try",
      start: 122.35,
      end: 122.51,
      confidence: 0.9997049,
      punctuated_word: "try",
    },
    {
      word: "it",
      start: 122.51,
      end: 122.99,
      confidence: 0.8638223,
      punctuated_word: "it.",
    },
    {
      word: "and",
      start: 123.87,
      end: 124.03,
      confidence: 0.9800402,
      punctuated_word: "And,",
    },
    {
      word: "you",
      start: 124.03,
      end: 124.19,
      confidence: 0.9969247,
      punctuated_word: "you",
    },
    {
      word: "know",
      start: 124.19,
      end: 124.27,
      confidence: 0.9999692,
      punctuated_word: "know,",
    },
    {
      word: "we'll",
      start: 124.27,
      end: 124.59,
      confidence: 0.9997697,
      punctuated_word: "we'll",
    },
    {
      word: "see",
      start: 124.59,
      end: 124.67,
      confidence: 0.99961483,
      punctuated_word: "see",
    },
    {
      word: "who",
      start: 124.67,
      end: 124.909996,
      confidence: 0.9997098,
      punctuated_word: "who",
    },
    {
      word: "succeeds",
      start: 124.909996,
      end: 125.229996,
      confidence: 0.7701503,
      punctuated_word: "succeeds,",
    },
    {
      word: "and",
      start: 125.229996,
      end: 125.47,
      confidence: 0.9994361,
      punctuated_word: "and",
    },
    {
      word: "maybe",
      start: 125.47,
      end: 125.63,
      confidence: 0.9993309,
      punctuated_word: "maybe",
    },
    {
      word: "different",
      start: 125.63,
      end: 126.03,
      confidence: 0.99983954,
      punctuated_word: "different",
    },
    {
      word: "customers",
      start: 126.03,
      end: 126.43,
      confidence: 0.9994949,
      punctuated_word: "customers",
    },
    {
      word: "will",
      start: 126.43,
      end: 126.59,
      confidence: 0.99970275,
      punctuated_word: "will",
    },
    {
      word: "succeed",
      start: 126.59,
      end: 126.83,
      confidence: 0.99954,
      punctuated_word: "succeed",
    },
    {
      word: "in",
      start: 126.83,
      end: 126.99,
      confidence: 0.9995777,
      punctuated_word: "in",
    },
    {
      word: "different",
      start: 126.99,
      end: 127.229996,
      confidence: 0.9992735,
      punctuated_word: "different",
    },
    {
      word: "ways",
      start: 127.229996,
      end: 127.729996,
      confidence: 0.9986489,
      punctuated_word: "ways.",
    },
    {
      word: "so",
      start: 128.35,
      end: 128.83,
      confidence: 0.9756055,
      punctuated_word: "So",
    },
    {
      word: "i",
      start: 128.83,
      end: 128.95,
      confidence: 0.99747616,
      punctuated_word: "I",
    },
    {
      word: "i",
      start: 128.95,
      end: 129.06999,
      confidence: 0.6640715,
      punctuated_word: "I",
    },
    {
      word: "both",
      start: 129.06999,
      end: 129.31,
      confidence: 0.99038345,
      punctuated_word: "both",
    },
    {
      word: "think",
      start: 129.31,
      end: 129.55,
      confidence: 0.99958307,
      punctuated_word: "think",
    },
    {
      word: "this",
      start: 129.55,
      end: 129.70999,
      confidence: 0.9996656,
      punctuated_word: "this",
    },
    {
      word: "is",
      start: 129.70999,
      end: 129.87,
      confidence: 0.998733,
      punctuated_word: "is",
    },
    {
      word: "super",
      start: 129.87,
      end: 130.11,
      confidence: 0.9998628,
      punctuated_word: "super",
    },
    {
      word: "promising",
      start: 130.11,
      end: 130.61,
      confidence: 0.8846637,
      punctuated_word: "promising,",
    },
    {
      word: "and",
      start: 130.75,
      end: 131.25,
      confidence: 0.98257005,
      punctuated_word: "and,",
    },
    {
      word: "you",
      start: 131.375,
      end: 131.455,
      confidence: 0.99947125,
      punctuated_word: "you",
    },
    {
      word: "know",
      start: 131.455,
      end: 131.615,
      confidence: 0.9999156,
      punctuated_word: "know,",
    },
    {
      word: "it's",
      start: 131.615,
      end: 131.775,
      confidence: 0.9998499,
      punctuated_word: "it's",
    },
    {
      word: "not",
      start: 131.775,
      end: 132.095,
      confidence: 0.9995795,
      punctuated_word: "not",
    },
    {
      word: "it's",
      start: 132.095,
      end: 132.255,
      confidence: 0.9995161,
      punctuated_word: "it's",
    },
    {
      word: "not",
      start: 132.255,
      end: 132.415,
      confidence: 0.99984956,
      punctuated_word: "not",
    },
    {
      word: "it's",
      start: 132.415,
      end: 132.575,
      confidence: 0.9987445,
      punctuated_word: "it's",
    },
    {
      word: "not",
      start: 132.575,
      end: 132.895,
      confidence: 0.9994822,
      punctuated_word: "not",
    },
    {
      word: "something",
      start: 132.895,
      end: 133.395,
      confidence: 0.8457218,
      punctuated_word: "something,",
    },
    {
      word: "you",
      start: 133.69499,
      end: 133.775,
      confidence: 0.9990262,
      punctuated_word: "you",
    },
    {
      word: "know",
      start: 133.775,
      end: 134.015,
      confidence: 0.9920624,
      punctuated_word: "know,",
    },
    {
      word: "anthropic",
      start: 134.015,
      end: 134.515,
      confidence: 0.8097627,
      punctuated_word: "anthropic",
    },
    {
      word: "isn't",
      start: 134.575,
      end: 134.895,
      confidence: 0.9948287,
      punctuated_word: "isn't",
    },
    {
      word: "isn't",
      start: 134.895,
      end: 135.215,
      confidence: 0.99932575,
      punctuated_word: "isn't",
    },
    {
      word: "eager",
      start: 135.215,
      end: 135.61499,
      confidence: 0.9994727,
      punctuated_word: "eager",
    },
    {
      word: "to",
      start: 135.61499,
      end: 135.855,
      confidence: 0.9996623,
      punctuated_word: "to",
    },
    {
      word: "to",
      start: 135.855,
      end: 136.175,
      confidence: 0.95272607,
      punctuated_word: "to,",
    },
    {
      word: "at",
      start: 136.175,
      end: 136.33499,
      confidence: 0.9997596,
      punctuated_word: "at",
    },
    {
      word: "least",
      start: 136.33499,
      end: 136.575,
      confidence: 0.9995414,
      punctuated_word: "least",
    },
    {
      word: "right",
      start: 136.575,
      end: 136.735,
      confidence: 0.9763466,
      punctuated_word: "right",
    },
    {
      word: "now",
      start: 136.735,
      end: 136.97499,
      confidence: 0.9994972,
      punctuated_word: "now,",
    },
    {
      word: "compete",
      start: 136.97499,
      end: 137.295,
      confidence: 0.99916935,
      punctuated_word: "compete",
    },
    {
      word: "with",
      start: 137.295,
      end: 137.455,
      confidence: 0.9998598,
      punctuated_word: "with",
    },
    {
      word: "all",
      start: 137.455,
      end: 137.61499,
      confidence: 0.99892586,
      punctuated_word: "all",
    },
    {
      word: "our",
      start: 137.61499,
      end: 137.69499,
      confidence: 0.998309,
      punctuated_word: "our",
    },
    {
      word: "companies",
      start: 137.69499,
      end: 138.095,
      confidence: 0.99968314,
      punctuated_word: "companies",
    },
    {
      word: "in",
      start: 138.095,
      end: 138.175,
      confidence: 0.9991904,
      punctuated_word: "in",
    },
    {
      word: "this",
      start: 138.175,
      end: 138.33499,
      confidence: 0.9200773,
      punctuated_word: "this",
    },
    {
      word: "space",
      start: 138.33499,
      end: 138.655,
      confidence: 0.99974054,
      punctuated_word: "space",
    },
    {
      word: "and",
      start: 138.655,
      end: 138.815,
      confidence: 0.92791903,
      punctuated_word: "and",
    },
    {
      word: "maybe",
      start: 138.815,
      end: 139.055,
      confidence: 0.9995091,
      punctuated_word: "maybe",
    },
    {
      word: "never",
      start: 139.055,
      end: 139.535,
      confidence: 0.99922943,
      punctuated_word: "never.",
    },
    {
      word: "yeah",
      start: 139.535,
      end: 139.69499,
      confidence: 0.9989363,
      punctuated_word: "Yeah.",
    },
    {
      word: "it's",
      start: 139.69499,
      end: 139.855,
      confidence: 0.9967904,
      punctuated_word: "It's",
    },
    {
      word: "been",
      start: 139.855,
      end: 140.015,
      confidence: 0.99953294,
      punctuated_word: "been",
    },
    {
      word: "interesting",
      start: 140.015,
      end: 140.33499,
      confidence: 0.998367,
      punctuated_word: "interesting",
    },
    {
      word: "to",
      start: 140.33499,
      end: 140.495,
      confidence: 0.9990483,
      punctuated_word: "to",
    },
    {
      word: "watch",
      start: 140.495,
      end: 140.735,
      confidence: 0.9998122,
      punctuated_word: "watch",
    },
    {
      word: "cursor",
      start: 140.735,
      end: 141.055,
      confidence: 0.6377346,
      punctuated_word: "Cursor",
    },
    {
      word: "try",
      start: 141.055,
      end: 141.215,
      confidence: 0.9281438,
      punctuated_word: "try",
    },
    {
      word: "to",
      start: 141.215,
      end: 141.375,
      confidence: 0.9910637,
      punctuated_word: "to",
    },
    {
      word: "integrate",
      start: 141.375,
      end: 141.775,
      confidence: 0.9986125,
      punctuated_word: "integrate",
    },
    {
      word: "clos",
      start: 141.775,
      end: 142.015,
      confidence: 0.43092847,
      punctuated_word: "Clos",
    },
    {
      word: "successfully",
      start: 142.015,
      end: 142.495,
      confidence: 0.9825721,
      punctuated_word: "successfully",
    },
    {
      word: "because",
      start: 142.495,
      end: 142.735,
      confidence: 0.8022374,
      punctuated_word: "because",
    },
    {
      word: "there's",
      start: 142.735,
      end: 142.97499,
      confidence: 0.9995394,
      punctuated_word: "there's",
    },
    {
      word: "it's",
      start: 143.055,
      end: 143.295,
      confidence: 0.99970603,
      punctuated_word: "it's",
    },
    {
      word: "actually",
      start: 143.295,
      end: 143.795,
      confidence: 0.98971856,
      punctuated_word: "actually",
    },
    {
      word: "been",
      start: 144.5,
      end: 144.9,
      confidence: 0.703366,
      punctuated_word: "been",
    },
    {
      word: "fascinating",
      start: 144.9,
      end: 145.37999,
      confidence: 0.9916323,
      punctuated_word: "fascinating",
    },
    {
      word: "how",
      start: 145.37999,
      end: 145.62,
      confidence: 0.98619616,
      punctuated_word: "how",
    },
    {
      word: "many",
      start: 145.62,
      end: 145.85999,
      confidence: 0.9996455,
      punctuated_word: "many",
    },
    {
      word: "places",
      start: 145.85999,
      end: 146.18,
      confidence: 0.9995633,
      punctuated_word: "places",
    },
    {
      word: "it",
      start: 146.18,
      end: 146.34,
      confidence: 0.99414104,
      punctuated_word: "it",
    },
    {
      word: "can",
      start: 146.34,
      end: 146.5,
      confidence: 0.99994767,
      punctuated_word: "can",
    },
    {
      word: "help",
      start: 146.5,
      end: 146.81999,
      confidence: 0.99969923,
      punctuated_word: "help",
    },
    {
      word: "the",
      start: 146.81999,
      end: 147.06,
      confidence: 0.9874743,
      punctuated_word: "the",
    },
    {
      word: "programming",
      start: 147.06,
      end: 147.54,
      confidence: 0.9960276,
      punctuated_word: "programming",
    },
    {
      word: "experience",
      start: 147.54,
      end: 147.93999,
      confidence: 0.99267596,
      punctuated_word: "experience.",
    },
    {
      word: "it's",
      start: 147.93999,
      end: 148.09999,
      confidence: 0.9923531,
      punctuated_word: "It's",
    },
    {
      word: "not",
      start: 148.09999,
      end: 148.26,
      confidence: 0.9994825,
      punctuated_word: "not",
    },
    {
      word: "as",
      start: 148.26,
      end: 148.42,
      confidence: 0.9992918,
      punctuated_word: "as",
    },
    {
      word: "trivial",
      start: 148.42,
      end: 148.81999,
      confidence: 0.9810165,
      punctuated_word: "trivial.",
    },
    {
      word: "it",
      start: 148.81999,
      end: 148.98,
      confidence: 0.98339635,
      punctuated_word: "It",
    },
    {
      word: "is",
      start: 148.98,
      end: 149.21999,
      confidence: 0.99612147,
      punctuated_word: "is",
    },
    {
      word: "it",
      start: 149.21999,
      end: 149.29999,
      confidence: 0.9560674,
      punctuated_word: "it",
    },
    {
      word: "is",
      start: 149.29999,
      end: 149.45999,
      confidence: 0.99974114,
      punctuated_word: "is",
    },
    {
      word: "really",
      start: 149.45999,
      end: 149.7,
      confidence: 0.999416,
      punctuated_word: "really",
    },
    {
      word: "astounding",
      start: 149.7,
      end: 150.2,
      confidence: 0.9997208,
      punctuated_word: "astounding.",
    },
    {
      word: "i",
      start: 150.5,
      end: 150.65999,
      confidence: 0.99944264,
      punctuated_word: "I",
    },
    {
      word: "feel",
      start: 150.65999,
      end: 150.81999,
      confidence: 0.9998456,
      punctuated_word: "feel",
    },
    {
      word: "like",
      start: 150.81999,
      end: 151.06,
      confidence: 0.9432194,
      punctuated_word: "like,",
    },
    {
      word: "you",
      start: 151.06,
      end: 151.21999,
      confidence: 0.9997335,
      punctuated_word: "you",
    },
    {
      word: "know",
      start: 151.21999,
      end: 151.37999,
      confidence: 0.99982226,
      punctuated_word: "know,",
    },
    {
      word: "as",
      start: 151.37999,
      end: 151.54,
      confidence: 0.9998388,
      punctuated_word: "as",
    },
    {
      word: "a",
      start: 151.54,
      end: 151.62,
      confidence: 0.99741745,
      punctuated_word: "a",
    },
    {
      word: "ceo",
      start: 151.62,
      end: 151.93999,
      confidence: 0.9987217,
      punctuated_word: "CEO,",
    },
    {
      word: "i",
      start: 151.93999,
      end: 152.01999,
      confidence: 0.99991643,
      punctuated_word: "I",
    },
    {
      word: "don't",
      start: 152.01999,
      end: 152.26,
      confidence: 0.9999755,
      punctuated_word: "don't",
    },
    {
      word: "get",
      start: 152.26,
      end: 152.42,
      confidence: 0.99978274,
      punctuated_word: "get",
    },
    {
      word: "to",
      start: 152.42,
      end: 152.5,
      confidence: 0.9969702,
      punctuated_word: "to",
    },
    {
      word: "program",
      start: 152.5,
      end: 152.9,
      confidence: 0.9996984,
      punctuated_word: "program",
    },
    {
      word: "that",
      start: 152.9,
      end: 153.06,
      confidence: 0.9997527,
      punctuated_word: "that",
    },
    {
      word: "much",
      start: 153.06,
      end: 153.29999,
      confidence: 0.8146239,
      punctuated_word: "much,",
    },
    {
      word: "and",
      start: 153.29999,
      end: 153.45999,
      confidence: 0.99966383,
      punctuated_word: "and",
    },
    {
      word: "i",
      start: 153.45999,
      end: 153.54,
      confidence: 0.9996824,
      punctuated_word: "I",
    },
    {
      word: "feel",
      start: 153.54,
      end: 153.78,
      confidence: 0.9999211,
      punctuated_word: "feel",
    },
    {
      word: "like",
      start: 153.78,
      end: 154.26,
      confidence: 0.9825952,
      punctuated_word: "like",
    },
    {
      word: "if",
      start: 154.26,
      end: 154.5,
      confidence: 0.94854057,
      punctuated_word: "if",
    },
    {
      word: "6",
      start: 154.5,
      end: 154.73999,
      confidence: 0.97532517,
      punctuated_word: "6",
    },
    {
      word: "months",
      start: 154.73999,
      end: 154.9,
      confidence: 0.9991037,
      punctuated_word: "months",
    },
    {
      word: "from",
      start: 154.9,
      end: 155.14,
      confidence: 0.99974364,
      punctuated_word: "from",
    },
    {
      word: "now",
      start: 155.14,
      end: 155.29999,
      confidence: 0.99979097,
      punctuated_word: "now",
    },
    {
      word: "i",
      start: 155.29999,
      end: 155.45999,
      confidence: 0.70108485,
      punctuated_word: "I",
    },
    {
      word: "go",
      start: 155.45999,
      end: 155.62,
      confidence: 0.9996062,
      punctuated_word: "go",
    },
    {
      word: "back",
      start: 155.62,
      end: 155.78,
      confidence: 0.9988061,
      punctuated_word: "back,",
    },
    {
      word: "it'll",
      start: 155.78,
      end: 156.01999,
      confidence: 0.99913836,
      punctuated_word: "it'll",
    },
    {
      word: "be",
      start: 156.01999,
      end: 156.18,
      confidence: 0.9996724,
      punctuated_word: "be",
    },
    {
      word: "completely",
      start: 156.18,
      end: 156.57999,
      confidence: 0.99984324,
      punctuated_word: "completely",
    },
    {
      word: "unrecognizable",
      start: 156.57999,
      end: 157.07999,
      confidence: 0.99741435,
      punctuated_word: "unrecognizable",
    },
    {
      word: "to",
      start: 157.37999,
      end: 157.45999,
      confidence: 0.99442977,
      punctuated_word: "to",
    },
    {
      word: "me",
      start: 157.45999,
      end: 157.62,
      confidence: 0.9888295,
      punctuated_word: "me.",
    },
    {
      word: "exactly",
      start: 157.62,
      end: 158.12,
      confidence: 0.99872696,
      punctuated_word: "Exactly.",
    },
  ],
  text: [
    {
      sentences: [
        {
          text: "And I wonder what the future of IDEs looks like.",
          start: 2.8,
          end: 4.96,
        },
        {
          text: "So the tooling of interacting with AI systems, this is true for programming and also probably true for in other contexts, like computer use, but maybe domain specific.",
          start: 4.96,
          end: 13.2,
        },
        {
          text: "Like, we mentioned biology.",
          start: 13.2,
          end: 14.42,
        },
        {
          text: "It probably needs its own tooling about how to be effective, and then programming needs its own tooling.",
          start: 14.639999,
          end: 19.655,
        },
        {
          text: "Is anthropic gonna play in that space of also tooling potentially?",
          start: 19.955,
          end: 22.935,
        },
      ],
      num_words: 71,
      start: 2.8,
      end: 22.935,
    },
    {
      sentences: [
        {
          text: "I'm absolutely convinced that, powerful IDEs, that that there's so much low hanging fruit to be grabbed there, that, you know, right now, it's just like you talk to the model and it talks back.",
          start: 22.994999,
          end: 36.629997,
        },
        {
          text: "But but look.",
          start: 36.629997,
          end: 37.91,
        },
        {
          text: "I mean, IDs are great at kind of lots of static analysis of of, you know, so much is possible with kind of static analysis, like many bugs you can find without even writing the code, then, you know, IDs are good for running particular things, organizing your code, measuring coverage of unit tests.",
          start: 37.91,
          end: 57.824997,
        },
        {
          text: "Like, there's so much that's been possible with a normal I with a normal IDs.",
          start: 57.824997,
          end: 61.96,
        },
        {
          text: "Now you add something like, well, the model now, you know, the model can now, like, write code and run code.",
          start: 62.420002,
          end: 69.48,
        },
      ],
      num_words: 128,
      start: 22.994999,
      end: 69.48,
    },
    {
      sentences: [
        {
          text: "Like, I am absolutely convinced that over the next year or 2, even if the quality of the models didn't improve, that there would be enormous opportunity to enhance people's productivity by catching a bunch of mistakes, doing a bunch of grunt work for people, and that we haven't even scratched the surface.",
          start: 69.62,
          end: 85.305,
        },
        {
          text: "Anthropic itself, I mean, you can't say, you know, no.",
          start: 85.845,
          end: 88.665,
        },
        {
          text: "You know, it's hard to say what will happen in the future.",
          start: 90,
          end: 92.18,
        },
        {
          text: "Currently, we're not trying to make such IDs ourself, rather we're powering the companies like Cursor or like Cognition or some of the other, you know, expo in the security space.",
          start: 92.32,
          end: 104.26,
        },
        {
          text: "You know, others that I can mention as well that are building such things themselves on top of our API.",
          start: 104.385,
          end: 111.665,
        },
      ],
      num_words: 126,
      start: 69.62,
      end: 111.665,
    },
    {
      sentences: [
        {
          text: "And our view has been let a 1,000 flowers bloom.",
          start: 111.665,
          end: 114.805,
        },
        {
          text: "We don't internally have the the, you know, the resources to trial these different things.",
          start: 115.025,
          end: 121.229996,
        },
        {
          text: "Let's let our customers try it.",
          start: 121.229996,
          end: 122.99,
        },
        {
          text: "And, you know, we'll see who succeeds, and maybe different customers will succeed in different ways.",
          start: 123.87,
          end: 127.729996,
        },
        {
          text: "So I I both think this is super promising, and, you know, it's not it's not it's not something, you know, anthropic isn't isn't eager to to, at least right now, compete with all our companies in this space and maybe never.",
          start: 128.35,
          end: 139.535,
        },
      ],
      num_words: 89,
      start: 111.665,
      end: 139.535,
    },
    {
      sentences: [
        {
          text: "Yeah.",
          start: 139.535,
          end: 139.69499,
        },
        {
          text: "It's been interesting to watch Cursor try to integrate Clos successfully because there's it's actually been fascinating how many places it can help the programming experience.",
          start: 139.69499,
          end: 147.93999,
        },
        {
          text: "It's not as trivial.",
          start: 147.93999,
          end: 148.81999,
        },
        {
          text: "It is it is really astounding.",
          start: 148.81999,
          end: 150.2,
        },
        {
          text: "I feel like, you know, as a CEO, I don't get to program that much, and I feel like if 6 months from now I go back, it'll be completely unrecognizable to me.",
          start: 150.5,
          end: 157.62,
        },
      ],
      num_words: 70,
      start: 139.535,
      end: 157.62,
    },
    {
      sentences: [
        {
          text: "Exactly.",
          start: 157.62,
          end: 158.12,
        },
      ],
      num_words: 1,
      start: 157.62,
      end: 158.12,
    },
  ],
};

const previewTextData = {
  words: [
    {
      word: "the",
      start: 0,
      end: 0.4,
      confidence: 0.9803699,
      punctuated_word: "The",
    },
    {
      word: "quick",
      start: 0.4,
      end: 0.8,
      confidence: 0.9803699,
      punctuated_word: "quick",
    },
    {
      word: "brown",
      start: 0.8,
      end: 1.2,
      confidence: 0.9803699,
      punctuated_word: "brown",
    },
    {
      word: "fox",
      start: 1.2,
      end: 1.6,
      confidence: 0.9803699,
      punctuated_word: "fox",
    },
    {
      word: "jumps",
      start: 1.6,
      end: 2.0,
      confidence: 0.9803699,
      punctuated_word: "jumps",
    },
    {
      word: "over",
      start: 2.0,
      end: 2.4,
      confidence: 0.9803699,
      punctuated_word: "over",
    },
    {
      word: "the",
      start: 2.4,
      end: 2.8,
      confidence: 0.9803699,
      punctuated_word: "the",
    },
    {
      word: "lazy",
      start: 2.8,
      end: 3.2,
      confidence: 0.9803699,
      punctuated_word: "lazy",
    },
    {
      word: "dog",
      start: 3.2,
      end: 3.6,
      confidence: 0.9803699,
      punctuated_word: "dog",
    },
  ],
};

function triggerRender(captionID, captionSettings) {
  const projectData = {
    startTime: 0,
    endTime: 175,
  };

  const context = {
    projectInfo: projectData,
    captionInfo: {
      id: captionID,
      config: captionSettings,
      access: true,
      tags: null,
      userId: "ORXANU5lHMWG4tz1uszuhaWqWXl2",
      creatorId: "neuro123",
      created: null,
      featured: false,
      score: 1,
    },
    transcript: previewTextData,
    renderMode: "caption-preview",
    timelineInfo: [],
  };

  const data = {
    payload: context,
    dims: { height: 405, width: 720 },
    totalFrameCount: 80,
    captionId: captionID,
  };

  console.log("DATA: ", data);
}

export { textData, previewTextData, triggerRender };
