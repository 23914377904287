export const defaultStyle = {
  featured: false,
  score: 1,
  access: true,
  title: 41512361,
  id: "546821db-cdb1-b69c-e9d5-1734d4202223",
  userId: "ORXANU5lHMWG4tz1uszuhaWqWXl2",
  config: {
    text: {
      options: {
        fill: "#ffffff",
        fontSize: 30,
        lineSpacing: 5,
        case: "sentence",
        fontWeight: 800,
        shadowInfo: {
          offsetY: 5,
          blur: 10,
          offsetX: 5,
          fill: "#000",
        },
        scale: 1,
        fontFamily: "Raleway",
        fontStyle: "normal",
      },
      boxOptions: null,
      anim: null,
      lines: null,
    },
    lineCount: 1,
    charCount: 10,
    activeText: {
      lines: null,
      words: null,
    },
  },
  presetUrl:
    "https://res.cloudinary.com/dlmsxlf2n/image/upload/v1735323681/Diveo/Assets/caption-presets/wlvdvcwb5vi4sz87gg3x.gif",
  created: {
    nanoseconds: 288000000,
    seconds: 1735323670,
  },
  creatorId: "Diveo Creative",
  tags: [],
};
