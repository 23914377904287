import React, { useState, useEffect, useRef, useCallback } from 'react'
import "../../css/captions.css";
import { useDispatch, useSelector } from 'react-redux'

import EnableSwitch from '../ui/EnableSwitch';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LineSettings from './LineSettings';
import TextSettings from './TextSettings';
import ActiveTextSettings from './ActiveTextSettings';
import useEffectOnChange from '../../hooks/useEffectOnChange';

import { updateCaptionInfo, setRefreshCaptionState, setCaptionConfigModalActive, setCaptionCollectionModalActive, setCaptionBtnLock } from '../../redux/projectSlice';
import FormatSettings from './FormatSettings';
import { areObjectsEqual } from '../../utils/commonUtils';
import Chevron from '../ui/Chevron';

const CaptionSettings = ({ captionRef }) => {
    const dispatch = useDispatch()

    const captionInfo = useSelector(
        (state) => state.projectReducer.captionInfo
    )

    const [captionConfig, setCaptionConfig] = useState()

    const captionBtnLock = useSelector(
        (state) => state.projectReducer.captionBtnLock
    )

    const refreshCaptionState = useSelector(
        (state) => state.projectReducer.refreshCaptionState
    )

    const isInternalUpdate = useRef(false);

    const prevCaptionIdRef = useRef(captionInfo.id);
    const prevCaptionConfigRef = useRef(captionInfo.config);

    const lineCountRef = useRef();
    const charCountRef = useRef();

    const [captionSettings, setCaptionSettings] = useState(captionInfo?.config)
    const [formatOptions, setFormatOptions] = useState(null)
    const [textSettings, setTextSettings] = useState(null)
    const [lineSettings, setLineSettings] = useState(null)
    const [activeTextSettings, setActiveTextSettings] = useState(null)

    const [enabler, setEnabler] = useState({ lines: false })

    useEffect(() => {
        dispatch(setCaptionBtnLock({isActive: false}))
    }, [])


    useEffect(() => {
        console.log('Caption Settings <-')
        setCaptionConfig(captionInfo.config)
        dispatch(setRefreshCaptionState({ flag: true }));
    }, [])

    useEffect(() => {
       
        if(prevCaptionIdRef.current != captionInfo.id){
           
            isInternalUpdate.current = false;
            console.log('prevCaptionIdRef.current ', prevCaptionIdRef.current)
            console.log('CAPTION STYlE UPDATED !!! -<')
            console.log('CAPTION ID: ', captionInfo.id)
            prevCaptionIdRef.current = captionInfo.id;
            if(captionRef.current){
                captionRef.current.updateSettings(captionConfig)
            }
            setCaptionConfig(captionInfo.config);
            setCaptionSettings(captionInfo.config);

            dispatch(setRefreshCaptionState({ flag: true }));
        }
    }, [captionInfo])

    useEffect(() => {
        if (captionConfig && refreshCaptionState) {
            console.log('REFRESH CAPTION STATE:: CAPTION SETTINGS ->  ')
            console.log('REFRESH CAPTIONS ', captionInfo.config)
            const caption = captionInfo.config;

            isInternalUpdate.current = false;
            setTextSettings(caption.text);

            setLineSettings(caption.text?.lines)

            setFormatOptions({
                id: captionInfo.id,
                title: captionInfo.title,
                access: captionInfo.access,
                userId: captionInfo.userId,
                lineCount: caption.lineCount ?? 1, 
                charCount: caption.charCount ?? 15,
                topPos: caption.topPos ?? 280
            })
            lineCountRef.current = caption.lineCount ?? 1;
            charCountRef.current = caption.charCount ?? 15;

            if (caption.activeText) {
                console.log('SETTING ACTIVE TEXT SETTIGNS: ', caption.activeText)
                setActiveTextSettings(caption.activeText)
            } else {
                setActiveTextSettings({})
            }
            
            setEnabler({
                lines: caption.text?.lines ? true : false
            })

            dispatch(setRefreshCaptionState({ flag: false }));

        }

    }, [refreshCaptionState])


    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            if(prevCaptionConfigRef.current != captionSettings){
                console.log('CAPTION SETTING N: ', captionSettings)
                console.log('CAP REF:: ', captionRef.current)
                const captionConfig = captionInfo?.captionConfig;
                if (captionRef.current) {
                    console.log('CAPTN OBJ:: ', captionRef.current.getTextObject())
                    captionRef.current.updateSettings(captionSettings)
                    
                    if(!areObjectsEqual(captionSettings, captionConfig)){
                        setCaptionConfig(captionSettings);
                        dispatch(setCaptionBtnLock({ isActive: false }))
                    }
                }

                prevCaptionConfigRef.current = captionSettings;
                const updatedCaptionInfo = { ...captionInfo, config: captionSettings}
                dispatch(updateCaptionInfo({ captionInfo: updatedCaptionInfo }))
        
            }
        }
    }, [captionSettings])
    
    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            console.log('CHANGING FORMAT OPTIONS')
            var newLineSettings;
            var newActiveLineSettings;
            if(lineCountRef.current < formatOptions?.lineCount){
                // Value Increased:: Remove delta 'n' lines from lineSettings
                var lineCountDiff = formatOptions?.lineCount - lineCountRef.current;
    
                newLineSettings = lineSettings ? [...lineSettings] : null;
                newActiveLineSettings = activeTextSettings?.lines ? [...activeTextSettings.lines] : null;
                for(let i=0; i < lineCountDiff ; i++){
                    var lineOptions = getInitialisedLineOptions();
                    if(newLineSettings){
                        newLineSettings.push(lineOptions);
                    }
                    
                    if(newActiveLineSettings){
                        newActiveLineSettings.push(lineOptions);
                    }
                }
                
            }else if(lineCountRef.current > formatOptions?.lineCount){
                // Value Reduced:: Add delta 'n' lines to lineSettings, inherit properties from textSettings
                console.log('LINE SETTINGS: ', lineSettings)
                newLineSettings = lineSettings ? lineSettings.slice(0, formatOptions?.lineCount) : null;
                newActiveLineSettings = activeTextSettings?.lines ? activeTextSettings.lines.slice(0, formatOptions?.lineCount) : null
            }
    
            var activeTextSettingsTmp = {
                ...activeTextSettings,
                lines: newActiveLineSettings,
            }
           
            const captionSettingsTmp = {
                ...captionSettings,
                lineCount: formatOptions?.lineCount,
                text: {
                    ...textSettings,
                    lines: newLineSettings
                },
                activeText: activeTextSettingsTmp
            }
    
            if(captionRef.current){
                // captionRef.current.updateSettings(captionSettingsTmp);
               
                lineCountRef.current = formatOptions?.lineCount ?? 1;
    
                console.log('FORMAT CHANGE:: UPDATING LINE SETTINGS')
                updateActiveTextSettings(activeTextSettingsTmp);
                updateLineSettings(newLineSettings);
    
                console.log('FORMAT CHANGE:: UPDATING LINE COUNT')           
    
                if(!lineSettings){
                    setCaptionSettings(prevValue => ({
                        ...prevValue,
                        lineCount: formatOptions?.lineCount ?? 1,
                    }));
                }
            }
        }
       
    }, [formatOptions?.lineCount])

    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            console.log('CHANGING FORMAT OPTIONS')
            setCaptionSettings(prevValue => ({
                ...prevValue,
                charCount: formatOptions?.charCount ?? 15
            }));
            charCountRef.current = formatOptions?.charCount ?? 15;
        }
        
    }, [formatOptions?.charCount])

    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            console.log('CHANGING FORMAT OPTIONS::TOP POS')
            setCaptionSettings(prevValue => ({
                ...prevValue,
                topPos: formatOptions?.topPos ?? 15
            }));
        }
    }, [formatOptions?.topPos])

    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            console.log('TEXT EFFECT')
            console.log('CAPTION SETTINGs: ', captionSettings)
    
            const newCaptionSettings1 = { ...captionSettings, text: textSettings }
            console.log('UPDATING CAPTION SETTINGS: ', newCaptionSettings1)
            setCaptionSettings(prevValue => ({
                ...prevValue,
                text: textSettings,
                lineCount: formatOptions?.lineCount ?? 1,
            }));
        }
        lineCountRef.current = formatOptions?.lineCount ?? 1;
    }, [textSettings])

    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            console.log('LINE EFFECT!! ')
            if (!enabler.lines && lineSettings) {
                setEnabler({ lines: true })
            }
            const newTextSettings = { ...textSettings, lines: lineSettings }
            console.log('UPDATING TEXT SETTINGS: ', newTextSettings)
    
            updateTextSettings(prevValue => ({
                ...prevValue,
                lines: lineSettings,
            }))
        }
      
    }, [lineSettings])

    useEffectOnChange(() => {
        if(isInternalUpdate.current){
            console.log('CAPTION SETTINGS:ACTIVE: ', captionSettings)
            const newCaptionSettings = { ...captionSettings, activeText: activeTextSettings }
            console.log('UPDATING CAPTION SETTINGS::ACTIVE ', newCaptionSettings)
            setCaptionSettings(prevValue => ({
                ...prevValue,
                activeText: activeTextSettings
            }));
        }
       
    }, [activeTextSettings])


    useEffectOnChange(() => {
        console.log('ENABLER: ', enabler)
        console.log('LINE S: ', lineSettings)
        if (!lineSettings && enabler.lines) {
            initialiseLineSettings()
        }

        if (!enabler.lines) {
            console.log('Disabling line settings!')
            updateLineSettings(null)
        }
    }, [enabler])

    
    function getInitialisedLineOptions() {
        var lineOptions = {
            options: {},
            boxOptions: null,
            anim: null,
        };
        var lineStyleOptions;
        const textOptions = textSettings.options;
        if (textOptions) {
            lineStyleOptions = {
                fontFamily: textOptions.fontFamily,
                fontWeight: textOptions.fontWeight,
                fontStyle: textOptions.fontStyle,
                case: textOptions.case,
                fill: textOptions.fill,
                wordSpacing: 5,
                opacity: 1
            };
            lineOptions['options'] = lineStyleOptions;
        }
        return lineOptions;
    }
    

    const initialiseLineSettings = () => {
        var lineOptionsArray = []
        for (let i = 0; i < lineCountRef.current; i++) {
            var lineOptions = getInitialisedLineOptions();
            lineOptionsArray.push(lineOptions);
        }

        updateLineSettings(lineOptionsArray)
        console.log('INITIALISING LINE SETTINGS: ', lineOptionsArray)
    }

    const updateCaptionSettings = (newCaptionSettings) => {
        isInternalUpdate.current = true;
        setCaptionSettings(newCaptionSettings)
    }

    const updateFormatOptions = (newFormatSettings) => {
        isInternalUpdate.current = true;
        setFormatOptions(newFormatSettings)
    }

    const updateTextSettings = (newTextSettings) => {
        isInternalUpdate.current = true;
        setTextSettings(newTextSettings)
    }

    const updateLineSettings = (newLineSettings) => {
        isInternalUpdate.current = true;
        setLineSettings(newLineSettings)
    }
    
    const updateActiveTextSettings = (newActiveTextSettings) => {
        isInternalUpdate.current = true;
        setActiveTextSettings(newActiveTextSettings)
    }

    const showLineSettings = () => {
        const mode = 'style'
        console.log('SHOW LINES: ', lineSettings)
        console.log('LINE COUNT: ', captionConfig.lineCount)
        var lineSettingsArray = []
        var lines = [
            { id: 0, text: 'This is sample sentence 1' },
            { id: 1, text: 'This is sample sentence 2' }
        ]
        for (let i = 0; i < captionConfig.lineCount; i++) {
            lineSettingsArray.push(<LineSettings idx={i} mode={mode} info={lines[i]} captionRef={captionRef} captionConfig={captionConfig} refreshCaptionState={refreshCaptionState} lineSettings={lineSettings} setLineSettings={updateLineSettings} />)
        }
        console.log('LINE ARRAY: ', lineSettingsArray)
        return lineSettingsArray;
    }

    // const showLineSettings = (mode) => {
    //     console.log('SHOW LINES: ', lineSettings)
    //     console.log('LINE COUNT: ', captionConfig.lineCount)
    //     var lineSettingsArray = []
    //     var lines = [
    //         { id: 0, text: 'This is sample sentence 1' },
    //         { id: 1, text: 'This is sample sentence 2' }
    //     ]
    //     for (let i = 0; i < captionConfig.lineCount; i++) {
    //         lineSettingsArray.push(<LineSettings idx={i} mode={mode} info={lines[i]} captionRef={captionRef} captionConfig={captionConfig} refreshCaptionState={refreshCaptionState} lineSettings={lineSettings} setLineSettings={updateLineSettings} />)
    //     }
    //     return lineSettingsArray;
    // }

    const publishCaptions = () => {
        dispatch(setCaptionConfigModalActive({isActive: true}))
    }

    const openCaptionCollection = () => {
        dispatch(setCaptionCollectionModalActive({isActive: true}))
    }
 
    return (
        <>
            {
                captionConfig &&
                <div style={{height: '100%', display: 'flex', flexDirection: 'column', fontSize: 14, fontFamily: 'Roboto Mono'}}>
                    {/* {captionInfo.id} */}
                    <div style={{  flex: 1, paddingBottom: 50, paddingRight: 10, overflowY: 'scroll', backgroundColor: '#fff', marginRight: 10 }}>

                        <div style={{ width: '98%', fontSize: '0.8rem', margin: '10px auto', padding: 10, paddingLeft: 20,  border: '1px solid #AEEAF3', borderLeft: '8px solid #AEEAF3', backgroundColor: '#fff', borderRadius: 10 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: 1, justifyContent: 'start', fontSize: 18, fontWeight: 700, width: '95%', margin: 'auto', color: '#424242' }}>
                                    Settings
                                </div>
                            </div>
                            {
                                formatOptions &&
                                <FormatSettings captionRef={captionRef} captionInfo={captionInfo} refreshCaptionState={refreshCaptionState} formatOptions={formatOptions} setFormatOptions={updateFormatOptions} />
                            }

                        </div>

                        <div style={{ width: '98%', fontSize: '0.8rem', margin: '10px auto', padding: 10, paddingLeft: 20,  border: '1px solid #BCDAFF', borderLeft: '8px solid #BCDAFF', backgroundColor: '#fff', borderRadius: 10 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: 1, justifyContent: 'start', fontSize: 18, fontWeight: 700, width: '95%', margin: 'auto', color: '#424242' }}>
                                    Text Style
                                </div>
                            </div>
                            {
                                textSettings &&
                                <TextSettings captionRef={captionRef} captionConfig={captionConfig} refreshCaptionState={refreshCaptionState} textSettings={textSettings} setTextSettings={updateTextSettings} />

                            }

                        </div>

                        <div style={{ width: '98%', fontSize: '0.8rem', margin: '10px auto', padding: 10, paddingLeft: 20, border: '1px solid #f2cc8f', borderLeft: '6px solid #f2cc8f', backgroundColor: '#fff', borderRadius: 10 }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ flex: 1, fontSize: 18, fontWeight: 700, width: '95%', margin: 'auto', color: '#424242' }}>
                                    Lines
                                </div>
                                <div style={{ flex: 2, display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                    <EnableSwitch target='lines' value={enabler.lines} setEnabler={setEnabler} />

                                </div>
                            </div>


                            <div style={{ display: enabler.lines ? 'block' : 'none' }}>
                                {
                                    lineSettings && showLineSettings()
                                }
                            </div>
                        </div>

                        {
                            activeTextSettings &&
                            <ActiveTextSettings
                                captionRef={captionRef}
                                captionConfig={captionConfig} 
                                refreshCaptionState={refreshCaptionState}
                                activeTextSettings={activeTextSettings}
                                setActiveTextSettings={updateActiveTextSettings}
                            />
                        }
                       
                    </div>
                    <div style={{display: 'flex', justifyContent: 'end', width: '100%', padding: 15, borderTop: '1px solid #eee', backgroundColor: '#fff'}}>
                        {/* <div className='action-btn-primary' style={{fontSize: '0.9rem'}} onClick={openCaptionCollection}>
                            Collection 
                        </div> */}
                       
                       {
                           captionBtnLock ? 
                            <div className='action-btn-primary' style={{fontSize: '0.9rem', opacity: 0.6}}>
                                Published
                            </div> : 
                            <div className='action-btn-primary' style={{fontSize: '0.9rem'}} onClick={publishCaptions}>
                                Publish 
                            </div>
                       }

                       
                    </div>

                </div>
               

            }
        </>
    )
}

export default CaptionSettings

