import React, { useState, useRef } from 'react'
import ColorPicker from './ColorPicker'
import LabeledSlider from './LabeledSlider'
import NumberInput from './NumberInput'


const BackgroundPanel = ({ boxOptions, setBoxOptions }) => {
    const isInternalUpdate = useRef()
    console.log('BOX OPTIONS ==> ', boxOptions)

    return (
        <>
            {
                boxOptions && 
                <div>
                    <div className='options-form-row'>
                        <div className='options-label'>
                            Color
                        </div>
                        <div className='options-edit-box'>
                            <ColorPicker fill={boxOptions?.fill} options={boxOptions} setOptions={setBoxOptions} />
                        </div>
                    </div>
                    <div className='options-form-row'>
                        <div className='options-label'>
                            Padding
                        </div>
                        <div className='options-edit-box'>
                            {/* <input type='number' style={{ width: '3rem', border: '1px solid #eee' }} /> */}
                            <NumberInput defaultValue={boxOptions?.padding ?? 0} options={boxOptions} setOptions={setBoxOptions} label='Padding' />
                        </div>
                    </div>
                    <div className='options-form-row'>
                        <div className='options-edit-box'>
                            <LabeledSlider
                                label='Roundness'
                                options={boxOptions}
                                setOptions={setBoxOptions}
                                defaultValue={boxOptions?.borderRadius ?? 0} />
                        </div>
                    </div>
                    <div className='options-form-row'>
                        <div className='options-edit-box'>
                            <LabeledSlider
                                label='Opacity'
                                options={boxOptions}
                                setOptions={setBoxOptions}
                                defaultValue={boxOptions?.opacity ?? 1} />
                        </div>
                    </div>
                </div>
      
            }
        </>
         )
}

export default BackgroundPanel
