import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import axios from 'axios'

const keywordCacheSlice = createSlice({
  name: "keywordCacheReducer",
  initialState: {
    keywordCache: {},
    loading: false,
    error: "",
  },
  reducers: {
    addKeywordCache(state, action) {
      state.keywordCache[action.payload.keyword] = action.payload.response;
    },
    refreshKeywordCache(state, action) {
      // state = { keywordCache: {}, loading: false, error: "" };
      state.keywordCache = {};
    },
  },
});

const { actions, reducer } = keywordCacheSlice;
export const { addKeywordCache, refreshKeywordCache } = actions;
export default reducer;

// It’s never easy to make purchases or drop off packages when you get busy with work, get stuck in traffic, or you might even end up forgetting about it completely.
// All you need to do is, Tell us where to go, what needs to be done and when. What happens next? Sit back, and let us worry about your task-at-hand. You could say that we are always on the move for you.
