import React, { useState, useEffect } from 'react'
import LetterCase from './LetterCase'
import Dropdown from './Dropdown';
import LabeledSlider from './LabeledSlider';


const AnimationPanel = ({ animInfo, setAnimInfo }) => {


    const animationStyles = [
        { label: 'Fade', value: 'fade' },
        { label: 'Pop', value: 'pop' },
        { label: 'Zoom', value: 'zoom' },
        { label: 'Pop Spin', value: 'popSpin' },
        { label: 'Zoom Spin', value: 'zoomSpin' },
        { label: 'Slide Up', value: 'slideUp' },
        { label: 'Slide Left', value: 'slideLeft' },
        { label: 'Slide Right', value: 'slideRight' },
        { label: 'Slide Down', value: 'slideDown' },
        { label: 'None', value: 'none' },
    ]

    const onAnimationStyleChange = (option) => {
        console.log('Changing Animation Type: ', option.value)
        setAnimInfo({ ...animInfo, type: option.value })
    }


    return (
        <>
            {
                animInfo && 
                <div>
                <div className='options-form-row'>
                    <div className='options-label'>
                        Style
                    </div>
                    <div className='options-edit-box'>
                        <div style={{ width: 150 }}>
                            <Dropdown
                                isSearchable={false}
                                menuSize={150}
                                placeHolder={animInfo?.type ?? 'None'}
                                options={animationStyles}
                                onChange={(value) => onAnimationStyleChange(value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='options-form-row'>
                    <div className='options-edit-box'>
                        <LabeledSlider
                            label='Speed'
                            options={animInfo}
                            setOptions={setAnimInfo}
                            defaultValue={animInfo?.speed ?? 100} />
                    </div>
                </div>
                <div className='options-form-row'>
                    <div className='options-edit-box'>
                        <LabeledSlider
                            label='Intensity'
                            options={animInfo}
                            setOptions={setAnimInfo}
                            defaultValue={animInfo?.intensity ?? 100} />
                    </div>
                </div>
            </div>
     
            }
        </>
           )
}

export default AnimationPanel
