import React, { useState, useEffect, useRef} from 'react'
import ColorPicker from './ColorPicker'
import NumberInput from './NumberInput'

const ShadowSettings = ({options, setOptions}) => {

    const isInternalUpdate = useRef()
    const [shadowOptions, setShadowOptions] = useState({});

    console.log('--> SHADOW INFO OPTIONS CHANGE::: ', options);

    useEffect(() => {
        isInternalUpdate.current = false;
        setShadowOptions(options.shadowInfo);
    }, [])

    useEffect(() => {
        if(isInternalUpdate.current){
            console.log('SHADOW SETTING CHANGED:: ', shadowOptions);
            if(shadowOptions){
                var shadowInfo = {
                    fill: shadowOptions?.fill,
                    blur: shadowOptions?.blur ?? 10,
                    offsetX: shadowOptions?.offsetX ?? 5,
                    offsetY: shadowOptions?.offsetY ?? 5
                };
                setOptions(prevValue => ({
                    ...prevValue,
                    shadowInfo: shadowInfo,
                }));
            }
        }
    }, [shadowOptions])

    const updateShadowOptions = (newShadowOptions) => {
        isInternalUpdate.current = true;
        setShadowOptions(newShadowOptions)
    }

    return (
        <>
            {
                shadowOptions && 
                <div>
                    <div className='options-form-row'>
                        <div className='options-label'>
                            Color
                        </div>
                        <div className='options-edit-box'>
                            <ColorPicker fill={shadowOptions?.fill} options={shadowOptions} setOptions={updateShadowOptions} />
                        </div>
                    </div>
                    <div className='options-form-row'>
                        <div className='options-label'>
                            Blur
                        </div>
                        <div className='options-edit-box'>
                            {/* <input type='number' style={{ width: '3rem', border: '1px solid #eee' }} /> */}
                            <NumberInput defaultValue={shadowOptions?.blur ?? 10} options={shadowOptions} setOptions={updateShadowOptions} label='ShadowBlur' />
                        </div>
                    </div>
                    <div className='options-form-row'>
                        <div className='options-label'>
                            Offset-X
                        </div>
                        <div className='options-edit-box'>
                            {/* <input type='number' style={{ width: '3rem', border: '1px solid #eee' }} /> */}
                            <NumberInput defaultValue={shadowOptions?.offsetX ?? 5} options={shadowOptions} setOptions={updateShadowOptions} label='ShadowOffsetX' />
                        </div>
                    </div>
                    <div className='options-form-row'>
                        <div className='options-label'>
                            Offset-Y
                        </div>
                        <div className='options-edit-box'>
                            {/* <input type='number' style={{ width: '3rem', border: '1px solid #eee' }} /> */}
                            <NumberInput defaultValue={shadowOptions?.offsetY ?? 5} options={shadowOptions} setOptions={updateShadowOptions} label='ShadowOffsetY' />
                        </div>
                    </div>
                </div>   
            }
        </>
        )
}

export default ShadowSettings
