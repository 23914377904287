import axios from "axios";

export const transcribeVideo = async (data) => {
  console.log("Calling Video Transcribe API with payload:", data);
  console.log(`${process.env.REACT_APP_SERVER_ENDPOINT}/transcribe`);

  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/transcribe`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    let result = response.data;
    console.log("API Response: ", result);
    return result;
  } catch (error) {
    console.log("Error Occurred");
    console.log(error);
    return { message: `Error: ${error}` };
  }
};
