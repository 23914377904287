import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "projectReducer",
  initialState: {
    captionInfo: null,
    videoInfo: {},
    assets: {},
    settings: {
      aspectRatio: "16/9",
      fps: 30,
    },
    timeline: [],
    actionLog: [],
    skipRanges: [],
    refreshCaptionState: false,
    menuIndexSelected: 0,
    isSettingsModalActive: false,
    isInputModalActive: false,
    isCaptionConfigModalActive: false,
    isCaptionCollectionModalActive: false,
    mediaPanelInfo: { isActive: false },
    captionBtnLock: false,
    loading: false,
  },
  reducers: {
    initNewState(state, action) {
      state.captionInfo = null;
      state.timeline = [];
      state.videoInfo = {};
      state.assets = {};
      state.skipRanges = [];
      state.actionLog = [];
      state.menuIndexSelected = 0;
      state.settings = {
        aspectRatio: "16/9",
        fps: 30,
      };
    },
    setMenuIndexSelected(state, action) {
      state.menuIndexSelected = action.payload.menuIndexSelected;
    },
    setRefreshCaptionState(state, action) {
      state.refreshCaptionState = action.payload.flag;
    },
    updateCaptionInfo(state, action) {
      state.captionInfo = action.payload.captionInfo;
    },
    updateVideoInfo(state, action) {
      state.videoInfo = action.payload.videoInfo;
    },
    updateSkipInfo(state, action) {
      state.skipRanges = action.payload.skipRanges;
    },
    updateTimeline(state, action) {
      state.timeline = action.payload.timeline;
    },
    updateActionLog(state, action) {
      state.actionLog = action.payload.actionLog;
    },
    updateLatestActionStatus(state, action) {
      state.actionLog[0].status = action.payload.status;
    },
    setAspectRatio(state, action) {
      state.settings.aspectRatio = action.payload.aspectRatio;
    },
    setInputModalActive(state, action) {
      state.isInputModalActive = action.payload.isActive;
    },
    setCaptionConfigModalActive(state, action) {
      state.isCaptionConfigModalActive = action.payload.isActive;
    },
    setCaptionCollectionModalActive(state, action) {
      state.isCaptionCollectionModalActive = action.payload.isActive;
    },
    setMediaPanelInfo(state, action) {
      state.mediaPanelInfo = action.payload.info;
    },
    setCaptionBtnLock(state, action) {
      state.captionBtnLock = action.payload.isActive;
    },
    updateSettings(state, action) {
      state.settings = action.payload.settings;
    },
    updateAssets(state, action) {
      state.assets = action.payload.assets;
    },
    clearAndApplyNewProject(state, action) {
      state.captionInfo = action.payload.captionInfo;
      state.timeline = action.payload.timeline;
      state.videoInfo = action.payload.videoInfo;
      state.settings = action.payload.settings;
      state.skipRanges = action.payload.skipRanges;
      state.assets = {};
      state.actionLog = [];
      state.menuIndexSelected = 0;
      state.refreshCaptionState = false;
      state.menuIndexSelected = 0;
      state.isSettingsModalActive = false;
      state.isInputModalActive = false;
      state.isCaptionConfigModalActive = false;
      state.isCaptionCollectionModalActive = false;
      state.mediaPanelInfo = { isActive: false };
      state.captionBtnLock = false;
      state.loading = false;
    },
  },
});

const { actions, reducer } = projectSlice;

export const {
  initNewState,
  setRefreshCaptionState,
  setMenuIndexSelected,
  updateCaptionInfo,
  updateVideoInfo,
  updateSkipInfo,
  updateTimeline,
  updateActionLog,
  updateLatestActionStatus,
  setAspectRatio,
  setInputModalActive,
  setCaptionConfigModalActive,
  setCaptionCollectionModalActive,
  setMediaPanelInfo,
  setCaptionBtnLock,
  updateSettings,
  updateAssets,
  clearAndApplyNewProject,
} = actions;

export default reducer;
