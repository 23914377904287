import { previewTextTimestampedData } from "../constants/common";
import { uploadCaptionStyleInfoToStore } from "../services/store";
import { renderPresetForCaptionStyle } from "../services/render";
import { store } from "../redux/store";
import { setCaptionBtnLock } from "../redux/projectSlice";

function getFallbackStyleOptions(captionSettings, lineIdx, mode = "style") {
  const textOptions = captionSettings.text;
  const lineOptionsArray = captionSettings.text?.lines;

  var fallbackFound = false;
  var flbkOptions;
  var flbkBoxOptions;

  if (lineOptionsArray) {
    const lineOptions = lineOptionsArray[lineIdx];
    if (lineOptions) {
      const lineOptionsStyle = lineOptions.options;
      const lineOptionsBoxStyle = lineOptions.boxOptions;

      flbkOptions = {
        ...lineOptionsStyle,
        fill: lineOptionsStyle?.fill,
        opacity: lineOptionsStyle?.opacity ?? 1,
      };

      if (lineOptionsBoxStyle) {
        flbkBoxOptions = {
          fill: lineOptionsBoxStyle?.fill,
          opacity: lineOptionsBoxStyle?.opacity,
          padding: lineOptionsBoxStyle?.padding,
          borderRadius: lineOptionsBoxStyle?.borderRadius,
        };
      } else {
        if (mode == "style") {
          flbkBoxOptions = {
            opacity: 1,
            padding: 0,
            borderRadius: 0,
          };
        }
      }

      fallbackFound = true;
    }
  }

  if (!fallbackFound && textOptions) {
    const textOptionsStyle = textOptions.options;
    const textOptionsBoxStyle = textOptions.boxOptions;

    flbkOptions = {
      ...textOptionsStyle,
      fill: textOptionsStyle?.fill,
      opacity: textOptionsStyle?.opacity ?? 1,
      scale: 1,
      scaleX: 1,
      scaleY: 1,
    };

    flbkBoxOptions = {
      fill: textOptionsBoxStyle?.fill,
      opacity: textOptionsBoxStyle?.opacity ?? 1,
      padding: textOptionsBoxStyle?.padding ?? 0,
      borderRadius: textOptionsBoxStyle?.borderRadius ?? 0,
    };
  }

  return { flbkOptions, flbkBoxOptions };
}

async function createAndUploadCaptionStyle(captionInfo) {
  /*
    1. Upload the Config to firebase in caption collection 
    2. Send the info for preset render processing
  */

  const { id, config } = captionInfo;
  // var captionInfoCopy = { ...captionInfo };
  // captionInfoCopy.config.topPos = null;
  // await uploadCaptionStyleInfoToStore(captionInfo);

  const context = {
    projectInfo: {
      transcript: previewTextTimestampedData,
      skipRanges: [],
    },
    captionInfo: captionInfo,
    renderMode: "caption-preview",
    timelineInfo: [],
  };

  const data = {
    payload: context,
    dims: {
      height: 405,
      width: 720,
    },
    totalFrameCount: 80,
    captionId: id,
  };

  console.log("DATA => ", data);

  var response = {};
  try {
    renderPresetForCaptionStyle(data);
    store.dispatch(setCaptionBtnLock({ isActive: true }));
    response.status = true;
    response.message = "Caption Config Uploaded Successfully!!";
  } catch (err) {
    console.log("ERROR:: ", err);
    response.status = false;
    response.message = "Error Occured while saving caption style";
  }

  return response;
}

function getTagsForCaptionStyle(config) {
  var tags = [];
  var { text, activeText, lineCount } = config;

  if (text.anim) {
    tags.push("animated");
  }
  if (text.lines) {
    text.lines.map((line) => {
      if (line.anim) {
        tags.push("animated");
      }
    });
  }
  if (lineCount > 1 && text.lines) {
    tags.push("multi-line");
  }
  if (activeText.lines) {
    tags.push("animated");
    tags.push("line-animated");
  }
  if (activeText.words) {
    tags.push("animated");
    tags.push("word-animated");
  }

  const uniqueTags = [...new Set(tags)];
  return uniqueTags;
}

export {
  getFallbackStyleOptions,
  createAndUploadCaptionStyle,
  getTagsForCaptionStyle,
};
