import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";
import { useIsFontListLoaded } from "./hooks/useIsFontListLoaded";
import Editor from "./components/Editor";
import RenderControl from "./components/RenderControl";
import TranscriptView from "./components/TranscriptView";
import Main from "./pages/Main";
import Auth from "./components/login/Auth";
import LoginWithEmail from "./components/login/LoginWithEmail";
import Logout from "./components/login/Logout";
import HomePage from "./pages/HomePage";
import ExportView from "./components/ExportView";
import PrivateRoute from "./components/PrivateRoutes";
import PricingPage from "./pages/PricingPage";
import Redirect from "./components/Redirect";

function App() {
  const isFontListLoaded = useIsFontListLoaded();

  useEffect(() => {
    if (isFontListLoaded) {
      window.fontsLoaded = true;
    }
  }, [isFontListLoaded]);

  return (
    isFontListLoaded && (
      <React.Fragment>
        <Router>
          <Routes>
            <Route path="/" element={<Main />} exact={true} />

            <Route path="/login" element={<Auth />} />
            <Route path="/login-with-email" element={<LoginWithEmail />} />

            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/checkout/new" element={<Redirect />} exact={true} />

            <Route
              path="/logout"
              element={
                <PrivateRoute>
                  <Logout />
                </PrivateRoute>
              }
            />

            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <HomePage activeMenuIndex={0} />
                </PrivateRoute>
              }
            />

            <Route
              path="/edit/:projectId"
              element={
                <PrivateRoute>
                  <Editor />
                </PrivateRoute>
              }
            />
            {/* <Route path="/transcript" element={<TranscriptView />} /> */}
            <Route path="/render" element={<RenderControl />} />
            <Route
              path="/export/:jobId"
              element={
                <PrivateRoute>
                  <ExportView />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </React.Fragment>
    )
  );
}

export default App;
