import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ToggleSwitch from '../ui/ToggleSwitch'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import uuid from 'react-uuid';
import { setCaptionBtnLock } from '../../redux/projectSlice';
import { createAndUploadCaptionStyle , getTagsForCaptionStyle} from '../../utils/captions';
import { Timestamp } from "firebase/firestore";
import { generateRandom8DigitNumber } from '../../utils/commonUtils';

const CaptionConfigUploader = ({closeModal}) => {

    const captionInfo = useSelector(
        (state) => state.projectReducer.captionInfo
    )

    const [creatorId, setCreatorId] = useState();
    const [title, setTitle] = useState(generateRandom8DigitNumber());
    const [switchConfig, setSwitchConfig] = useState({visibility: true})

    const handleInput = (e) => {
        const value = e.target.value
        setCreatorId(value)
    }  

    const handleTitleChange = (e) => {
        const value = e.target.value
        setTitle(value)
    }


    const saveCaption = () => {
        
    }

    const createCaption = () => {
        const captionID = uuid();
        const captionConfig = captionInfo.config;

        const captionSettings = {
            id: captionID,
            title: title,
            config: captionConfig,
            access: switchConfig.visibility,
            tags: getTagsForCaptionStyle(captionConfig),
            userId: 'ORXANU5lHMWG4tz1uszuhaWqWXl2',
            creatorId: 'Diveo Creative',
            created: Timestamp.now(),
            featured: false,
            score: 1
        }

        createAndUploadCaptionStyle(captionSettings);
        closeModal()
    }

    return (
        <div style={{width: 500, margin: 10, padding: 10}}>
            <h5>Publish Captions</h5> 

            <form style={{width: 500, float: 'left', marginTop: 20, marginBottom: 10 }}>
                <div className='form-row' style={{border: 'none'}}>
                    <div className='label' style={{fontSize: 15, fontWeight: 500, color: '#424242' }}> 
                        Visibility
                    </div>
                    <div className='edit-box' >
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{flex: 1, margin: 3, fontSize: 15,}}>Private</div> 
                            <ToggleSwitch target='visibility' value={switchConfig.visibility} setSwitchConfig={setSwitchConfig}/>  
                            <div style={{flex: 1, margin: 3, fontSize: 15,}}>Public</div>
                        </div>
                        
                    </div>
                </div> 
                <div className='form-row' style={{border: 'none'}}>
                    <div className='label' style={{fontSize: 15, fontWeight: 500, color: '#424242' }}> 
                       Caption Title
                      
                    </div>
                    <div className='edit-box'>
                        <input 
                            type='text' 
                            name='name' 
                            placeholder='Enter title...' 
                            onChange={handleTitleChange}
                            value={title} 
                            style={{width: '12rem', fontSize: 14, padding:5, borderRadius: 5, border: '1px solid #eee'}}>
                        </input>
                    </div>
                </div> 
                {/* <div className='form-row' style={{border: 'none'}}>
                    <div className='label' style={{fontSize: 15, fontWeight: 500, color: '#424242' }}> 
                        Creator ID 
                        <Tooltip title="Author of the caption design. This needs to be set only once.">
                            <IconButton>
                                <InfoIcon style={{ fontSize: 20 }}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className='edit-box'>
                        <input 
                            type='text' 
                            name='name' 
                            placeholder='Enter here...' 
                            onChange={handleInput}
                            value={creatorId} 
                            style={{width: '12rem', fontSize: 14, padding:5, borderRadius: 5, border: '1px solid #eee'}}>
                        </input>
                    </div>
                </div>  */}
                
                

                <div style={{width: '100%', marginTop: 20}}>
                   
                    <div class='action-btn-primary' style={{marginTop: 20, fontSize: '0.85rem', marginRight: 0}} onClick={createCaption}>
                        Create New
                    </div>
                    {
                        switchConfig?.visibility && 
                            <div class='action-btn-secondary' style={{marginTop: 20, fontSize: '0.85rem', marginRight: 15}} onClick={saveCaption}>
                                Save Existing
                            </div>
                    }
                    
                </div>

            </form>
        </div>
    )
}

export default CaptionConfigUploader
