import React, { useState, useRef, useEffect } from 'react'

import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';

var animationFrameId;
var frameControl;

function formatTime(seconds) {
  // Ensure the input is a non-negative number
  if (seconds < 0 || !Number.isFinite(seconds)) {
      throw new Error("Input must be a non-negative number representing seconds.");
  }

  // Convert seconds to milliseconds for precise calculations
  const totalMilliseconds = seconds * 1000;

  // Calculate minutes, seconds, and milliseconds
  const minutes = Math.floor(totalMilliseconds / 60000);
  const remainingSeconds = Math.floor((totalMilliseconds % 60000) / 1000);
  const millis = ((totalMilliseconds % 1000) / 1000).toFixed(2).slice(2); // Extract up to 2 decimal points

  // Format as mm:ss:SS (e.g., 02:05:12)
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  const formattedMillis = millis.padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}:${formattedMillis}`;
}

  
const VideoPlayback = ({ duration, setCurrTime, startRender, setPlaying}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlayEnabled, setPlayEnabled] = useState(false)
  const currentTimeRef = useRef(0);
  const sliderRef = useRef(null);
  const isDragging = useRef(false);

  const progressRef = useRef(false);
  const timerRef = useRef(false);

  const playRef = useRef(false);
  const renderRef = useRef(null);

  const handleMouseDown = (e) => {
    isDragging.current = true;
    updateTime(e);
  };

  const handleMouseMove = (e) => {
    if (isDragging.current) {
      updateTime(e);
    }
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const updateTime = (e) => {
    const slider = sliderRef.current;
    const rect = slider.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const newTime = (offsetX / rect.width) * duration;
    setCurrentTime(Math.max(0, Math.min(newTime, duration)));
  };

  useEffect(() => {
    currentTimeRef.current = currentTime;
    setCurrTime(currentTime)

  }, [currentTime])

  useEffect(() => {
    const handleMouseUpGlobal = () => handleMouseUp();
    window.addEventListener('mouseup', handleMouseUpGlobal);
    window.addEventListener('mousemove', handleMouseMove);

    renderRef.current = renderProgressAnim();
    return () => {
      window.removeEventListener('mouseup', handleMouseUpGlobal);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // useEffect(() => {
  //   if(playRef.current){
  //     setPlayEnabled(true)
  //   }else{
  //     setPlayEnabled(false)
  //   }
  // }, [playRef.current])

  // useEffect(() => {
  //   if(isPlayEnabled && renderRef.current){
  //     renderRef.current.end();
  //   }else{
  //     renderRef.current.start();
  //   }
    
  // }, [isPlayEnabled])

  const renderAnim = async () => {
    // await startRender();
    await  startRender()
    if(playRef.current){
      playRef.current = false;
      
      setPlayEnabled(false)
      renderRef.current.end()
    }else{
      playRef.current = true;
      setPlayEnabled(true)
     
      renderRef.current.start()
    }
  
  }

  const setPlay = (value) => {
    setPlayEnabled(value)
    setPlaying(value);
  }

  const renderProgressAnim = () => {
    let renderInitTime = performance.now() / 1000;
    let startTime = currentTimeRef.current;
    let lastTimestamp = 0;

    function render(timestamp){
      animationFrameId = requestAnimationFrame(render);
      if (timestamp - lastTimestamp < 1000 / 30) return;

      const time = performance.now() / 1000;
      const elapsedTime = time - renderInitTime;

      currentTimeRef.current = startTime + elapsedTime
      
      progressRef.current.style.width = `${(currentTimeRef.current / duration) * 100}%`;
      timerRef.current.innerHTML = formatTime(currentTimeRef.current);

      if(elapsedTime >= duration){
        cancelAnimationFrame(animationFrameId);
        animationFrameId = null;
        setPlaying(false);
        return;
      }

      lastTimestamp = timestamp;
    }

    return {
      start(){
        lastTimestamp = 0;
        renderInitTime = performance.now() / 1000;
        startTime = currentTimeRef.current;
        setPlaying(true);
        if(animationFrameId){
          cancelAnimationFrame(animationFrameId);
        }
        animationFrameId = requestAnimationFrame(render);
      },
      end(){
        if(animationFrameId){
          cancelAnimationFrame(animationFrameId);
          setPlaying(false);
          animationFrameId = null;
          
        }
      }
    }
   
  }

  return (
    <div className="video-slider-container">
      <div
        className="slider"
        ref={sliderRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
      >
        <div
          className="progress-bar"
          ref={progressRef}
          style={{ width: `${(currentTimeRef.current / duration) * 100}%` }}
        ></div>
      </div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 5, marginBottom: 5}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{cursor: "pointer", marginRight: 5}}>
            <span onClick={renderAnim}>
                {
                    isPlayEnabled ?
                        <PauseRoundedIcon style={{ fontSize: 35 }} />
                        : <PlayArrowRoundedIcon style={{ fontSize: 35 }} />
                }
            </span>

          </div>
          <div style={{height: '100%', display: 'flex',  alignItems: 'center', justifyContent: 'start', fontFamily: 'monospace'}}>
            <div ref={timerRef} style={{marginRight: 5}}>{formatTime(currentTimeRef.current)}</div> / <div style={{marginLeft: 5}}> {formatTime(duration)}</div>
        
          </div>
        </div>
        {/* <div style={{}}>
          Re
        </div> */}
        
        
      </div>
     
     
    </div>
  );
}

export default VideoPlayback
